import { useLazyQuery } from '@apollo/client';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
import purple from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';
import yellow from '@material-ui/core/colors/yellow';
import gql from 'graphql-tag';
import * as React from 'react';
// @ts-ignore
import { fade } from '@material-ui/core/styles';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { parse } from 'query-string';
import { buildVariables } from 'ra-data-hasura';
import { useLocation } from 'react-router-dom';
import { AppraisalStatus, StatusMap, statusMap as statusMapStatic } from 'shared/constants/appraisalStatus';
import introspectionResult from 'shared/dataProvider/instrospection';
import { Appraisal_Statuses, Appraisals_Aggregate } from 'shared/generated/types';
import { formatCurrency, formatUSNumber } from 'shared/utils';
import { Appraisals } from 'views/Appraisal/types';

const APPRAISAL_STATUS_QUERY = gql`
  query AppraisalStatusAggregates($where: appraisals_bool_exp) {
    appraisals_aggregate(where: $where) {
      aggregate {
        count
        sum {
          report_fee
          quote_fee
        }
      }
    }
    appraisal_statuses {
      id
      status
      order
      appraisals_aggregate(where: $where) {
        aggregate {
          count
          sum {
            report_fee
            quote_fee
          }
        }
      }
    }
  }
`;
type AppraisalStatusAggregatesQueryResponse = {
  appraisal_statuses: Appraisal_Statuses[];
  appraisals_aggregate: Appraisals_Aggregate;
};

export enum BackGroundType {
  Cell,
  Tab,
}
export type GetBackgroundColor = (statusId: number, backgroundType: BackGroundType) => string;
export type GetColor = (statusId: string) => string;
const appraisalResource = introspectionResult.resources.find((e: any) => e.type.name === 'appraisals');

function useAppraisalRowStyle(listLoading?: boolean) {
  const location = useLocation();
  const qs = parse(location.search);
  const variables = buildVariables(null)(appraisalResource, 'GET_LIST', {
    filter: omit(JSON.parse(qs.filter || '{}'), 'appraisal_status_id'),
  });
  const response = useLazyQuery<AppraisalStatusAggregatesQueryResponse>(APPRAISAL_STATUS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [getAppraisalStatus, { data, loading }] = response;

  React.useEffect(() => {
    if (!listLoading) {
      getAppraisalStatus({
        variables: pick(variables, 'where'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listLoading]);

  const statusMap = React.useMemo(() => {
    if (!data || loading || listLoading) {
      return statusMapStatic;
    } else {
      const { appraisal_statuses } = data;
      return appraisal_statuses.reduce<StatusMap>(
        (acc, el) => ({
          ...acc,
          [`${el.id ?? ''}`]: {
            name: el.status as AppraisalStatus,
            count: el?.appraisals_aggregate?.aggregate?.count
              ? formatUSNumber(el?.appraisals_aggregate?.aggregate?.count)
              : 0,
            order: el.order ?? 0,
            aggregate: el?.order
              ? el.order === 1
                ? el?.appraisals_aggregate?.aggregate?.sum?.quote_fee
                  ? formatCurrency(el?.appraisals_aggregate?.aggregate?.sum?.quote_fee)
                  : ''
                : el?.appraisals_aggregate?.aggregate?.sum?.report_fee
                ? formatCurrency(el?.appraisals_aggregate?.aggregate?.sum?.report_fee)
                : ''
              : '',
          },
        }),
        {
          '0': {
            name: AppraisalStatus.All,
            count: data?.appraisals_aggregate?.aggregate?.count
              ? formatUSNumber(data?.appraisals_aggregate?.aggregate?.count)
              : 0,
            order: 0,
            aggregate: data?.appraisals_aggregate?.aggregate?.sum?.report_fee
              ? formatCurrency(data?.appraisals_aggregate?.aggregate?.sum?.report_fee)
              : '',
          },
        },
      );
    }
  }, [data, listLoading, loading]);

  const getColor = (statusId: string) => getColorMapping(statusMap[statusId]?.name);
  const getBackgroundColor: GetBackgroundColor = (statusId, type = BackGroundType.Tab) =>
    getBackgroundColorMapping(statusMap[statusId]?.name, type);

  return [
    {
      statusMap,
      loading,
    },
    {
      rowStyle,
      getColor,
      getBackgroundColor,
    },
  ] as const;
  function rowStyle(record: Appraisals, index: number): any {
    const color = getBackgroundColor(record?.appraisal_status_id || 0, BackGroundType.Tab);
    if (!color) {
      return {};
    }
    return {
      borderLeftColor: color,
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  }
}

export function getColorMapping(status: AppraisalStatus): string {
  switch (status) {
    case AppraisalStatus.Request:
      return orange[500];
    case AppraisalStatus.Canceled:
      return red[500];
    case AppraisalStatus.InProgress:
      return green[500];
    case AppraisalStatus.Submitted:
      return blue[500];
    case AppraisalStatus.InReview:
      return purple[500];
    case AppraisalStatus.Revision:
      return blue[500];
    case AppraisalStatus.Completed:
      return teal[500];
    case AppraisalStatus.OnHold:
      return '#d4af37';
    default:
      return '';
  }
}

export function getBackgroundColorMapping(status?: AppraisalStatus, type: BackGroundType = BackGroundType.Tab): string {
  switch (status) {
    case AppraisalStatus.Canceled:
    case AppraisalStatus.InProgress:
    case AppraisalStatus.Request:
    case AppraisalStatus.Submitted:
    case AppraisalStatus.InReview:
    case AppraisalStatus.Revision:
      return type === BackGroundType.Cell ? fade(getColorMapping(status), 0.08) : getColorMapping(status);
    case AppraisalStatus.OnHold:
      return type === BackGroundType.Cell ? fade(yellow[500], 0.1) : getColorMapping(status);
    case AppraisalStatus.Completed:
      return type === BackGroundType.Cell ? '#e0f2f1' : teal[500];
    default:
      return fade(grey['A400'], 0.4);
  }
}

export default useAppraisalRowStyle;

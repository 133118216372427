/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  TextInput,
  required,
  useRefresh,
  useGetIdentity,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid';
import { Card, CardHeader, Divider, Typography, Box, InputAdornment, Checkbox } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import styles from '../hooks/useContactPopupStyles';
import { Assignee_Roles, User_Profiles } from 'shared/generated/types';
import { useAssigneeRoleOptions, useAssigneesOptions } from 'shared/hooks/useAppraisalOptions';
import AutocompleteInput from 'shared/components/AutocompleteInput';
import { useAssigneeCommission, useGenericCommissionQuery } from 'shared/hooks/useAppraisalFees';
import { formatDecimalNumber } from 'shared/utils';
import { DeleteOutlined } from '@material-ui/icons';

type AddCommissionProps = {
  appraisal?: any;
  active?: boolean;
  clientId?: string;
  onSave?: any;
  reportFee?: any;
  refetchCommission?: any;
  expenses?: any;
};
interface State {
  quantity: number;
  rate: number;
}

function AddCommissionButton({
  appraisal,
  active = true,
  clientId,
  onSave,
  reportFee,
  refetchCommission,
  expenses,
}: AddCommissionProps) {
  const popUpClasses = styles();
  const autocompleteRef: any = useRef(null);

  const [showDialog, setShowDialog] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isFlat, setFlat] = useState(true);
  const [isGross, setGross] = useState(true);
  const [subtractExpensesBefore, setSubtractExpensesBefore] = useState(true);
  const [values, setValues] = useState<State>({
    quantity: 1,
    rate: 0,
  });
  // const [percentage, setPercentage] = useState({
  //   gross: 0,
  //   net: 0,
  // });
  const [percentage, setPercentage] = useState<any>(0);
  const [hasFetchedAssignee, setHasFetchedAssignee] = useState(false);
  const [assigneeId, setAssigneeId] = useState('');
  const [roleId, setRoleId] = useState(0);
  const [hasRoleId, setHasRoleId] = useState(false);
  const [customExpenses, setCustomExpenses] = useState<any>([]);
  const [removedExpenses, setRemovedExpenses] = useState<any>([]);
  const [addExpense, setAddExpense] = useState<string>('');

  const { identity } = useGetIdentity();
  const [assigneeOptions] = useAssigneesOptions();
  const [getAssigneeRoleOptions, { data: assigneeRoleOptions }] = useAssigneeRoleOptions();

  const assignees = useMemo(() => {
    if (showDialog) {
      let options = assigneeOptions.data?.assignees ?? [];
      if (identity?.role === 'appraisal_firm_limited_access') {
        options = options.filter((e) => e.id === identity?.id);
      }
      getAssigneeRoleOptions();
      return options;
    }
  }, [identity, assigneeOptions, showDialog]);

  const assigneeRoles = useMemo(() => {
    if (showDialog) {
      let options = assigneeRoleOptions?.assignee_roles ?? [];
      return options;
    }
  }, [assigneeRoleOptions, showDialog]);

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangePercentage = (prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    // setPercentage({ ...percentage, [prop]: event.target.value });
    setPercentage(event.target.value);
  };

  const { templatePropertyId, residentialId, commercialId, template, templateDefault } = {
    templatePropertyId: appraisal?.property?.property_type_id,
    residentialId:
      appraisal?.property?.property_type_id === 1 ? appraisal?.property?.residential_ownership_type_id : null,
    commercialId: appraisal?.property?.property_type_id === 2 ? appraisal?.property?.commercial_property_type_id : null,
    template: true,
    templateDefault: true,
  };

  const [getAssigneeData, { data: commissionData1 }] = useAssigneeCommission({
    assigneeId,
    roleId,
    templatePropertyId,
    residentialId,
    commercialId,
    template,
  });

  const [getAssigneeData2, { data: commissionData2 }] = useGenericCommissionQuery({
    assigneeId,
    template,
    templateDefault,
  });

  const templateData: any = useMemo(() => {
    if (showDialog && assigneeId) {
      if (commissionData1?.appraisal_commission?.length) {
        return commissionData1?.appraisal_commission[0];
      } else {
        getAssigneeData2();
        return '';
      }
    }
  }, [showDialog, commissionData1]);

  const defaultTemplateData: any = useMemo(() => {
    if (showDialog || !appraisal) {
      if (commissionData2?.appraisal_commission?.length) {
        return commissionData2?.appraisal_commission[0];
      } else return '';
    }
  }, [showDialog, commissionData2]);

  useEffect(() => {
    if (assigneeId && hasFetchedAssignee) {
      let temp = templateData ? templateData : defaultTemplateData;
      temp?.rate_type_id === 2 ? setFlat(true) : setFlat(false);

      if (temp?.rate_type_id === 1) {
        setFlat(false);
        setGross(temp?.net_expenses ? false : true);
        // setPercentage({
        //   gross: formatDecimalNumber(temp?.rate * 100, 2),
        //   net: formatDecimalNumber(temp?.rate * 100, 2),
        // });
        setPercentage(formatDecimalNumber(temp?.rate * 100, 2));
        setValues({
          quantity: temp?.quantity,
          rate: temp?.rate,
        });
      } else {
        setFlat(true);
        setValues({
          quantity: temp?.quantity,
          rate: temp?.rate,
        });
      }
    }
  }, [templateData, defaultTemplateData]);

  const [create, { loading }] = useCreate('appraisal_commission');
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
    moveToolbarBelow();
  };

  const handleCloseClick = () => {
    setShowDialog(false);
    setHasFetchedAssignee(false);
  };

  const moveToolbarBelow = () => {
    setTimeout(() => {
      const toolbar = document.querySelector('#notes-popup .ql-toolbar') as any;
      const container = document.querySelector('#notes-popup .ql-container') as any;
      const editor = document.querySelector('#notes-popup .ql-editor') as any;
      if (toolbar && container && editor) {
        container.append(toolbar);
        container.style.border = 'none';
        editor.style.border = '1px solid rgba(0, 0, 0, 0.12)';
        editor.style.borderRadius = '3px';
      }
    }, 2);
  };

  const handleSubmit = async (values: any) => {
    delete values.total_amount;
    const values2 = {
      ...values,
      // rate: formatDecimalNumber(isGross ? percentage?.gross / 100 : percentage?.net / 100, 2),
      rate: formatDecimalNumber(percentage / 100, 2),
      appraisal_expense_ids: customExpenses?.map((each: any) => each?.id),
      expenses_after: !subtractExpensesBefore,
    };
    setDisabled(true);
    if (appraisal) {
      create(
        {
          payload: {
            data: values.rate_type_id === 2 ? values : values2,
          },
        },
        {
          onSuccess: () => {
            refetchCommission();
            setShowDialog(false);
            setHasFetchedAssignee(false);
            setDisabled(false);
            notify('appraisal_commission.created');
            refresh();
          },
          onFailure: ({ error }: any) => {
            setDisabled(false);
            notify(error.message, 'error');
          },
        },
      );
      setShowDialog(false);
      setDisabled(false);
      setAssigneeId('');
      refresh();
    } else {
      let full_name = assignees?.find((each) => each?.id === values?.assignee_user_account_id)?.full_name;
      let role = assigneeRoles?.find((each) => each?.id === values?.assignee_role_id)?.role;
      const newCommissionData = {
        ...(values.rate_type_id === 2 ? values : values2),
        assignee_full_name: full_name,
        assignee_role: role,
      };
      onSave(newCommissionData);
      setShowDialog(false);
      setDisabled(false);
      setAssigneeId('');
      setHasFetchedAssignee(false);
    }
  };

  const handleAssigneeSelect = (val: string) => {
    setAssigneeId(val);
    setHasFetchedAssignee(true);
    if (val) {
      appraisal
        ? getAssigneeData()
        : getAssigneeData2({
            variables: {
              assigneeId: val,
              template,
              templateDefault,
            },
          });
    }
  };

  const handleRoleSelect = (val: number) => {
    setRoleId(val);
    setHasRoleId(val ? true : false);
    if (appraisal) {
      getAssigneeData();
      setHasFetchedAssignee(true);
    }
  };
  let dataObj: any;
  if (hasFetchedAssignee || !appraisal) {
    if (assigneeId) {
      dataObj = templateData ? templateData : defaultTemplateData;
    }
  }
  useEffect(() => {
    if (dataObj?.assignee_role_id) {
      setHasRoleId(true);
    }
  }, [dataObj]);

  useEffect(() => {
    return () => setAddExpense('');
  }, []);

  useMemo(() => {
    expenses?.length > 0 && setCustomExpenses(expenses);
  }, [expenses]);

  useEffect(() => {
    if (addExpense === '' && autocompleteRef.current) {
      autocompleteRef.current.querySelector('input').blur();
    }
  }, [addExpense]);

  const GridContainer = ({ title, value, hasDivider = false }: any) => (
    <Grid
      container
      direction="row"
      md={7}
      sm={12}
      justify="space-between"
      alignItems="center"
      className={popUpClasses.commissionGrid}
      style={hasDivider ? { borderBottom: '1px solid #e2e2e2', paddingBottom: '4px' } : {}}
    >
      <Typography>{title}</Typography>
      <Typography>{value}</Typography>
    </Grid>
  );

  const handleRemoveExpense = (removedData: any) => {
    setAddExpense('');
    setCustomExpenses(customExpenses.filter((each: any) => each?.id !== removedData?.id));
    setRemovedExpenses((prevState: any) => [...prevState, removedData]);
  };

  const handleAddExpense = (value: any) => {
    if (value) {
      setAddExpense(value);
      setCustomExpenses((prevState: any) => [...prevState, removedExpenses?.find((each: any) => each?.id === value)]);
      setRemovedExpenses(removedExpenses.filter((each: any) => each?.id !== value));
      setAddExpense('');
      // Unfocus and blur the input field
      setTimeout(() => {
        if (autocompleteRef.current) {
          const inputElement = autocompleteRef.current.querySelector('input');
          if (inputElement) {
            inputElement.blur(); // Remove focus
            inputElement.setAttribute('tabindex', '-1'); // Remove the ability to refocus with Tab
          }
        }
      }, 0);
    }
  };

  const VerticalLayoutGrid = ({ title, input, applyMargin = false }: any) => (
    <Grid
      container
      direction="row"
      md={7}
      sm={12}
      justify="space-between"
      style={!applyMargin ? { marginBottom: '-7px' } : {}}
      className={popUpClasses.commissionGrid}
    >
      <Typography className={popUpClasses.labelMargin}>{title}</Typography>
      <Grid item md={3} sm={5} xs={7} alignItems="center">
        {input}
      </Grid>
    </Grid>
  );

  const percentageSplit = () => (
    <Grid
      container
      direction="row"
      md={7}
      sm={12}
      justify="space-between"
      alignItems="center"
      className={popUpClasses.commissionGrid}
    >
      <Typography>PERCENTAGE SPLIT</Typography>
      <Grid item md={3} sm={5} xs={7}>
        <TextInput
          validate={required()}
          source={'percentage'}
          variant="outlined"
          type="number"
          // value={percentage?.net}
          value={percentage}
          onChange={handleChangePercentage('net')}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label={false}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Button onClick={handleClick} label="ADD ASSIGNEE" disabled={!active}>
        <IconContentAdd />
      </Button>
      <Dialog fullWidth={true} maxWidth={'md'} open={showDialog} onClose={handleCloseClick} aria-label="Add Assignee">
        <FormWithRedirect
          initialValues={{
            appraisal_id: appraisal?.id,
            assignee_role_id: dataObj?.assignee_role_id,
            client_id: clientId,
            rate_type_id: isFlat ? 2 : 1,
            net_expenses: !isGross && !isFlat ? true : false,
            quantity: dataObj ? dataObj?.quantity : 0,
            rate: dataObj ? dataObj?.rate : 0,
            percentage: dataObj ? formatDecimalNumber(dataObj?.rate * 100, 2) : 0,
            // total_amount: appraisal
            //   ? (appraisal?.total_fees * (percentage?.gross / 100)).toFixed(2)
            //   : (reportFee * (percentage?.gross / 100)).toFixed(2),
            total_amount: appraisal
              ? (appraisal?.total_fees * (percentage / 100)).toFixed(2)
              : (reportFee * (percentage / 100)).toFixed(2),
            // appraisal_expense_ids: customExpenses?.map((each: any) => each?.id),
            // expenses_after: !subtractExpensesBefore,
          }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving, ...rest }: any) => (
            <DialogContent classes={{ root: popUpClasses.dialogContent }}>
              <Card variant="outlined" className={popUpClasses.formBottom}>
                <CardHeader
                  title="Add Assignee & Set Commission"
                  classes={{ root: popUpClasses.cardHeader }}
                ></CardHeader>
                <Divider></Divider>
                <Box className={popUpClasses.formContainer}>
                  <Typography classes={{ root: popUpClasses.heading }}>ASSIGNEE</Typography>
                  <Divider classes={{ root: popUpClasses.divider }}></Divider>

                  <Grid container direction="row">
                    <Grid item md={6} sm={8} xs={12}>
                      <AutocompleteInput
                        autoFocus
                        openOnFocus
                        label="Assignee"
                        fullWidth
                        variant="outlined"
                        source="assignee_user_account_id"
                        optionText={(record: User_Profiles) => record?.full_name}
                        choices={assignees}
                        onChange={handleAssigneeSelect}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item md={6} sm={8} xs={12}>
                      <AutocompleteInput
                        label="Role"
                        fullWidth
                        variant="outlined"
                        source="assignee_role_id"
                        optionText={(record: Assignee_Roles) => record?.role}
                        choices={assigneeRoles}
                        onChange={handleRoleSelect}
                      />
                    </Grid>
                  </Grid>

                  <Typography classes={{ root: popUpClasses.heading }}>COMMISSION</Typography>
                  <Divider classes={{ root: popUpClasses.divider }}></Divider>

                  <Grid container direction="row" style={{ marginBottom: '32px' }}>
                    <Button
                      label="Flat"
                      onClick={() => setFlat(true)}
                      variant={isFlat ? 'contained' : 'outlined'}
                      style={{ borderRadius: '24px', marginRight: '16px', paddingRight: '16px' }}
                    />
                    <Button
                      label="Percentage of Total Fees"
                      onClick={() => setFlat(false)}
                      variant={isFlat ? 'outlined' : 'contained'}
                      style={{ borderRadius: '24px', paddingRight: '16px' }}
                    />
                  </Grid>
                  {isFlat ? (
                    ''
                  ) : (
                    <Grid container direction="row" style={{ marginBottom: '32px' }}>
                      <Button
                        label="Gross Expenses"
                        onClick={() => setGross(true)}
                        variant={isGross ? 'contained' : 'outlined'}
                        style={{ borderRadius: '24px', marginRight: '16px', paddingRight: '16px' }}
                      />
                      <Button
                        label="Net Expenses"
                        onClick={() => setGross(false)}
                        variant={isGross ? 'outlined' : 'contained'}
                        style={{ borderRadius: '24px', paddingRight: '16px' }}
                      />
                    </Grid>
                  )}
                  <Grid container direction="row" justify="space-between">
                    {isFlat ? (
                      <>
                        <VerticalLayoutGrid
                          title="QUANTITY"
                          input={
                            <TextInput
                              validate={required()}
                              source={'quantity'}
                              variant="outlined"
                              type="number"
                              value={values?.quantity}
                              onChange={handleChange('quantity')}
                              fullWidth
                              label={false}
                            />
                          }
                        />

                        <VerticalLayoutGrid
                          title="RATE"
                          input={
                            <TextInput
                              validate={required()}
                              value={values?.rate}
                              onChange={handleChange('rate')}
                              source="rate"
                              variant="outlined"
                              type="number"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              fullWidth
                              label={false}
                            />
                          }
                        />

                        <VerticalLayoutGrid
                          title="FEE"
                          input={
                            <Typography style={{ paddingTop: '12.5px', textAlign: 'right' }}>
                              $
                              {values?.rate
                                ? isFlat
                                  ? (values?.rate * values?.quantity)?.toFixed(2)
                                  : ((values?.rate / 100) * appraisal?.total_fees)?.toFixed(2)
                                : 0}
                            </Typography>
                          }
                          applyMargin={true}
                        />
                      </>
                    ) : isGross ? (
                      <>
                        <VerticalLayoutGrid
                          title="TOTAL FEES"
                          input={
                            <Typography style={{ padding: '12.5px 0px 16.5px 0px', textAlign: 'right' }}>
                              ${appraisal ? appraisal?.total_fees?.toFixed(2) : reportFee}
                            </Typography>
                          }
                          applyMargin={true}
                        />

                        <VerticalLayoutGrid
                          title="PERCENTAGE"
                          input={
                            <TextInput
                              validate={required()}
                              value={percentage}
                              onChange={handleChangePercentage('gross')}
                              source={'percentage'}
                              variant="outlined"
                              type="number"
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                              fullWidth
                              label={false}
                            />
                          }
                        />

                        <VerticalLayoutGrid
                          title="COMMISSION"
                          input={
                            <Typography style={{ paddingTop: '10.5px', textAlign: 'right' }}>
                              $
                              {appraisal
                                ? (appraisal?.total_fees * (percentage / 100)).toFixed(2)
                                : (reportFee * (percentage / 100)).toFixed(2)}
                            </Typography>
                          }
                          applyMargin={true}
                        />
                      </>
                    ) : (
                      <>
                        <GridContainer
                          title="Subtract Expenses before Commission calculation?"
                          value={
                            <Checkbox
                              onChange={(e) => {
                                setSubtractExpensesBefore(e.target.checked);
                              }}
                              style={{ color: '#2196F3' }}
                              checked={!!subtractExpensesBefore}
                            />
                          }
                        />
                        <GridContainer
                          title="TOTAL FEES"
                          value={`$${appraisal ? appraisal?.total_fees?.toFixed(2) : reportFee}`}
                        />
                        {!subtractExpensesBefore && (
                          <>
                            {percentageSplit()}
                            <GridContainer
                              title="FEES BEFORE EXPENSES"
                              // value={`$${(appraisal?.total_fees * (percentage?.net / 100))?.toFixed(2)}`}
                              value={`$${(appraisal?.total_fees * (percentage / 100))?.toFixed(2)}`}
                            />
                          </>
                        )}
                        {expenses?.length > 0 && (
                          <>
                            {customExpenses?.length > 0 && (
                              <>
                                <Grid container style={{ marginBottom: '10px' }}>
                                  EXPENSE
                                </Grid>

                                {customExpenses?.map((each: any) => (
                                  <GridContainer
                                    title={
                                      <Grid alignItems="center">
                                        <Button
                                          onClick={() => handleRemoveExpense(each)}
                                          title="Remove Expense"
                                          className={popUpClasses.delIcon}
                                        >
                                          <DeleteOutlined
                                            style={{
                                              color: '#434445',
                                              fontSize: '22px',
                                            }}
                                          />
                                        </Button>
                                        {each?.description}
                                      </Grid>
                                    }
                                    value={`$${each?.total_amount?.toFixed(2)}`}
                                  />
                                ))}
                              </>
                            )}
                            {removedExpenses?.length > 0 && (
                              <Grid container direction="row" style={{ marginBottom: '10px' }}>
                                <Grid item md={5} sm={8} xs={12}>
                                  <AutocompleteInput
                                    label="+ Select Expenses"
                                    fullWidth
                                    variant="outlined"
                                    source="delId"
                                    optionText={(record: any) => record?.description}
                                    choices={removedExpenses}
                                    onChange={handleAddExpense}
                                    value={addExpense || null}
                                    ref={autocompleteRef}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            {customExpenses?.length > 0 && appraisal && (
                              <>
                                <GridContainer
                                  hasDivider={true}
                                  title="TOTAL EXPENSES"
                                  // value={`-$${appraisal?.total_expenses?.toFixed(2)}`}
                                  value={`-$${customExpenses
                                    ?.reduce((sum: any, expense: any) => sum + expense?.total_amount, 0)
                                    .toFixed(2)}`}
                                />
                                {subtractExpensesBefore && (
                                  <GridContainer
                                    title="FEES AFTER EXPENSES"
                                    value={`$${(
                                      appraisal?.total_fees -
                                      customExpenses
                                        ?.reduce((sum: any, expense: any) => sum + expense?.total_amount, 0)
                                        .toFixed(2)
                                    )?.toFixed(2)}`}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                        {subtractExpensesBefore && <>{percentageSplit()}</>}
                        <GridContainer
                          title="COMMISSION"
                          // value={`$${
                          //   appraisal
                          //     ? subtractExpensesBefore
                          //       ? (
                          //           (appraisal?.total_fees -
                          //             customExpenses
                          //               ?.reduce((sum: any, expense: any) => sum + expense?.total_amount, 0)
                          //               .toFixed(2)) *
                          //           ((percentage?.net ? percentage?.net : percentage?.gross) / 100)
                          //         ).toFixed(2)
                          //       : (
                          //           appraisal?.total_fees *
                          //             ((percentage?.net ? percentage?.net : percentage?.gross) / 100) -
                          //           customExpenses?.reduce((sum: any, expense: any) => sum + expense?.total_amount, 0)
                          //         ).toFixed(2)
                          //     : (reportFee * ((percentage?.net ? percentage?.net : percentage?.gross) / 100)).toFixed(2)
                          // }`}
                          value={`$${
                            appraisal
                              ? subtractExpensesBefore
                                ? (
                                    (appraisal?.total_fees -
                                      customExpenses
                                        ?.reduce((sum: any, expense: any) => sum + expense?.total_amount, 0)
                                        .toFixed(2)) *
                                    (percentage / 100)
                                  ).toFixed(2)
                                : (
                                    appraisal?.total_fees * (percentage / 100) -
                                    customExpenses?.reduce((sum: any, expense: any) => sum + expense?.total_amount, 0)
                                  ).toFixed(2)
                              : (reportFee * (percentage?.net / 100)).toFixed(2)
                          }`}
                        />
                      </>
                    )}
                  </Grid>

                  <Divider classes={{ root: popUpClasses.divider }}></Divider>

                  <DialogActions classes={{ root: popUpClasses.editActions }}>
                    <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
                      <IconCancel />
                    </Button>
                    <SaveButton
                      handleSubmitWithRedirect={handleSubmitWithRedirect}
                      saving={saving}
                      disabled={loading || disabled || !assigneeId || !hasRoleId}
                    />
                  </DialogActions>
                </Box>
              </Card>
            </DialogContent>
          )}
        />
      </Dialog>
    </>
  );
}
export default AddCommissionButton;

import { Filter, FilterProps } from 'react-admin';
import { displayFields, Field } from 'shared/components/Resource/List';
import { filterFields } from './fields';
import { useFilterStyles } from 'shared/components/Resource/styles';
import React, { useCallback, useEffect, useState } from 'react';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import { useClientsSearch } from 'shared/hooks/useClientOptions';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { useCustomStyles } from 'shared/components/Resource/SearchInput';
import { debounce } from 'lodash';

export default (props: Omit<FilterProps, 'children'>) => {
  const classes = useFilterStyles();

  const [qs, onChangeParams] = useLocationQuery();
  const filters = qs.filter ? JSON.parse(qs.filter) : {};
  let displayFilterFields: Field[] = filterFields;
  const [getClienttsFullTextSearch, { data }] = useClientsSearch();

  // const key = 'organization_name,organization_name';
  // const value = filters[key];

  const [value, setValue] = useState('');
  const searchInputClass = useCustomStyles();

  const handleSearch = (val: string) => {
    getClienttsFullTextSearch({ variables: { keyword: val } });
  };
  const debounceFullTextSearch = useCallback(debounce(handleSearch, 0), []);

  useEffect(() => {
    const filter = { ...filters };
    if (value) {
      debounceFullTextSearch(value);
    } else {
      delete filter['id,id'];
    }
    onChangeParams({
      filter: JSON.stringify(filter),
      search: value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!data) return;
    const { search_clients } = data;
    if (search_clients?.length !== 0) {
      const ids = search_clients.map((item) => item.id);

      const idField = filters['id,id'];

      const oldIds = idField?.value._in || [];

      const filter = {
        ...filters,
        'id,id': { format: 'raw-query', value: { _in: ids } },
      };

      if (JSON.stringify(ids) !== JSON.stringify(oldIds)) {
        onChangeParams({
          filter: JSON.stringify(filter),
        });
      }
    } else if (search_clients?.length === 0) {
      const filter = {
        ...filters,
        'id,id': { format: 'raw-query', value: { _in: [] } },
      };
      onChangeParams({
        filter: JSON.stringify(filter),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return displayFields(Filter, { ...props, classes } as FilterProps, [
    {
      autoFocus: true,
      type: 'SearchInput',
      label: 'Search Clients',
      value,
      onChange: (e: any) => setValue(e.target.value),
      fullWidth: true,
      custom: true,
      alwaysOn: true,
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            {value ? (
              <IconButton onClick={() => setValue('')} size="small">
                <Close fontSize="small" />
              </IconButton>
            ) : (
              <IconButton>
                <SearchIcon color="disabled" />
              </IconButton>
            )}
          </InputAdornment>
        ),
      },
      className: searchInputClass.input,
      variant: 'filled',
      size: 'small',
      handleReset: () => setValue(''),
    },
    ...displayFilterFields,
  ]);
};

import { useMutation } from '@apollo/client';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Cancel, Check, CheckCircle, Delete, ErrorOutline, FileCopy, Save } from '@material-ui/icons';
import Pizzly from 'pizzly-js';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, ResourceComponentInjectedProps, SimpleForm, useGetIdentity } from 'react-admin';

import { elavatedRoles } from 'shared/constants/roles';
import { Integrations, Integration_Webhook } from 'shared/generated/types';
import { simpleFormContainer } from 'shared/hooks/useEditFormStyle';
import {
  ADD_INTEGRATION_AUTHENTICATION,
  DELETE_INTEGRATION_AUTHENTICATION,
  DELETE_INTEGRATION_WEBHOOK_QUERY,
  INSERT_INTEGRATION_WEBHOOK_QUERY,
  UPDATE_INTEGRATION_WEBHOOK_QUERY,
  useIntegrationResponse,
} from 'shared/hooks/useIntegrationsQuery';
import useProfileOptions from 'shared/hooks/useProfileOptions';
import { simpleCopyToClipboard, urlPatternValidation } from 'shared/utils';
import styles from 'views/Client/components/hooks/useContactListStyles';
import PageLoader from '../PageLoader';

const PIZZLY_HOSTNAME = process.env.REACT_APP_PIZZLY_HOSTNAME;
const PIZZLY_PUBLISHABLE_KEY = process.env.REACT_APP_PIZZLY_PUBLISHABLE_KEY;
const PIZZLY_SETUP_ID_GOOGLE_CALENDAR = process.env.REACT_APP_PIZZLY_SETUP_ID_GOOGLE_CALENDAR;
const WEBHOOK_SECURITY_LINK = 'https://www.appraisalinbox.com/docs/guides/integrations/send-webhooks';
function UserIntegrationPage(props: ResourceComponentInjectedProps) {
  const pizzly = new Pizzly({
    host: PIZZLY_HOSTNAME,
    publishableKey: PIZZLY_PUBLISHABLE_KEY,
  });

  const googleCalendar = pizzly.integration('google-calendar', {
    setupId: PIZZLY_SETUP_ID_GOOGLE_CALENDAR,
  });

  const identityState = useGetIdentity();
  const tableClasses = styles();
  const classes = simpleFormContainer();

  const [isOpen, setOpen] = useState(false);
  const [rowItem, setRowItem] = useState<Integrations>();

  const [showDialog, setShowDialog] = useState(false);
  const [formData, setFormData] = useState<Integration_Webhook | undefined>();
  const [errorMessage, setErrorMessage] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [{ loading }] = useProfileOptions({ user_account_id: (identityState?.identity?.id as string) || '' });
  const [integrationResponse] = useIntegrationResponse();

  const [addIntegration] = useMutation(ADD_INTEGRATION_AUTHENTICATION, {
    onCompleted: () => {
      integrationResponse.refetch();
    },
  });

  const [deleteIntegration] = useMutation(DELETE_INTEGRATION_AUTHENTICATION, {
    onCompleted: () => {
      integrationResponse.refetch();
      setOpen(false);
    },
  });

  const [insertIntegrationWebhook] = useMutation(INSERT_INTEGRATION_WEBHOOK_QUERY, {
    onCompleted: (insertIntegrationData) => {
      integrationResponse.refetch();
      setFormData(insertIntegrationData?.insert_integration_webhook_one);
    },
  });
  const handleQueryComplete = () => {
    integrationResponse.refetch();
    setFormData(undefined);
    setShowDialog(false);
    setShowDeleteModal(false);
  };
  const [updateIntegrationWebhook] = useMutation(UPDATE_INTEGRATION_WEBHOOK_QUERY, {
    onCompleted: handleQueryComplete,
  });

  const [deleteIntegrationWebhook] = useMutation(DELETE_INTEGRATION_WEBHOOK_QUERY, {
    onCompleted: handleQueryComplete,
  });

  const hasAccessToSetConnection = useMemo(() => {
    if (elavatedRoles.includes(identityState.identity?.role)) {
      return true;
    }
    return false;
  }, [identityState]);

  useEffect(() => {
    if (formData?.webhook_url) {
      const isValidUrl = urlPatternValidation(formData.webhook_url);
      if (isValidUrl) {
        setErrorMessage('');
      } else {
        setErrorMessage('Url is not valid');
      }
    }
    setShowDialog(formData ? true : false);
  }, [formData]);

  const handleCloseClick = () => {
    setFormData(undefined);
    setShowDialog(false);
    setShowDeleteModal(false);
  };

  const handleSubmit = () => {
    if (formData) {
      updateIntegrationWebhook({
        variables: {
          ...formData,
        },
      });
    }
  };

  const handleDeleteClick = (id: string) => {
    deleteIntegrationWebhook({ variables: { id } });
  };

  const checkConnection = (integrationId: any) => {
    if (integrationResponse.data?.integration_authentication.length === 0) return false;

    return integrationResponse.data?.integration_authentication.find((item) => item.integration_id === integrationId)
      ? true
      : false;
  };

  const onConnect = (integration: any) => {
    googleCalendar
      .connect()
      .then(({ authId }) => {
        addIntegration({
          variables: {
            integrationId: integration.id,
            integrationLevel: integration.integration_level,
            authId: authId,
          },
        });
      })
      .catch(console.error);
  };
  const onDelete = (integrationId: any) => {
    deleteIntegration({
      variables: {
        integrationId: integrationId,
      },
    });
  };

  if (identityState.loading || loading || integrationResponse.loading) {
    return <PageLoader />;
  }

  return (
    <SimpleForm {...props} toolbar={<span />} margin="none">
      <Box p={0} className={classes.formContainerOrganization}>
        <Table>
          <TableHead classes={{ root: tableClasses.tableHead }}>
            <TableRow>
              <TableCell classes={{ root: tableClasses.headCell }}>INTEGRATION</TableCell>
              <TableCell classes={{ root: tableClasses.headCell }}>DESCRIPTION</TableCell>
              <TableCell classes={{ root: tableClasses.headCell }}>ACTIVE</TableCell>
              <TableCell classes={{ root: tableClasses.headCell }}>CONNECTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrationResponse?.data?.integrations.map((row, idx: number) => {
              let isActive = 'Yes';

              if (row.id === 1) {
                isActive = checkConnection(row.id) ? 'Yes' : 'No';
              }

              if (row.id === 3) {
                if (integrationResponse.data?.integration_webhook) {
                  const webHook = integrationResponse.data?.integration_webhook.find(
                    (item) => item.integration_id === row.id,
                  );
                  if (webHook && webHook.active) {
                    isActive = 'Yes';
                  } else {
                    isActive = 'No';
                  }
                }
              }

              return (
                <TableRow key={idx}>
                  <TableCell component="th" scope="row" classes={{ root: tableClasses.cell }}>
                    {row.name}
                  </TableCell>
                  <TableCell classes={{ root: tableClasses.cell }}>{row.description}</TableCell>
                  <TableCell classes={{ root: tableClasses.cell }}>{isActive}</TableCell>
                  <TableCell classes={{ root: tableClasses.cell }}>
                    {row.id === 1 && (
                      <>
                        {checkConnection(row.id) ? (
                          <Button
                            onClick={() => {
                              setOpen(true);
                              setRowItem({ id: row.id, name: row.name });
                            }}
                            variant="outlined"
                            style={{ lineHeight: 'normal' }}
                            label="Disconnect from Google Calendar"
                          ></Button>
                        ) : (
                          <Button
                            onClick={() => onConnect(row)}
                            label="Connect to Google Calendar"
                            style={{ lineHeight: 'normal', color: '#434445' }}
                          >
                            <img src="/icons/btn_google.svg" alt="Connect to Google Calendar" />
                          </Button>
                        )}
                      </>
                    )}
                    {row.id === 3 && hasAccessToSetConnection && (
                      <Button
                        label="SET DESTINATION URL AND VIEW SECRET"
                        variant="outlined"
                        style={{ lineHeight: 'normal' }}
                        onClick={() => {
                          const webHook = integrationResponse.data?.integration_webhook.find(
                            (integration_webhook) => integration_webhook.integration_id === row.id,
                          );

                          if (webHook) {
                            setFormData(webHook);
                          } else {
                            insertIntegrationWebhook({
                              variables: {
                                integration_id: row.id,
                              },
                            });
                          }
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Modal open={isOpen} onClose={() => setOpen(false)}>
          <Box className={classes.modal}>
            <Typography classes={{ root: classes.modalHeading }}>Disconnect Integration: {rowItem?.name}</Typography>
            <Typography classes={{ root: classes.modalBody }}>
              Are you sure you want to disconnect this integration?
            </Typography>
            <Box className={classes.modalActions}>
              <Button
                onClick={() => setOpen(false)}
                label="Cancel"
                size="large"
                color="default"
                startIcon={<ErrorOutline />}
              ></Button>
              <Button
                onClick={() => onDelete(rowItem?.id)}
                label="Confirm"
                color="primary"
                size="large"
                startIcon={<CheckCircle />}
              ></Button>
            </Box>
          </Box>
        </Modal>

        <Dialog open={showDialog} onClose={handleCloseClick} aria-label="Add Integration">
          <DialogContent>
            <Box m={2}>
              <Box m={1}>
                <Typography variant={'h6'}>Send Webhook Configuration</Typography>
                <Divider></Divider>
              </Box>

              <Box m={1} my={1}>
                <Box mt={2}>
                  <Typography variant={'subtitle1'}>DESTINATION</Typography>
                  <Divider></Divider>
                  <Box my={3}>
                    <TextField
                      label={'URL'}
                      error={!!errorMessage}
                      helperText={errorMessage}
                      size="small"
                      name="webhook_url"
                      value={formData?.webhook_url}
                      required
                      fullWidth
                      variant="outlined"
                      onChange={(e) => {
                        setFormData((prev) => prev && { ...prev, webhook_url: e.target.value });
                      }}
                    />
                  </Box>
                </Box>
                <Box mb={1}>
                  <Typography variant={'subtitle1'}>SECURITY</Typography>
                  <Divider></Divider>
                  <Box mt={3} mb={1}>
                    <TextField
                      label={'Signing Secret'}
                      name="signing_secret"
                      value={formData?.signing_secret}
                      size="small"
                      required
                      fullWidth
                      variant="outlined"
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                formData?.signing_secret && simpleCopyToClipboard(formData?.signing_secret);
                              }}
                            >
                              <FileCopy />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Typography variant="body2">
                    Use the Signing Secret to verity that requests are coming form Appraisal Inbox and nobody else.
                    Learn more:{' '}
                    <Link
                      href={WEBHOOK_SECURITY_LINK}
                      variant="body2"
                      underline="hover"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Webhook Security
                    </Link>
                  </Typography>
                </Box>
                <Divider></Divider>
              </Box>

              <Box display={'flex'} justifyContent={'space-between'} my={2}>
                <Button
                  label="ra.action.delete"
                  onClick={() => {
                    setShowDialog(false);
                    setShowDeleteModal(true);
                  }}
                >
                  <Delete />
                </Button>

                <Box display={'flex'} m={1}>
                  <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
                    <Cancel />
                  </Button>
                  <Box ml={1}>
                    <Button
                      label="ra.action.save"
                      onClick={handleSubmit}
                      variant={'contained'}
                      disabled={!formData?.webhook_url || !!errorMessage}
                    >
                      <Save />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>

        <Modal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box className={classes.paper}>
            <Typography classes={{ root: classes.heading }}>Delete Webhook Integration?</Typography>
            <p>Are you sure you want to Delete this webhook integration?</p>
            <Box className={classes.confirmBtnBox}>
              <Button
                onClick={() => {
                  setShowDeleteModal(false);
                  setShowDialog(true);
                }}
                color="secondary"
                startIcon={<ErrorOutline />}
                label={'Cancel'}
              />
              <Button
                onClick={() => handleDeleteClick(formData?.id)}
                color="primary"
                startIcon={<Check />}
                label={'Confirm'}
              />
            </Box>
          </Box>
        </Modal>
      </Box>
    </SimpleForm>
  );
}

export default UserIntegrationPage;

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { ContactActivities } from 'views/Contact/types';

const QUERY = gql`
  query ContactActivitySummary($contactId: uuid, $offset: Int, $limit: Int) {
    contact_activity_summary(
      args: { _contact_id: $contactId }
      offset: $offset
      limit: $limit
      order_by: { commit_timestamp: desc }
    ) {
      activity
      activity_id
      appraisal_file_number
      appraisal_location_address
      appraisal_status
      changes
      commit_timestamp
      contact_ids
      notes
      organization_id
      timestamp_group_by
      type
      user_account_id
      user_account_name
    }
  }
`;

export type ContactActivitySummaryResponse = {
  contact_activity_summary: ContactActivities[];
};
export function useContactActivitySummary(props: { contactId: string; offset: number; limit: number }) {
  const variables = { ...props, contactId: props.contactId };
  return useQuery<ContactActivitySummaryResponse>(QUERY, { fetchPolicy: 'cache-first', variables });
}

const AGG_QUERY = gql`
  query ContactActivitySummaryAgg($contactId: uuid) {
    contact_activity_summary_aggregate(args: { _contact_id: $contactId }) {
      aggregate {
        count
      }
    }
  }
`;

export type ContactActivitySummaryAggResponse = {
  contact_activity_summary_aggregate: { aggregate: { count: number } };
};

export function useContactActivitySummaryAgg(props: { contactId: string }) {
  return useQuery<ContactActivitySummaryAggResponse>(AGG_QUERY, {
    fetchPolicy: 'cache-first',
    variables: { contactId: props.contactId },
  });
}

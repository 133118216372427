import { Box, Divider, ThemeProvider, Typography } from '@material-ui/core';
import { Loading, NotFound } from 'ra-ui-materialui';
import React, { useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';

import { authProvider } from 'shared/AuthProvider';
import theme from 'shared/constants/theme';
import { StepperForm } from './StepperForm';
import { useGetOrganization } from './queries';

const Orders = () => {
  const { id } = useParams<{ id: string }>();
  const redirect = useRedirect();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data: organizationData, loading: organizationLoading } = useGetOrganization(!isAuthenticated);
  useEffect(() => {
    const setJwt = async () => {
      try {
        setIsLoading(true);
        const data = await authProvider.setExternalToken(id);
        if (data.jwt_token) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
        redirect('/account/login');
      }
      setIsLoading(false);
    };
    setJwt();
  }, [id, redirect]);

  if (isLoading || organizationLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <NotFound />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box minHeight={'100vh'} display="flex" flexDirection="column">
        <Box>
          <Box textAlign={'center'} py={3}>
            <Typography variant="h5">Appraisal Order Form</Typography>
          </Box>
          <Divider />
        </Box>
        <Box display="flex" flex={1}>
          <Box flex={1} p={2}>
            <Typography variant="h6">{organizationData?.organization[0].name}</Typography>
            <Typography>{organizationData?.organization[0].location_address}</Typography>
            <Typography>{organizationData?.organization[0].phone_number}</Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={3} px={3}>
            <StepperForm />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Orders;

import React, { useCallback, useEffect, useState } from 'react';
import { Filter, FilterProps } from 'react-admin';
import { displayFields, Field } from 'shared/components/Resource/List';
import { useFilterStyles } from 'shared/components/Resource/styles';
import { useContactsSearch } from 'shared/hooks/useContactOptions';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import filterFields from './fields';

import { Close, Search } from '@material-ui/icons';
import { useCustomStyles } from 'shared/components/Resource/SearchInput';
import { IconButton, InputAdornment } from '@material-ui/core';
import { debounce } from 'lodash';

const idKey = 'id,id';

function ListFilter(props: Omit<FilterProps, 'children'>) {
  const classes = useFilterStyles();
  const [qs, onChangeParams] = useLocationQuery();
  const filters = qs.filter ? JSON.parse(qs.filter) : {};
  let displayFilterFields: Field[] = filterFields;
  const [getContactsFullTextSearch, { data }] = useContactsSearch();

  // const key = 'client_name,client_name';
  // const value = filters[key];

  const [value, setValue] = useState('');
  const searchInputClass = useCustomStyles();

  const handleSearch = (val: string) => {
    getContactsFullTextSearch({ variables: { keyword: val } });
  };
  const debounceFullTextSearch = useCallback(debounce(handleSearch, 0), []);

  useEffect(() => {
    let filter = { ...filters };

    if (value) {
      debounceFullTextSearch(value);
    } else {
      delete filter[idKey];
    }
    onChangeParams({
      filter: JSON.stringify(filter),
      search: value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const { search_contacts } = data;
    if (search_contacts?.length !== 0) {
      const ids = search_contacts.map((item) => item.id);

      const idField = filters[idKey];

      const oldIds = idField?.value._in || [];

      const filter = {
        ...filters,
        [idKey]: { format: 'raw-query', value: { _in: ids } },
      };

      if (JSON.stringify(ids) !== JSON.stringify(oldIds)) {
        onChangeParams({
          filter: JSON.stringify(filter),
        });
      }
    } else {
      const filter = {
        ...filters,
        [idKey]: { format: 'raw-query', value: { _in: [] } },
      };
      onChangeParams({
        filter: JSON.stringify(filter),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return displayFields(Filter, { ...props, classes } as FilterProps, [
    {
      autoFocus: true,
      type: 'SearchInput',
      label: 'Search Contacts',
      value,
      onChange: (e: any) => setValue(e.target.value),
      fullWidth: true,
      custom: true,
      alwaysOn: true,
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            {value ? (
              <IconButton onClick={() => setValue('')} size="small">
                <Close fontSize="small" />
              </IconButton>
            ) : (
              <IconButton>
                <Search color="disabled" />
              </IconButton>
            )}
          </InputAdornment>
        ),
      },
      className: searchInputClass.input,
      variant: 'filled',
      size: 'small',
      handleReset: () => setValue(''),
    },
    ...displayFilterFields,
  ]);
}

export default ListFilter;

import { makeStyles } from '@material-ui/core/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import truncate from 'lodash/truncate';
import React from 'react';
import { FieldProps, Record } from 'react-admin';
import ReactResizeDector from 'react-resize-detector';
import useLocationQuery from 'shared/hooks/useLocationQuery';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    minHeight: '46px',
    minWidth: '150px',
    flexDirection: 'column',
  },
});
type WithLocation = Record & {
  location_address?: string;
};
function AddressField(props: FieldProps<WithLocation>) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const classes = useStyles();
  const address = props.record?.location_address ?? '';
  const addresses = address.split(',');
  const [street] = addresses;
  const restAddress = addresses.slice(1).join(', ');

  const [qs] = useLocationQuery();
  const search = qs.search || '';

  return (
    <div ref={ref}>
      <ReactResizeDector targetRef={ref}>
        {({ width }: { width: number; height: number }) => {
          const streetCurrentValue = formatAddress(street, width);
          const restAddressCurrentValue = formatAddress(restAddress, width);

          const streetMatchData: any = streetCurrentValue && match(streetCurrentValue, search);
          const restMatchData: any = restAddressCurrentValue && match(restAddressCurrentValue, search);

          const partsData = {
            street: streetCurrentValue ? parse(streetCurrentValue, streetMatchData) : [],
            rest: restAddressCurrentValue ? parse(restAddressCurrentValue, restMatchData) : [],
          };

          return (
            <div className={classes.root}>
              <span>
                {partsData?.street?.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 800 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                {addresses.length > 1 && ','}
              </span>
              <span>
                {partsData?.rest?.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 800 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </span>
            </div>
          );
        }}
      </ReactResizeDector>
    </div>
  );
}

function formatAddress(address: string, width: number): string {
  if ((width && width > 200) || address.length < 19) {
    return address;
  }
  return truncate(address, { length: 19 });
}

export default AddressField;

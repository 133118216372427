import { gql, useQuery } from '@apollo/client';

const COUNTS_QUERY = gql`
  query CommissionAggregates {
    all_count: commissions_aggregate {
      aggregate {
        count
        sum {
          total_amount
        }
      }
    }
    unpaid_count: commissions_aggregate(where: { paid_date: { _is_null: true } }) {
      aggregate {
        count
        sum {
          total_amount
        }
      }
    }
    paid_count: commissions_aggregate(where: { paid_date: { _is_null: false } }) {
      aggregate {
        count
        sum {
          total_amount
        }
      }
    }
  }
`;

type COUNT = 'all_count' | 'paid_count' | 'unpaid_count';

type CommissionAggregatesResponse = {
  [x in COUNT]: { aggregate: { count: number } };
};
export const useGetCommissionCounts = () => {
  const { data } = useQuery<CommissionAggregatesResponse>(COUNTS_QUERY, { fetchPolicy: 'cache-and-network' });
  return {
    all_count: data?.all_count.aggregate,
    paid_count: data?.paid_count.aggregate,
    unpaid_count: data?.unpaid_count.aggregate,
  };
};

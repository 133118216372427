import { useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Appraisal_Fee, Appraisal_Expense, Expenses, Appraisal_Commissions } from 'shared/generated/types';

const FEE_QUERY = gql`
  query AppraisalFees($appraisalId: uuid) {
    appraisal_fee(where: { appraisal_id: { _eq: $appraisalId } }) {
      id
      appraisal_id
      quantity
      rate
      rate_type_id
      total_amount
      description
      appraisal {
        report_fee
        total_fees
        id
      }
    }
  }
`;
const EXPENSE_QUERY = gql`
  query AppraisalExpenses($appraisalId: uuid) {
    appraisal_expense(where: { appraisal_id: { _eq: $appraisalId } }) {
      id
      appraisal_id
      description
      quantity
      rate
      rate_type_id
      total_amount
      appraisal {
        report_fee
        total_expenses
        id
      }
    }
  }
`;
const COMMISSION_QUERY = gql`
  query AppraisalCommissions($appraisalId: uuid) {
    appraisal_commissions(where: { appraisal_id: { _eq: $appraisalId } }, order_by: { total_amount: desc }) {
      id
      appraisal_id
      net_expenses
      quantity
      rate
      rate_type_id
      rate_type
      assignee_user_account_id
      assignee_role_id
      assignee_role
      assignee_full_name
      paid_date
      total_amount
      template
      template_commercial_property_type_id
      template_default
      template_property_type_id
      template_residential_form_type_ids
      template_residential_ownership_type_id
      organization_id
      appraisal_expense_ids
      expenses_after
    }
  }
`;

const ASSIGNEE_COMMISSION_DEFAULT_QUERY = gql`
  query AppraisalCommissions($assigneeId: uuid, $template: Boolean, $templateDefault: Boolean) {
    appraisal_commission(
      where: {
        user_account_id: { _eq: $assigneeId }
        template: { _eq: $template }
        template_default: { _eq: $templateDefault }
      }
    ) {
      id
      appraisal_id
      net_expenses
      quantity
      rate
      assignee_user_account_id
      assignee_role_id
      paid_date
      rate_type_id
      total_amount
      template
      template_commercial_property_type_id
      template_default
      template_property_type_id
      template_residential_form_type_ids
      template_residential_ownership_type_id
      organization_id
    }
  }
`;

const EXPENSES_QUERY = gql`
  query AppraisalCommissions {
    expenses {
      appraisal_file_number
      description
      quantity
      rate
      rate_type_id
      total_amount
      expense_date
      expense_type
      appraisal_id
      user_account_id
    }
  }
`;

export function useExpenses() {
  const options = useQuery<Expenses>(EXPENSES_QUERY, {
    fetchPolicy: 'cache-first',
  });
  return [options] as const;
}

export default function useAppraisalFee(props: { appraisalId: string }) {
  const variables = { ...props };
  return useQuery<Appraisal_Fee>(FEE_QUERY, { nextFetchPolicy: 'cache-first', variables });
}

export function useAppraisalExpense(props: { appraisalId: string }) {
  const options = useQuery<Appraisal_Expense>(EXPENSE_QUERY, {
    fetchPolicy: 'cache-first',
    variables: props,
  });
  return [options] as const;
}

export function useAppraisalCommission(props: { appraisalId: string }) {
  return useLazyQuery<Appraisal_Commissions>(COMMISSION_QUERY, {
    fetchPolicy: 'cache-first',
    variables: props,
  });
}

interface AssigneeCommissionResponse {
  appraisal_commission: Array<[]>;
}
export function useAssigneeCommission(props: {
  assigneeId: string;
  roleId: number;
  templatePropertyId: number;
  residentialId: number;
  commercialId: number;
  template: boolean;
}) {
  let queryString = `query AppraisalCommissions(
    $assigneeId: uuid
    $roleId: Int
    $templatePropertyId: Int
    $residentialId: Int
    $commercialId: Int
    $template: Boolean
  ) {
    appraisal_commission(
      where: {
        assignee_user_account_id: { _eq: $assigneeId }
        template: { _eq: $template }
        ${props?.roleId !== 0 ? 'assignee_role_id: { _eq : $roleId }' : ''}

        _or: [
          { template_property_type_id: { _eq: $templatePropertyId } }
          { template_property_type_id: { _is_null: true } }

          {
            ${
              props.templatePropertyId === 1 && props.residentialId
                ? 'template_residential_ownership_type_id: { _eq: $residentialId }'
                : 'template_residential_ownership_type_id: { _is_null: true }'
            }
          }
          {
            ${
              props.templatePropertyId === 2 && props.commercialId
                ? 'template_commercial_property_type_id: { _eq: $commercialId }'
                : 'template_commercial_property_type_id: { _is_null: true }'
            }
          }
        ]
      }
    ) {
      id
      appraisal_id
      net_expenses
      quantity
      rate
      assignee_user_account_id
      assignee_role_id
      paid_date
      rate_type_id
      total_amount
      template
      template_commercial_property_type_id
      template_default
      template_property_type_id
      template_residential_form_type_ids
      template_residential_ownership_type_id
      organization_id
    }}`;
  const query = gql`
    ${queryString}
  `;

  return useLazyQuery<AssigneeCommissionResponse>(query, {
    fetchPolicy: 'cache-first',
    variables: props,
  });
}
export function useGenericCommissionQuery(props: { assigneeId: string; template: boolean; templateDefault: boolean }) {
  return useLazyQuery<AssigneeCommissionResponse>(ASSIGNEE_COMMISSION_DEFAULT_QUERY, {
    fetchPolicy: 'cache-first',
    variables: props,
  });
}

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { AppraisalActivities } from 'views/Appraisal/types';

const QUERY = gql`
  query AppraisalActivitySummary($appraisalId: uuid, $offset: Int, $limit: Int) {
    appraisal_activity_summary(
      args: { _appraisal_id: $appraisalId }
      limit: $limit
      offset: $offset
      order_by: { commit_timestamp: desc }
    ) {
      appraisal_id
      appraisal_status
      assignee_ids
      assignees
      commit_timestamp
      contacts
      dates
      fees
      filename
      notes
      timestamp_group_by
      type
      user_account_id
      user_account_name
      task_description
      task_assignee_full_name
      task_assignee_user_account_id
    }
  }
`;

interface AppraisalActivitySummaryResponse {
  appraisal_activity_summary: AppraisalActivities[];
}
interface AppraisalActivitySummaryAggregate {
  appraisal_activity_summary_aggregate: { aggregate: { count: number } };
}

export function useAppraisalActivities(props: { appraisalId: string; offset?: number; limit?: number }) {
  const variables = { ...props };
  return useQuery<AppraisalActivitySummaryResponse>(QUERY, {
    nextFetchPolicy: 'cache-first',
    variables,
  });
}

const APPRAISAL_ACTIVITY_SUMMARY_AGGREGATE = gql`
  query AppraisalActivitySummaryAggregate($appraisalId: uuid) {
    appraisal_activity_summary_aggregate(args: { _appraisal_id: $appraisalId }) {
      aggregate {
        count
      }
    }
  }
`;

export const useAppraisalActivitiesSummary = ({ appraisalId }: { appraisalId: string }) => {
  return useQuery<AppraisalActivitySummaryAggregate>(APPRAISAL_ACTIVITY_SUMMARY_AGGREGATE, {
    variables: { appraisalId },
  });
};

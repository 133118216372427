import { Box, Divider, Tab, Tabs } from '@material-ui/core';
import { omit } from 'lodash';
import React from 'react';
import { Datagrid, DateField, FunctionField, TextField } from 'react-admin';
import { withGridLoader } from 'shared/components/TablePreloader';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import { formatDecimalNumber } from 'shared/utils';
import FileNumber from 'views/Appraisal/components/FileNumber';
import { useGetCommissionCounts } from './hooks';
// import { useIsLimitedAndRestrictedRole } from 'shared/hooks/useResourcePermissions';

const CommissionsGrid = (props: any) => {
  const [, onChangeQuery] = useLocationQuery();
  const { filterValues } = props;

  const { all_count, paid_count, unpaid_count } = useGetCommissionCounts();

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    if (value === 0) {
      onChangeQuery({
        filter: JSON.stringify(omit(filterValues, 'paid_date')),
      });
    } else if (value === 1) {
      onChangeQuery({
        filter: JSON.stringify({
          ...filterValues,
          paid_date: {
            format: 'raw-query',
            value: {
              _is_null: false,
            },
            index: value,
          },
        }),
      });
    } else if (value === 2) {
      onChangeQuery({
        filter: JSON.stringify({
          ...filterValues,
          paid_date: {
            format: 'raw-query',
            value: {
              _is_null: true,
            },
            index: value,
          },
        }),
      });
    }
  };

  // const canViewAggregate = !useIsLimitedAndRestrictedRole();

  const TabLabel = ({ title, data }: any) => (
    <>
      <span>{title}</span>{' '}
      {/* {canViewAggregate && (
        <span style={{ fontSize: '0.75rem', fontWeight: 400 }}>
          {data?.sum?.total_amount ? formatCurrency(data?.sum?.total_amount) : ''}
        </span>
      )} */}
    </>
  );
  const TABS = [
    {
      title: <TabLabel title="All Commissions" data={all_count} />,
      index: 0,
      key: 'all',
    },
    {
      title: <TabLabel title="Paid Commissions" data={paid_count} />,
      index: 1,
      key: 'paid',
    },
    { title: <TabLabel title="Unpaid Commissions" data={unpaid_count} />, index: 2, key: 'unpaid' },
  ];

  return (
    <Box>
      <Tabs
        variant="scrollable"
        value={filterValues?.paid_date?.index || 0}
        textColor="primary"
        indicatorColor="primary"
        onChange={handleChange}
      >
        {TABS.map((choice) => {
          if ((choice.index === 1 && paid_count?.count === 0) || (choice.index === 2 && unpaid_count?.count === 0)) {
            return null;
          }
          return <Tab key={choice?.index} label={choice?.title} value={choice?.index} />;
        })}
      </Tabs>
      <Divider />
      <CommissionDataGrid />
    </Box>
  );
};

export default withGridLoader()(CommissionsGrid);

function CommissionDataGrid() {
  return (
    <Datagrid optimized rowClick={(id, resource, record) => `/appraisals/${record?.appraisal_id}#accounting`}>
      <FileNumber label="File #" source="appraisal_file_number" />
      <TextField label="Assignee" source="assignee_full_name" />
      <FunctionField
        label="Qty./Perc."
        sortBy="quantity"
        sortByOrder="ASC"
        render={(record: any) =>
          `${record.rate_type_id === 1 ? `${(record.rate * 100).toFixed(0)}%` : record.quantity}`
        }
      />
      <FunctionField
        label="Rate"
        sortBy="rate"
        sortByOrder="ASC"
        render={(record: any) => `${record.rate_type_id === 1 ? '' : '$' + record.rate}`}
      />
      <FunctionField
        label="Total"
        sortBy="total_amount"
        sortByOrder="ASC"
        render={(record: any) => `$${formatDecimalNumber(record.total_amount, 2)}`}
      />
      {/* <ReportFeeColumn label="Total" source="total_amount" /> */}
      <DateField label="Paid" source="paid_date" />
      <DateField label="Created" source="created_at" />
    </Datagrid>
  );
}

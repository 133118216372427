import {
  Box,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Cancel, Clear } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  FieldTitle,
  FormWithRedirect,
  ReferenceInput,
  SaveButton,
  TextInput,
  email as emailValidation,
  required,
  useCreate,
  useDataProvider,
  useNotify,
} from 'react-admin';
import AutocompleteInput from 'shared/components/AutocompleteInput';
import PlacesAutocomplete from 'shared/components/PlacesAutocomplete';
import { Contact } from 'shared/generated/types';
import { useContactsSearch } from 'shared/hooks/useContactOptions';
import { abbreviateLastName, validatePhone } from 'shared/utils';
import styles from 'views/Client/components/hooks/useContactPopupStyles';

interface Props {
  owners: any[];
  setOwners: (x: any) => void;
  propertyLoading?: boolean;
  isEditPage?: boolean;
}

const filter = createFilterOptions<any>();

const OwnerInput: FC<Props> = ({ owners, setOwners, propertyLoading, isEditPage = false }) => {
  const popUpClasses = styles();
  const notify = useNotify();
  const [create, { loading }] = useCreate('contact');
  const [searchContacts, { data: searchData, refetch, loading: searchContactsLoading }] = useContactsSearch();

  const [value, setValue] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isNewContact, setIsNewContact] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dataProvider = useDataProvider();

  const debouncedSearch = useCallback(
    debounce((value: string) => searchContacts({ variables: { keyword: value } }), 0),
    [],
  );

  useEffect(() => {
    if (inputValue) {
      debouncedSearch(inputValue);
    }
  }, [debouncedSearch, inputValue]);

  const handleCloseClick = () => {
    setIsNewContact(false);
  };

  useEffect(() => {
    if (value.length > 0 || isEditPage) {
      setOwners(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.length]);

  const createContact = useCallback(
    (values: any, updateValue?: boolean) => {
      return new Promise<Contact>((resolve: any, reject: any) => {
        create(
          {
            payload: {
              data: values,
            },
          },
          {
            onSuccess: ({ data }: any) => {
              refetch();
              resolve(data);
              setIsNewContact(false);
              if (updateValue) {
                setValue((prev) => {
                  const newItem = {
                    id: data.id,
                    full_name: `${data.first_name || ''} ${data.last_name || ''}`,
                  };
                  return prev ? prev.concat(newItem) : [newItem];
                });
              }
            },
            onFailure: ({ error }: any) => {
              notify(error.message, 'error');
              reject(error);
            },
          },
        );
      });
    },
    [create, notify, refetch],
  );

  useEffect(() => {
    if (owners && !isEditPage) {
      owners.forEach((owner) => {
        const data = {
          ...owner,
          contact_type_id: owner.contact_type_id ?? 17,
          location_address: owner.full_address,
        };
        createContact(data, true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createContact, owners]);

  useEffect(() => {
    //set previous owner ids if exists
    if (owners && isEditPage) {
      dataProvider.getMany('contacts', { ids: owners }).then(({ data }) => {
        setValue(
          data.map((item: any) => ({
            id: item.id,
            full_name: abbreviateLastName(item.first_name + (item?.last_name ? ' ' + item?.last_name : '')),
            type: item?.type,
          })),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, owners]);

  const handleSubmit = async (values: any) => {
    setDisabled(true);
    const name = values.name.split(' ');

    const sanitizedValue = { ...values, first_name: name[0], last_name: name.slice(1).join(' ') };

    const newContact = await createContact(sanitizedValue);

    if (newContact) {
      const newItem = {
        id: newContact.id,
        full_name: `${newContact.first_name} ${newContact.last_name}`,
      };
      setValue((prev: any) => {
        return prev ? prev.concat(newItem) : [newItem];
      });
      setInputValue('');
    }
    setDisabled(false);
  };

  if (isNewContact) {
    return (
      <>
        <Box mt={2}>
          <TextField
            color="primary"
            label="Add Contact"
            fullWidth
            defaultValue={`Add "${inputValue}" as new contact`}
            variant="outlined"
            // onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setIsNewContact(false)}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText style={{ fontSize: '1rem' }}>A new contact will be created</FormHelperText>
        </Box>
        <Dialog fullWidth={true} maxWidth={'md'} open={isNewContact} onClose={handleCloseClick} aria-label="Add Owner">
          <FormWithRedirect
            initialValues={{
              name: inputValue,
              contact_type_id: 17,
            }}
            save={handleSubmit}
            render={({ handleSubmitWithRedirect, saving }: any) => (
              <DialogContent classes={{ root: popUpClasses.dialogContent }}>
                <Card variant="outlined" className={popUpClasses.formBottom}>
                  <CardHeader title="Add Owner" classes={{ root: popUpClasses.cardHeader }} />

                  <Divider></Divider>
                  <Box className={popUpClasses.formContainer}>
                    <Typography classes={{ root: popUpClasses.heading }}>CONTACT</Typography>
                    <Divider classes={{ root: popUpClasses.divider }}></Divider>
                    <Grid container direction="row">
                      <Grid item md={8} sm={10} xs={12}>
                        <TextInput
                          value={inputValue}
                          validate={required()}
                          source="name"
                          variant="outlined"
                          fullWidth
                          autoFocus
                          openOnFocus
                        />
                      </Grid>
                      <Grid item md={8} sm={10} xs={12}>
                        <TextInput validate={[emailValidation()]} source="email" variant="outlined" fullWidth />
                      </Grid>
                      <Grid item md={8} sm={10} xs={12}>
                        <TextInput
                          source="phone_number"
                          label="Phone"
                          validate={validatePhone}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={8} sm={10} xs={12} style={{ paddingBottom: '0px' }}>
                        <PlacesAutocomplete
                          variant="outlined"
                          autoFocus={false}
                          label="Address"
                          source="location_address"
                          isMapVisible={false}
                          onChange={(e: any) => {
                            console.log('place', { e });
                          }}
                        />
                      </Grid>
                      <Grid item md={8} sm={10} xs={12}>
                        <ReferenceInput
                          label="Contact Type"
                          source="contact_type_id"
                          reference="contact_types"
                          allowEmpty={false}
                          fullWidth
                          perPage={100}
                          variant="outlined"
                          sort={{ field: 'order', order: 'ASC' }}
                          validate={[required()]}
                          filter={{ type: '%owner%' }}
                          defaultValue={17}
                        >
                          <AutocompleteInput source="contact_type_id" optionText="type" />
                        </ReferenceInput>
                      </Grid>
                    </Grid>
                    <DialogActions classes={{ root: popUpClasses.editActions }}>
                      <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={disabled}>
                        <Cancel />
                      </Button>
                      <SaveButton
                        saving={saving}
                        disabled={loading || disabled}
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                      />
                    </DialogActions>
                  </Box>
                </Card>
              </DialogContent>
            )}
          />
        </Dialog>
      </>
    );
  }

  return (
    <Autocomplete
      disabled={propertyLoading}
      loading={searchContactsLoading}
      freeSolo
      multiple
      fullWidth
      // limitTags={2}
      value={value}
      id="multiple-owners"
      options={searchData?.search_contacts || []}
      getOptionLabel={(option) => option?.full_name || ''}
      // defaultValue={[data[0]]}
      onInputChange={(_, value, event) => {
        if (event === 'input') {
          setInputValue(value);
        }
      }}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        const newItem = newValue.slice(-1)[0];
        if (newItem && newItem.id === 'new') {
          setIsNewContact(true);
        } else {
          setValue(newValue);
          setInputValue('');
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={!isEditPage ? <FieldTitle label={'Owners'} /> : ''}
          variant="outlined"
          fullWidth
          margin={'dense'}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      filterOptions={(options: any, params: any) => {
        const filtered = filter(options, params);
        setInputValue(params.inputValue);

        //to make email filtering work
        options.forEach((element: any) => {
          if (
            element?.email &&
            element?.email?.replace(',', '').toLowerCase().includes(params.inputValue.toLowerCase())
          ) {
            const checkDuplicate = (obj: any) => obj?.id === element?.id;
            const duplicateStatus = filtered.some(checkDuplicate);
            !duplicateStatus && filtered.push(element);
          }
        });

        //for new contact
        if (params.inputValue !== '') {
          filtered.push({
            id: `new`,
            client_name: '',
            email: '',
            full_name: `Add "${params.inputValue}" as new contact`,
          });
        }

        return filtered;
      }}
      selectOnFocus
    />
  );
};

export default OwnerInput;

import { useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import {
  Appraisal,
  Appraisal_Priorities,
  Appraisal_Purposes,
  Appraisal_Statuses,
  Assignee_Roles,
  // Commercial_Building_Classes,
  Commercial_Property_Subtype,
  Commercial_Property_Type,
  Loan_Types,
  Property_Types,
  Report_Types,
  Residential_Form_Types,
  Residential_Ownership_Types,
  Residential_Styles,
  User_Profiles,
} from 'shared/generated/types';

import { Client } from 'views/Client/types';
import { useGetIdentity } from 'react-admin';
const OPTIONS_QUERY = gql`
  query AppraisalOptions {
    propertyTypes: property_types(order_by: { order: asc }) {
      id
      type
    }
    appraisalPurposes: appraisal_purposes(order_by: { order: asc }) {
      id
      purpose
      is_loan
    }
    loanTypes: loan_types(order_by: { order: asc }) {
      id
      type
    }
    residentialOwnershipTypes: residential_ownership_types(order_by: { order: asc }) {
      id
      type
    }
    residentialStyles: residential_styles(order_by: { order: asc }) {
      id
      style
    }
    reportTypes: report_types(order_by: { order: asc }) {
      id
      type
    }
    residentialFormTypes: residential_form_types(order_by: { order: asc }) {
      id
      type
    }
    commercialPropertyTypes: commercial_property_type(order_by: { order: asc }) {
      id
      type
    }
    commercialPropertySubTypes: commercial_property_subtype(order_by: { order: asc }) {
      id
      subtype
      commercial_property_type_id
    }
    appraisalStatuses: appraisal_statuses(order_by: { order: asc }) {
      id
      status
    }
    appraisalPriorities: appraisal_priorities(order_by: { order: asc }) {
      id
      priority
    }
  }
`;

const OPTIONS_ASSIGNEES_QUERY = gql`
  query AssigneesOptions {
    assignees: user_profiles(where: { enabled: { _eq: true } }, order_by: { full_name: asc }) {
      id: user_account_id
      full_name
    }
  }
`;
const ASSIGNEE_ROLES_QUERY = gql`
  query AssigneesRoles {
    assignee_roles {
      id
      order
      role
    }
  }
`;

const ORGANIZATION_BY_PK = gql`
  query getOrganizationByPk($id: uuid!) {
    organization_by_pk(id: $id) {
      id
      user_accounts_active_count
    }
  }
`;

const SEARCH_APPRAISAL_QUERY = gql`
  query appraisalSearch($keyword: String) {
    search_appraisals(args: { search: $keyword }) {
      id
    }
  }
`;

export type AppraisalOptionsResponse = {
  propertyTypes: Property_Types[];
  appraisalPurposes: Appraisal_Purposes[];
  loanTypes: Loan_Types[];
  residentialOwnershipTypes: Residential_Ownership_Types[];
  reportTypes: Report_Types[];
  residentialFormTypes: Residential_Form_Types[];
  commercialPropertyTypes: Commercial_Property_Type[];
  commercialPropertySubTypes: Commercial_Property_Subtype[];
  appraisalStatuses: Appraisal_Statuses[];
  appraisalPriorities: Appraisal_Priorities[];
  // commercialBuildingClass: Commercial_Building_Classes[];
  residentialStyles: Residential_Styles[];
  // properties: Property[];
  // assignees: User_Profiles[];
  // clients: Client[];
};

export type OrganizationByPkResponse = {
  organization_by_pk: { user_accounts_active_count: number };
};

export function useAppraisalOptions() {
  const options = useQuery<AppraisalOptionsResponse>(OPTIONS_QUERY, { fetchPolicy: 'cache-first' });
  return [options] as const;
}
// Fetch assignees using network
// Sometimes appraisal will not be able to save if assignees options are not synchronized with backend
export function useAssigneesOptions() {
  const options = useQuery<{ assignees: User_Profiles[] }>(OPTIONS_ASSIGNEES_QUERY, { fetchPolicy: 'network-only' });
  return [options] as const;
}

export function useOrganizationByPk(props: { id: string }) {
  return useQuery<OrganizationByPkResponse>(ORGANIZATION_BY_PK, {
    fetchPolicy: 'cache-first',
    variables: props,
    skip: !props.id,
  });
}

export function useAssigneeRoleOptions() {
  return useLazyQuery<{ assignee_roles: Assignee_Roles[] }>(ASSIGNEE_ROLES_QUERY, { fetchPolicy: 'cache-first' });
}

export const useIsOnlyOneUserActive = () => {
  const { identity } = useGetIdentity();
  const { data } = useOrganizationByPk({
    id: identity?.organization_id,
  });
  return data?.organization_by_pk.user_accounts_active_count === 1;
};

export function useAppraisalsSearch() {
  return useLazyQuery<{ search_appraisals: Appraisal[] }>(SEARCH_APPRAISAL_QUERY);
}

const STATUS_QUERY = gql`
  query AppraisalStatuses {
    appraisalStatuses: appraisal_statuses(order_by: { order: asc }) {
      id
      status
    }
  }
`;

export const useAppraisalStatus = () => {
  return useQuery<{ appraisalStatuses: Appraisal_Statuses[] }>(STATUS_QUERY);
};

const APPRAISAL_ASSIGNEES = gql`
  query AppraisalAddress {
    assignees: user_profiles(order_by: { full_name: asc }) {
      id: user_account_id
      full_name
    }
  }
`;

export const useAppraisalAssigneesOptions = () => {
  return useQuery<{ assignees: User_Profiles[] }>(APPRAISAL_ASSIGNEES);
};

const APPRAISAL_CLIENTS = gql`
  query AppraisalClients {
    clients(order_by: { name: asc }) {
      id
      name
    }
  }
`;

export const useAppraisalClients = () => {
  return useQuery<{ clients: Client[] }>(APPRAISAL_CLIENTS);
};

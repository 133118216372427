import { LazyQueryExecFunction, QueryResult } from '@apollo/client';
import { Divider, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, TextInput, required, useInput } from 'react-admin';

import AutocompleteInput from 'shared/components/AutocompleteInput';
import PlacesAutocomplete from 'shared/components/PlacesAutocomplete';
import { AppraisalOptionsResponse, useAppraisalOptions } from 'shared/hooks/useAppraisalOptions';
import useLastAppraisal from 'shared/hooks/useLastAppraisal';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import { PropertyDataRequest, PropertyDataResponse } from 'shared/hooks/usePropertyData';
import { incrementFileNumber } from 'shared/utils';
import { styles } from '../../appraisalStyles';
import GroupedInput from '../GroupedInput/GroupedInput';
import OwnerInput from '../OwnerInput';

interface Props {
  formData: any;
  propertyDataResponse?: QueryResult<PropertyDataResponse, PropertyDataRequest>;
  getPropertyData?: LazyQueryExecFunction<PropertyDataResponse, PropertyDataRequest>;
  propertyType: number;
  setPropertyType: Dispatch<SetStateAction<number>>;
  setProperty: Dispatch<any>;
}

const PropertySection = ({
  formData,
  propertyDataResponse,
  getPropertyData,
  propertyType,
  setPropertyType,
  setProperty,
}: Props) => {
  const classes = styles();
  const [isDirty, setDirty] = useState<{ [key: string]: boolean }>({});
  const [query] = useLocationQuery();
  const [appraisalOptions] = useAppraisalOptions();

  const [isExistingProperty, setIsExistingProperty] = useState(Boolean(query.property_id));

  return (
    <>
      <Typography classes={{ root: classes.sectionHeading }}>PROPERTY</Typography>
      <Divider classes={{ root: classes.divider }}></Divider>

      <Grid container direction="column" alignItems="center">
        <Grid container md={5} sm={12} xs={12}>
          <TextInput
            variant="outlined"
            fullWidth
            source="property.property_name"
            label={`Name${!formData.property.location_address ? ' (must select address first)' : ''}`}
            disabled={!isDirty.address || propertyDataResponse?.loading}
          />
        </Grid>

        <Grid container md={5} sm={12} xs={12}>
          <PropertyField
            label="Subject Address"
            variant="outlined"
            setIsExistingProperty={setIsExistingProperty}
            appraisalOptions={appraisalOptions}
            getPropertyData={getPropertyData}
            propertyDataResponse={propertyDataResponse}
            setDirty={setDirty}
          />
        </Grid>

        <Grid container md={5} sm={12} xs={12}>
          <TextInput
            variant="outlined"
            fullWidth
            source="property.subdivision"
            label="Subdivision / Neighborhood description"
          />
        </Grid>

        <Grid container md={5} sm={12} xs={12}>
          <Grid container direction="row" style={{ marginBottom: '32px' }}>
            <Button
              label="Residential"
              variant={propertyType === 1 ? 'contained' : 'outlined'}
              style={{ borderRadius: '24px', marginRight: '16px', paddingRight: '16px' }}
              disabled={isExistingProperty || propertyDataResponse?.loading}
              onClick={() => setPropertyType(1)}
            />
            <Button
              label="Commercial"
              variant={propertyType === 2 ? 'contained' : 'outlined'}
              style={{ borderRadius: '24px', paddingRight: '16px' }}
              disabled={isExistingProperty || propertyDataResponse?.loading}
              onClick={() => setPropertyType(2)}
            />
          </Grid>
        </Grid>

        {propertyType === 1 && (
          <>
            <Grid container md={5} sm={12} xs={12}>
              <AutocompleteInput
                label="Ownership"
                variant="outlined"
                fullWidth
                source="property.residential_ownership_type_id"
                optionText="type"
                choices={appraisalOptions.data?.residentialOwnershipTypes ?? []}
                disabled={propertyDataResponse?.loading}
              />
            </Grid>

            <Grid container md={5} sm={12} xs={12}>
              <AutocompleteInput
                label="Style"
                fullWidth
                variant="outlined"
                source="property.residential_style_id"
                optionText="style"
                choices={appraisalOptions.data?.residentialStyles ?? []}
                disabled={propertyDataResponse?.loading}
              />
            </Grid>
          </>
        )}
        {propertyType === 2 && (
          <Grid container md={5} sm={12} xs={12} style={{ marginBottom: '1rem' }}>
            <GroupedInput disabled={propertyDataResponse?.loading} />
          </Grid>
        )}

        <Grid container md={5} sm={12} xs={12}>
          <OwnerInput
            propertyLoading={propertyDataResponse?.loading}
            owners={propertyDataResponse?.data?.property_data?.owners ?? []}
            setOwners={(owners: any) => setProperty((prev: any[]) => ({ ...prev, owners }))}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PropertySection;

type PropertyFieldProps = {
  label: string;
  setIsExistingProperty(arg: boolean): void;
  appraisalOptions: QueryResult<AppraisalOptionsResponse>;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  isMapVisible?: boolean;
  getPropertyData?: LazyQueryExecFunction<PropertyDataResponse, PropertyDataRequest>;
  propertyDataResponse?: QueryResult<PropertyDataResponse, PropertyDataRequest>;
  setDirty?: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
};

function PropertyField(props: PropertyFieldProps) {
  const fileNumberField = useInput({ source: 'appraisal_file_number' });
  const lastAppraisalQuery = useLastAppraisal();
  useEffect(() => {
    if (lastAppraisalQuery.data?.appraisal?.length) {
      const appraisalFileNumber = lastAppraisalQuery.data?.appraisal[0].appraisal_file_number;
      fileNumberField.input.onChange(incrementFileNumber(appraisalFileNumber === null ? '' : appraisalFileNumber));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAppraisalQuery.data]);

  return (
    <PlacesAutocomplete
      label={props.label}
      key={1}
      height="calc(100vh - 480px)"
      prefix="property"
      isRequired
      validate={required()}
      xlCols={12}
      variant={props.variant}
      isMapVisible={false}
      allowToggleMap={true}
      getPropertyData={props.getPropertyData}
      disabled={props.propertyDataResponse?.loading}
      setDirty={props.setDirty}
    />
  );
}

import { gql, useQuery } from '@apollo/client';
import { Order_Forward_Documents } from 'shared/generated/types';

const GET_ORDER_FORWARD_DOCUMENT = gql`
  query OrderForwardDocument($appraisalId: uuid) {
    order_forward_documents(where: { appraisal_id: { _eq: $appraisalId } }) {
      raw_document
    }
  }
`;

interface GetOrderForwardDocumentResponse {
  order_forward_documents: Order_Forward_Documents[];
}

export const useGetOrderForwardDocument = (appraisalId: string) => {
  return useQuery<GetOrderForwardDocumentResponse>(GET_ORDER_FORWARD_DOCUMENT, {
    variables: { appraisalId },
    skip: !appraisalId,
  });
};

import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Commercial_Property_Subtype,
  Commercial_Property_Type,
  Property_Types,
  Query_RootTasklist_By_PkArgs,
  Residential_Ownership_Types,
  Residential_Styles,
  Tasklist,
  Tasklist_Templates,
  Task_Arr_Rel_Insert_Input,
} from 'shared/generated/types';

export const GET_TASKLIST_TEMPLATE = gql`
  query TasklistTemplate {
    tasklist_templates(order_by: { template_default: desc }) {
      id
      appraisal_id
      name
      tasks_incompleted_count
      tasks_completed_count
      tasks_count
      template
      template_default
      template_property_type_id
      template_commercial_property_type_id
      template_residential_ownership_type_id
      template_residential_form_type_ids
      property_type
      commercial_property_type
      residential_ownership_type
    }
    propertyTypes: property_types(order_by: { order: asc }) {
      id
      type
    }
    residentialOwnershipTypes: residential_ownership_types(order_by: { order: asc }) {
      id
      type
    }
    commercialPropertyTypes: commercial_property_type(order_by: { order: asc }) {
      id
      type
    }
  }
`;

export interface TasklistTemplateResponse {
  propertyTypes: Property_Types[];
  tasklist_templates: Tasklist_Templates[];
  residentialOwnershipTypes: Residential_Ownership_Types[];
  residentialStyles: Residential_Styles[];
  commercialPropertyTypes: Commercial_Property_Type[];
  commercialPropertySubTypes: Commercial_Property_Subtype[];
}

export const useTasklistTemplates = () => {
  return useQuery<TasklistTemplateResponse>(GET_TASKLIST_TEMPLATE, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
};

export const ADD_TASKLIST_QUERY = gql`
  mutation AddTasklist(
    $name: String
    $tasks: task_arr_rel_insert_input
    $template_commercial_property_type_id: Int
    $template_property_type_id: Int
    $template_residential_form_type_ids: jsonb
    $template_residential_ownership_type_id: Int
  ) {
    insert_tasklist_one(
      object: {
        name: $name
        tasks: $tasks
        template: true
        template_commercial_property_type_id: $template_commercial_property_type_id
        template_property_type_id: $template_property_type_id
        template_residential_form_type_ids: $template_residential_form_type_ids
        template_residential_ownership_type_id: $template_residential_ownership_type_id
      }
    ) {
      id
      appraisal_id
      name
      tasks {
        id
        description
        order
      }
      template
      template_commercial_property_type_id
      template_property_type_id
      template_residential_form_type_ids
      template_residential_ownership_type_id
    }
  }
`;

interface AddTaskListRequest {
  appraisal_id?: string;
  name: string;
  tasks: Task_Arr_Rel_Insert_Input;
  template_commercial_property_type_id: number;
  template_property_type_id: number;
  template_residential_form_type_ids: any;
  template_residential_ownership_type_id: string;
}

export const useAddTasklist = () => {
  return useMutation<AddTaskListRequest>(ADD_TASKLIST_QUERY);
};

const TASK_LIST_BY_PK_QUERY = gql`
  query TasklistByPK($id: uuid!) {
    tasklist_by_pk(id: $id) {
      id
      name
      template
      template_default
      template_property_type_id
      template_commercial_property_type_id
      template_residential_ownership_type_id
      template_residential_form_type_ids
      tasks(order_by: { order: asc }) {
        id
        description
        order
        tasklist_id
        appraisal_id
        assignee_user_account_id
        color
        completed_date
        due_date
      }
    }
  }
`;

export const useTaskListByPk = (id?: string) => {
  return useQuery<{ tasklist_by_pk: Tasklist }, Query_RootTasklist_By_PkArgs>(TASK_LIST_BY_PK_QUERY, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'network-only',
  });
};

const UPDATE_TASKLIST_BY_PK_QUERY = gql`
  mutation updateTasklist(
    $id: uuid!
    $name: String
    $template_commercial_property_type_id: Int
    $template_property_type_id: Int
    $template_residential_form_type_ids: jsonb
    $template_residential_ownership_type_id: Int
    $tasks: [task_insert_input!]!
  ) {
    update_tasklist_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        template_commercial_property_type_id: $template_commercial_property_type_id
        template_property_type_id: $template_property_type_id
        template_residential_form_type_ids: $template_residential_form_type_ids
        template_residential_ownership_type_id: $template_residential_ownership_type_id
      }
    ) {
      id
      appraisal_id
      name
      tasks_incompleted_count
      tasks_completed_count
      tasks_count
      template
      template_default
      template_property_type_id
      template_commercial_property_type_id
      template_residential_ownership_type_id
      template_residential_form_type_ids
    }

    delete_task(where: { tasklist_id: { _eq: $id } }) {
      affected_rows
      returning {
        id
        tasklist_id
      }
    }

    insert_task(objects: $tasks) {
      affected_rows
      returning {
        id
        description
        order
        tasklist_id
      }
    }
  }
`;

export const useUpdateTasklistByPk = () => {
  return useMutation(UPDATE_TASKLIST_BY_PK_QUERY, {
    refetchQueries: [{ query: GET_TASKLIST_TEMPLATE }, 'tasklist_templates'],
  });
};

export const DELETE_TASKLIST_QUERY = gql`
  mutation DeleteTasklist($id: uuid!) {
    delete_tasklist_by_pk(id: $id) {
      id
    }
  }
`;

import { Box, Card, Divider, IconButton, InputAdornment, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

import CurrencyInput from 'shared/components/CurrencyInput';
import { DateInput } from 'shared/components/Pickers';
import { styleRight } from 'shared/hooks/useEditFormStyle';
import useFormPermissions from 'shared/hooks/useResourcePermissions';
import { copyToClipboard } from 'shared/utils';
import getAppraisalPermission from 'views/Appraisal/permission';

interface Props {}

const RequestSection = (props: Props) => {
  const classes = styleRight();

  const dataProvider = useDataProvider();

  const [{ permissions }] = useFormPermissions({ getPermission: getAppraisalPermission });
  const formData = useFormState();
  const form = useForm();

  const [requestEdit, setRequestEdit] = useState(false);
  const [sourceData, setSourceData] = useState<any>(null);

  useEffect(() => {
    dataProvider.getOne('appraisal_sources', { id: formData?.values?.appraisal_source_id }).then(({ data }) => {
      setSourceData(data);
    });
    // eslint-disable-next-line
  }, [dataProvider, formData?.values?.appraisal_source_id]);

  const getRequestFields = (initialValue: boolean) => ({
    appraisal_source_id: initialValue,
    quote_fee: initialValue,
    quote_made_date: initialValue,
    quote_accepted_date: initialValue,
    quote_declined_date: initialValue,
  });

  const [requestFields, setRequestFields] = useState<any>(getRequestFields(false));

  const setRequests = (val: boolean) => {
    setRequestFields(getRequestFields(val));
    setRequestEdit(val);
  };

  const save = useCallback(debounce(form.submit, 0), []);
  const isFormDirty = (): boolean => !!Object.keys(formData.dirtyFields).length;

  const showRequestSection =
    formData.values.quote_fee > 0 ||
    formData.values.quote_made_date ||
    formData.values.quote_accepted_date ||
    formData.values.quote_declined_date ||
    formData.values.appraisal_source_id >= 2;

  return (
    <>
      {showRequestSection && (
        <Box className={classes.formContainer}>
          <Card
            variant="outlined"
            classes={{ root: `${classes.card} ${classes.overflow}` }}
            style={{ paddingBottom: '10px' }}
          >
            <Box className={classes.flexBox}>
              <Typography classes={{ root: `${classes.heading} ${classes.heading500} ${classes.fontLarge}` }}>
                Request
              </Typography>
              {isFormDirty() && requestEdit && (
                <IconButton
                  className={classes.saveActionButton}
                  edge="end"
                  onClick={() => {
                    save();
                    setRequestEdit(false);
                    setRequests(false);
                  }}
                >
                  <SaveIcon classes={{ root: classes.icon }} />
                </IconButton>
              )}
              {permissions.edit && !requestEdit && (
                <IconButton
                  className={classes.topEditBtn}
                  edge="end"
                  disabled={requestEdit}
                  onClick={() => setRequests(true)}
                >
                  <EditIcon classes={{ root: classes.icon }} />
                </IconButton>
              )}
              {requestEdit && (
                <IconButton
                  classes={{ root: classes.topEditBtn }}
                  disabled={!requestEdit}
                  edge="end"
                  onClick={() => {
                    setRequestEdit(false);
                    setRequests(false);
                    Object.keys(formData.dirtyFields).forEach((field: string) => {
                      const [a, b] = field.split('.');
                      if (a && b) {
                        form.change(field, formData.initialValues[a][b]);
                      } else {
                        form.change(field, formData.initialValues[a]);
                      }
                    });
                  }}
                >
                  <CloseIcon classes={{ root: classes.icon }} />
                </IconButton>
              )}
            </Box>
            <Divider classes={{ root: classes.dividerSubSelect }}></Divider>
            {!requestEdit && (
              <Box display={'flex'} justifyContent={'space-between'} width={'100%'} position={'relative'}>
                <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Source</Typography>
                <Box pr={2} className={classes.inputContainer}>
                  <Box pr={2} className={classes.inputContainer}>
                    <Typography classes={{ root: classes.nextTask }}>{sourceData?.source}</Typography>
                  </Box>
                </Box>
              </Box>
            )}

            {(requestEdit || formData.values.quote_fee > 0) && (
              <Box className={classes.flexBox}>
                <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Quote Fee</Typography>
                <Box pr={2} className={classes.inputContainer}>
                  <CurrencyInput
                    onClick={(e: any) => copyToClipboard(formData.values.quote_fee, e, requestEdit)}
                    size="small"
                    color="secondary"
                    InputProps={{
                      placeholder: 'Click pencil to edit',
                      classes: {
                        root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                          requestFields.quote_fee ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                        }`,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            className={classes.fieldEditBtn}
                            edge="end"
                            disabled={requestFields.quote_fee && !formData.dirtyFields.quote_fee}
                          >
                            {requestFields.quote_fee && formData.dirtyFields.quote_fee && (
                              <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                            )}
                            &nbsp;
                            {requestFields.quote_fee && formData.dirtyFields.quote_fee && (
                              <CloseIcon
                                fontSize="small"
                                classes={{ root: classes.icon }}
                                onClick={() => form.change('quote_fee', formData.initialValues.quote_fee)}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    FormHelperTextProps={{ style: { display: 'none' } }}
                    variant="outlined"
                    disabled={!permissions.edit || !requestFields.quote_fee}
                    fullWidth
                    label=""
                    source="quote_fee"
                  />
                </Box>
              </Box>
            )}

            {(requestEdit || formData.values.quote_made_date) && (
              <Box className={classes.flexBox}>
                <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
                  Made
                </Typography>
                <Box pr={2} className={classes.inputContainer}>
                  <DateInput
                    onClick={(e: any) => copyToClipboard(formData.values.quote_made_date, e, requestEdit)}
                    size="small"
                    color="secondary"
                    InputProps={{
                      placeholder: 'Click pencil to edit',
                      classes: {
                        root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                          requestFields.quote_made_date ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                        }`,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                    FormHelperTextProps={{ style: { display: 'none' } }}
                    variant="outlined"
                    disabled={!permissions.edit || !requestFields.quote_made_date}
                    fullWidth
                    label=""
                    source="quote_made_date"
                  />
                  <IconButton
                    size="small"
                    className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
                    edge="end"
                    disabled={requestFields.quote_made_date && !formData.dirtyFields.quote_made_date}
                  >
                    {requestFields.quote_made_date && formData.dirtyFields.quote_made_date && (
                      <SaveIcon
                        fontSize="small"
                        classes={{ root: classes.icon }}
                        onClick={() => {
                          save();
                        }}
                      />
                    )}
                    &nbsp;
                    {requestFields.quote_made_date && formData.dirtyFields.quote_made_date && (
                      <CloseIcon
                        fontSize="small"
                        classes={{ root: classes.icon }}
                        onClick={() => form.change('quote_made_date', formData.initialValues.quote_made_date)}
                      />
                    )}
                  </IconButton>
                </Box>
              </Box>
            )}

            {(requestEdit || formData.values.quote_accepted_date) && (
              <Box className={classes.flexBox}>
                <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
                  Accepted
                </Typography>
                <Box pr={2} className={classes.inputContainer}>
                  <DateInput
                    onClick={(e: any) => copyToClipboard(formData.values.quote_accepted_date, e, requestEdit)}
                    size="small"
                    color="secondary"
                    InputProps={{
                      placeholder: 'Click pencil to edit',
                      classes: {
                        root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                          requestFields.quote_accepted_date ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                        }`,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                    FormHelperTextProps={{ style: { display: 'none' } }}
                    variant="outlined"
                    disabled={!permissions.edit || !requestFields.quote_accepted_date}
                    fullWidth
                    label=""
                    source="quote_accepted_date"
                  />
                  <IconButton
                    size="small"
                    className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
                    edge="end"
                    disabled={requestFields.quote_accepted_date && !formData.dirtyFields.quote_accepted_date}
                  >
                    {requestFields.quote_accepted_date && formData.dirtyFields.quote_accepted_date && (
                      <SaveIcon
                        fontSize="small"
                        classes={{ root: classes.icon }}
                        onClick={() => {
                          save();
                        }}
                      />
                    )}
                    &nbsp;
                    {requestFields.quote_accepted_date && formData.dirtyFields.quote_accepted_date && (
                      <CloseIcon
                        fontSize="small"
                        classes={{ root: classes.icon }}
                        onClick={() => form.change('quote_accepted_date', formData.initialValues.quote_accepted_date)}
                      />
                    )}
                  </IconButton>
                </Box>
              </Box>
            )}
            {(requestEdit || formData.values.quote_declined_date) && (
              <Box className={classes.flexBox}>
                <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
                  Declined
                </Typography>
                <Box pr={2} className={classes.inputContainer}>
                  <DateInput
                    onClick={(e: any) => copyToClipboard(formData.values.quote_declined_date, e, requestEdit)}
                    size="small"
                    color="secondary"
                    InputProps={{
                      placeholder: 'Click pencil to edit',
                      classes: {
                        root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                          requestFields.quote_declined_date ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                        }`,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                    FormHelperTextProps={{ style: { display: 'none' } }}
                    variant="outlined"
                    disabled={!permissions.edit || !requestFields.quote_declined_date}
                    fullWidth
                    label=""
                    source="quote_declined_date"
                  />
                  <IconButton
                    size="small"
                    className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
                    edge="end"
                    disabled={requestFields.quote_declined_date && !formData.dirtyFields.quote_declined_date}
                  >
                    {requestFields.quote_declined_date && formData.dirtyFields.quote_declined_date && (
                      <SaveIcon
                        fontSize="small"
                        classes={{ root: classes.icon }}
                        onClick={() => {
                          save();
                        }}
                      />
                    )}
                    &nbsp;
                    {requestFields.quote_declined_date && formData.dirtyFields.quote_declined_date && (
                      <CloseIcon
                        fontSize="small"
                        classes={{ root: classes.icon }}
                        onClick={() => form.change('quote_declined_date', formData.initialValues.quote_declined_date)}
                      />
                    )}
                  </IconButton>
                </Box>
              </Box>
            )}
          </Card>
        </Box>
      )}
    </>
  );
};

export default RequestSection;

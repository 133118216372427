import { useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Contact } from 'shared/generated/types';
import { Client } from 'views/Client/types';
import { ContactTypes } from 'views/Contact/types';

const QUERY = gql`
  query {
    clients: client(order_by: { name: asc }) {
      id
      name
      client_type_id
    }
    contactTypes: contact_types(order_by: { order: asc }) {
      id
      type
      client_type_id
    }
  }
`;

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const alphabet: any = [...alpha.map((x) => String.fromCharCode(x))];
type Alphabets = (typeof alphabet)[number];

const CONTACTS_COUNT_QUERY = gql`
  query ContactsCount {
    ${alphabet?.map((each: any) => {
      return each + ': contacts_aggregate(where: {full_name: {_similar:"(' + each + ')%"}}){aggregate{count}}';
    })}
  }
`;

const SEARCH_CONTACTS_QUERY = gql`
  query contactSearch($keyword: String) {
    search_contacts(args: { search: $keyword }) {
      id
      full_name
      type
    }
  }
`;

const CONTACT_DETAIL_QUERY = gql`
  query contactById($id: uuid!) {
    contact_by_pk(id: $id) {
      id
      first_name
      client_id
    }
  }
`;
export const BULK_CONTACT_DETAIL_QUERY = gql`
  query bulkContacts($contact_ids: [uuid!]!) {
    contacts(where: { id: { _in: $contact_ids } }) {
      id
      full_name
      type
      email
      phone_number
    }
  }
`;

export type ContactOptionsResponse = {
  clients: Pick<Client, 'id' | 'name' | 'client_type_id'>[];
  contactTypes: Pick<ContactTypes, 'id' | 'type' | 'client_type_id'>[];
};
export default function useContactOptions() {
  const options = useQuery<ContactOptionsResponse>(QUERY, { fetchPolicy: 'cache-first' });
  return [options] as const;
}

export type ContactsCountResponse = {
  [x in Alphabets]: { aggregate: { count: number } };
};
export function useContactsTotal() {
  const options = useQuery<ContactsCountResponse>(CONTACTS_COUNT_QUERY, {
    fetchPolicy: 'cache-first',
  });
  return [options] as const;
}

export function useContactsSearch() {
  return useLazyQuery<{ search_contacts: Contact[] }, { keyword: string }>(SEARCH_CONTACTS_QUERY, {
    fetchPolicy: 'network-only',
  });
}

export function useContactDetailById() {
  return useLazyQuery<{ contact_by_pk: Contact }>(CONTACT_DETAIL_QUERY);
}

export function useBulkContactDetailsByIds() {
  return useLazyQuery<{ contact_ids: Contact[] }>(BULK_CONTACT_DETAIL_QUERY);
}

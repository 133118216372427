import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { authProvider } from 'shared/AuthProvider';
import { Config } from './constants/config';
import { recordGraphQL } from './openreplay';

export const XHasuraClientName = 'hasura-client-name';

const retryLink = new RetryLink();

const errorLink = onError((err) => {
  const { graphQLErrors, networkError, operation } = err;
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`);
    });
  }
  if (networkError) {
    console.log(`[Network error ${operation.operationName}]: ${networkError.message}`);
  }
});

const authMiddleware = setContext(async (operation) => {
  let updatedToken = '';

  if (operation.operationName !== 'RefreshToken') {
    updatedToken = await authProvider.getUpdatedToken();
  }
  if (operation.variables && operation.variables.isExternal) {
    updatedToken = authProvider.accessToken;
  }

  const newContext = {
    headers: {
      ...(updatedToken && {
        Authorization: `Bearer ${updatedToken}`,
      }),
    },
  };
  return newContext;
});

const httpLink = createHttpLink({
  uri: Config.httpDataHost,
  headers: {
    [XHasuraClientName]: Config.hasuraClientName,
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: Config.wsDataHost,
    connectionParams: async () => {
      const token = await authProvider.accessToken;
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    },
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const merge = (existing = [], incoming: any) => {
  return [...existing, ...incoming];
};

export const gqlClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          client_activity_summary: {
            keyArgs: false,
            merge,
            read(existing: any[]) {
              return existing;
            },
          },
          contact_activity_summary: {
            keyArgs: false,
            merge,
            read(existing: any[]) {
              return existing;
            },
          },
          appraisal_activity_summary: {
            keyArgs: false,
            merge,
            read(existing: any[]) {
              return existing;
            },
          },
          user_profile_activity_summary: {
            keyArgs: false,
            merge,
            read(existing: any[]) {
              return existing;
            },
          },
        },
      },
    },
  }),
  link: ApolloLink.from([
    new ApolloLink((operation: any, forward) => {
      return forward(operation).map((result) =>
        recordGraphQL(operation.query.definitions[0]?.operation, operation.operationName, operation.variables, result),
      );
    }),
    errorLink,
    retryLink,
    authMiddleware,
    splitLink,
  ]),
});

import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { Letter } from 'react-letter';
import { styleRight } from 'shared/hooks/useEditFormStyle';

export const Request = ({ rawDocument }: { rawDocument: string }) => {
  const formClasses = styleRight();

  return (
    <Card variant="outlined" classes={{ root: `${formClasses.card}` }} style={{ marginTop: '30px' }}>
      <CardContent>
        <Letter html={rawDocument} className={formClasses.letterContainer} />
      </CardContent>
    </Card>
  );
};

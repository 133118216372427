import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import React from 'react';

export default function CustomDatePicker({ open, onClose, onChange, value }: any) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker style={{ width: 0 }} value={value} onChange={onChange} open={open} onClose={onClose} clearable />
    </MuiPickersUtilsProvider>
  );
}

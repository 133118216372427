import React, { ReactNode } from 'react';
import { useRedirect } from 'react-admin';
import { EventProps } from 'react-big-calendar';

import { AppraisalEvent } from 'views/Appraisal/types';

// const useStyles = makeStyles({
//   paper: {
//     borderRadius: 0,
//   },
// });
type EventWrapperProps = Omit<EventProps<AppraisalEvent>, 'title'> & {
  children: ReactNode;
};
function EventWrapper(props: EventWrapperProps) {
  const redirect = useRedirect();
  // const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  // const classes = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    redirect(`/appraisals/${props.event.appraisal_id}`);

    // event.stopPropagation();

    // props.event.type !== 'in_progress'
    //   ? setAnchorEl(event.currentTarget)
    //   : redirect(`/appraisals/${props.event.appraisal_id}`);
  };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  return (
    <>
      <div aria-describedby={props.event.id} onClick={handleClick}>
        {props.children}
      </div>
      {/* <Popover
        id={props.event.id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={classes}
      >
        <MapPopup
          hideInspection={props.event.type === 'due_date'}
          hideDue={props.event.type === 'inspection_date'}
          selected={props.event}
        />
      </Popover> */}
    </>
  );
}

export default EventWrapper;

import { Box, Card, Chip, Divider, IconButton, InputAdornment, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useState } from 'react';
import { TextInput, useGetIdentity, useRedirect } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import AutocompleteArrayInput from 'shared/components/AutocompleteArrayInput';
import AutocompleteInput from 'shared/components/AutocompleteInput';
import CurrencyInput from 'shared/components/CurrencyInput';
import { DateInput } from 'shared/components/Pickers';
import { restrictedRoles } from 'shared/constants/roles';
import { useAppraisalOptions } from 'shared/hooks/useAppraisalOptions';
import { styleRight } from 'shared/hooks/useEditFormStyle';
import useFormPermissions from 'shared/hooks/useResourcePermissions';
import { abbreviateLastName, copyToClipboard } from 'shared/utils';
import getAppraisalPermission from 'views/Appraisal/permission';
import ClientReferenceInput from 'views/Client/ClientReferenceInputV2';
import OrderedByInput from '../OrderedByInput';
import BorrowerInput from '../BorrowerInput';
import AddressedToInput from '../AddressedToInput';

interface IEngagementSection {
  orderedContactsView: any;
  borrowerContactsView: any;
  addressedContactsView: any;
}
const EngagementSection = ({
  addressedContactsView,
  borrowerContactsView,
  orderedContactsView,
}: IEngagementSection) => {
  const redirect = useRedirect();
  const location = useLocation();

  const classes = styleRight();

  const { identity } = useGetIdentity();
  const [appraisalOptions] = useAppraisalOptions();

  const [{ permissions }] = useFormPermissions({ getPermission: getAppraisalPermission });
  const formData = useFormState();
  const form = useForm();

  const [engagementEdit, setEngagementEdit] = useState(false);
  const [orderedByContacts, setOrderedByContacts] = useState<any>([]);
  const [borrowerContacts, setBorrowerContacts] = useState<any>([]);
  const [addressedToContacts, setAddressedToContacts] = useState<any>([]);

  const { isRestrictedUser } = useMemo(() => {
    return {
      // showTotal: !limitedAndRestrictedRoles.includes(identity?.role) && !isOnlyOneUserActive,
      isRestrictedUser: restrictedRoles.includes(identity?.role),
    };
  }, [identity]);

  const getEngagementFields = (initialValue: boolean) => ({
    engagement_date: initialValue,
    client_file_number: initialValue,
    client_id: initialValue,
    client_loan_number: initialValue,
    loan_type_id: initialValue,
    fha_case_number: initialValue,
    usda_case_number: initialValue,
    va_case_number: initialValue,
    appraisal_purpose_id: initialValue,
    report_type_id: initialValue,
    residential_form_type_ids: initialValue,
    report_fee: initialValue,
    total_fees: initialValue,
    paid_date: initialValue,
    ordered_by_contact_ids: initialValue,
    borrower_contact_ids: initialValue,
  });

  const [engagementFields, setEngagementFields] = useState<any>(getEngagementFields(false));

  const setEngagements = (val: boolean) => {
    setEngagementFields(getEngagementFields(val));
    setEngagementEdit(val);
  };

  const save = useCallback(debounce(form.submit, 0), []);
  // const isFormDirty = (): boolean => !!Object.keys(formData.dirtyFields).length;

  return (
    <Card
      variant="outlined"
      classes={{ root: `${classes.card} ${classes.overflow} ${classes.gap}` }}
      style={{ paddingBottom: '5px' }}
    >
      <Box className={classes.flexBox}>
        <Typography classes={{ root: `${classes.heading} ${classes.heading500} ${classes.fontLarge}` }}>
          Engagement
        </Typography>
        {/* {isFormDirty() && engagementEdit && ( */}
        {engagementEdit && (
          <IconButton
            className={classes.saveActionButton}
            edge="end"
            onClick={(e) => {
              e.preventDefault();
              form.change('ordered_by_contact_ids', orderedByContacts);
              form.change('borrower_contact_ids', borrowerContacts);
              form.change('addressed_to_contact_ids', addressedToContacts);
              save();
              setEngagementEdit(false);
              setEngagements(false);
            }}
          >
            <SaveIcon classes={{ root: classes.icon }} />
          </IconButton>
        )}
        {permissions.edit && !engagementEdit && (
          <IconButton
            className={classes.topEditBtn}
            edge="end"
            disabled={engagementEdit}
            onClick={() => setEngagements(true)}
          >
            <EditIcon classes={{ root: classes.icon }} />
          </IconButton>
        )}
        {engagementEdit && (
          <IconButton
            classes={{ root: classes.topEditBtn }}
            disabled={!engagementEdit}
            edge="end"
            onClick={() => {
              setEngagementEdit(false);
              setEngagements(false);
              Object.keys(formData.dirtyFields).forEach((field: string) => {
                const [a, b] = field.split('.');
                if (a && b) {
                  form.change(field, formData.initialValues[a][b]);
                } else {
                  form.change(field, formData.initialValues[a]);
                }
              });
            }}
          >
            <CloseIcon classes={{ root: classes.icon }} />
          </IconButton>
        )}
      </Box>
      <Divider classes={{ root: classes.dividerSubSelect }}></Divider>

      {(engagementEdit || formData.values.engagement_date) && (
        <Box className={classes.flexBox} style={{ position: 'relative' }}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
            Engaged
          </Typography>
          <Box pr={2} className={classes.inputContainer}>
            <DateInput
              onClick={(e: any) => copyToClipboard(formData.values.engagement_date, e, engagementEdit)}
              size="small"
              color="secondary"
              InputProps={{
                placeholder: 'Click pencil to edit',
                classes: {
                  root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                    engagementFields.engagement_date ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                  }`,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
              variant="outlined"
              disabled={!permissions.edit || !engagementFields.engagement_date}
              fullWidth
              label=""
              source="engagement_date"
            />
            <IconButton
              size="small"
              className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
              edge="end"
              disabled={engagementFields.engagement_date && !formData.dirtyFields.engagement_date}
            >
              {engagementFields.engagement_date && formData.dirtyFields.engagement_date && (
                <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
              )}
              &nbsp;
              {engagementFields.engagement_date && formData.dirtyFields.engagement_date && (
                <CloseIcon
                  fontSize="small"
                  classes={{ root: classes.icon }}
                  onClick={() => form.change('engagement_date', formData.initialValues.engagement_date)}
                />
              )}
            </IconButton>
          </Box>
        </Box>
      )}

      <Box className={classes.flexBox} style={{ height: '100%' }}>
        <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Client</Typography>
        <Box pr={2} className={classes.inputContainer}>
          <ClientReferenceInput
            size="small"
            color="secondary"
            onlyIconLabel={true}
            InputProps={{
              placeholder: 'Click pencil to edit',
              classes: {
                root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                  engagementFields.client_id ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                }`,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    className={classes.fieldEditBtn}
                    edge="end"
                    disabled={engagementFields.client_id && !formData.dirtyFields.client_id}
                  >
                    {engagementFields.client_id && formData.dirtyFields.client_id && (
                      <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                    )}
                    &nbsp;
                    {engagementFields.client_id && formData.dirtyFields.client_id && (
                      <CloseIcon
                        fontSize="small"
                        classes={{ root: classes.icon }}
                        onClick={() => form.change('client_id', formData.initialValues.client_id)}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            FormHelperTextProps={{ style: { display: 'none' } }}
            variant="outlined"
            disabled={!permissions.edit || !engagementFields.client_id}
            label=""
            source="client_id"
            reference="client"
            fullWidth
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            perPage={Infinity}
            children={<span />}
            showContacts={true}
            customCSS={{
              root: {
                display: 'flex',
                alignItems: 'self-start',
                flex: 1,
              },
              create: {
                marginBottom: '0px',
                display: 'none',
              },
            }}
            clientEdit={engagementEdit}
            clientId={formData.values.client_id}
          />
        </Box>
      </Box>

      <Box className={classes.flexBoxAuto}>
        <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
          Ordered by
        </Typography>
        {formData?.values?.ordered_by_contact_ids?.length === 0 && !engagementEdit ? (
          <Box pr={2} className={classes.inputContainer}>
            <Typography classes={{ root: `${classes.nextTask} ${classes.disabledInput}` }}>
              Click pencil to edit
            </Typography>
          </Box>
        ) : (
          <Box
            pr={2}
            pb={1}
            pt={1}
            mb={formData?.values?.ordered_by_contact_ids?.length !== 0 || engagementEdit ? 0 : 4}
            className={classes.inputContainer}
          >
            {engagementEdit ? (
              <OrderedByInput
                orderedByIds={formData?.values?.ordered_by_contact_ids}
                clientId={formData?.values?.client_id}
                setOrderedByContacts={(data: any) => {
                  const contacts = data.map((item: any) => item.id);
                  setOrderedByContacts(contacts);
                }}
                contactIds={formData?.values?.contact_ids}
                appraisalDetails={formData?.values}
              />
            ) : orderedContactsView?.length ? (
              orderedContactsView?.map((choice: any) => (
                <Chip
                  key={choice?.id}
                  label={`${abbreviateLastName(
                    choice?.first_name + (choice?.last_name ? ' ' + choice?.last_name : ''),
                  )} (${choice?.type})`}
                  style={{
                    backgroundColor: '#e0e0e0',
                    color: 'rgba(0, 0, 0, 0.87)',
                    height: '32px',
                    margin: '3px',
                  }}
                  onClick={() => redirect(`/appraisals/${formData?.values?.id}#contacts`)}
                />
              ))
            ) : (
              ''
            )}
          </Box>
        )}
      </Box>

      {(engagementEdit || formData.values.client_file_number) && (
        <Box className={classes.flexBox}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Client File #</Typography>
          <Box pr={2} className={classes.inputContainer}>
            <TextInput
              onClick={(e: any) => copyToClipboard(formData.values.client_file_number, e, engagementEdit)}
              size="small"
              color="secondary"
              InputProps={{
                placeholder: 'Click pencil to edit',
                classes: {
                  root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                    engagementFields.client_file_number ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                  }`,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      className={classes.fieldEditBtn}
                      edge="end"
                      disabled={engagementFields.client_file_number && !formData.dirtyFields.client_file_number}
                    >
                      {engagementFields.client_file_number && formData.dirtyFields.client_file_number && (
                        <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                      )}
                      &nbsp;
                      {engagementFields.client_file_number && formData.dirtyFields.client_file_number && (
                        <CloseIcon
                          fontSize="small"
                          classes={{ root: classes.icon }}
                          onClick={() => form.change('client_file_number', formData.initialValues.client_file_number)}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
              variant="outlined"
              disabled={!permissions.edit || !engagementFields.client_file_number}
              fullWidth
              label=""
              source="client_file_number"
            />
          </Box>
        </Box>
      )}
      {(engagementEdit || formData.values.appraisal_purpose_id) && (
        <Box className={classes.flexBox}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Purpose</Typography>
          <Box pr={2} className={classes.inputContainer}>
            <AutocompleteInput
              disabled={!permissions.edit || !engagementFields.appraisal_purpose_id}
              label=""
              source="appraisal_purpose_id"
              fullWidth
              optionText="purpose"
              variant="outlined"
              allowEmpty={true}
              choices={appraisalOptions.data?.appraisalPurposes ?? []}
              css={{ popupIndicator: classes.popupIndicator, popupIndicatorOpen: classes.popupIndicatorOpen }}
              options={{
                InputProps: {
                  placeholder: 'Click pencil to edit',
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontSize} ${
                      engagementFields.appraisal_purpose_id ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                    }`,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        className={classes.fieldEditBtn}
                        edge="end"
                        disabled={engagementFields.appraisal_purpose_id && !formData.dirtyFields.appraisal_purpose_id}
                      >
                        {engagementFields.appraisal_purpose_id && formData.dirtyFields.appraisal_purpose_id && (
                          <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                        )}
                        &nbsp;
                        {engagementFields.appraisal_purpose_id && formData.dirtyFields.appraisal_purpose_id && (
                          <CloseIcon
                            fontSize="small"
                            classes={{ root: classes.icon }}
                            onClick={() =>
                              form.change('appraisal_purpose_id', formData.initialValues.appraisal_purpose_id)
                            }
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
                InputLabelProps: {
                  classes: {
                    root: `${classes.inputFontSize}`,
                  },
                },
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
            />
          </Box>
        </Box>
      )}
      {(engagementEdit || formData.values.loan_type_id) && (
        <Box className={classes.flexBox}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Loan Type</Typography>
          <Box pr={2} className={classes.inputContainer}>
            <AutocompleteInput
              disabled={!permissions.edit || !engagementFields.loan_type_id}
              label=""
              source="loan_type_id"
              fullWidth
              optionText="type"
              variant="outlined"
              allowEmpty={true}
              choices={appraisalOptions.data?.loanTypes ?? []}
              css={{ popupIndicator: classes.popupIndicator, popupIndicatorOpen: classes.popupIndicatorOpen }}
              options={{
                InputProps: {
                  placeholder: 'Click pencil to edit',
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontSize} ${
                      engagementFields.loan_type_id ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                    }`,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        className={classes.fieldEditBtn}
                        edge="end"
                        disabled={engagementFields.loan_type_id && !formData.dirtyFields.loan_type_id}
                      >
                        {engagementFields.loan_type_id && formData.dirtyFields.loan_type_id && (
                          <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                        )}
                        &nbsp;
                        {engagementFields.loan_type_id && formData.dirtyFields.loan_type_id && (
                          <CloseIcon
                            fontSize="small"
                            classes={{ root: classes.icon }}
                            onClick={() => form.change('loan_type_id', formData.initialValues.loan_type_id)}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
                InputLabelProps: {
                  classes: {
                    root: `${classes.inputFontSize}`,
                  },
                },
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
            />
          </Box>
        </Box>
      )}
      {(engagementEdit || formData.values.client_loan_number) && (
        <Box className={classes.flexBox}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Loan #</Typography>
          <Box pr={2} className={classes.inputContainer}>
            <TextInput
              onClick={(e: any) => copyToClipboard(formData.values.client_loan_number, e, engagementEdit)}
              size="small"
              color="secondary"
              InputProps={{
                placeholder: 'Click pencil to edit',
                classes: {
                  root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                    engagementFields.client_loan_number ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                  }`,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      className={classes.fieldEditBtn}
                      edge="end"
                      disabled={engagementFields.client_loan_number && !formData.dirtyFields.client_loan_number}
                    >
                      {engagementFields.client_loan_number && formData.dirtyFields.client_loan_number && (
                        <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                      )}
                      &nbsp;
                      {engagementFields.client_loan_number && formData.dirtyFields.client_loan_number && (
                        <CloseIcon
                          fontSize="small"
                          classes={{ root: classes.icon }}
                          onClick={() => form.change('client_loan_number', formData.initialValues.client_loan_number)}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
              variant="outlined"
              disabled={!permissions.edit || !engagementFields.client_loan_number}
              fullWidth
              label=""
              source="client_loan_number"
            />
          </Box>
        </Box>
      )}

      {(engagementEdit || formData.values.borrower_contact_ids?.length > 0) && (
        <Box className={classes.flexBoxAuto}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
            Borrowers
          </Typography>
          {formData?.values?.borrower_contact_ids?.length === 0 && !engagementEdit ? (
            <Box pr={2} className={classes.inputContainer}>
              <Typography classes={{ root: `${classes.nextTask} ${classes.disabledInput}` }}>
                Click pencil to edit
              </Typography>
            </Box>
          ) : (
            <Box
              pr={2}
              pb={1}
              pt={1}
              mb={formData?.values?.borrower_contact_ids?.length !== 0 || engagementEdit ? 0 : 4}
              className={classes.inputContainer}
            >
              {engagementEdit ? (
                <BorrowerInput
                  allContacts={formData?.values?.all_contact_ids}
                  borrowerContacts={formData?.values?.borrower_contact_ids}
                  clientId={formData?.values?.client_id}
                  setBorrowerContacts={(data: any) => {
                    const contacts = data.map((item: any) => item.id);
                    setBorrowerContacts(contacts);
                  }}
                />
              ) : borrowerContactsView?.length ? (
                borrowerContactsView?.map((choice: any) => (
                  <Chip
                    key={choice?.id}
                    label={`${abbreviateLastName(
                      choice?.first_name + (choice?.last_name ? ' ' + choice?.last_name : ''),
                    )} (${choice?.type})`}
                    style={{
                      backgroundColor: '#e0e0e0',
                      color: 'rgba(0, 0, 0, 0.87)',
                      height: '32px',
                      margin: '3px',
                    }}
                    onClick={() => redirect(`/appraisals/${formData?.values?.id}#contacts`)}
                  />
                ))
              ) : (
                ''
              )}
            </Box>
          )}
        </Box>
      )}
      {[4, 5, 6].includes(formData.values.loan_type_id || NaN) && (
        <>
          {formData.values.loan_type_id === 4 && (
            <Box className={classes.flexBox}>
              <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>FHA Case #</Typography>
              <Box pr={2} className={classes.inputContainer}>
                <TextInput
                  onClick={(e: any) => copyToClipboard(formData.values.fha_case_number, e, engagementEdit)}
                  size="small"
                  color="secondary"
                  InputProps={{
                    placeholder: 'Click pencil to edit',
                    classes: {
                      root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                        engagementFields.fha_case_number ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                      }`,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          className={classes.fieldEditBtn}
                          edge="end"
                          disabled={engagementFields.fha_case_number && !formData.dirtyFields.fha_case_number}
                        >
                          {engagementFields.fha_case_number && formData.dirtyFields.fha_case_number && (
                            <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                          )}
                          &nbsp;
                          {engagementFields.fha_case_number && formData.dirtyFields.fha_case_number && (
                            <CloseIcon
                              fontSize="small"
                              classes={{ root: classes.icon }}
                              onClick={() => form.change('fha_case_number', formData.initialValues.fha_case_number)}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  disabled={!permissions.edit || !engagementFields.fha_case_number}
                  fullWidth
                  label=""
                  source="fha_case_number"
                />
              </Box>
            </Box>
          )}
          {formData.values.loan_type_id === 5 && (
            <Box className={classes.flexBox}>
              <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>USDA Case #</Typography>
              <Box pr={2} className={classes.inputContainer}>
                <TextInput
                  onClick={(e: any) => copyToClipboard(formData.values.usda_case_number, e, engagementEdit)}
                  size="small"
                  color="secondary"
                  InputProps={{
                    placeholder: 'Click pencil to edit',
                    classes: {
                      root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                        engagementFields.usda_case_number ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                      }`,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          className={classes.fieldEditBtn}
                          edge="end"
                          disabled={engagementFields.usda_case_number && !formData.dirtyFields.usda_case_number}
                        >
                          {engagementFields.usda_case_number && formData.dirtyFields.usda_case_number && (
                            <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                          )}
                          &nbsp;
                          {engagementFields.usda_case_number && formData.dirtyFields.usda_case_number && (
                            <CloseIcon
                              fontSize="small"
                              classes={{ root: classes.icon }}
                              onClick={() => form.change('usda_case_number', formData.initialValues.usda_case_number)}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  disabled={!permissions.edit || !engagementFields.usda_case_number}
                  fullWidth
                  label=""
                  source="usda_case_number"
                />
              </Box>
            </Box>
          )}
          {formData.values.loan_type_id === 6 && (
            <Box className={classes.flexBox}>
              <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>VA Case #</Typography>
              <Box pr={2} className={classes.inputContainer}>
                <TextInput
                  onClick={(e: any) => copyToClipboard(formData.values.va_case_number, e, engagementEdit)}
                  size="small"
                  color="secondary"
                  InputProps={{
                    placeholder: 'Click pencil to edit',
                    classes: {
                      root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                        engagementFields.va_case_number ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                      }`,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          className={classes.fieldEditBtn}
                          edge="end"
                          disabled={engagementFields.va_case_number && !formData.dirtyFields.va_case_number}
                        >
                          {engagementFields.va_case_number && formData.dirtyFields.va_case_number && (
                            <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                          )}
                          &nbsp;
                          {engagementFields.va_case_number && formData.dirtyFields.va_case_number && (
                            <CloseIcon
                              fontSize="small"
                              classes={{ root: classes.icon }}
                              onClick={() => form.change('va_case_number', formData.initialValues.va_case_number)}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  disabled={!permissions.edit || !engagementFields.va_case_number}
                  fullWidth
                  label=""
                  source="va_case_number"
                />
              </Box>
            </Box>
          )}
        </>
      )}

      {(engagementEdit || formData.values.report_type_id) && (
        <Box className={classes.flexBox}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Report Type</Typography>
          <Box pr={2} className={classes.inputContainer}>
            <AutocompleteInput
              disabled={!permissions.edit || !engagementFields.report_type_id}
              label=""
              source="report_type_id"
              fullWidth
              optionText="type"
              variant="outlined"
              allowEmpty={true}
              choices={appraisalOptions.data?.reportTypes ?? []}
              css={{ popupIndicator: classes.popupIndicator, popupIndicatorOpen: classes.popupIndicatorOpen }}
              options={{
                InputProps: {
                  placeholder: 'Click pencil to edit',
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontSize} ${
                      engagementFields.report_type_id ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                    }`,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        className={classes.fieldEditBtn}
                        edge="end"
                        disabled={engagementFields.report_type_id && !formData.dirtyFields.report_type_id}
                      >
                        {engagementFields.report_type_id && formData.dirtyFields.report_type_id && (
                          <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                        )}
                        &nbsp;
                        {engagementFields.report_type_id && formData.dirtyFields.report_type_id && (
                          <CloseIcon
                            fontSize="small"
                            classes={{ root: classes.icon }}
                            onClick={() => form.change('report_type_id', formData.initialValues.report_type_id)}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
                InputLabelProps: {
                  classes: {
                    root: `${classes.inputFontSize}`,
                  },
                },
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
            />
          </Box>
        </Box>
      )}
      {(engagementEdit || formData.values?.addressed_to_contact_ids?.length > 0) && (
        <Box className={classes.flexBoxAuto}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
            Addressed to
          </Typography>
          {formData?.values?.addressed_to_contact_ids?.length === 0 && !engagementEdit ? (
            <Box pr={2} className={classes.inputContainer}>
              <Typography classes={{ root: `${classes.nextTask} ${classes.disabledInput}` }}>
                Click pencil to edit
              </Typography>
            </Box>
          ) : (
            <Box
              pr={2}
              pb={1}
              pt={1}
              mb={formData?.values?.addressed_to_contact_ids?.length !== 0 || engagementEdit ? 0 : 4}
              className={classes.inputContainer}
            >
              {engagementEdit ? (
                <AddressedToInput
                  allContacts={formData?.values?.all_contact_ids}
                  addressedToContacts={formData?.values?.addressed_to_contact_ids}
                  clientId={formData?.values?.client_id}
                  setAddressedToContacts={(data: any) => {
                    const contacts = data.map((item: any) => item.id);
                    setAddressedToContacts(contacts);
                  }}
                />
              ) : addressedContactsView?.length ? (
                addressedContactsView?.map((choice: any) => (
                  <Chip
                    key={choice?.id}
                    label={`${abbreviateLastName(
                      choice?.first_name + (choice?.last_name ? ' ' + choice?.last_name : ''),
                    )} (${choice?.type})`}
                    style={{
                      backgroundColor: '#e0e0e0',
                      color: 'rgba(0, 0, 0, 0.87)',
                      height: '32px',
                      margin: '3px',
                    }}
                    onClick={() => redirect(`/appraisals/${formData?.values?.id}#contacts`)}
                  />
                ))
              ) : (
                ''
              )}
            </Box>
          )}
        </Box>
      )}
      {formData.values.property_type_id === 1 && (
        <Box className={classes.flexBoxAuto}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Form Types</Typography>
          <Box pr={2} className={classes.inputContainer}>
            <AutocompleteArrayInput
              disabled={!permissions.edit || !engagementFields.residential_form_type_ids}
              label=""
              source="residential_form_type_ids"
              fullWidth
              optionText="type"
              variant="outlined"
              choices={appraisalOptions.data?.residentialFormTypes ?? []}
              css={{ popupIndicator: classes.popupIndicator, popupIndicatorOpen: classes.popupIndicatorOpen }}
              options={{
                InputProps: {
                  placeholder: `${
                    formData.values.residential_form_type_ids?.length === 0 ? 'Click pencil to edit' : ''
                  }`,
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontSize} ${
                      engagementFields.residential_form_type_ids
                        ? classes.cssOutlinedActive
                        : classes.cssOutlinedDisabled
                    }`,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        className={classes.fieldEditBtnMultiSelect}
                        edge="end"
                        disabled={
                          engagementFields.residential_form_type_ids && !formData.dirtyFields.residential_form_type_ids
                        }
                      >
                        {engagementFields.residential_form_type_ids &&
                          formData.dirtyFields.residential_form_type_ids && (
                            <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                          )}
                        &nbsp;
                        {engagementFields.residential_form_type_ids &&
                          formData.dirtyFields.residential_form_type_ids && (
                            <CloseIcon
                              fontSize="small"
                              classes={{ root: classes.icon }}
                              onClick={() =>
                                form.change(
                                  'residential_form_type_ids',
                                  formData.initialValues.residential_form_type_ids,
                                )
                              }
                            />
                          )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
                InputLabelProps: {
                  classes: {
                    root: `${classes.inputFontSize}`,
                  },
                },
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
            />
          </Box>
        </Box>
      )}
      {!isRestrictedUser && (
        <>
          <Box className={classes.flexBox}>
            <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Report Fee</Typography>
            <Box pr={2} className={classes.inputContainer}>
              <CurrencyInput
                onClick={(e: any) => copyToClipboard(formData.values.report_fee, e, engagementEdit)}
                size="small"
                color="secondary"
                InputProps={{
                  placeholder: 'Click pencil to edit',
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                      engagementFields.report_fee ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                    }`,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        className={classes.fieldEditBtn}
                        edge="end"
                        disabled={engagementFields.report_fee && !formData.dirtyFields.report_fee}
                      >
                        {engagementFields.report_fee && formData.dirtyFields.report_fee && (
                          <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                        )}
                        &nbsp;
                        {engagementFields.report_fee && formData.dirtyFields.report_fee && (
                          <CloseIcon
                            fontSize="small"
                            classes={{ root: classes.icon }}
                            onClick={() => form.change('report_fee', formData.initialValues.report_fee)}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{ style: { display: 'none' } }}
                variant="outlined"
                disabled={!permissions.edit || !engagementFields.report_fee}
                fullWidth
                label=""
                source="report_fee"
              />
            </Box>
          </Box>
          <Box className={classes.flexBox}>
            <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Total Fees</Typography>
            <Box pr={2} className={classes.inputContainer} onClick={() => redirect(`${location.pathname}#accounting`)}>
              <CurrencyInput
                onClick={(e: any) => copyToClipboard(formData.values.total_fees, e, engagementEdit)}
                size="small"
                color="secondary"
                InputProps={{
                  placeholder: 'Click pencil to edit',
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${classes.cssOutlinedDisabled}`,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                FormHelperTextProps={{ style: { display: 'none' } }}
                variant="outlined"
                disabled={true}
                fullWidth
                label=""
                source="total_fees"
              />
            </Box>
          </Box>
        </>
      )}

      {(engagementEdit || formData.values.paid_date) && (
        <Box className={classes.flexBox}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
            Paid On
          </Typography>
          <Box pr={2} className={classes.inputContainer}>
            <DateInput
              onClick={(e: any) => copyToClipboard(formData.values.paid_date, e, engagementEdit)}
              size="small"
              color="secondary"
              InputProps={{
                placeholder: 'Click pencil to edit',
                classes: {
                  root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                    engagementFields.paid_date ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                  }`,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
              variant="outlined"
              disabled={!permissions.edit || !engagementFields.paid_date}
              fullWidth
              label=""
              source="paid_date"
            />
            <IconButton
              size="small"
              className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
              edge="end"
              disabled={engagementFields.paid_date && !formData.dirtyFields.paid_date}
            >
              {engagementFields.paid_date && formData.dirtyFields.paid_date && (
                <SaveIcon
                  fontSize="small"
                  classes={{ root: classes.icon }}
                  onClick={() => {
                    save();
                  }}
                />
              )}
              &nbsp;
              {engagementFields.paid_date && formData.dirtyFields.paid_date && (
                <CloseIcon
                  fontSize="small"
                  classes={{ root: classes.icon }}
                  onClick={() => form.change('paid_date', formData.initialValues.paid_date)}
                />
              )}
            </IconButton>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default EngagementSection;

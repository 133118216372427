import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';
import React, { useEffect, useMemo, useState } from 'react';
import { useListContext } from 'react-admin';
import { Calendar as ReactBigCalendar, View } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import TablePreloader from 'shared/components/TablePreloader';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import useMaxDimensions from 'shared/hooks/useMaxDimension';
import { AppraisalEvent, AppraisalEventType } from 'views/Appraisal/types';
import AgendaDate from './AgendaDate';
import AgendaEvent from './AgendaEvent';
import AgendaTime from './AgendaTime';
import CalendarToolbar from './CalendarToolbar';
import { DueCheckbox, InProgressCheckbox, InspectionCheckbox, TaskCheckbox } from './Checkboxes';
import EventView from './EventView';
import MonthEvent from './MonthEvent';
import { getEvents, localizer } from './helpers';
import { useStyles } from './styles';

const InitialCheckbox = {
  [AppraisalEventType.Inspection]: true,
  [AppraisalEventType.Due]: true,
  [AppraisalEventType.TASK_DUE]: true,
  [AppraisalEventType.IN_PROGRESS]: true,
};

type CalendarProps = {
  defaultFilter: {
    [key in string]: any;
  };
  currentDate: Date;
  onChangeDate(date: Date): void;
};

function Calendar(props: CalendarProps) {
  const { ids, data, loading, filterValues, setFilters, displayedFilters } = useListContext();

  useEffect(() => {
    if (Object.keys(filterValues).length === 0) {
      return;
    }
    const newFilters: any = {
      ...filterValues,
    };
    const appraisal_assignee_key = 'appraisal_assignee_user_account_ids,appraisal_assignee_user_account_ids';
    const task_assignee_key = 'task_assignee_user_account_id,task_assignee_user_account_id';

    const user_account_ids = newFilters[appraisal_assignee_key];
    if (!user_account_ids) {
      delete newFilters[task_assignee_key];
    } else {
      newFilters[task_assignee_key] = {
        format: 'raw-query',
        value: { _in: user_account_ids },
      };
    }
    setFilters(newFilters, displayedFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterValues)]);

  const [queryString] = useLocationQuery();
  const defaultView: View = queryString.calendarView ?? 'agenda';

  const [checkboxState, setCheckboxState] = useState<typeof InitialCheckbox>();

  useEffect(() => {
    checkboxState && localStorage.setItem('schedule_checkbox', JSON.stringify(checkboxState));
  }, [checkboxState]);

  useEffect(() => {
    const items = localStorage.getItem('schedule_checkbox');
    if (items && items !== 'undefined') {
      setCheckboxState(JSON.parse(items));
    } else {
      setCheckboxState(InitialCheckbox);
    }
  }, []);

  const classes = useStyles();
  const [currentView, setCurrentView] = useState<View>(defaultView);

  const handleCheckboxToggle = (eventType: AppraisalEventType) => {
    setCheckboxState(
      (prev) =>
        prev && {
          ...prev,
          [eventType]: !prev[eventType],
        },
    );
  };

  const events = useMemo(() => {
    let eventArr: AppraisalEvent[] = [];
    if (checkboxState) {
      if (checkboxState[AppraisalEventType.Inspection]) {
        eventArr = [...eventArr, ...getEvents(ids, data, AppraisalEventType.Inspection)];
      }
      if (checkboxState[AppraisalEventType.IN_PROGRESS] && currentView === 'month') {
        eventArr = [...eventArr, ...getEvents(ids, data, AppraisalEventType.IN_PROGRESS)];
      }
      const isDueSelected = checkboxState[AppraisalEventType.Due];
      const isTaskSelected = checkboxState[AppraisalEventType.TASK_DUE];
      if (isDueSelected || isTaskSelected) {
        eventArr = [...eventArr, ...getEvents(ids, data, AppraisalEventType.Due, { isDueSelected, isTaskSelected })];
      }
    }

    return eventArr;
  }, [checkboxState, currentView, ids, data]);

  const dimensions = useMaxDimensions();

  return (
    <div className={classes.calendar} style={{ width: dimensions.width, height: 'calc(100vh - 136px)' }}>
      {loading ? (
        <TablePreloader columns={3} />
      ) : (
        <>
          <ReactBigCalendar<AppraisalEvent>
            onNavigate={props.onChangeDate}
            date={props.currentDate}
            localizer={localizer}
            events={events}
            startAccessor="start"
            onView={(newView) => setCurrentView(newView)}
            view={currentView}
            endAccessor="end"
            popup
            style={{ height: '90%' }}
            eventPropGetter={(event) => {
              let style = undefined;
              let className = 'inspection';
              if (event.schedule_type === 'task') {
                className = 'task';
                style = {
                  backgroundColor: '#2196f3',
                };
              } else if (event.type === 'due_date') {
                className = 'due';
                style = {
                  backgroundColor: '#ff9800',
                };
              } else if (event.type === 'in_progress') {
                className = 'progress';
                style = {
                  backgroundColor: green[500],
                };
              }

              return {
                className,
                style: currentView === 'month' ? style : undefined,
              };
            }}
            popupOffset={{ x: 30, y: 20 }}
            messages={{
              event: 'Appraisal File Number',
            }}
            components={{
              toolbar: CalendarToolbar,
              event: EventView,
              month: {
                event: MonthEvent,
              },
              agenda: {
                // @ts-ignore
                date: AgendaDate,
                time: AgendaTime,
                event: AgendaEvent,
              },
            }}
          />
          <Box display="flex">
            {checkboxState && (
              <>
                {currentView === 'month' && (
                  <FormControlLabel
                    onChange={() => handleCheckboxToggle(AppraisalEventType.IN_PROGRESS)}
                    checked={checkboxState[AppraisalEventType.IN_PROGRESS]}
                    control={<InProgressCheckbox name="inProgress" />}
                    label="In Progress"
                  />
                )}
                <FormControlLabel
                  onChange={() => handleCheckboxToggle(AppraisalEventType.Due)}
                  checked={checkboxState[AppraisalEventType.Due]}
                  control={<DueCheckbox name="checkedDue" />}
                  label="Due"
                />
                <FormControlLabel
                  onChange={() => handleCheckboxToggle(AppraisalEventType.Inspection)}
                  checked={checkboxState[AppraisalEventType.Inspection]}
                  control={<InspectionCheckbox name="checkedDue" />}
                  label="Inspections"
                />

                <FormControlLabel
                  onChange={() => handleCheckboxToggle(AppraisalEventType.TASK_DUE)}
                  checked={checkboxState[AppraisalEventType.TASK_DUE]}
                  control={<TaskCheckbox name="checkedDue" />}
                  label="Tasks"
                />
              </>
            )}
          </Box>
        </>
      )}
    </div>
  );
}

export default Calendar;

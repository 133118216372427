import { Box, Typography } from '@material-ui/core';
import formatDate from 'date-fns/format';
import React, { Fragment } from 'react';
import { styleLeft } from 'shared/hooks/useEditFormStyle';
import { formatDateForActivity } from 'shared/utils';
import { AppraisalActivities } from 'views/Appraisal/types';

interface Props {
  row: AppraisalActivities;
  currentUser: string;
  appraisal?: any;
  taskAssignee?: string;
}

export const AppraisalActivityRow = ({ row, currentUser, taskAssignee }: Props) => {
  const classes = styleLeft();

  const {
    appraisal_status,
    assignees,
    commit_timestamp,
    contacts,
    dates,
    notes,
    type,
    fees,
    files,
    task_assignee_full_name,
    task_description,
    user_account_name,
  } = row;

  const displayTime = formatDateForActivity(commit_timestamp, true);
  const { due_date, inspection_date, completed_date } = dates;
  const { quote_fee, report_fee } = fees;

  const CurrentUser = () => (
    <Typography className={classes.bold} component={'span'}>
      {currentUser}
    </Typography>
  );
  const DisplayTime = () => <Typography color="textSecondary">{displayTime}</Typography>;

  const Contacts = () => {
    return (
      <>
        {contacts.length > 0 && (
          <>
            {contacts.map((contact: string, index: number) => {
              return (
                <Fragment key={index}>
                  {index > 0 && (
                    <Typography component={'span'}>{index + 1 === contacts.length ? ' and ' : ', '}</Typography>
                  )}
                  <Typography component={'span'}>{contact}</Typography>
                </Fragment>
              );
            })}
            <Typography component={'span'}> as Contact{contacts.length > 1 ? 's' : ''}</Typography>
          </>
        )}
      </>
    );
  };

  const Assignees = () => {
    return (
      <>
        {assignees?.length > 0 && (
          <>
            {assignees.map((assignee: string, index: number) => {
              return (
                <Fragment key={index}>
                  {index > 0 && (
                    <Typography component={'span'}>{index + 1 === assignees.length ? ' and ' : ', '}</Typography>
                  )}
                  <Typography component={'span'}>{assignee}</Typography>
                </Fragment>
              );
            })}
            <Typography component={'span'}> as {assignees?.length > 1 ? 'Assignees' : 'Assignee'} </Typography>
          </>
        )}
      </>
    );
  };

  const Action = () => (
    <Typography component={'span'} className={classes.italic}>
      {type === 'insert' ? ' added ' : ' updated '}
    </Typography>
  );
  if (appraisal_status === 'Completed' && dates.completed_date) {
    const completedDate = new Date(dates.completed_date);
    const formattedCompletedDate = formatDate(completedDate, 'M/d/YYY @ h:mma');
    return (
      <Box mb={2}>
        <CurrentUser />
        <Action />
        <Typography component={'span'}>Status to Completed and Completed Date to {formattedCompletedDate}</Typography>
        {type === 'update' && notes && (
          <>
            <Typography component={'span'}> and Notes</Typography>
          </>
        )}
        <DisplayTime />
      </Box>
    );
  }

  if (type === 'update' && task_description && (task_assignee_full_name || due_date || completed_date)) {
    const isAssignorAssignee = user_account_name === task_assignee_full_name;
    const activityBy = !isAssignorAssignee ? ` by ${user_account_name}` : '';

    if (completed_date) {
      return (
        <Box mb={2}>
          <Typography component={'span'}>Task: {task_description} was set to </Typography>
          <Typography component={'span'} className={classes.italic}>
            Completed
          </Typography>
          <Typography component={'span'}> {activityBy}</Typography>
          <DisplayTime />
        </Box>
      );
    }

    return (
      <Box mb={2}>
        <Typography className={classes.bold} component={'span'}>
          {taskAssignee}
        </Typography>

        {task_assignee_full_name && due_date ? (
          <>
            <Typography component={'span'}>&nbsp;{taskAssignee === 'You' ? 'were' : 'was'}</Typography>
            <Typography component={'span'} className={classes.italic}>
              {' assigned '}
            </Typography>
            <Typography component={'span'}>
              {' '}
              Task: {task_description} with Due Date of {formatDate(new Date(due_date), 'M/d/YYY @ h:mma')}
              {activityBy}
            </Typography>
          </>
        ) : (
          <>
            {task_assignee_full_name && (
              <>
                <Typography component={'span'}>&nbsp;{taskAssignee === 'You' ? 'were' : 'was'}</Typography>
                <Typography component={'span'} className={classes.italic}>
                  {' assigned '}
                </Typography>
                <Typography component={'span'}>
                  {' '}
                  Task: {task_description} {activityBy}
                </Typography>
              </>
            )}

            {due_date && (
              <Typography component={'span'}>
                Task: {task_description} Due Date was set to {formatDate(new Date(due_date), 'M/d/YYY @ h:mma')}
                {activityBy}
              </Typography>
            )}
          </>
        )}
        <DisplayTime />
      </Box>
    );
  }

  if (due_date || inspection_date) {
    return (
      <Box mb={2}>
        <CurrentUser />
        <Action />
        <Typography component="span">{'Appraisal '}</Typography>
        {notes && type === 'update' && <Typography component="span">Notes </Typography>}

        {appraisal_status && <Typography component={'span'}>with Status of {appraisal_status} </Typography>}

        {type === 'insert' && !notes ? (
          <Typography component="span">and set</Typography>
        ) : (
          appraisal_status && <Typography component="span">and</Typography>
        )}

        {inspection_date && (
          <>
            <Typography component="span">
              {' '}
              Inspection Date to {formatDate(new Date(inspection_date), 'M/d/YYY @ h:mma')}
            </Typography>

            {due_date && <Typography component="span"> and</Typography>}
          </>
        )}
        {due_date && (
          <Typography component="span"> Due Date to {formatDate(new Date(due_date), 'M/d/YYY @ h:mma')} </Typography>
        )}
        {!!quote_fee && <Typography component={'span'}> and Quote Fee to ${quote_fee}</Typography>}
        {!!report_fee && <Typography component={'span'}> and Report Fee to ${report_fee}</Typography>}

        {contacts?.length > 0 && <Typography component={'span'}>{appraisal_status ? ' and ' : ' with '}</Typography>}
        <Contacts />
        {(!!quote_fee || !!report_fee || contacts?.length > 0 || appraisal_status) && assignees?.length > 0 && (
          <Typography component="span"> and </Typography>
        )}
        <Assignees />

        <DisplayTime />
      </Box>
    );
  }

  if (files && files.length > 0) {
    return (
      <Box mb={2}>
        <CurrentUser />
        <Typography component={'span'} className={classes.italic}>
          {' uploaded '}
        </Typography>
        {files.map((file: string, index: number) => (
          <Fragment key={index}>
            {index > 0 && <Typography component={'span'}>{index + 1 === files.length ? ' and ' : ', '}</Typography>}
            <Typography component={'span'}>{file}</Typography>
          </Fragment>
        ))}
        <DisplayTime />
      </Box>
    );
  }

  if (
    appraisal_status ||
    contacts?.length > 0 ||
    assignees?.length > 0 ||
    quote_fee ||
    report_fee ||
    // type === 'insert' ||
    notes
  ) {
    return (
      <Box mb={2}>
        <CurrentUser />

        <Action />

        <Typography component={'span'}>Appraisal </Typography>
        {notes && type === 'update' && <Typography component={'span'}>Notes</Typography>}

        {appraisal_status && <Typography component={'span'}> with Status of {appraisal_status}</Typography>}

        {(contacts?.length > 0 || assignees?.length > 0 || !!quote_fee || !!report_fee) && (
          <Typography component="span">
            {type === 'insert' ? ' and set ' : appraisal_status ? ' and ' : ' with '}
          </Typography>
        )}

        <Contacts />
        {contacts?.length > 0 && assignees?.length > 0 && <Typography component="span"> and </Typography>}
        <Assignees />

        {(assignees?.length > 0 || contacts?.length > 0) && (!!quote_fee || !!report_fee) && (
          <Typography component={'span'}> and </Typography>
        )}

        {!!quote_fee && <Typography component={'span'}>Quote Fee to ${quote_fee}</Typography>}

        {(!!quote_fee || notes) && !!report_fee && <Typography component={'span'}> and </Typography>}
        {!!report_fee && <Typography component={'span'}>Report Fee to ${report_fee}</Typography>}
        <DisplayTime />
      </Box>
    );
  }

  if (type === 'update' && notes) {
    return (
      <Box mb={2}>
        <CurrentUser />
        <Action />
        <Typography component={'span'}>Appraisal Notes</Typography>
        <DisplayTime />
      </Box>
    );
  }

  return null;
};

import React, { useState } from 'react';

import { Box, Card, Divider, Grid, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import {
  Create,
  CreateProps,
  DateInput,
  Record,
  required,
  ResourceContextProvider,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import EditAction from 'shared/components/Resource/EditAction';
import getExpensePermission from './permissions';
import Toolbar from './components/Toolbar';
import { Expense } from './types';

interface State {
  description: string;
  quantity: number;
  rate: number;
}

const CreateExpense = (props: CreateProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = styles();

  const onSuccess = ({ data }: { data: Expense }) => {
    notify('expense.created');
    redirect(`/expenses/${data?.id}`);
  };
  const transform = (data: Record) => {
    const newExpense: Expense = {
      ...(data as Expense),
    };
    return newExpense;
  };

  return (
    <ResourceContextProvider value="expense">
      <Create
        {...props}
        actions={<EditAction />}
        basePath="/expenses"
        onSuccess={onSuccess}
        transform={transform}
        resource="expense"
        className={classes.actionContainer}
      >
        <CreateExpenseFields formType="add" />
      </Create>
    </ResourceContextProvider>
  );
};

export const CreateExpenseFields = (props: any) => {
  const { formType, ...restProps } = props;

  const classes = styles();
  const [values, setValues] = useState<State>({
    description: '',
    quantity: formType === 'add' ? 1 : props?.record?.quantity,
    rate: formType === 'add' ? 0 : props?.record?.rate,
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  return (
    <Card variant="outlined" className={classes.formBottom}>
      <Box pr={2}>
        <SimpleForm
          {...restProps}
          initialValues={{
            expense_date: new Date(),
            quantity: 1,
          }}
          toolbar={<Toolbar getPermission={getExpensePermission} formType={formType} />}
        >
          <Box className={classes.formContainer}>
            <Typography classes={{ root: classes.headingFirst }}>DESCRIPTION</Typography>
            <Divider classes={{ root: classes.divider }}></Divider>
            <Grid container direction="column">
              <Grid container item md={6} sm={12} xs={12}>
                <TextInput source="description" variant="outlined" fullWidth validate={required()} />
              </Grid>
            </Grid>
            <Grid container item md={4}>
              <DateInput source="expense_date" variant="outlined" fullWidth />
            </Grid>
            <Typography classes={{ root: classes.headingFirst }}>EXPENSE</Typography>
            <Divider classes={{ root: classes.divider }}></Divider>
            <Grid container direction="row" justify="space-between">
              <Grid item md={3} xs={12}>
                <TextInput
                  validate={required()}
                  source={'quantity'}
                  variant="outlined"
                  type="number"
                  value={values.quantity}
                  onChange={handleChange('quantity')}
                  fullWidth
                  onWheel={(e: any) => {
                    e.target.blur();
                  }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextInput
                  validate={required()}
                  value={values.rate}
                  onChange={handleChange('rate')}
                  source="rate"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  fullWidth
                  onWheel={(e: any) => {
                    e.target.blur();
                  }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography style={{ paddingTop: '12.5px' }}>
                  Fee: ${(values.rate * values.quantity).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </SimpleForm>
      </Box>
    </Card>
  );
};

const styles = makeStyles({
  divider: {
    marginBottom: '20px',
    width: '100%',
  },
  dividerBottom: {
    marginBottom: '5px',
    marginTop: '30px',
    width: '100%',
  },
  formContainer: {
    display: 'flow-root',
    width: '100%',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  sectionHeading: {
    marginTop: '30px',
  },
  heading: {
    marginTop: '14px',
  },
  mapBottomText: {
    fontStyle: 'italic',
    marginTop: '5px',
    marginBottom: '15px',
    fontSize: '16px',
    color: '#444444',
  },
  formBottom: {
    marginBottom: '35px',
    padding: '0px',
  },
  actionContainer: {
    marginTop: '2rem',
    padding: '0 1em',
  },
  pillsMargin: {
    marginBottom: '23px',
  },
  headingFirst: {
    marginTop: '14px',
  },
});

export default CreateExpense;

import { gql, useMutation } from '@apollo/client';

const INSERT_MESSAGE_QUERY = gql`
  mutation InsertMessage($messageThreadId: uuid, $senderType: message_user_type, $message: String, $senderId: uuid) {
    insert_message(
      objects: {
        message_thread_id: $messageThreadId
        sender_type: $senderType
        message: $message
        sender_id: $senderId
      }
    ) {
      returning {
        id
      }
    }
  }
`;

interface InsertMessageRequestData {
  messageThreadId: string;
  senderType: string;
  senderId: any;
  message: string;
}

export const useInsertMessage = (variables: InsertMessageRequestData) => {
  return useMutation(INSERT_MESSAGE_QUERY, { variables });
};

const MESSAGE_MARK_READ_QUERY = gql`
  mutation MessageMarkRead($message_id: String, $read_where: String, $recipient_id: String) {
    message_mark_read(args: { message_id: $message_id, read_where: $read_where, recipient_id: $recipient_id }) {
      read
    }
  }
`;
interface MessageMarkReadRequestData {
  message_id: string;
  read_where: string;
  recipient_id: string;
}
interface MessageMarkReadResponseData {
  message_mark_read: {
    read: boolean;
  };
}
export const useMessageMarkRead = () => {
  return useMutation<MessageMarkReadResponseData, MessageMarkReadRequestData>(MESSAGE_MARK_READ_QUERY);
};

const MESSAGE_DELETE_QUERY = gql`
  mutation MessageDelete($id: uuid) {
    delete_message(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

interface MessageDeleteMutationRequest {
  id: string;
}

interface MessageDeleteMutationResponse {
  delete_message: { affected_rows: number };
}

export const useMessageDeleteMutation = () => {
  return useMutation<MessageDeleteMutationResponse, MessageDeleteMutationRequest>(MESSAGE_DELETE_QUERY);
};

const MESSAGE_UPDATE_QUERY = gql`
  mutation UpdateMessage($id: uuid, $message: String) {
    update_message(where: { id: { _eq: $id } }, _set: { message: $message }) {
      affected_rows
    }
  }
`;

interface MessageUpdateMutationRequest {
  id: any;
  message: string;
}
interface MessageUpdateMutationResponse {
  update_message: { affected_rows: number };
}

export const useMessageUpdateMutation = () => {
  return useMutation<MessageUpdateMutationResponse, MessageUpdateMutationRequest>(MESSAGE_UPDATE_QUERY);
};

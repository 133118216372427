import React, { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Grid, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FieldTitle } from 'react-admin';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { isValidDate } from 'shared/utils';
import { AccessTime } from '@material-ui/icons';

type CustomDateTimeInputProps = {
  source?: string;
  initialValue?: any;
  onChange: (data: any) => void;
};
const CustomDateTimeInput = ({ source, initialValue, onChange }: CustomDateTimeInputProps) => {
  const [dateVal, setDateVal] = useState<any>(null);
  const [timeVal, setTimeVal] = useState<any>(null);
  const classes = stylesDate();

  useEffect(() => {
    return () => {
      setDateVal(null);
      setTimeVal(null);
    };
  }, []);

  useEffect(() => {
    if (initialValue) {
      setDateVal(initialValue);
      setTimeVal(initialValue);
    }
  }, [initialValue]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container item sm={12} md={12} lg={12} style={{ margin: '1.2rem 0' }}>
        <Grid container item sm={7} md={7} lg={7} className={classes.dateWrapper}>
          <KeyboardDatePicker
            label={<FieldTitle source={source} />}
            autoOk
            variant="dialog"
            inputVariant="outlined"
            value={dateVal}
            format="MM/dd/yyyy"
            onChange={function (date: MaterialUiPickersDate): void {
              setDateVal(date);
              onChange(date);
              //   setDueDateDirty(true);
              //   form.change('due_date', date?.toISOString());

              //for setting 5:00 PM as default time
              if (date && !timeVal) {
                date.setHours(source === 'due_date' ? 17 : 13); // Set custom hours
                date.setMinutes(0); // Set custom minutes
                date.setSeconds(0);
                setTimeVal(date.toLocaleString('en-US'));
                onChange(date);
                // form.change('due_date', date?.toISOString());
              }
            }}
            margin="dense"
            clearable
            fullWidth
          />
        </Grid>
        <Grid container item sm={5} md={5} lg={5} alignItems="flex-end" className={classes.timeWrapper}>
          <KeyboardTimePicker
            label={<FieldTitle label="Time" source="d_time" />}
            variant="dialog"
            inputVariant="outlined"
            autoOk
            value={timeVal}
            onChange={(date: any) => {
              date && setTimeVal(date.toLocaleString('en-US'));
              //   setDueDateDirty(true);
              if (dateVal && date !== 'Invalid Date') {
                let tVal = new Date(date);
                let dVal = new Date(dateVal);
                dVal.setHours(tVal.getHours());
                dVal.setMinutes(tVal.getMinutes());
                dVal.setSeconds(tVal.getSeconds());
                isValidDate(dVal) && setDateVal(dVal);
                onChange(dVal);

                // form.change('due_date', dVal);
              }
            }}
            margin="dense"
            clearable
            keyboardIcon={<AccessTime />}
            fullWidth
            disabled={!timeVal}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default CustomDateTimeInput;

export const stylesDate = makeStyles({
  dateWrapper: {
    paddingRight: '5px',
    '@media (max-width: 960px)': {
      padding: '0px',
    },
  },
  timeWrapper: {
    paddingLeft: '5px',
    '@media (max-width: 960px)': {
      padding: '0px',
    },
  },
});

import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Card,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import IconCancel from '@material-ui/icons/Cancel';
import IconCheck from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import debounce from 'lodash/debounce';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  SaveButton,
  TextInput,
  useGetIdentity,
  useMutation,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import { AddToCalendar } from 'shared/components/AddToCalendar/AddToCalendar';
import AutocompleteInput from 'shared/components/AutocompleteInput';
import CurrencyInput from 'shared/components/CurrencyInput';
import MenuButtonInput from 'shared/components/MenuButtonInputV2';
import { DateInput, getTime, isToday, isTomorrow, isYesterday } from 'shared/components/Pickers';
import { STATUS_MAPPING } from 'shared/constants/appraisalStatus';
import { Appraisal_Commissions } from 'shared/generated/types';
import { useAppraisalOptions, useOrganizationByPk } from 'shared/hooks/useAppraisalOptions';
import { useGetNextTaskSubscription } from 'shared/hooks/useAppraisalTasks';
import useFormPermissions from 'shared/hooks/useResourcePermissions';
import { copyToClipboard } from 'shared/utils';
import { activeDateFieldType } from './types';
import getAppraisalPermission from 'views/Appraisal/permission';
import AddCommissionButton from 'views/Client/components/popups/AddCommission';
import EditCommissionButton from 'views/Client/components/popups/EditCommission';

import { styleRight } from 'shared/hooks/useEditFormStyle';
import styles from '../../hooks/useAssigneeListStyles';

type Props = {
  commissionData?: any;
  refetch?: any;
  setActiveDateField: Dispatch<SetStateAction<activeDateFieldType | undefined>>;
  setOpenDateModal: any;
};

const WorkFlowSection = ({ commissionData, setActiveDateField, setOpenDateModal }: Props) => {
  const classes = styleRight();
  const modalClasses = styles();

  const { identity } = useGetIdentity();
  const formData = useFormState();
  const form = useForm();
  const location = useLocation();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const [appraisalOptions] = useAppraisalOptions();

  const [{ permissions }] = useFormPermissions({ getPermission: getAppraisalPermission });
  const { data: organization } = useOrganizationByPk({ id: identity?.organization_id });
  const isOnlyOneUserActive = organization?.organization_by_pk.user_accounts_active_count === 1;

  const [workflowEdit, setWorkflowEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editData, setEditData] = useState<any>('');

  const getWorkflowFields = (initialValue: boolean) => ({
    appraisal_file_number: initialValue,
    appraisal_status_id: initialValue,
    appraisal_priority_id: initialValue,
    assignee_user_account_ids: initialValue,
    due_date: initialValue,
    reviewed_date: initialValue,
    revision_request_date: initialValue,
    on_hold_date: initialValue,
    canceled_date: initialValue,
    completed_date: initialValue,
    submitted_date: initialValue,
  });

  const [workflowFields, setWorkflowFields] = useState<any>(getWorkflowFields(false));

  // const [dateVal, setDateVal] = useState<MaterialUiPickersDate>(formData?.values?.due_date);
  // const [timeVal, setTimeVal] = useState<any>(formData?.values?.due_date);
  const [isDueDateDirty, setDueDateDirty] = useState<boolean>(false);
  const [selectedCommission, setSelectedCommission] = useState<any>('');

  const setWorkflows = (val: boolean) => {
    setWorkflowFields(getWorkflowFields(val));
    setWorkflowEdit(val);
  };

  const save = useCallback(debounce(form.submit, 0), []);
  const isFormDirty = (): boolean => !!Object.keys(formData.dirtyFields).length;
  let appraisal_commissions: Appraisal_Commissions[] = commissionData?.data?.appraisal_commissions;

  const { data: nextTaskData } = useGetNextTaskSubscription(formData?.values.id);

  const nextTask = useMemo(() => {
    if (!nextTaskData) {
      return '';
    }
    const tasklist = nextTaskData.tasklist[0];

    if (!tasklist || tasklist.tasks_count === 0) {
      return 'None';
    }

    if (tasklist.tasks_count === tasklist.tasks_completed_count) {
      return 'Completed';
    }
    return tasklist.tasks[0].description;
  }, [nextTaskData]);

  const isStartRange = useMediaQuery('(min-width: 960px)');
  const isEndRange = useMediaQuery('(max-width: 1540px)');

  const [deleteCommissionMutation] = useMutation({
    type: 'delete',
    resource: 'appraisal_commission',
    payload: {},
  });

  const deleteCommission = async () => {
    return new Promise((resolve, reject) => {
      deleteCommissionMutation(
        {
          payload: {
            id: selectedCommission,
            data: {},
          },
        },
        {
          onSuccess: ({ data }: any) => {
            resolve(data);
          },
          onFailure: ({ error }: any) => {
            notify(error.message, 'error');
            reject(error);
          },
        },
      );
    });
  };
  const removeCommission = async () => {
    await deleteCommission();
    redirect(`/appraisals/`);
    commissionData.refetch();
    setOpenDeleteModal(false);
    notify('appraisal_commission.deleted');
    refresh();
  };

  const statusData = useMemo(() => {
    if (appraisalOptions.data && formData) {
      const { appraisal_status_id: initialAppraisalStatusId } = formData?.initialValues;
      const { appraisal_status_id: newAppraisalStatusId } = formData?.values;

      if (initialAppraisalStatusId !== newAppraisalStatusId) {
        const newStatus = appraisalOptions.data.appraisalStatuses.find((item) => item.id === newAppraisalStatusId);

        const showReportFee = initialAppraisalStatusId === 8 && newAppraisalStatusId === 1;

        if (newStatus?.id) {
          const label = STATUS_MAPPING[newStatus.id].source;
          const value = formData.values[label];
          if (!value) {
            form.change(label, new Date().toISOString());
          }

          return {
            title: `Change Status to ${newStatus?.status}?`,
            ...STATUS_MAPPING[newStatus.id],
            showReportFee,
          };
        }
      }
    }
  }, [appraisalOptions.data, form, formData]);

  useEffect(() => {
    const { report_fee, quote_fee } = formData.values;

    if (statusData?.showReportFee && !report_fee) {
      form.change('report_fee', quote_fee || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusData?.showReportFee]);

  return (
    <>
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(!openDeleteModal)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className={modalClasses.paper}>
          <Typography classes={{ root: modalClasses.heading }}>Remove Assignee</Typography>
          <p>Are you sure you want to remove this assignee?</p>
          <Box className={modalClasses.confirmBtnBox}>
            <Button
              onClick={() => setOpenDeleteModal(false)}
              label="Cancel"
              color="secondary"
              startIcon={<ErrorOutline />}
            ></Button>
            <Button
              onClick={() => removeCommission()}
              label="Confirm"
              color="primary"
              startIcon={<IconCheck />}
            ></Button>
          </Box>
        </Box>
      </Modal>

      {openEditModal && (
        <EditCommissionButton
          appraisal={formData?.values}
          currentCommission={editData}
          refetchCommission={() => commissionData.refetch()}
          hideEditButton={true}
          triggerCloseModal={() => setOpenEditModal(false)}
        />
      )}

      {!!isFormDirty && statusData && (
        <Modal
          open={!!(workflowFields.appraisal_status_id && formData.dirtyFields.appraisal_status_id)}
          onClose={() => form.change('appraisal_status_id', formData.initialValues.appraisal_status_id)}
          aria-labelledby="appraisal-status-change"
          aria-describedby="appraisal-status-change-modal"
        >
          <Box className={modalClasses.appraisalStatusModalContent}>
            <Box mb={3}>
              <Typography classes={{ root: modalClasses.heading }}>{statusData.title}</Typography>
            </Box>

            <DateInput
              size="small"
              variant="outlined"
              fullWidth
              label={statusData.label.includes('Date') ? statusData.label : statusData.label + ' Date'}
              source={statusData.source}
            />

            {statusData.showReportFee && <CurrencyInput source="report_fee" variant="outlined" size="small" />}

            <Box display={'flex'} justifyContent={'space-between'}>
              <Button
                onClick={() => form.change('appraisal_status_id', formData.initialValues.appraisal_status_id)}
                label="Cancel"
                startIcon={<IconCancel />}
              />
              <SaveButton
                handleSubmitWithRedirect={() => {
                  save();
                  setWorkflowEdit(false);
                }}
              />
            </Box>
          </Box>
        </Modal>
      )}

      <Card variant="outlined" classes={{ root: `${classes.card} ${classes.overflow}` }}>
        <Box className={classes.flexBox}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading500} ${classes.fontLarge}` }}>
            Workflow
          </Typography>
          {(isFormDirty() || isDueDateDirty) && workflowEdit && (
            <IconButton
              className={classes.saveActionButton}
              edge="end"
              onClick={() => {
                save();
                setWorkflowEdit(false);
                setWorkflows(false);
                setDueDateDirty(false);
              }}
            >
              <SaveIcon classes={{ root: classes.icon }} />
            </IconButton>
          )}
          {permissions.edit && !workflowEdit && (
            <IconButton
              className={classes.topEditBtn}
              edge="end"
              disabled={workflowEdit}
              onClick={() => setWorkflows(true)}
            >
              <EditIcon classes={{ root: classes.icon }} />
            </IconButton>
          )}
          {workflowEdit && (
            <IconButton
              classes={{ root: classes.topEditBtn }}
              disabled={!workflowEdit}
              edge="end"
              onClick={() => {
                setWorkflowEdit(false);
                setWorkflows(false);
                Object.keys(formData.dirtyFields).forEach((field: string) => {
                  const [a, b] = field.split('.');
                  if (a && b) {
                    form.change(field, formData.initialValues[a][b]);
                  } else {
                    form.change(field, formData.initialValues[a]);
                  }
                });
              }}
            >
              <CloseIcon classes={{ root: classes.icon }} />
            </IconButton>
          )}
        </Box>
        <Divider classes={{ root: classes.dividerSubSelect }}></Divider>
        <Box className={classes.flexBox}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>File #</Typography>
          <Box pr={2} className={classes.inputContainer}>
            <TextInput
              onClick={(e: any) => copyToClipboard(formData.values.appraisal_file_number, e, workflowEdit)}
              size="small"
              color="secondary"
              InputProps={{
                placeholder: 'Click pencil to edit',
                classes: {
                  root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                    workflowFields.appraisal_file_number ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                  }`,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      className={classes.fieldEditBtn}
                      edge="end"
                      disabled={workflowFields.appraisal_file_number && !formData.dirtyFields.appraisal_file_number}
                    >
                      {workflowFields.appraisal_file_number && formData.dirtyFields.appraisal_file_number && (
                        <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                      )}
                      &nbsp;
                      {workflowFields.appraisal_file_number && formData.dirtyFields.appraisal_file_number && (
                        <CloseIcon
                          fontSize="small"
                          classes={{ root: classes.icon }}
                          onClick={() =>
                            form.change('appraisal_file_number', formData.initialValues.appraisal_file_number)
                          }
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
              variant="outlined"
              disabled={!permissions.edit || !workflowFields.appraisal_file_number}
              fullWidth
              label=""
              source="appraisal_file_number"
            />
          </Box>
        </Box>
        <Box className={classes.flexBox}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Status</Typography>
          <Box pr={2} className={classes.inputContainer}>
            {appraisalOptions.data && (
              <MenuButtonInput
                label=""
                variant="outlined"
                source="appraisal_status_id"
                optionText="status"
                disabled={!permissions.edit || !workflowFields.appraisal_status_id}
                choices={appraisalOptions.data.appraisalStatuses ?? []}
              />
            )}
            {/* <IconButton
                size="small"
                className={`${classes.fieldEditBtn} ${classes.fieldEditStatusBtn}`}
                edge="end"
                disabled={workflowFields.appraisal_status_id && !formData.dirtyFields.appraisal_status_id}
              >
                {workflowFields.appraisal_status_id && formData.dirtyFields.appraisal_status_id && (
                  <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                )}
                &nbsp;
                {workflowFields.appraisal_status_id && formData.dirtyFields.appraisal_status_id && (
                  <CloseIcon
                    fontSize="small"
                    classes={{ root: classes.icon }}
                    onClick={() => form.change('appraisal_status_id', formData.initialValues.appraisal_status_id)}
                  />
                )}
              </IconButton> */}
          </Box>
        </Box>
        {(formData.values.appraisal_priority_id === 2 || workflowEdit) && (
          <Box className={classes.flexBox} style={{ marginTop: '4px' }}>
            <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Priority</Typography>
            <Box pr={2} className={classes.inputContainer}>
              <AutocompleteInput
                disabled={!permissions.edit || !workflowFields.appraisal_priority_id}
                label=""
                source="appraisal_priority_id"
                fullWidth
                optionText="priority"
                variant="outlined"
                allowEmpty={true}
                choices={appraisalOptions.data?.appraisalPriorities ?? []}
                css={{ popupIndicator: classes.popupIndicator, popupIndicatorOpen: classes.popupIndicatorOpen }}
                options={{
                  InputProps: {
                    placeholder: 'Click pencil to edit',
                    classes: {
                      root: `${classes.cssOutlinedInput} ${classes.inputFontSize} ${
                        workflowFields.appraisal_priority_id ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                      } ${formData.values.appraisal_priority_id === 2 ? classes.priorityRush : classes.priorityNormal}`,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          className={classes.fieldEditBtn}
                          edge="end"
                          disabled={workflowFields.appraisal_priority_id && !formData.dirtyFields.appraisal_priority_id}
                        >
                          {workflowFields.appraisal_priority_id && formData.dirtyFields.appraisal_priority_id && (
                            <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                          )}
                          &nbsp;
                          {workflowFields.appraisal_priority_id && formData.dirtyFields.appraisal_priority_id && (
                            <CloseIcon
                              fontSize="small"
                              classes={{ root: classes.icon }}
                              onClick={() =>
                                form.change('appraisal_priority_id', formData.initialValues.appraisal_priority_id)
                              }
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                  InputLabelProps: {
                    classes: {
                      root: `${classes.inputFontSize}`,
                    },
                  },
                }}
                FormHelperTextProps={{ style: { display: 'none' } }}
              />
            </Box>
          </Box>
        )}
        {/* {!isOnlyOneUserActive ? (
            <Divider classes={{ root: classes.dividerEnd }}></Divider>
          ) : (
            <div style={{ marginTop: '5px' }}></div>
          )} */}
        <Box className={classes.flexBox}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
            Due
          </Typography>
          <Box pr={2} className={classes.inputContainer}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                onClick={(e: any) => {
                  if (workflowEdit) {
                    setOpenDateModal(true);
                    setActiveDateField({
                      label: 'Due Date',
                      key: 'due_date',
                    });
                  }
                }}
                FormHelperTextProps={{ style: { display: 'none' } }}
                variant="dialog"
                inputVariant="outlined"
                disabled={!permissions.edit || !workflowFields.due_date || !workflowEdit}
                fullWidth
                keyboardIcon={''}
                onChange={(date: any) => console.log('')}
                // value={dateVal}
                value={formData?.values?.due_date}
                labelFunc={(date: any) => {
                  if (formData?.values?.due_date) {
                    if (new Date(date).toString() === 'Invalid Date') return '';
                    if (isToday(date)) {
                      return `Today @ ${getTime(date)}`;
                    } else if (isTomorrow(date)) {
                      return `Tomorrow @ ${getTime(date)}`;
                    } else if (isYesterday(date)) {
                      return `Yesterday @ ${getTime(date)}`;
                    } else {
                      return `${new Date(date).toLocaleDateString()} @ ${getTime(date)}`;
                    }
                  } else return '';
                }}
                format="MM/dd/yyyy @ hh:mma"
                margin="dense"
                InputProps={{
                  placeholder: `Click ${workflowEdit ? '' : 'pencil'} to edit`,
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                      workflowFields.due_date ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                    }`,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </MuiPickersUtilsProvider>
            {!workflowEdit && formData?.values?.due_date && (
              <Box
                className={`${classes.addToCalendarBtn}`}
                style={{ display: isStartRange && isEndRange ? 'none' : '' }}
              >
                <AddToCalendar
                  date={formData.values.due_date}
                  title={`Appraisal Due: ${formData.values.appraisal_file_number}`}
                  location={formData.values.property.location_address}
                />
              </Box>
            )}
          </Box>
        </Box>
        {formData.values.reviewed_date && (
          <Box className={classes.flexBox}>
            <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
              In Review
            </Typography>
            <Box pr={2} className={classes.inputContainer}>
              <DateInput
                onClick={(e: any) => copyToClipboard(formData.values.reviewed_date, e, workflowEdit)}
                size="small"
                color="secondary"
                InputProps={{
                  placeholder: 'Click pencil to edit',
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                      workflowFields.reviewed_date ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                    }`,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                FormHelperTextProps={{ style: { display: 'none' } }}
                variant="outlined"
                disabled={!permissions.edit || !workflowFields.reviewed_date}
                fullWidth
                label=""
                source="reviewed_date"
              />
              <IconButton
                size="small"
                className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
                edge="end"
                disabled={workflowFields.reviewed_date && !formData.dirtyFields.reviewed_date}
              >
                {workflowFields.reviewed_date && formData.dirtyFields.reviewed_date && (
                  <SaveIcon
                    fontSize="small"
                    classes={{ root: classes.icon }}
                    onClick={() => {
                      save();
                    }}
                  />
                )}
                &nbsp;
                {workflowFields.reviewed_date && formData.dirtyFields.reviewed_date && (
                  <CloseIcon
                    fontSize="small"
                    classes={{ root: classes.icon }}
                    onClick={() => form.change('submitted_date', formData.initialValues.submitted_date)}
                  />
                )}
              </IconButton>
            </Box>
          </Box>
        )}
        {formData.values.revision_request_date && (
          <Box className={classes.flexBox}>
            <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
              In Revision
            </Typography>
            <Box pr={2} className={classes.inputContainer}>
              <DateInput
                onClick={(e: any) => copyToClipboard(formData.values.revision_request_date, e, workflowEdit)}
                size="small"
                color="secondary"
                InputProps={{
                  placeholder: 'Click pencil to edit',
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                      workflowFields.revision_request_date ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                    }`,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                FormHelperTextProps={{ style: { display: 'none' } }}
                variant="outlined"
                disabled={!permissions.edit || !workflowFields.revision_request_date}
                fullWidth
                label=""
                source="revision_request_date"
              />
              <IconButton
                size="small"
                className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
                edge="end"
                disabled={workflowFields.revision_request_date && !formData.dirtyFields.revision_request_date}
              >
                {workflowFields.revision_request_date && formData.dirtyFields.revision_request_date && (
                  <SaveIcon
                    fontSize="small"
                    classes={{ root: classes.icon }}
                    onClick={() => {
                      save();
                    }}
                  />
                )}
                &nbsp;
                {workflowFields.revision_request_date && formData.dirtyFields.revision_request_date && (
                  <CloseIcon
                    fontSize="small"
                    classes={{ root: classes.icon }}
                    onClick={() => form.change('revision_request_date', formData.initialValues.revision_request_date)}
                  />
                )}
              </IconButton>
            </Box>
          </Box>
        )}
        {formData.values.on_hold_date && (
          <Box className={classes.flexBox}>
            <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
              On Hold
            </Typography>
            <Box pr={2} className={classes.inputContainer}>
              <DateInput
                onClick={(e: any) => copyToClipboard(formData.values.on_hold_date, e, workflowEdit)}
                size="small"
                color="secondary"
                InputProps={{
                  placeholder: 'Click pencil to edit',
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                      workflowFields.on_hold_date ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                    }`,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                FormHelperTextProps={{ style: { display: 'none' } }}
                variant="outlined"
                disabled={!permissions.edit || !workflowFields.on_hold_date}
                fullWidth
                label=""
                source="on_hold_date"
              />
              <IconButton
                size="small"
                className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
                edge="end"
                disabled={workflowFields.on_hold_date && !formData.dirtyFields.paid_date}
              >
                {workflowFields.on_hold_date && formData.dirtyFields.on_hold_date && (
                  <SaveIcon
                    fontSize="small"
                    classes={{ root: classes.icon }}
                    onClick={() => {
                      save();
                    }}
                  />
                )}
                &nbsp;
                {workflowFields.on_hold_date && formData.dirtyFields.on_hold_date && (
                  <CloseIcon
                    fontSize="small"
                    classes={{ root: classes.icon }}
                    onClick={() => form.change('on_hold_date', formData.initialValues.on_hold_date)}
                  />
                )}
              </IconButton>
            </Box>
          </Box>
        )}
        {formData.values.canceled_date && (
          <Box className={classes.flexBox}>
            <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
              Canceled
            </Typography>
            <Box pr={2} className={classes.inputContainer}>
              <DateInput
                onClick={(e: any) => copyToClipboard(formData.values.canceled_date, e, workflowEdit)}
                size="small"
                color="secondary"
                InputProps={{
                  placeholder: 'Click pencil to edit',
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                      workflowFields.canceled_date ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                    }`,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                FormHelperTextProps={{ style: { display: 'none' } }}
                variant="outlined"
                disabled={!permissions.edit || !workflowFields.canceled_date}
                fullWidth
                label=""
                source="canceled_date"
              />
              <IconButton
                size="small"
                className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
                edge="end"
                disabled={workflowFields.canceled_date && !formData.dirtyFields.canceled_date}
              >
                {workflowFields.canceled_date && formData.dirtyFields.canceled_date && (
                  <SaveIcon
                    fontSize="small"
                    classes={{ root: classes.icon }}
                    onClick={() => {
                      save();
                    }}
                  />
                )}
                &nbsp;
                {workflowFields.canceled_date && formData.dirtyFields.canceled_date && (
                  <CloseIcon
                    fontSize="small"
                    classes={{ root: classes.icon }}
                    onClick={() => form.change('canceled_date', formData.initialValues.canceled_date)}
                  />
                )}
              </IconButton>
            </Box>
          </Box>
        )}
        {formData.values.completed_date && (
          <Box className={classes.flexBox}>
            <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
              Completed
            </Typography>
            <Box pr={2} className={classes.inputContainer}>
              <DateInput
                onClick={(e: any) => copyToClipboard(formData.values.completed_date, e, workflowEdit)}
                size="small"
                color="secondary"
                InputProps={{
                  placeholder: 'Click pencil to edit',
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                      workflowFields.completed_date ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                    }`,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                FormHelperTextProps={{ style: { display: 'none' } }}
                variant="outlined"
                disabled={!permissions.edit || !workflowFields.completed_date}
                fullWidth
                label=""
                source="completed_date"
              />
              <IconButton
                size="small"
                className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
                edge="end"
                disabled={workflowFields.completed_date && !formData.dirtyFields.completed_date}
              >
                {workflowFields.completed_date && formData.dirtyFields.completed_date && (
                  <SaveIcon
                    fontSize="small"
                    classes={{ root: classes.icon }}
                    onClick={() => {
                      save();
                    }}
                  />
                )}
                &nbsp;
                {workflowFields.completed_date && formData.dirtyFields.completed_date && (
                  <CloseIcon
                    fontSize="small"
                    classes={{ root: classes.icon }}
                    onClick={() => form.change('completed_date', formData.initialValues.completed_date)}
                  />
                )}
              </IconButton>
            </Box>
          </Box>
        )}
        <Divider classes={{ root: classes.dividerEnd }}></Divider>
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'} position={'relative'}>
          <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Next Task</Typography>

          <Box pr={2} className={classes.inputContainer} onClick={() => redirect(`${location.pathname}#tasks`)}>
            <Typography classes={{ root: classes.nextTask }}>{nextTask}</Typography>
          </Box>
        </Box>{' '}
        {!isOnlyOneUserActive ? (
          <Divider classes={{ root: classes.dividerEnd }}></Divider>
        ) : (
          <div style={{ marginTop: '8px' }}></div>
        )}
        {!isOnlyOneUserActive && (
          <>
            <Box className={classes.flexBoxAuto}>
              <Typography
                classes={{ root: `${classes.heading} ${classes.heading400}` }}
                style={{ marginBottom: '12px' }}
              >
                Assignees
              </Typography>
              {appraisal_commissions?.length === 0 && !workflowEdit ? (
                <Box pr={2} className={classes.inputContainer}>
                  <Typography classes={{ root: classes.nextTask }}>Unassigned</Typography>
                </Box>
              ) : (
                <Box
                  pr={2}
                  pb={1}
                  pt={1}
                  mb={appraisal_commissions?.length !== 0 || workflowEdit ? 0 : 4}
                  className={classes.inputContainer}
                >
                  {appraisal_commissions?.map((choice) =>
                    workflowEdit ? (
                      <Chip
                        key={choice?.id}
                        label={`${choice?.assignee_full_name} (${choice?.assignee_role})`}
                        size="medium"
                        style={{
                          backgroundColor: '#e0e0e0',
                          color: 'rgba(0, 0, 0, 0.87)',
                          height: '32px',
                          margin: '3px',
                        }}
                        onDelete={() => {
                          setSelectedCommission(choice?.id);
                          setOpenDeleteModal(true);
                        }}
                        onClick={() => {
                          setOpenEditModal(true);
                          setEditData(choice);
                        }}
                        // deleteIcon={
                        //   <CloseIcon
                        //     fontSize="large"
                        //     // classes={{ root: classes.icon }}
                        //     onClick={() =>
                        //       form.change('assignee_user_account_ids', formData.initialValues.assignee_user_account_ids)
                        //     }
                        //   />
                        // }
                      />
                    ) : (
                      <Chip
                        key={choice?.id}
                        label={`${choice?.assignee_full_name} (${choice?.assignee_role})`}
                        style={{
                          backgroundColor: '#e0e0e0',
                          color: 'rgba(0, 0, 0, 0.87)',
                          height: '32px',
                          margin: '3px',
                        }}
                        onClick={() => redirect(`/appraisals/${formData?.values?.id}#accounting`)}
                      />
                    ),
                  )}
                  {workflowEdit && (
                    <div>
                      <AddCommissionButton
                        appraisal={formData?.values}
                        clientId={formData?.values?.client_id}
                        refetchCommission={() => commissionData.refetch()}
                      />
                    </div>
                  )}
                </Box>
              )}
            </Box>
          </>
        )}
      </Card>
    </>
  );
};

export default WorkFlowSection;

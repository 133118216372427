import DateFnsUtils from '@date-io/date-fns';
import { Divider, Grid, Typography } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { FieldTitle } from 'react-admin';
import { styles } from '../../appraisalStyles';
import MeetingInput from '../MeetingInput';

interface Props {
  formData: any;
  dateVal: MaterialUiPickersDate;
  setDateVal: Dispatch<SetStateAction<MaterialUiPickersDate>>;
  setInspectionContactIds: Dispatch<any>;
}

const InspectionSection = ({ formData, setDateVal, dateVal, setInspectionContactIds }: Props) => {
  const classes = styles();
  const [timeVal, setTimeVal] = useState<any>(null);

  function isValidDate(date: any) {
    const parsedDate: any = new Date(date);
    // Check if the parsed date is a valid date and not NaN
    return !isNaN(parsedDate) && !isNaN(parsedDate.getDate());
  }
  const allContacts = [
    ...(formData?.ordered_by_contact_ids ? formData?.ordered_by_contact_ids : []),
    ...formData?.owner_contact_ids,
  ];

  return (
    <>
      <Typography classes={{ root: classes.sectionHeading }}>INSPECTION</Typography>
      <Divider classes={{ root: classes.divider }}></Divider>
      <Grid container direction="column" alignItems="center">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container sm={12} md={5} lg={5} style={{ marginBottom: '1.2rem', marginTop: '-3px' }}>
            <Grid container sm={12} md={7} lg={7} className={classes.dateWrapper}>
              <KeyboardDatePicker
                label={<FieldTitle label="Inspection Date" source="inspection_date" />}
                autoOk
                variant="dialog"
                inputVariant="outlined"
                value={dateVal}
                format="MM/dd/yyyy"
                onChange={function (date: MaterialUiPickersDate): void {
                  setDateVal(date);
                  //for setting 1:00 PM as default time
                  if (date && !timeVal) {
                    date.setHours(13); // Set custom hours
                    date.setMinutes(0); // Set custom minutes
                    date.setSeconds(0);
                    setTimeVal(date.toLocaleString('en-US'));
                  }
                }}
                margin="dense"
                clearable
                fullWidth
              />
            </Grid>

            <Grid container sm={12} md={5} lg={5} alignItems="flex-end" className={classes.timeWrapper}>
              <KeyboardTimePicker
                label={<FieldTitle label="Time" source="d_time" />}
                variant="dialog"
                inputVariant="outlined"
                autoOk
                value={timeVal}
                onChange={(date: any) => {
                  setTimeVal(date.toLocaleString('en-US'));
                  if (dateVal && date !== 'Invalid Date') {
                    let tVal = new Date(date);
                    let dVal = new Date(dateVal);
                    dVal.setHours(tVal.getHours());
                    dVal.setMinutes(tVal.getMinutes());
                    dVal.setSeconds(tVal.getSeconds());
                    isValidDate(dVal) && setDateVal(dVal);
                  }
                }}
                margin="dense"
                clearable
                keyboardIcon={<AccessTime />}
                fullWidth
                disabled={!timeVal}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid container md={5} sm={12} xs={12}>
          <MeetingInput
            allContacts={allContacts}
            meetingContacts={[]}
            clientId={formData?.client_id}
            setMeetingContacts={(data: any) => {
              const contacts = data.map((item: any) => item.id);
              setInspectionContactIds(contacts);
            }}
            isEditPage={false}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InspectionSection;

import React from 'react';
import { required } from 'react-admin';
import PlacesAutocomplete from 'shared/components/PlacesAutocomplete';

export const Property = () => {
  return (
    <PlacesAutocomplete
      fullWidth
      isRequired
      validate={required()}
      label="Address"
      variant="outlined"
      isMapVisible={false}
      allowToggleMap={true}
    />
  );
};

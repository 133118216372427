/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Filter, FilterProps } from 'react-admin';
import { displayFields, Field } from 'shared/components/Resource/List';
import { useFilterStyles } from 'shared/components/Resource/styles';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import { onChangePropertyFilter } from 'views/Appraisal/ListFilter';
import { filterFields } from './fields';

function ListFilter(props: Omit<FilterProps, 'children'>) {
  const classes = useFilterStyles();
  const [qs, onChangeParams] = useLocationQuery();
  const [isInteracted, setIsInteracted] = useState(false);

  const filters = qs.filter ? JSON.parse(qs.filter) : {};
  let displayFilterFields: Field[] = filterFields;

  if (filters.property_type_id === 1) {
    displayFilterFields = displayFilterFields.filter((e) => !e.source?.includes('commercial'));
  } else if (filters.property_type_id === 2) {
    displayFilterFields = displayFilterFields.filter((e) => !e.source?.includes('residential'));
    if (filters.commercial_property_type_id !== undefined) {
      displayFilterFields = displayFilterFields.map((e) =>
        e.source === 'commercial_property_subtype_id'
          ? { ...e, filter: { commercial_property_type_id: filters.commercial_property_type_id } }
          : e,
      );
    } else {
      displayFilterFields = displayFilterFields.filter((e) => e.source !== 'commercial_property_subtype_id');
    }
  } else {
    displayFilterFields = displayFilterFields.filter(
      (e) => !e.source?.includes('residential') && !e.source?.includes('commercial'),
    );
  }

  useEffect(() => {
    setIsInteracted(true);
  }, [filters.property_type_id]);

  useEffect(() => {
    if (isInteracted) {
      const currentFilter = qs?.filter ?? '{}';
      const newFilters = onChangePropertyFilter(JSON.parse(currentFilter));
      onChangeParams({
        filter: JSON.stringify(onChangePropertyFilter(newFilters)),
      });
    }
  }, [filters.property_type_id]);

  return displayFields(Filter, { ...props, classes } as FilterProps, displayFilterFields);
}

export default ListFilter;

import React, { useEffect, useState } from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  CreateProps,
  useNotify,
  useCreate,
  Create,
  useRedirect,
  ResourceContextProvider,
  ReferenceInput,
  email,
} from 'react-admin';
import EditAction from 'shared/components/Resource/EditAction';
import { Grid, Card, Divider, Typography, Box } from '@material-ui/core';
import AutocompleteInput from 'shared/components/AutocompleteInput';
import Toolbar from './components/Toolbar';
import getClientPermission from './permissions';
import { Client } from './types';
import { useClientQuery, useContactQuery } from './components/hooks/useContactOptions';
import styles from './components/hooks/useContactCreateStyles';
import { validatePhone } from 'shared/utils';
import PlacesAutocomplete from 'shared/components/PlacesAutocomplete';

function ClientCreate(props: CreateProps) {
  const classes = styles();
  const queryData = useClientQuery();
  const [clientTypeId, setClientTypeId] = useState(12);
  const [defaultContactType, setDefaultContactType] = useState<any>(0);
  const [clientName, setClientName] = useState('');

  const contactQueryData = useContactQuery();
  const contactArray = ['name', 'email', 'phone', 'contact_type_id'];

  const notify = useNotify();
  const redirect = useRedirect();
  const [contactFormData, setContactFormData] = useState({ name: '', email: '', phone: '', contact_type_id: '' });
  // const onContactAdd = (val: any) => {
  //   Object.assign(window, {
  //     contacts: val,
  //     queryData: queryData,
  //     contactQueryData: contactQueryData,
  //   });
  // };

  const [createContact] = useCreate('contact');

  useEffect(() => {
    contactArray.map((each) => window.localStorage.removeItem(each));
    setTimeout(() => {
      const toolbar = document.querySelector('.ql-toolbar') as any;
      const container = document.querySelector('.ql-container') as any;
      const editor = document.querySelector('.ql-editor') as any;
      if (toolbar && container && editor) {
        container.append(toolbar);
        container.style.border = 'none';
        editor.style.border = '1px solid rgba(0, 0, 0, 0.12)';
        editor.style.borderRadius = '3px';
      }
    }, 20);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (clientTypeId) {
      const type = getContactType(clientTypeId) || getOtherContactTypeId();
      setDefaultContactType(type);
      type && window.localStorage.setItem('contact_type_id', type);
    }
    //eslint-disable-next-line
  }, [contactQueryData, clientTypeId]);

  const getOtherContactTypeId = () => {
    const type = (contactQueryData?.data?.contactTypes ?? []).find((item: any) => item.type === 'Other');
    return type?.id;
  };

  const getContactType = (typeId: number) => {
    const type = (contactQueryData?.data?.contactTypes ?? []).find((item: any) => item.client_type_id === typeId);
    return type?.id;
  };

  const addContact = (clientId: number, contactData: any) => {
    const results: any = [];
    results.push(
      new Promise((resolve, reject) => {
        const name = contactData?.name.split(' ');
        createContact(
          {
            payload: {
              data: {
                first_name: name[0],
                last_name: name.slice(1).join(' '),
                email: contactData.email,
                contact_type_id: contactData?.contact_type_id,
                phone_number: contactData.phone,
                client_id: clientId,
              },
            },
          },
          {
            onSuccess: ({ data }: any) => {
              resolve(data);
            },
            onFailure: ({ error }: any) => {
              notify(error.message, 'error');
              reject(error);
            },
          },
        );
      }),
    );
    return Promise.all(results);
  };

  const onSuccess = ({ data }: { data: Client }) => {
    let contactData: any = { name: '', email: '', phone: '', contact_type_id: '' };
    contactArray.map((each) => {
      if (window.localStorage.getItem(each)) contactData[each] = window.localStorage.getItem(each);
      return '';
    });
    const { name } = contactData;
    if (!name) contactData.name = data?.name;
    addContact(data.id, contactData).then((createdContacts: any) => {
      contactArray.map((each) => window.localStorage.removeItem(each));
    });
    notify('client.created');
    redirect(`/clients/${data.id}`);
  };

  const handleContactFormData = (key: any, value: any) => {
    window.localStorage.setItem(key, value);
    setContactFormData({ ...contactFormData, [key]: value });
  };

  return (
    <Box className={classes.actionContainer}>
      <EditAction />
      <Card variant="outlined" classes={{ root: classes.cardRoot }}>
        <ResourceContextProvider value="client">
          <Create basePath="/clients" {...props} onSuccess={onSuccess} resource="client">
            <SimpleForm
              initialValues={{
                client_type_id: 12,
                contact_type_id: defaultContactType,
                primary_contact_name: clientName,
              }}
              toolbar={<Toolbar saveDisabled={false} getPermission={getClientPermission} />}
            >
              <Box className={classes.formContainer}>
                <Typography classes={{ root: classes.headingFirst }}>CLIENT</Typography>
                <Divider classes={{ root: classes.divider }}></Divider>
                <Grid container direction="column" alignItems="center">
                  <Grid container item md={5}>
                    <TextInput
                      autoFocus
                      validate={required()}
                      label="Client Name"
                      source="name"
                      variant="outlined"
                      fullWidth
                      onChange={(e: any) => setClientName(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="column" alignItems="center">
                  <Grid container item md={5}>
                    <PlacesAutocomplete
                      variant="outlined"
                      autoFocus={false}
                      label="Client Billing Address"
                      source="location_address"
                      isMapVisible={false}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="column" alignItems="center">
                  <Grid container item md={5}>
                    <AutocompleteInput
                      fullWidth
                      defaultValue={12}
                      variant="outlined"
                      label="Client Type"
                      source="client_type_id"
                      optionText="type"
                      choices={queryData.data?.clientTypes ?? []}
                      onChange={(v: any) => setClientTypeId(v)}
                    />
                  </Grid>
                </Grid>
                <Typography classes={{ root: classes.headingFirst }}>PRIMARY CONTACT</Typography>

                <Divider classes={{ root: classes.divider }}></Divider>
                <Grid container direction="column" alignItems="center">
                  <Grid container item md={5}>
                    <TextInput
                      source="primary_contact_name"
                      label="Primary Contact Name"
                      variant="outlined"
                      fullWidth
                      onChange={(e: any) => handleContactFormData('name', e.target.value)}
                    />
                  </Grid>

                  <Grid container item md={5}>
                    <TextInput
                      source="primary_contact_email"
                      label="Primary Contact Email"
                      fullWidth
                      variant="outlined"
                      validate={[email()]}
                      onChange={(e: any) => handleContactFormData('email', e.target.value)}
                    />
                  </Grid>
                  <Grid container item md={5}>
                    <TextInput
                      source="primary_contact_phone"
                      label="Primary Contact Phone"
                      validate={validatePhone}
                      variant="outlined"
                      fullWidth
                      onChange={(e: any) => handleContactFormData('phone', e.target.value)}
                    />
                  </Grid>
                  <Grid container item md={5}>
                    <ReferenceInput
                      label="Contact Type"
                      source="contact_type_id"
                      reference="contact_types"
                      allowEmpty={false}
                      fullWidth
                      perPage={100}
                      variant="outlined"
                      sort={{ field: 'order', order: 'ASC' }}
                      validate={[required()]}
                      defaultValue={0}
                      filterToQuery={(searchText: string) => ({ type: searchText })}
                      onChange={(v: any) => handleContactFormData('contact_type_id', v)}
                    >
                      <AutocompleteInput source="contact_type_id" optionText="type" />
                    </ReferenceInput>
                  </Grid>
                </Grid>
                {/* <Typography classes={{ root: classes.heading }}>CONTACTS</Typography>
                <Divider classes={{ root: classes.divider }}></Divider>
                <Grid container direction="row">
                  <Grid container item md={4}></Grid>
                  <Grid container item md={8}>
                    <CreateContactList clientTypeId={clientTypeId} saveContacts={onContactAdd}></CreateContactList>
                  </Grid>
                </Grid> */}
                {/* <Typography classes={{ root: classes.heading }}>NOTES</Typography>
                <Divider classes={{ root: classes.divider }}></Divider>
                <Grid container direction="row">
                  <Grid container item md={4}></Grid>
                  <Grid container item md={8}>
                    <RichTextInput
                      options={{
                        readOnly: false,
                        placeholder: 'Additional Client information, report requirements, etc',
                      }}
                      fullWidth
                      source="notes"
                      multiline
                      variant="outlined"
                      label=""
                      key={`text-false`}
                    />
                  </Grid>
                </Grid> */}
                <Typography classes={{ root: classes.heading }}>&nbsp;</Typography>
                <Divider classes={{ root: classes.divider }}></Divider>
              </Box>
            </SimpleForm>
          </Create>
        </ResourceContextProvider>
      </Card>
    </Box>
  );
}

export default ClientCreate;

import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    chipButton: {
      borderRadius: '24px',
      marginRight: '16px',
      paddingRight: '16px',
      marginBottom: '8px',
    },
    heading: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    dropzoneClass: {
      whiteSpace: 'pre-line',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  }),
);

export enum AppraisalStatus {
  Request = 'Request',
  InProgress = 'In Progress',
  Submitted = 'Submitted',
  Completed = 'Completed',
  OnHold = 'On Hold',
  Canceled = 'Canceled',
  InReview = 'In Review',
  Revision = 'In Revision',
  All = 'All',
}

export const STATUS_MAPPING: { [key: string]: { source: string; label: string } } = {
  1: { source: 'engagement_date', label: 'Engagement Date' },
  8: { source: 'quote_made_date', label: 'Quote Made' },
  4: { source: 'revision_request_date', label: 'In Revision' },
  3: { source: 'reviewed_date', label: 'In Review' },
  5: { source: 'completed_date', label: 'Completed' },
  7: { source: 'canceled_date', label: 'Canceled' },
  6: { source: 'on_hold_date', label: 'On Hold' },
};

export type StatusMap = {
  [key in string]: {
    name: AppraisalStatus;
    count: any;
    order: number;
    aggregate: string;
  };
};

export const statusMap: StatusMap = {
  '0': {
    name: AppraisalStatus.All,
    count: 0,
    order: 0,
    aggregate: '',
  },
  '1': {
    name: AppraisalStatus.InProgress,
    count: 0,
    order: 2,
    aggregate: '',
  },
  '3': {
    name: AppraisalStatus.InReview,
    count: 0,
    order: 3,
    aggregate: '',
  },
  '4': {
    name: AppraisalStatus.Revision,
    count: 0,
    order: 4,
    aggregate: '',
  },
  '5': {
    name: AppraisalStatus.Completed,
    count: 0,
    order: 5,
    aggregate: '',
  },
  '6': {
    name: AppraisalStatus.OnHold,
    count: 0,
    order: 6,
    aggregate: '',
  },
  '7': {
    name: AppraisalStatus.Canceled,
    count: 0,
    order: 7,
    aggregate: '',
  },
  '8': {
    name: AppraisalStatus.Request,
    count: 0,
    order: 1,
    aggregate: '',
  },
};

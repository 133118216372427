import {
  Box,
  Card,
  Chip,
  Divider,
  List,
  ListItem,
  makeStyles,
  Slide,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useGetIdentity } from 'react-admin';

import { Message_Thread } from 'shared/generated/types';
import { useIsOnlyOneUserActive } from 'shared/hooks/useAppraisalOptions';
import { useIsLimitedAndRestrictedRole } from 'shared/hooks/useResourcePermissions';
import { useMessageCountSubscription, useMessageThreadList } from 'views/Appraisal/hooks/messageThreadHooks';
import { Participant } from 'views/Appraisal/types';
import { MessageThreadSkeleton } from './index';
import { MessageList } from './MessagesList';

export const COMMON_BORDER = '1px solid rgba(0,0,0,0.12)';

export interface MessageProps {
  formData: any;
}
export const Messages = ({ formData }: MessageProps) => {
  const identityState = useGetIdentity();
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const formClasses = styles();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedThread, setSelectedThread] = useState<Message_Thread>();

  const isOnlyOneUserActive = useIsOnlyOneUserActive();

  const isLimitedAndRestrictedRole = useIsLimitedAndRestrictedRole();

  const {
    data: messageThreadData,
    loading: messageThreadDataLoading,
    refetch: messageThreadRefetch,
  } = useMessageThreadList({
    messageTopicId: formData.values.id,
  });

  useMessageCountSubscription({
    messageTopicId: formData.values.id,
  });

  useEffect(() => {
    if (isXSmall) {
      setIsSidebarOpen(false);
    }
  }, [isXSmall]);

  const { teamMessageThread, clientMessageThread, messageThread } = useMemo(() => {
    const messageThread = messageThreadData?.message_thread;
    const teamMessageThread = messageThread?.filter((item) => item.message_subtopic === 'general');
    const clientMessageThread = messageThread?.filter((item) => item.message_subtopic === 'client_contact');

    return {
      clientMessageThread,
      messageThread,
      teamMessageThread,
    };
  }, [messageThreadData]);

  useEffect(() => {
    if (!selectedThread) {
      if (teamMessageThread && teamMessageThread?.length > 0) {
        setSelectedThread(teamMessageThread[0]);
      } else if (clientMessageThread && clientMessageThread?.length > 0) {
        setSelectedThread(clientMessageThread[0]);
      }
    } else {
      const currentThread = messageThread?.find((item) => item.id === selectedThread.id);
      setSelectedThread(currentThread);
    }
  }, [clientMessageThread, messageThread, selectedThread, teamMessageThread]);

  return (
    <Card variant="outlined" classes={{ root: `${formClasses.card}` }}>
      <Slide in={isSidebarOpen} direction={'right'} mountOnEnter unmountOnExit>
        <Box className={formClasses.leftSectionContainer} flex={1}>
          <Box height={'3rem'}></Box>
          <Divider />
          <Box pb={3}>
            {!isOnlyOneUserActive && (
              <>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} px={2} py={1}>
                  <Typography>TEAM</Typography>
                </Box>

                <Divider />

                <List component="nav" disablePadding>
                  {messageThreadDataLoading ? (
                    <MessageThreadSkeleton />
                  ) : (
                    <>
                      {teamMessageThread && teamMessageThread.length > 0 ? (
                        <>
                          {teamMessageThread?.map((thread, key) => {
                            const currentRecipient = thread.participants?.find(
                              (participant: { participant_id: any }) => {
                                return participant.participant_id === identityState.identity?.id;
                              },
                            );

                            const selected =
                              selectedThread?.id === thread.id && selectedThread?.message_subtopic === 'general';

                            return (
                              <ListItem
                                button
                                className={formClasses.listItem}
                                selected={selected}
                                key={thread.key || key}
                                onClick={() => {
                                  setSelectedThread(thread);
                                }}
                              >
                                <Box>
                                  {thread.participants && thread.participants.length > 0 ? (
                                    thread.participants.map((participant: Participant, index: number) => {
                                      const name =
                                        (participant && participant.full_name && participant.full_name.split(' ')) ||
                                        '';
                                      if (!name) {
                                        return <></>;
                                      }
                                      let shortName = `${name[0]} ${name[1].charAt(0)}.`;
                                      if (participant.assignee_role) {
                                        shortName += ` (${participant.assignee_role})`;
                                      }
                                      return (
                                        <Fragment key={index}>
                                          {index > 0 ? (
                                            <Typography component="span">, {shortName}</Typography>
                                          ) : (
                                            <Typography component="span">{shortName}</Typography>
                                          )}
                                        </Fragment>
                                      );
                                    })
                                  ) : (
                                    <Typography component="span">No messages yet</Typography>
                                  )}
                                </Box>
                                {currentRecipient?.unread_messages_count > 0 ? (
                                  <Chip
                                    label={currentRecipient.unread_messages_count}
                                    size="small"
                                    style={{ backgroundColor: 'rgb(244, 67, 54)', color: '#fff' }}
                                  />
                                ) : (
                                  <Chip label={thread.messages_count} size="small" />
                                )}
                              </ListItem>
                            );
                          })}
                        </>
                      ) : (
                        <ListItem>
                          <Typography component="span">No messages yet</Typography>
                        </ListItem>
                      )}
                    </>
                  )}
                </List>
              </>
            )}

            <Divider />

            {!isLimitedAndRestrictedRole && clientMessageThread && clientMessageThread.length > 0 && (
              <>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} px={2} pb={1} mt={3}>
                  <Typography>CLIENT</Typography>
                </Box>

                <Divider />

                <List component="nav" disablePadding>
                  <>
                    {clientMessageThread.map((thread, key) => {
                      const currentRecipient = thread.participants?.find((participant: { participant_id: any }) => {
                        return participant.participant_id === identityState.identity?.id;
                      });

                      const selected =
                        selectedThread?.id === thread.id && selectedThread?.message_subtopic === 'client_contact';

                      return (
                        <ListItem
                          button
                          className={formClasses.listItem}
                          selected={selected}
                          key={thread.key || key}
                          onClick={() => {
                            setSelectedThread(thread);
                          }}
                        >
                          <Box>
                            {thread.participants?.map((participant: Participant, index: number) => {
                              const name =
                                (participant && participant.full_name && participant.full_name.split(' ')) || '';
                              if (!name || !name[0] || !name[1]) {
                                return <></>;
                              }
                              let shortName = `${name[0]} ${name[1].charAt(0)}.`;
                              if (participant.participant_type === 'user_account') {
                                if (participant.assignee_role) {
                                  shortName += ` (${participant.assignee_role})`;
                                }
                              } else if (
                                participant.participant_type === 'client_contact' &&
                                participant.contact_type
                              ) {
                                shortName += ` (${participant.contact_type})`;
                              }

                              return (
                                <Fragment key={index}>
                                  {index > 0 ? (
                                    <Typography component="span">, {shortName}</Typography>
                                  ) : (
                                    <Typography component="span">{shortName}</Typography>
                                  )}
                                </Fragment>
                              );
                            })}
                          </Box>
                          {currentRecipient?.unread_messages_count > 0 ? (
                            <Chip
                              label={currentRecipient.unread_messages_count}
                              size="small"
                              style={{ backgroundColor: 'rgb(244, 67, 54)', color: '#fff' }}
                            />
                          ) : (
                            <Chip label={thread.messages_count} size="small" />
                          )}
                        </ListItem>
                      );
                    })}
                  </>
                </List>
              </>
            )}
          </Box>
        </Box>
      </Slide>
      <MessageList
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        selectedThread={selectedThread}
        messageTopicId={formData.values.id}
        messageThreadRefetch={messageThreadRefetch}
      />
    </Card>
  );
};

export const styles = makeStyles({
  card: {
    display: 'flex',
    marginTop: '30px',
    borderBottom: 'none',
  },
  leftSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: COMMON_BORDER,
    borderBottom: COMMON_BORDER,
    borderBottomLeftRadius: '4px',
  },

  messageInput: {
    display: 'flex',
  },

  messageContainer: {
    minHeight: '40vh',
    maxHeight: '60vh',
    overflow: 'auto',
  },

  listItem: {
    justifyContent: 'space-between',
  },

  iconButton: {
    padding: 0,
  },

  sendButton: {
    height: '56px',
    paddingRight: '16px',
  },
});

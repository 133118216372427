import { Box } from '@material-ui/core';
import React from 'react';
import { TextInput } from 'react-admin';

const Instructions = () => {
  return (
    <Box>
      <TextInput
        multiline
        fullWidth
        variant="outlined"
        source="order_instructions"
        rows={7}
        label=""
        placeholder="Please share any special instructions or additional information we need to know about this appraisal order"
        inputProps={{
          style: { color: 'black' },
        }}
      />
    </Box>
  );
};

export default Instructions;

import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Commercial_Property_Type,
  Commission_Templates,
  Property_Types,
  Residential_Ownership_Types,
} from 'shared/generated/types';

const GET_COMMISSION_TEMPLATE = gql`
  query CommissionTemplates($user_account_id: uuid) {
    commission_templates(
      where: { assignee_user_account_id: { _eq: $user_account_id } }
      order_by: { created_at: asc }
    ) {
      assignee_role_id
      assignee_user_account_id
      #client_id
      #created_at
      id
      net_expenses
      #paid_date
      property_type
      #quantity
      rate
      rate_type_id
      #template
      template_commercial_property_type_id
      template_default
      template_property_type_id
      #template_residential_form_type_ids
      template_residential_ownership_type_id
      #total_amount
    }
  }
`;

export const useGetCommissionTemplate = (user_account_id?: string) => {
  return useQuery<{ commission_templates: Commission_Templates[] }>(GET_COMMISSION_TEMPLATE, {
    variables: { user_account_id },
    skip: !user_account_id,
  });
};

const INSERT_COMMISSION_TEMPLATE = gql`
  mutation InsertCommissionTemplate(
    $assignee_role_id: Int
    $assignee_user_account_id: uuid
    $rate_type_id: Int
    $template_property_type_id: Int
    $template_commercial_property_type_id: Int
    $template_residential_ownership_type_id: Int
    $net_expenses: Boolean
    $rate: numeric
  ) {
    insert_appraisal_commission(
      objects: {
        assignee_role_id: $assignee_role_id
        assignee_user_account_id: $assignee_user_account_id
        net_expenses: $net_expenses
        rate: $rate
        rate_type_id: $rate_type_id
        template: true
        template_property_type_id: $template_property_type_id
        template_commercial_property_type_id: $template_commercial_property_type_id
        template_residential_ownership_type_id: $template_residential_ownership_type_id
      }
    ) {
      affected_rows
    }
  }
`;

interface InsertMutationResponse {
  insert_appraisal_commission: { affected_rows: number };
}

export const useInsertCommissionTemplate = () => {
  return useMutation<InsertMutationResponse, Commission_Templates>(INSERT_COMMISSION_TEMPLATE);
};

const OPTIONS_QUERY = gql`
  query Options {
    propertyTypes: property_types(order_by: { order: asc }) {
      id
      type
    }
    residentialOwnershipTypes: residential_ownership_types(order_by: { order: asc }) {
      id
      type
    }
    commercialPropertyTypes: commercial_property_type(order_by: { order: asc }) {
      id
      type
    }
  }
`;

interface OptionsResponse {
  propertyTypes: Property_Types[];
  residentialOwnershipTypes: Residential_Ownership_Types[];
  commercialPropertyTypes: Commercial_Property_Type[];
}

export const useOptions = () => {
  return useQuery<OptionsResponse>(OPTIONS_QUERY, { fetchPolicy: 'cache-first' });
};

const DELETE_COMMISSION_TEMPLATE = gql`
  mutation DeleteAC($id: uuid) {
    delete_appraisal_commission(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

interface DeleteCommissionTemplateRequest {
  id: string;
}
interface DeleteCommissionTemplateResponse {
  delete_appraisal_commission: { affected_rows: number };
}
export const useDeleteCommissionTemplate = () => {
  return useMutation<DeleteCommissionTemplateResponse, DeleteCommissionTemplateRequest>(DELETE_COMMISSION_TEMPLATE);
};

const UPDATE_COMMISSION_TEMPLATE_QUERY = gql`
  mutation UpdateCommissionTemplate(
    $id: uuid
    #$assignee_role_id: Int
    $rate_type_id: Int
    $template_property_type_id: Int
    $template_commercial_property_type_id: Int
    $template_residential_ownership_type_id: Int
    $net_expenses: Boolean
    $rate: numeric
  ) {
    update_appraisal_commission(
      where: { id: { _eq: $id } }
      _set: {
        #assignee_role_id: $assignee_role_id
        net_expenses: $net_expenses
        rate: $rate
        rate_type_id: $rate_type_id
        #template: true
        template_property_type_id: $template_property_type_id
        template_commercial_property_type_id: $template_commercial_property_type_id
        template_residential_ownership_type_id: $template_residential_ownership_type_id
      }
    ) {
      affected_rows
    }
  }
`;

interface UpdateCommissionTemplateResponse {
  update_appraisal_commission: { affected_rows: number };
}

export const useUpdateCommissionTemplate = () => {
  return useMutation<UpdateCommissionTemplateResponse, Commission_Templates>(UPDATE_COMMISSION_TEMPLATE_QUERY);
};

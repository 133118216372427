import { Box, Card, Checkbox, Chip, IconButton, LinearProgress, Modal, TextField, Typography } from '@material-ui/core';
import {
  Add as IconContentAdd,
  Cancel as IconCancel,
  Check as IconCheck,
  Delete as DeleteIcon,
  Done,
  Edit,
  PersonAdd as PersonAddIcon,
  Schedule,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, SaveButton } from 'react-admin';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { Task, Task_Updates } from 'shared/generated/types';
import { useIsOnlyOneUserActive } from 'shared/hooks/useAppraisalOptions';
import {
  useCreateTask,
  useDeleteTask,
  useGetTaskList,
  useUpdateTask,
  useUpdateTaskMany,
} from 'shared/hooks/useAppraisalTasks';
import CustomDatePicker from './DatetimePicker';
import { useStyles } from './styles';

export interface TasklistProps {
  formData: any;
}

export const TaskList = (props: TasklistProps) => {
  const classes = useStyles();
  const { id, assignee_user_account_names } = props.formData.values;
  const [open, setOpen] = useState(false);
  const [assigneeModalOpen, setAssigneeModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task>();

  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isHovered, setIsHovered] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [newTasks, setNewTasks] = useState<Task[] | undefined>();

  const { data: taskListData, refetch: taskListRefetch } = useGetTaskList(id);
  const [deleteTask, { data: deleteTaskData }] = useDeleteTask(taskListRefetch);
  const [updateTask, { data: updateTaskData }] = useUpdateTask(taskListRefetch);

  const [updateTaskMany, { data: updateTaskManyData }] = useUpdateTaskMany(taskListRefetch);

  const isOnlyOneUserActive = useIsOnlyOneUserActive();

  const { tasks, tasklist, countPercentage, user_profiles } = useMemo(() => {
    const tasklist = taskListData?.tasklist?.[0];
    const tasks = tasklist?.tasks;

    if (tasks) {
      setNewTasks(tasks);
    }
    let countPercentage = 0;
    if (tasklist?.tasks_count) {
      countPercentage = Number(((tasklist.tasks_completed_count / tasklist.tasks_count) * 100).toFixed());
    }
    const { user_profiles } = taskListData || {};

    return { tasks, tasklist, countPercentage, user_profiles };
  }, [taskListData]);

  const getAssigneeUser = ({
    name,
    assignee_user_account_id,
  }: {
    name?: string;
    assignee_user_account_id?: string;
  }) => {
    if (!user_profiles) {
      return null;
    }

    if (name) {
      return user_profiles.find((user) => user.first_name && name.includes(user.first_name))?.user_account_id || null;
    } else {
      const user = user_profiles.find((user) => user.user_account_id === assignee_user_account_id);

      if (!user || !user.full_name) {
        return null;
      }
      const name = user.full_name.split(' ') || '';
      const shortName = `${name[0]} ${name[1].charAt(0)}.`;
      return shortName;
    }
  };

  const [createTask, { data: setTaskResponse }] = useCreateTask(
    {
      appraisal_id: id,
      tasklist_id: tasklist?.id || '',
      order: tasklist?.tasks_count ? tasklist.tasks_count + 1 : 1,
      description: selectedTask?.description || '',
    },
    taskListRefetch,
  );

  useEffect(() => {
    if (setTaskResponse || updateTaskData || deleteTaskData || updateTaskManyData) {
      setIsDelete(false);
      setIsEdit(false);
      setOpen(false);
      setAssigneeModalOpen(false);
      setSelectedTask(undefined);
      taskListRefetch();
    }
  }, [setTaskResponse, updateTaskData, deleteTaskData, updateTaskManyData, taskListRefetch]);

  const handelClick = () => {
    setSelectedTask(undefined);
    setOpen(!open);
  };

  const handleTaskUpdate = () => {
    selectedTask?.id ? updateTask({ variables: selectedTask }) : createTask();
  };

  const handleEnterPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      handleTaskUpdate();
    }
  };

  const reorder = (list: Task[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination || !tasks) {
      return;
    }

    const items = tasks && reorder(tasks, result.source.index, result.destination.index);

    setNewTasks(items);

    let updates: Task_Updates[] = [];
    items?.map((task, index) => {
      updates.push({ where: { id: { _eq: task.id } }, _set: { order: index + 1 } });
    });
    updateTaskMany({
      variables: {
        updates,
      },
    });
  };

  return (
    <Card variant="outlined" className={classes.card}>
      <Box className={classes.percentageLine}>
        <Typography>{countPercentage}%</Typography>
        <Box className={classes.progressContainer}>
          <LinearProgress variant="determinate" value={countPercentage} className={classes.progress} />
        </Box>
      </Box>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {newTasks?.map((task, index) => {
                return (
                  <Draggable key={task.id} draggableId={task.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          userSelect: 'none',
                          ...provided.draggableProps.style,
                        }}
                      >
                        <Box
                          className={classes.listOfTasks}
                          style={{
                            backgroundColor: snapshot.isDragging ? '#f5f5f5' : '#ffffff',
                          }}
                          onMouseEnter={() => {
                            setIsHovered(task.id);
                          }}
                          onMouseLeave={() => setIsHovered(undefined)}
                        >
                          <Box display={'flex'} alignItems={'center'} flex={2}>
                            <Checkbox
                              onChange={(e) => {
                                const completed_date = e.target.checked ? moment().format() : null;
                                updateTask({
                                  variables: { ...task, completed_date },
                                });
                              }}
                              style={{ color: '#2196F3' }}
                              checked={!!task.completed_date}
                            />

                            <Box display={'flex'} alignItems={'center'} flex={1}>
                              {selectedTask?.id === task.id && isEdit ? (
                                <Box display={'flex'} alignItems={'center'} flex={1}>
                                  <Box flex={1}>
                                    <TextField
                                      autoFocus
                                      onFocus={(e) => {
                                        const temp_value = e.target.value;
                                        e.target.value = '';
                                        e.target.value = temp_value;
                                      }}
                                      size="small"
                                      onKeyUp={handleEnterPress}
                                      className={classes.inputStyle}
                                      value={selectedTask?.description}
                                      onChange={(e) =>
                                        setSelectedTask((prev: any) => ({ ...prev, description: e.target.value }))
                                      }
                                      variant="outlined"
                                      fullWidth
                                      multiline
                                    />
                                  </Box>
                                  <IconButton onClick={handleTaskUpdate} disabled={!selectedTask?.description}>
                                    <Done />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      setSelectedTask(undefined);
                                    }}
                                    disabled={!selectedTask?.description}
                                  >
                                    <IconCancel />
                                  </IconButton>
                                </Box>
                              ) : (
                                <>
                                  <Typography className={task.completed_date ? classes.completedTask : ''}>
                                    {task.description}
                                  </Typography>
                                  {task.id === isHovered && (
                                    <Box>
                                      <IconButton
                                        onClick={() => {
                                          setIsEdit(true);
                                          setSelectedTask(task);
                                        }}
                                      >
                                        <Edit />
                                      </IconButton>
                                    </Box>
                                  )}
                                </>
                              )}
                            </Box>
                          </Box>

                          <Box display={'flex'} justifyContent={'end'} alignItems={'center'} flex={1}>
                            {!isOnlyOneUserActive && assignee_user_account_names?.length > 0 && (
                              <>
                                {task.assignee_user_account_id &&
                                getAssigneeUser({ assignee_user_account_id: task.assignee_user_account_id }) ? (
                                  <Chip
                                    className={classes.datePickerChip}
                                    label={getAssigneeUser({ assignee_user_account_id: task.assignee_user_account_id })}
                                    variant="default"
                                    onClick={() => {
                                      setSelectedTask(task);
                                      setAssigneeModalOpen(true);
                                    }}
                                    // onDelete={() => console.log('delete click')}
                                  />
                                ) : (
                                  <IconButton
                                    onClick={() => {
                                      setSelectedTask(task);
                                      setAssigneeModalOpen(true);
                                    }}
                                  >
                                    <PersonAddIcon />
                                  </IconButton>
                                )}
                              </>
                            )}

                            <CustomDatePicker
                              value={selectedTask?.due_date}
                              open={selectedTask?.id === task.id && showDatePicker}
                              onClose={() => {
                                setShowDatePicker(false);
                                setSelectedTask(undefined);
                              }}
                              onChange={(date: Date) => {
                                selectedTask && updateTask({ variables: { ...selectedTask, due_date: date } });
                              }}
                            />
                            {task?.due_date ? (
                              <Chip
                                label={moment(task?.due_date).format('MMM D [@] h:mm a')}
                                variant="default"
                                onClick={() => {
                                  setShowDatePicker(true);
                                  setSelectedTask(task);
                                }}
                              />
                            ) : (
                              <IconButton
                                onClick={() => {
                                  setShowDatePicker(true);
                                  setSelectedTask(task);
                                }}
                              >
                                <Schedule />
                              </IconButton>
                            )}

                            <IconButton
                              style={{
                                visibility: task.id === isHovered ? 'visible' : 'hidden',
                              }}
                              onClick={() => {
                                setIsDelete(true);
                                setSelectedTask(task);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Box className={classes.addButton}>
        <Typography className={classes.note}>
          Note: You can configure your Default Tasklist as well as create custom&nbsp;
          <Link to="/account/my-profile#appraisal_settings" className={classes.link}>
            Taskslists
          </Link>
        </Typography>
        {tasklist && (
          <Button color="primary" onClick={() => handelClick()} label="Add Task">
            <IconContentAdd />
          </Button>
        )}
      </Box>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className={classes.modalStyles}>
          <Typography className={classes.headerTitle}>Add Task</Typography>
          <Box className={classes.inputBoxStyle}>
            <TextField
              autoFocus
              fullWidth
              label="Description"
              value={selectedTask?.description}
              onChange={(e) => setSelectedTask((prev: any) => ({ ...prev, description: e.target.value }))}
              variant="outlined"
              multiline
            />
          </Box>
          <Box className={classes.ButtonStyle}>
            <Button label="ra.action.cancel" onClick={handelClick}>
              <IconCancel />
            </Button>
            <SaveButton
              handleSubmitWithRedirect={() => {
                createTask();
              }}
              disabled={!selectedTask?.description}
            />
          </Box>
        </Box>
      </Modal>

      <Modal open={assigneeModalOpen}>
        <Box className={classes.modalStyles}>
          <Typography className={classes.headerTitle}>Add Assignee</Typography>
          <Box className={classes.inputBoxStyle}>
            <Autocomplete
              disabled={!assignee_user_account_names}
              options={assignee_user_account_names}
              defaultValue={getAssigneeUser({ assignee_user_account_id: selectedTask?.assignee_user_account_id })}
              onInputChange={(_e, value) => {
                let assignee_user_account_id = getAssigneeUser({ name: value });
                setSelectedTask((task: any) => ({ ...task, assignee_user_account_id }));
              }}
              renderInput={(params) => <TextField {...params} label="Assignee" variant={'outlined'} />}
            />
          </Box>
          <Box className={classes.ButtonStyle}>
            <Button label="ra.action.cancel" onClick={() => setAssigneeModalOpen(false)}>
              <IconCancel />
            </Button>
            <SaveButton
              handleSubmitWithRedirect={() => {
                updateTask({ variables: selectedTask });
              }}
            />
          </Box>
        </Box>
      </Modal>

      <Modal
        open={isDelete}
        onClose={() => setSelectedTask(undefined)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className={classes.paper}>
          <Typography classes={{ root: classes.heading }}>Delete Task</Typography>
          <Box py={2}>
            <Typography>Are you sure you want to Delete this task?</Typography>
          </Box>
          <Box className={classes.ButtonStyle}>
            <Button
              label="ra.action.cancel"
              onClick={() => {
                setIsDelete(false);
                setSelectedTask(undefined);
              }}
            >
              <IconCancel />
            </Button>
            <Box ml={2}>
              <Button
                label="Confirm"
                onClick={() => {
                  deleteTask({
                    variables: {
                      taskId: selectedTask?.id,
                    },
                  });
                }}
                color="primary"
                startIcon={<IconCheck />}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Card>
  );
};

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useMemo } from 'react';

interface Props {
  display: string;
  search: string;
}
export const usePartsData = ({ display, search }: Props) => {
  const partsData = useMemo(() => {
    if (display && search) {
      let matchData: any = display && match(display, search);
      return display ? parse(display, matchData) : [];
    }
  }, [display, search]);

  return partsData;
};

export const getPartsData = ({ display, search }: Props) => {
  if (display && search) {
    let matchData: any = display && match(display, search);
    return parse(display, matchData);
  }
};

import React from 'react';
/*import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import ApartmentIcon from '@material-ui/icons/Apartment';*/
import { makeStyles } from '@material-ui/core/styles';
import { FieldProps } from 'react-admin';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import { usePartsData } from 'shared/hooks/usePartsData';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    minHeight: '46px',
    alignItems: 'center',
  },
});

function FileNumber(props: FieldProps<any>) {
  const classes = useStyles();
  const { record, source = '' } = props;
  const [qs] = useLocationQuery();
  const display = record[source];
  const search = qs.search || '';

  const partsData = usePartsData({ display, search });

  /*let icon: JSX.Element | null = null;
  if (record?.property_type_id === 1) {
    if (record?.residential_ownership_type_id === 1) {
      icon = <HomeIcon />;
    } else {
      icon = <ApartmentIcon />;
    }
  } else {
    icon = <BusinessIcon />;
  }
  */

  if (!partsData) return <span>{display}</span>;

  return (
    <span className={classes.root}>
      {partsData.map((part, index) => (
        <span
          key={index}
          style={{
            fontWeight: part.highlight ? 800 : 400,
            whiteSpace: 'nowrap',
          }}
        >
          {part.text}
        </span>
      ))}
      <br />
    </span>
  );
}

export default FileNumber;

import TextField from '@material-ui/core/TextField';
import { makeStyles, styled } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useMemo, useState } from 'react';
import { FieldTitle, useInput } from 'react-admin';

import { getPartsData } from 'shared/hooks/usePartsData';
import {
  useCommercialPropertyTypes,
  useSearchCommercialPropertyTypes,
} from 'views/Appraisal/hooks/useCommercialPropertyTypes';

interface Props {
  disabled?: boolean;
}
const GroupedInput = ({ disabled }: Props) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');

  const { data: commercialPropertyTypesData } = useCommercialPropertyTypes();
  const [getSearchData, { data: searchData }] = useSearchCommercialPropertyTypes();

  const {
    input: { value: commercial_property_type_id, onChange: commercialPropertyTypeInputChange },
  } = useInput({ source: 'property.commercial_property_type_id' });

  const {
    input: { value: commercial_property_subtype_id, onChange: commercialPropertySubTypeInputChange },
  } = useInput({ source: 'property.commercial_property_subtype_id' });

  const options = useMemo(() => {
    const options =
      inputValue && searchData && searchData.search_commercial_property_types.length > 0
        ? searchData.search_commercial_property_types
        : commercialPropertyTypesData?.commercial_property_types;

    return options ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commercialPropertyTypesData, inputValue]);

  const value: any = useMemo(() => {
    return (
      options?.find(
        (item) =>
          item.commercial_property_type_id === commercial_property_type_id &&
          item.commercial_property_subtype_id === commercial_property_subtype_id,
      ) || null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commercial_property_subtype_id, commercial_property_type_id, options]);

  return (
    <div className={classes.container}>
      <Autocomplete
        disabled={disabled}
        fullWidth
        value={value}
        onChange={(_, newValue) => {
          if (newValue) {
            const { commercial_property_type_id, commercial_property_subtype_id } = newValue;
            commercialPropertyTypeInputChange(commercial_property_type_id);
            commercialPropertySubTypeInputChange(commercial_property_subtype_id);
          } else {
            commercialPropertyTypeInputChange('');
            commercialPropertySubTypeInputChange('');
          }
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue, reason) => {
          setInputValue(newInputValue);

          if (reason === 'input' && !!newInputValue) {
            getSearchData({ variables: { search: newInputValue } });
          }
        }}
        options={options}
        groupBy={(option) => option.commercial_property_type || ''}
        filterOptions={(options, { inputValue }) => {
          return options.filter(
            (option) =>
              option.commercial_property_type?.toLowerCase().includes(inputValue.toLowerCase()) ||
              option.commercial_property_subtype?.toLowerCase().includes(inputValue.toLowerCase()),
          );
        }}
        getOptionLabel={(option) => `${option.commercial_property_type} - ${option.commercial_property_subtype}`}
        renderOption={(option) => {
          if (!option.commercial_property_subtype) {
            return;
          }
          const partsData = getPartsData({ display: option.commercial_property_subtype, search: inputValue });

          return partsData ? (
            <span>
              {partsData.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 800 : 400,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {part.text}
                </span>
              ))}
            </span>
          ) : (
            option.commercial_property_subtype
          );
        }}
        renderGroup={({ group, children, key }) => {
          const partsData = getPartsData({ display: group, search: inputValue });
          return (
            <li key={key}>
              <GroupHeader>
                {partsData ? (
                  <span>
                    {partsData.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 800 : 400,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </span>
                ) : (
                  group
                )}
              </GroupHeader>
              <GroupItems>{children}</GroupItems>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // error={!!(touched && error)}
            label={<FieldTitle label={'Property Type'} />}
            // helperText={<InputHelperText touched={Boolean(touched)} error={error} />}
            variant="outlined"
            fullWidth
            margin={'dense'}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </div>
  );
};
const useStyles = makeStyles(
  {
    root: {
      flexGrow: 1,
      height: 250,
    },
    container: {
      flexGrow: 1,
      position: 'relative',
      marginRight: 0,
      marginBottom: 8,
    },
  },
  { name: 'RaAutocompleteInput' },
);

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: 'gray',
  backgroundColor: '#ffffff',
  //   theme.palette.mode === 'light'
  //     ? lighten(theme.palette.primary.light, 0.85)
  //     : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

export default GroupedInput;

import { useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { User_Profiles, User_Role } from 'shared/generated/types';

const QUERY = gql`
  query {
    roles: user_roles {
      id
      display
      description
    }
    role: user_role {
      id
      display
      description
    }
  }
`;
const PROFILE_QUERY = gql`
  query getProfile($id: uuid) {
    profile: user_profiles(where: { id: { _eq: $id } }) {
      id
      last_active_at
      appraisals_count
    }
  }
`;

const SEARCH_PROFILES_QUERY = gql`
  query profileSearch($keyword: String) {
    search_user_profiles(args: { search: $keyword }) {
      id
      full_name
    }
  }
`;

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const alphabet: any = [...alpha.map((x) => String.fromCharCode(x))];
type Alphabets = (typeof alphabet)[number];

const USER_PROFILES_COUNT_QUERY = gql`
  query UserProfilesCount {
    ${alphabet?.map((each: any) => {
      return each + ': user_profiles_aggregate(where: {full_name: {_similar:"(' + each + ')%"}}){aggregate{count}}';
    })}
  }
`;

export type TeamOptionsResponse = {
  roles: Pick<User_Role, 'id' | 'display' | 'description'>[];
  role: Pick<User_Role, 'id' | 'display' | 'description'>[];
};

export type ProfileOptionsResponse = {
  profile: { id: string; appraisals_count: number; last_active_at: string }[];
};

export type UserProfilesCountResponse = {
  [x in Alphabets]: { aggregate: { count: number } };
};

export default function useTeamOptions() {
  const options = useQuery<TeamOptionsResponse>(QUERY, { fetchPolicy: 'cache-first' });
  return [options] as const;
}

export function useProfileOptions(props: { id: string }) {
  const options = useQuery<ProfileOptionsResponse>(PROFILE_QUERY, { fetchPolicy: 'cache-first', variables: props });
  return [options] as const;
}

export function useUserProfilesTotal() {
  const options = useQuery<UserProfilesCountResponse>(USER_PROFILES_COUNT_QUERY, {
    fetchPolicy: 'cache-first',
  });
  return [options] as const;
}

export function useUserProfilesSearch() {
  return useLazyQuery<{ search_user_profiles: User_Profiles[] }>(SEARCH_PROFILES_QUERY);
}

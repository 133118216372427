import { Box, Card, Divider, IconButton, InputAdornment, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { TextInput, useDataProvider } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { DateInput } from 'shared/components/Pickers';
import { styleRight } from 'shared/hooks/useEditFormStyle';
import useFormPermissions from 'shared/hooks/useResourcePermissions';
import { copyToClipboard } from 'shared/utils';
import getAppraisalPermission from 'views/Appraisal/permission';
import moment from 'moment';

const InvoiceSection = () => {
  const classes = styleRight();
  const dataProvider = useDataProvider();

  const [{ permissions }] = useFormPermissions({ getPermission: getAppraisalPermission });
  const formData = useFormState();
  const form = useForm();

  const [invoiceEdit, setInvoiceEdit] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>({});

  useEffect(() => {
    if (formData?.values?.invoice_id) {
      dataProvider.getOne('invoice', { id: formData?.values?.invoice_id }).then(({ data }) => {
        setInvoiceData(data);
      });
    }
    // eslint-disable-next-line
  }, [dataProvider, formData?.values?.invoice_id]);

  const getInvoiceFields = (initialValue: boolean) => ({
    invoice_id: initialValue,
    'invoice.invoice_number': initialValue,
    'invoice.invoice_date': initialValue,
    'invoice.due_date': initialValue,
  });

  const [invoiceFields, setInvoiceFields] = useState<any>(getInvoiceFields(false));

  const setInvoices = (val: boolean) => {
    setInvoiceFields(getInvoiceFields(val));
    setInvoiceEdit(val);
  };

  const save = useCallback(debounce(form.submit, 0), []);

  return (
    <Card
      variant="outlined"
      classes={{ root: `${classes.card} ${classes.overflow} ${classes.gap}` }}
      style={{ paddingBottom: '5px' }}
    >
      <Box className={classes.flexBox}>
        <Typography classes={{ root: `${classes.heading} ${classes.heading500} ${classes.fontLarge}` }}>
          Invoice
        </Typography>

        {invoiceEdit && (
          <IconButton
            className={classes.saveActionButton}
            edge="end"
            onClick={(e) => {
              e.preventDefault();
              save();
              setInvoiceEdit(false);
              setInvoices(false);
            }}
          >
            <SaveIcon classes={{ root: classes.icon }} />
          </IconButton>
        )}
        {permissions.edit && !invoiceEdit && (
          <IconButton
            className={classes.topEditBtn}
            edge="end"
            disabled={invoiceEdit}
            onClick={() => {
              setInvoices(true);
              form.change('invoice.invoice_number', invoiceData?.invoice_number);
              form.change('invoice.invoice_date', invoiceData?.invoice_date);
              form.change('invoice.due_date', invoiceData?.due_date);
            }}
          >
            <EditIcon classes={{ root: classes.icon }} />
          </IconButton>
        )}

        {invoiceEdit && (
          <IconButton
            classes={{ root: classes.topEditBtn }}
            disabled={!invoiceEdit}
            edge="end"
            onClick={() => {
              setInvoiceEdit(false);
              setInvoices(false);
            }}
          >
            <CloseIcon classes={{ root: classes.icon }} />
          </IconButton>
        )}
      </Box>

      <Divider classes={{ root: classes.dividerSubSelect }}></Divider>

      <Box className={classes.flexBox}>
        <Typography classes={{ root: `${classes.heading} ${classes.heading400}` }}>Invoice Number</Typography>
        <Box pr={2} className={classes.inputContainer}>
          {!invoiceEdit ? (
            <Box pr={2} className={classes.inputContainer}>
              <Typography classes={{ root: classes.nextTask }}>{invoiceData?.invoice_number}</Typography>
            </Box>
          ) : (
            <TextInput
              onClick={(e: any) => copyToClipboard(formData?.values?.invoice?.invoice_number, e, invoiceEdit)}
              size="small"
              color="secondary"
              InputProps={{
                placeholder: 'Click pencil to edit',
                classes: {
                  root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                    invoiceFields['invoice.invoice_number'] ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                  }`,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      className={classes.fieldEditBtn}
                      edge="end"
                      disabled={
                        invoiceFields['invoice.invoice_number'] && !formData.dirtyFields['invoice.invoice_number']
                      }
                    >
                      {invoiceFields['invoice.invoice_number'] && formData.dirtyFields['invoice.invoice_number'] && (
                        <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                      )}
                      &nbsp;
                      {invoiceFields['invoice.invoice_number'] && formData.dirtyFields['invoice.invoice_number'] && (
                        <CloseIcon
                          fontSize="small"
                          classes={{ root: classes.icon }}
                          onClick={() =>
                            form.change('invoice.invoice_number', formData.initialValues['invoice.invoice_number'])
                          }
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
              variant="outlined"
              disabled={!permissions.edit || !invoiceFields['invoice.invoice_number']}
              fullWidth
              label=""
              source="invoice.invoice_number"
            />
          )}
        </Box>
      </Box>

      <Box className={classes.flexBox} style={{ position: 'relative' }}>
        <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
          Invoice Date
        </Typography>
        {!invoiceEdit ? (
          <Box pr={2} className={classes.inputContainer}>
            <Typography classes={{ root: classes.nextTask }}>
              {invoiceData?.invoice_date ? moment(invoiceData?.invoice_date).format('MM/DD/yyyy') : ''}
            </Typography>
          </Box>
        ) : (
          <Box pr={2} className={classes.inputContainer}>
            <DateInput
              onClick={(e: any) => copyToClipboard(formData?.values?.invoice?.invoice_date, e, invoiceEdit)}
              size="small"
              color="secondary"
              InputProps={{
                placeholder: 'Click pencil to edit',
                classes: {
                  root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                    invoiceFields['invoice.invoice_date'] ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                  }`,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
              variant="outlined"
              disabled={!permissions.edit || !invoiceFields['invoice.invoice_date']}
              fullWidth
              label=""
              source="invoice.invoice_date"
            />
            <IconButton
              size="small"
              className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
              edge="end"
              disabled={invoiceFields['invoice.invoice_date'] && !formData.dirtyFields['invoice.invoice_date']}
            >
              {invoiceFields['invoice.invoice_date'] && formData.dirtyFields['invoice.invoice_date'] && (
                <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
              )}
              &nbsp;
              {invoiceFields['invoice.invoice_date'] && formData.dirtyFields['invoice.invoice_date'] && (
                <CloseIcon
                  fontSize="small"
                  classes={{ root: classes.icon }}
                  onClick={() => form.change('invoice.invoice_date', formData.initialValues['invoice.invoice_date'])}
                />
              )}
            </IconButton>
          </Box>
        )}
      </Box>

      <Box className={classes.flexBox} style={{ position: 'relative' }}>
        <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
          Invoice Due
        </Typography>
        {!invoiceEdit ? (
          <Box pr={2} className={classes.inputContainer}>
            <Typography classes={{ root: classes.nextTask }}>
              {invoiceData?.due_date ? moment(invoiceData?.due_date).format('MM/DD/yyyy') : ''}
            </Typography>
          </Box>
        ) : (
          <Box pr={2} className={classes.inputContainer}>
            <DateInput
              onClick={(e: any) => copyToClipboard(formData?.values?.invoice?.due_date, e, invoiceEdit)}
              size="small"
              color="secondary"
              InputProps={{
                placeholder: 'Click pencil to edit',
                classes: {
                  root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${
                    invoiceFields['invoice.due_date'] ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                  }`,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
              variant="outlined"
              disabled={!permissions.edit || !invoiceFields['invoice.due_date']}
              fullWidth
              label=""
              source="invoice.due_date"
            />
            <IconButton
              size="small"
              className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
              edge="end"
              disabled={invoiceFields['invoice.due_date'] && !formData.dirtyFields['invoice.due_date']}
            >
              {invoiceFields['invoice.due_date'] && formData.dirtyFields['invoice.due_date'] && (
                <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
              )}
              &nbsp;
              {invoiceFields['invoice.due_date'] && formData.dirtyFields['invoice.due_date'] && (
                <CloseIcon
                  fontSize="small"
                  classes={{ root: classes.icon }}
                  onClick={() => form.change('invoice.due_date', formData.initialValues['invoice.due_date'])}
                />
              )}
            </IconButton>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default InvoiceSection;

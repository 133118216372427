import { Box, Button, Divider, IconButton, InputAdornment, TextField as MuiTextField, styled } from '@material-ui/core';
import { Markunread, Send, Unsubscribe } from '@material-ui/icons';
import formatDate from 'date-fns/format';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import MenuIcon from '@material-ui/icons/Menu';
import { useGetIdentity } from 'react-admin';
import { Message_Thread } from 'shared/generated/types';
import { groupDataByDay } from 'shared/utils';
import { useInsertMessage } from 'views/Appraisal/hooks/messageHooks';
import {
  useMessageThreadSubscribeMutation,
  useMessageThreadUnsubscribeMutation,
} from 'views/Appraisal/hooks/messageSubscriptionHooks';
import { useMessageSubscription } from 'views/Appraisal/hooks/messagesHooks';
import { COMMON_BORDER, styles } from './Messages';
import { DividerWithText, Message, MessageSkeleton } from './index';

interface MessageListProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  selectedThread?: Message_Thread;
  messageTopicId: any;
  messageThreadRefetch: any;
}
export const MessageList = ({
  setIsSidebarOpen,
  selectedThread,
  isSidebarOpen,
  messageTopicId,
  messageThreadRefetch,
}: MessageListProps) => {
  const [currentMessage, setCurrentMessage] = useState('');

  const identityState = useGetIdentity();

  const bottomRef = useRef<HTMLDivElement>(null);
  const formClasses = styles();

  const { data: messagesData, loading: messagesDataLoading } = useMessageSubscription({
    messageThreadId: selectedThread?.id,
  });

  const [messageThreadUnsubscribeMutate, { data: messageThreadUnsubscribeMutateData }] =
    useMessageThreadUnsubscribeMutation();

  const [messageThreadSubscribeMutate, { data: messageThreadSubscribeMutateData }] =
    useMessageThreadSubscribeMutation();

  useEffect(() => {
    if (messageThreadUnsubscribeMutateData || messageThreadSubscribeMutateData) {
      messageThreadRefetch();
    }
  }, [messageThreadUnsubscribeMutateData, messageThreadSubscribeMutateData, messageThreadRefetch]);

  useEffect(() => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 500);
  }, [messagesData]);

  const { subscriptionVariables, isSubscribed } = useMemo(() => {
    const participant = selectedThread?.participants?.find((participant: any) => {
      return participant.participant_id === identityState.identity?.id;
    });

    const subscriptionVariables = {
      messageThreadId: selectedThread?.id,
      participantId: identityState.identity?.id,
      participantType: 'user_account',
    };

    return { subscriptionVariables, isSubscribed: !!participant?.subscribed };
  }, [identityState.identity, selectedThread]);

  const TextField = styled(MuiTextField)(() => ({
    '& .MuiOutlinedInput-root': {
      padding: 0,
      borderRadius: 0,
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: isSidebarOpen ? 0 : '4px',
    },
    '& .MuiInputAdornment-root': {
      padding: '28px 0',
      // borderTopLeftRadius: theme.shape.borderRadius + 'px',
      // borderBottomLeftRadius: theme.shape.borderRadius + 'px',
      borderLeft: COMMON_BORDER,
    },
  }));

  const [sendMessage] = useInsertMessage({
    message: currentMessage,
    messageThreadId: selectedThread?.id,
    senderId: String(identityState.identity?.id),
    senderType: 'user_account',
  });

  // const [insertMessageThread, { data: insertMessageData }] = useInsertMessageThread({
  //   message_topic: 'appraisal',
  //   message_topic_id: messageTopicId,
  //   message_subtopic: 'general',
  //   sender_type: 'user_account',
  //   sender_id: String(identityState.identity?.id),
  //   message: currentMessage,
  // });

  // useEffect(() => {
  //   if (insertMessageData) {
  //     messageThreadRefetch();
  //   }
  // }, [insertMessageData, messageThreadRefetch]);

  const handleSubmit = () => {
    if (identityState.identity?.id && currentMessage) {
      if (selectedThread?.id) {
        sendMessage();
      }
      // else {
      //   insertMessageThread();
      // }
      setCurrentMessage('');
    }
  };

  const handleKeypress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const groupKey = (date: Date) => {
    return formatDate(date, 'MMM d, YYY');
  };

  const groupedData = groupDataByDay(messagesData?.messages, groupKey, 'created_at');

  return (
    <Box flex={1.5}>
      <Box height={'3rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} px={2}>
        <IconButton onClick={() => setIsSidebarOpen((prev) => !prev)} className={formClasses.iconButton}>
          <MenuIcon />
        </IconButton>
        {messagesData?.messages && messagesData?.messages.length > 0 && (
          <Box display={'flex'}>
            {isSubscribed ? (
              <Button
                variant="text"
                size="small"
                startIcon={<Unsubscribe />}
                onClick={() => {
                  messageThreadUnsubscribeMutate({
                    variables: subscriptionVariables,
                  });
                }}
              >
                Unsubscribe
              </Button>
            ) : (
              <Button
                variant="text"
                size="small"
                startIcon={<Markunread />}
                onClick={() => {
                  messageThreadSubscribeMutate({
                    variables: subscriptionVariables,
                  });
                }}
              >
                Subscribe
              </Button>
            )}
          </Box>
        )}
      </Box>

      <Divider />

      <Box px={2} className={formClasses.messageContainer}>
        {messagesDataLoading ? (
          <MessageSkeleton />
        ) : (
          <>
            {messagesData?.messages && messagesData?.messages.length > 0 ? (
              <>
                {Object.keys(groupedData).map((item, index) => {
                  return (
                    <Box key={index}>
                      <DividerWithText text={item} />

                      {groupedData[item].map((message, messageKey) => {
                        return <Message message={message} key={messageKey} />;
                      })}
                    </Box>
                  );
                })}
              </>
            ) : (
              <DividerWithText text={'No messages yet'} />
            )}
          </>
        )}
        <div ref={bottomRef} />
      </Box>

      <TextField
        autoFocus
        className={formClasses.messageInput}
        placeholder="Write a message"
        variant="outlined"
        value={currentMessage}
        onChange={(e) => setCurrentMessage(e.target.value)}
        onKeyPress={handleKeypress}
        InputProps={{
          // disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="text"
                endIcon={<Send />}
                size={'large'}
                className={formClasses.sendButton}
                onClick={handleSubmit}
              >
                Send
              </Button>
            </InputAdornment>
          ),
          // classes: { notchedOutline: formClasses.noBottomBorder },
        }}
      />
    </Box>
  );
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  card: {
    marginTop: '15px',
    padding: '15px',
  },
  container: {
    padding: '30px',
    marginTop: '25px',
    border: '0.5px solid gray',
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px',
    alignItems: 'center',
  },
  modalStyles: {
    position: 'absolute',
    width: '500px',
    // height: '200px',
    backgroundColor: '#FFFFFF',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '24px',
  },
  inputBoxStyle: {
    marginTop: '16px',
  },
  inputStyle: {
    width: '100%',
  },
  headerTitle: {
    fontSize: '1.5rem',
    fontFamily: 'Roboto, Helvetica Arial , sans-serif',
    lineHeight: '1.334',
    letterSpacing: '0em',
    paddingBottom: '10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  ButtonStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '24px',
  },
  listOfTasks: {
    background: 'white',
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    paddingBottom: '8px',
  },
  percentageLine: {
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    fontWeight: 500,
    fontSize: '1.2rem',
  },

  paper: {
    position: 'absolute',
    width: 520,
    backgroundColor: 'white',
    padding: '20px',
    top: `${47}%`,
    left: `${47}%`,
    transform: `translate(-${47}%, -${47}%)`,
    outline: 'none',
  },

  progressContainer: { width: '95%', marginLeft: '10px' },
  progress: { height: '8px', borderRadius: '8px' },

  checkBox: {
    color: '#2196F3',
  },

  datePickerChip: { marginRight: '0.75rem' },

  completedTask: { textDecoration: 'line-through' },

  note: {
    margin: '0',
    fontSize: '14px',
    fontStyle: 'italic',
  },

  link: {
    padding: '0px',
    fontWeight: 'bold',
    // textDecoration: 'underLined',
    color: '#434445',
    '&:hover': {
      color: '#2196f3',
    },
  },
});

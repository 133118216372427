import { Box, FormHelperText, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { useContactsSearch } from 'shared/hooks/useContactOptions';
import { abbreviateLastName } from 'shared/utils';
import AddContactButton from 'views/Client/components/popups/AddContactDialog';
import { Autocomplete } from '@material-ui/lab';

interface Props {
  orderedByIds: any[];
  setOrderedByContacts: (x: any) => void;
  clientId: string;
  contactIds: any[];
  appraisalDetails: any;
}

const filter = createFilterOptions<any>();

const OrderedByInput: FC<Props> = ({ orderedByIds, setOrderedByContacts, clientId, contactIds, appraisalDetails }) => {
  const [searchContacts, { data: searchData, refetch, loading: searchContactsLoading }] = useContactsSearch();

  const [contacts, setContacts] = useState<any>([]);
  const [value, setValue] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isNewContact, setIsNewContact] = useState(false);
  const dataProvider = useDataProvider();

  const debouncedSearch = useCallback(
    debounce((value: string) => searchContacts({ variables: { keyword: value } }), 0),
    [],
  );

  useEffect(() => {
    if (inputValue) {
      debouncedSearch(inputValue);
    }
  }, [debouncedSearch, inputValue]);

  const handleCloseClick = (values: any) => {
    setIsNewContact(false);
  };

  useEffect(() => {
    //preload dropdown with client's contacts
    if (clientId) {
      dataProvider
        .getList('contacts', {
          filter: { client_id: clientId },
          pagination: { page: 1, perPage: 50 },
          sort: { field: 'full_name', order: 'ASC' },
        })
        .then(({ data }) => {
          const selectedValues = value?.map((item: any) => item?.id);
          const newArr: any = selectedValues
            ? data.filter((item: any) => selectedValues.indexOf(item?.id) === -1)
            : data;
          setContacts(
            newArr.map((item: any) => ({
              id: item.id,
              full_name:
                abbreviateLastName(item?.full_name) ||
                abbreviateLastName(item.first_name + (item?.last_name ? ' ' + item?.last_name : '')),
              email: item.email,
              phone: item.phone_number,
              appraisalsCount: item.appraisals_count,
              contact_type_id: item.contact_type_id,
              type: item.type,
            })),
          );
          // setLoading(false);
        });
    }
  }, [dataProvider, clientId, value]);

  useEffect(() => {
    //set previous ordered by ids if exists
    if (orderedByIds) {
      dataProvider.getMany('contacts', { ids: orderedByIds }).then(({ data }) => {
        setValue(
          data.map((item: any) => ({
            id: item.id,
            full_name: abbreviateLastName(item.first_name + (item?.last_name ? ' ' + item?.last_name : '')),
            type: item?.type,
          })),
        );
      });
    }
  }, [dataProvider, orderedByIds]);

  useEffect(() => {
    setOrderedByContacts(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleContactCreated = async (values: any) => {
    if (values) {
      const newItem = {
        id: values.id,
        full_name: `${values.first_name}${values.last_name ? ' ' + values.last_name : ''}`,
      };
      setValue((prev: any) => {
        return prev ? prev.concat(newItem) : [newItem];
      });
      setInputValue('');
      refetch();
    }
  };

  if (isNewContact) {
    return (
      <>
        <Box mt={2}>
          <TextField
            color="primary"
            label="Add Contact"
            fullWidth
            defaultValue={`Add "${inputValue}" as new contact`}
            variant="outlined"
            // onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setIsNewContact(false)}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText style={{ fontSize: '1rem' }}>A new contact will be created</FormHelperText>
        </Box>
        <AddContactButton
          contactName={inputValue}
          showDialogView={isNewContact}
          clientId={clientId}
          persist={true}
          onSave={handleCloseClick}
          label="ADD NEW CONTACT"
          contactsCount={contactIds?.length}
          appraisal={appraisalDetails}
          onContactCreated={handleContactCreated}
          onCancel={() => setIsNewContact(false)}
        ></AddContactButton>
      </>
    );
  }
  return (
    <Autocomplete
      // disabled={propertyLoading}
      loading={searchContactsLoading}
      freeSolo
      multiple
      fullWidth
      // limitTags={2}
      value={value}
      id="multiple-owners"
      options={
        searchData?.search_contacts ||
        // searchData?.search_contacts?.filter((item: any) => value?.map((item: any) => item?.id).indexOf(item) === -1) ||
        contacts ||
        []
      }
      getOptionLabel={(option) => {
        if (option?.id === 'new') {
          return `${option.full_name}`;
        } else return `${abbreviateLastName(option?.full_name)} ${option?.type ? '(' + option?.type + ')' : ''}`;
      }}
      // defaultValue={[data[0]]}
      onInputChange={(_, value, event) => {
        if (event === 'input') {
          setInputValue(value);
        }
      }}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        const newItem = newValue.slice(-1)[0];
        if (newItem && newItem.id === 'new') {
          setIsNewContact(true);
        } else {
          setValue(newValue);
          setInputValue('');
        }
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          // label={<FieldTitle label={''} />}
          variant="outlined"
          fullWidth
          margin={'dense'}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      filterOptions={(options: any, params: any) => {
        const filtered = filter(options, params);
        setInputValue(params.inputValue);

        //to make email filtering work
        options.forEach((element: any) => {
          if (
            element?.email &&
            element?.email?.replace(',', '').toLowerCase().includes(params.inputValue.toLowerCase())
          ) {
            const checkDuplicate = (obj: any) => obj?.id === element?.id;
            const duplicateStatus = filtered.some(checkDuplicate);
            !duplicateStatus && filtered.push(element);
          }
        });

        //for new contact
        if (params.inputValue !== '') {
          filtered.push({
            id: `new`,
            client_name: '',
            email: '',
            full_name: `Add "${params.inputValue}" as new contact`,
          });
        }

        return filtered;
      }}
      selectOnFocus
    />
  );
  // return (
  //   <>
  //     <ReferenceArrayInput
  //       source="selected_contact_id"
  //       onChange={(val: any) => {
  //         if (val && val.split('.').length === 2) {
  //           // props.onSubmit();
  //           setIsNewContact(true);
  //           // setNewValue(val.split('.')[0]);
  //         }
  //       }}
  //       reference="contacts"
  //       fullWidth
  //       perPage={Infinity}
  //       // variant="standard"
  //       variant={'outlined'}
  //       sort={{ field: 'full_name', order: 'ASC' }}
  //       filter={{ client_id: clientId }}
  //       renderInput={(params: any) => (
  //         <TextField
  //           {...params}
  //           // label={<FieldTitle label={''} />}
  //           variant="outlined"
  //           fullWidth
  //           margin={'dense'}
  //           InputProps={{
  //             ...params.InputProps,
  //           }}
  //         />
  //       )}
  //       filterToQuery={(searchText: string) => ({ full_name: searchText })}
  //     >
  //       <AutocompleteArrayInput
  //         multiple
  //         renderOption={(option: any, { inputValue }: any) => {
  //           const searchKeys = ['full_name', 'client_name', 'email'];
  //           let partsData: any;
  //           searchKeys?.forEach((i) => {
  //             let matchData: any = option[i] && match(option[i], inputValue);
  //             partsData = {
  //               ...partsData,
  //               [i]: option[i] ? parse(option[i], matchData) : [],
  //             };
  //           });

  //           return (
  //             <div>
  //               <Box component="li">
  //                 {partsData?.full_name?.map((part: any, index: any) => (
  //                   <span key={index} style={{ fontSize: '1.1rem', fontWeight: part.highlight ? 800 : 400 }}>
  //                     {part.text}
  //                   </span>
  //                 ))}
  //                 <br />
  //                 <span style={{ fontWeight: 300 }}>
  //                   {option?.type} {option?.client_name ? 'at' : ''}{' '}
  //                 </span>
  //                 <span>
  //                   {partsData?.client_name?.map((part: any, index: any) => (
  //                     <span key={index} style={{ fontWeight: part.highlight ? 800 : 400 }}>
  //                       {part?.text}
  //                     </span>
  //                   ))}
  //                 </span>
  //                 <br />
  //                 <span>
  //                   {partsData?.email?.map((part: any, index: any) => (
  //                     <span key={index} style={{ fontWeight: part.highlight ? 800 : 300 }}>
  //                       {part?.text}
  //                     </span>
  //                   ))}
  //                   {option?.email && option?.phone_number ? ' - ' : ''} {option?.phone_number}
  //                 </span>
  //               </Box>
  //             </div>
  //           );
  //         }}
  //         freeSolo
  //         filterOptions={(options: any, params: any) => {
  //           const filtered = filter(options, params);
  //           setInputValue(params.inputValue);

  //           //to make email filtering work
  //           options.forEach((element: any) => {
  //             if (
  //               element?.email &&
  //               element?.email?.replace(',', '').toLowerCase().includes(params.inputValue.toLowerCase())
  //             ) {
  //               const checkDuplicate = (obj: any) => obj?.id === element?.id;
  //               const duplicateStatus = filtered.some(checkDuplicate);
  //               !duplicateStatus && filtered.push(element);
  //             }
  //           });

  //           //for new contact
  //           if (params.inputValue !== '') {
  //             filtered.push({
  //               id: `${params.inputValue}.new`,
  //               client_name: '',
  //               email: '',
  //               full_name: `Add "${params.inputValue}" as new contact`,
  //             });
  //           }

  //           return filtered;
  //         }}
  //         source=""
  //         optionText={(record: Contacts) => [record.full_name, record.client_name].filter(Boolean).join(' - ')}
  //         autoFocus
  //         openOnFocus
  //       />
  //     </ReferenceArrayInput>
  //   </>
  // );
};

export default OrderedByInput;

import React, { useEffect, useState } from 'react';
import { ReferenceInput, TextInput, required } from 'react-admin';
import { Box, Chip } from '@material-ui/core';
import AutocompleteInput from 'shared/components/AutocompleteInput';
import { PublicFieldProps } from 'ra-ui-materialui/lib/field/types';
import { Contacts } from 'views/Contact/types';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useContactDetailById, useContactsSearch } from 'shared/hooks/useContactOptions';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useForm } from 'react-final-form';
import ClientQuickCreateButton from 'views/Client/ClientQuickCreateButtonV2';

const filter = createFilterOptions<any>();
type AddContactFieldProps = PublicFieldProps & {
  source: string;
  filter?: {
    [key in string]?: any;
  };
  onAssign?(id: string): Promise<void>;
  onSubmit?: any;
  onTextSearch?: any;
  clearTextSearch?: any;
  onAdd?: any;
  label?: string;
};
function AddClientField({ label = 'Client', ...props }: AddContactFieldProps) {
  const [newValue, setNewValue] = useState<string>('');
  const [getContactsFullTextSearch, { data }] = useContactsSearch();
  const [inputValue, setInputValue] = useState('');
  const form = useForm();
  const [getContactDetails, { data: contactData }] = useContactDetailById();
  const [isNewClient, setIsNewClient] = useState(false);
  const [newAddedClient, setNewAddedClient] = useState<any>(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [contactIds, setContactIds] = useState<Array<[]>>([]);

  useEffect(() => {
    if (inputValue) {
      getContactsFullTextSearch({ variables: { keyword: inputValue } });
    } else {
      props.clearTextSearch();
    }
    // eslint-disable-next-line
  }, [inputValue]);

  useEffect(() => {
    //for old contact selection
    if (contactData) {
      const contactDetails = contactData?.contact_by_pk;
      if (contactDetails) {
        props.onAdd(contactDetails);
        form.change('client_id', contactDetails?.client_id);
        form.change('ordered_by_contact_ids', [contactDetails?.id]);
      }
    }
    // eslint-disable-next-line
  }, [contactData]);

  useEffect(() => {
    if (data && data?.search_contacts?.length !== 0) {
      const { search_contacts } = data;
      const ids = search_contacts.map((item) => item.id);
      setContactIds(ids);
      props.onTextSearch(ids);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (newAddedClient) {
      form.change('selected_contact_ids', newAddedClient?.id);
      form.change('client_id', newAddedClient?.client_id);
      form.change('ordered_by_contact_ids', [newAddedClient?.id]);
    }
    // eslint-disable-next-line
  }, [newAddedClient]);

  //   const handleChange = (e: any) => {
  //     if (!e.target.value) {
  //       setIsNewClient(false);
  //     }
  //   };

  if (isNewClient) {
    return (
      <>
        <TextInput label={label} source="selected_contact_ids" defaultValue={newValue} fullWidth variant="outlined" />

        {/* <TextField
          color="primary"
          label="Client"
          fullWidth
          defaultValue={newValue}
          variant="outlined"
          onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setIsNewClient(false)}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
        /> */}
        {openDialog && (
          <ClientQuickCreateButton
            showContacts={true}
            classes={{ marginBottom: '20px' }}
            onChange={() => console.log('')}
            hideButton={true}
            newClientName={newValue}
            onSuccess={(newClient: any) => {
              const newIds = [...contactIds, newClient?.id];
              props.onTextSearch(newIds);
              props.onAdd(newClient);
              setNewAddedClient(newClient);
              setIsNewClient(false);
            }}
            onCancel={() => {
              setOpenDialog(false);
              setIsNewClient(false);
            }}
          />
        )}
      </>
    );
  }
  return (
    <>
      <ReferenceInput
        label={label}
        source="selected_contact_ids"
        onChange={(val: any) => {
          if (val && val.split('^').length === 2) {
            setOpenDialog(true);
            setIsNewClient(true);
            setNewValue(val.split('^')[0]);
          } else {
            val && getContactDetails({ variables: { id: val } });
          }
        }}
        reference="client_contacts"
        fullWidth
        perPage={100}
        variant={'outlined'}
        sort={''}
        filter={props.filter}
        validate={required()}
      >
        <AutocompleteInput
          renderOption={(option: any, { inputValue }: any) => {
            const searchKeys = ['full_name', 'client_name', 'email'];
            let partsData: any;
            searchKeys?.forEach((i) => {
              let matchData: any = option[i] && match(option[i], inputValue);
              partsData = {
                ...partsData,
                [i]: option[i] ? parse(option[i], matchData) : [],
              };
            });

            return (
              <div>
                <Box component="li" {...props}>
                  {partsData?.full_name?.map((part: any, index: any) => (
                    <span key={index} style={{ fontSize: '1.1rem', fontWeight: part.highlight ? 800 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                  {option?.primary && (
                    <Chip
                      style={{
                        marginLeft: '8px',
                        marginBottom: '6px',
                        padding: '2px',
                        height: '1rem',
                        fontSize: '0.7rem',
                      }}
                      label="Primary"
                      size="small"
                    />
                  )}
                  <br />
                  <span style={{ fontWeight: 300 }}>
                    {option?.type} {option?.client_name ? 'at' : ''}{' '}
                  </span>
                  <span>
                    {partsData?.client_name?.map((part: any, index: any) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 800 : 400 }}>
                        {part?.text}
                      </span>
                    ))}
                  </span>
                  <br />
                  <span>
                    {partsData?.email?.map((part: any, index: any) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 800 : 300 }}>
                        {part?.text}
                      </span>
                    ))}
                    <span style={{ fontWeight: 300 }}>
                      {option?.email && option?.phone_number ? ' - ' : ''} {option?.phone_number}
                    </span>
                  </span>{' '}
                </Box>
              </div>
            );
          }}
          // freeSolo={true}
          filterOptions={(options: any, params: any) => {
            const filtered = filter(options, params);
            setInputValue(params.inputValue);

            //to make email filtering work
            options.forEach((element: any) => {
              if (
                element?.email &&
                element?.email?.replace(',', '').toLowerCase().includes(params.inputValue.toLowerCase())
              ) {
                const checkDuplicate = (obj: any) => obj?.id === element?.id;
                const duplicateStatus = filtered.some(checkDuplicate);
                !duplicateStatus && filtered.push(element);
              }
            });

            //for new contact
            if (params.inputValue !== '') {
              filtered.push({
                id: `${params.inputValue}^new`,
                client_name: '',
                email: '',
                full_name: `Add "${params.inputValue}" as new client`,
              });
            }

            return filtered;
          }}
          source=""
          optionText={(record: Contacts) => [record?.full_name, record?.client_name].filter(Boolean).join(' - ')}
          // autoFocus
          openOnFocus
        />
      </ReferenceInput>
    </>
  );
}

export default AddClientField;

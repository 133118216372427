import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Cancel, Clear } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  FieldTitle,
  FormDataConsumer,
  FormWithRedirect,
  ReferenceInput,
  SaveButton,
  TextInput,
  email as emailValidation,
  required,
  useCreate,
  useDataProvider,
  useNotify,
} from 'react-admin';
import AutocompleteInput from 'shared/components/AutocompleteInput';
import PlacesAutocomplete from 'shared/components/PlacesAutocomplete';
import { Contact } from 'shared/generated/types';
import { useClientDetailById } from 'shared/hooks/useClientOptions';
import useContactOptions, { useContactsSearch } from 'shared/hooks/useContactOptions';
import { abbreviateLastName, validatePhone } from 'shared/utils';
import styles from 'views/Client/components/hooks/useContactPopupStyles';

interface Props {
  allContacts: any[];
  meetingContacts: any[];
  setMeetingContacts: (x: any) => void;
  clientId?: string;
  isEditPage?: boolean;
}

const filter = createFilterOptions<any>();

const MeetingInput: FC<Props> = ({ allContacts, meetingContacts, setMeetingContacts, clientId, isEditPage = true }) => {
  const popUpClasses = styles();
  const notify = useNotify();
  const [create, { loading }] = useCreate('contact');
  const [searchContacts, { data: searchData, refetch, loading: searchContactsLoading }] = useContactsSearch();

  const [contacts, setContacts] = useState<any>([]);
  const [value, setValue] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isNewContact, setIsNewContact] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [contactOptions] = useContactOptions();
  const { data } = useClientDetailById({ id: clientId });
  const [isCurrentClient, setAsCurrentClient] = useState<boolean>(false);
  const [contactType, setContactType] = useState(17);
  const [showContactTypeSelector, setShowContactTypeSelector] = useState<boolean>(false);
  const [priorityContactTypes, setPriorityContactTypes] = useState<any>([]);
  const dataProvider = useDataProvider();

  const debouncedSearch = useCallback(
    debounce((value: string) => searchContacts({ variables: { keyword: value } }), 0),
    [],
  );

  useEffect(() => {
    if (inputValue) {
      debouncedSearch(inputValue);
    }
    return () => {
      setAsCurrentClient(false);
      setContactType(17);
    };
  }, [debouncedSearch, inputValue]);

  const handleCloseClick = () => {
    setIsNewContact(false);
  };

  useEffect(() => {
    //preload dropdown with form's all_contact_ids
    if (allContacts?.length !== 0) {
      // setLoading(true);
      const selectedValues = value?.map((item: any) => item?.id);
      const newArr: any = selectedValues
        ? allContacts.filter((item: any) => selectedValues.indexOf(item) === -1)
        : allContacts;
      dataProvider.getMany('contacts', { ids: newArr }).then(({ data }) => {
        setContacts(
          data.map((item: any) => ({
            id: item.id,
            full_name:
              abbreviateLastName(item?.full_name) ||
              abbreviateLastName(item.first_name + (item?.last_name ? ' ' + item?.last_name : '')),
            email: item.email,
            phone: item.phone_number,
            appraisalsCount: item.appraisals_count,
            contact_type_id: item.contact_type_id,
            type: item.type,
          })),
        );
        // setLoading(false);
      });
    }
  }, [dataProvider, allContacts, value]);

  useEffect(() => {
    //set previous meeting ids if exists
    if (meetingContacts?.length !== 0) {
      dataProvider.getMany('contacts', { ids: meetingContacts }).then(({ data }) => {
        setValue(
          data.map((item: any) => ({
            id: item.id,
            full_name: abbreviateLastName(item.first_name + (item?.last_name ? ' ' + item?.last_name : '')),
            type: item?.type,
          })),
        );
      });
    }
  }, [dataProvider, meetingContacts]);

  useEffect(() => {
    const priorityIds = [17, 29, 24, 25, 26, 27];
    const prioritizedTypes = priorityIds?.map((id) => contactOptions?.data?.contactTypes?.find((el) => el.id === id));
    setPriorityContactTypes(prioritizedTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactOptions?.data?.contactTypes]);

  useEffect(() => {
    // if (value.length > 0) {
    setMeetingContacts(value);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const createContact = useCallback(
    (values: any, updateValue?: boolean) => {
      return new Promise<Contact>((resolve: any, reject: any) => {
        create(
          {
            payload: {
              data: values,
            },
          },
          {
            onSuccess: ({ data }: any) => {
              refetch();
              resolve(data);
              setIsNewContact(false);
              if (updateValue) {
                setValue((prev) => {
                  const newItem = {
                    id: data.id,
                    full_name: `${data.first_name || ''}${data.last_name ? ' ' + data?.last_name : ''}`,
                    type: data?.type,
                  };
                  return prev ? prev.concat(newItem) : [newItem];
                });
              }
            },
            onFailure: ({ error }: any) => {
              notify(error.message, 'error');
              reject(error);
            },
          },
        );
      });
    },
    [create, notify, refetch],
  );

  const handleSubmit = async (values: any) => {
    setDisabled(true);
    const name = values.name.split(' ');
    if (isCurrentClient) values.client_id = clientId;
    const sanitizedValue = { ...values, first_name: name[0], last_name: name.slice(1).join(' ') };
    const newContact = await createContact(sanitizedValue);

    if (newContact) {
      const newItem = {
        id: newContact.id,
        full_name: `${newContact.first_name}${newContact.last_name ? ' ' + newContact.last_name : ''}`,
      };
      setValue((prev: any) => {
        return prev ? prev.concat(newItem) : [newItem];
      });
      setInputValue('');
    }
    setDisabled(false);
  };

  if (isNewContact) {
    return (
      <>
        <Box mt={2}>
          <TextField
            color="primary"
            label="Add Contact"
            fullWidth
            defaultValue={`Add "${inputValue}" as new contact`}
            variant="outlined"
            // onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setIsNewContact(false)}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText style={{ fontSize: '1rem' }}>A new contact will be created</FormHelperText>
        </Box>
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={isNewContact}
          onClose={handleCloseClick}
          aria-label="Add Meeting with"
        >
          <FormWithRedirect
            initialValues={{
              name: inputValue,
              contact_type_id: contactType,
            }}
            save={handleSubmit}
            render={({ handleSubmitWithRedirect, saving }: any) => (
              <DialogContent classes={{ root: popUpClasses.dialogContent }}>
                <Card variant="outlined" className={popUpClasses.formBottom}>
                  <CardHeader title="Add Meeting with" classes={{ root: popUpClasses.cardHeader }} />

                  <Divider></Divider>
                  <Box className={popUpClasses.formContainer}>
                    <Typography classes={{ root: popUpClasses.heading }}>CONTACT</Typography>
                    <Divider classes={{ root: popUpClasses.divider }}></Divider>
                    <Grid container direction="row">
                      <Grid item md={8} sm={10} xs={12}>
                        <TextInput
                          value={inputValue}
                          validate={required()}
                          source="name"
                          variant="outlined"
                          fullWidth
                          autoFocus
                          openOnFocus
                        />
                      </Grid>
                      <Grid item md={8} sm={10} xs={12}>
                        <TextInput validate={[emailValidation()]} source="email" variant="outlined" fullWidth />
                      </Grid>
                      <Grid item md={8} sm={10} xs={12}>
                        <TextInput
                          source="phone_number"
                          label="Phone"
                          validate={validatePhone}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={8} sm={10} xs={12} style={{ paddingBottom: '0px' }}>
                        <PlacesAutocomplete
                          variant="outlined"
                          autoFocus={false}
                          label="Address"
                          source="location_address"
                          isMapVisible={false}
                          onChange={(e: any) => {
                            console.log('place', { e });
                          }}
                        />
                      </Grid>
                      {clientId && (
                        <Grid container md={8} sm={10} xs={12} style={{ marginBottom: '1rem', marginTop: '-7px' }}>
                          <Grid item md={11} sm={11} xs={11} style={{ display: 'flex', alignItems: 'center' }}>
                            <FormDataConsumer>
                              {({ formData, ...rest }) => (
                                <span>
                                  {formData?.name || 'Contact'} works for (belongs to) {data?.client_by_pk?.name}?
                                </span>
                              )}
                            </FormDataConsumer>
                          </Grid>
                          <Grid item md={1} sm={1} xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Checkbox
                              onChange={(e) => {
                                setAsCurrentClient(e.target.checked);
                              }}
                              style={{ color: '#2196F3' }}
                              checked={!!isCurrentClient}
                            />
                          </Grid>
                        </Grid>
                      )}

                      <Grid item md={8} sm={10} xs={12}>
                        {showContactTypeSelector ? (
                          <ReferenceInput
                            label="Contact Type"
                            source="contact_type_id"
                            reference="contact_types"
                            allowEmpty={false}
                            fullWidth
                            perPage={100}
                            variant="outlined"
                            sort={{ field: 'order', order: 'ASC' }}
                            validate={[required()]}
                            // defaultValue={0}
                          >
                            <AutocompleteInput source="contact_type_id" optionText="type" />
                          </ReferenceInput>
                        ) : (
                          <Box>
                            <Typography variant="subtitle1">Contact Type</Typography>
                            <Box mb={2.5} mt={0.5}>
                              {priorityContactTypes?.map((item: any) => (
                                <Button
                                  label={item?.type || ''}
                                  variant={contactType === item?.id ? 'contained' : 'outlined'}
                                  className={popUpClasses.chipButton}
                                  onClick={() => {
                                    setContactType(item?.id);
                                  }}
                                />
                              ))}
                              <Button
                                label={'More'}
                                variant={'outlined'}
                                className={popUpClasses.chipButton}
                                onClick={() => {
                                  setShowContactTypeSelector(true);
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <DialogActions classes={{ root: popUpClasses.editActions }}>
                      <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={disabled}>
                        <Cancel />
                      </Button>
                      <SaveButton
                        saving={saving}
                        disabled={loading || disabled}
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                      />
                    </DialogActions>
                  </Box>
                </Card>
              </DialogContent>
            )}
          />
        </Dialog>
      </>
    );
  }

  return (
    <Autocomplete
      // disabled={propertyLoading}
      loading={searchContactsLoading}
      freeSolo
      multiple
      fullWidth
      // limitTags={2}
      value={value}
      id="multiple-owners"
      options={
        searchData?.search_contacts ||
        // searchData?.search_contacts?.filter((item: any) => value?.map((item: any) => item?.id).indexOf(item) === -1) ||
        contacts ||
        []
      }
      getOptionLabel={(option) => {
        if (option?.id === 'new') {
          return `${option.full_name}`;
        } else return `${abbreviateLastName(option?.full_name)} ${option?.type ? '(' + option?.type + ')' : ''}`;
      }}
      // defaultValue={[data[0]]}
      onInputChange={(_, value, event) => {
        if (event === 'input') {
          setInputValue(value);
        }
      }}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        const newItem = newValue.slice(-1)[0];
        if (newItem && newItem.id === 'new') {
          setIsNewContact(true);
        } else {
          setValue(newValue);
          setInputValue('');
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={!isEditPage ? <FieldTitle label={'Meeting with'} /> : ''}
          variant="outlined"
          fullWidth
          margin={'dense'}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      filterOptions={(options: any, params: any) => {
        const filtered = filter(options, params);
        setInputValue(params.inputValue);

        //to make email filtering work
        options.forEach((element: any) => {
          if (
            element?.email &&
            element?.email?.replace(',', '').toLowerCase().includes(params.inputValue.toLowerCase())
          ) {
            const checkDuplicate = (obj: any) => obj?.id === element?.id;
            const duplicateStatus = filtered.some(checkDuplicate);
            !duplicateStatus && filtered.push(element);
          }
        });

        //for new contact
        if (params.inputValue !== '') {
          filtered.push({
            id: `new`,
            client_name: '',
            email: '',
            full_name: `Add "${params.inputValue}" as new contact`,
          });
        }

        return filtered;
      }}
      selectOnFocus
    />
  );
};

export default MeetingInput;

import React, { useMemo, useState, useEffect } from 'react';
import ListActions from 'shared/components/Resource/ListActions';
import { List, ListProps, useGetIdentity, useListContext } from 'react-admin';
import Calendar from './components';
import ListFilter from './ListFilter';
import dayjs from 'dayjs';
import omit from 'lodash/omit';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import { useListStyles } from 'shared/components/Resource/styles';
import { standardMutationRoles } from 'shared/constants/roles';

function CalendarView(props: ListProps) {
  const { identity } = useGetIdentity();
  const classes = useListStyles();
  const [queryString, onChangeQuery] = useLocationQuery();
  const [currentDate, setCurrentDate] = useState(
    queryString.currentDate ? new Date(queryString.currentDate) : new Date(),
  );
  useEffect(() => {
    queryString.currentDate && setCurrentDate(new Date(queryString.currentDate));
  }, [queryString.currentDate]);

  useEffect(() => {
    if (!queryString.calendarView) {
      onChangeQuery({ calendarView: 'agenda' });
    }
  }, [onChangeQuery, queryString.calendarView]);

  const dateRange = useMemo(
    () =>
      queryString.calendarView === 'month'
        ? {
            startDate: dayjs(currentDate).subtract(1, 'month').toDate(),
            endDate: dayjs(currentDate).add(1, 'month').toDate(),
          }
        : {
            startDate: dayjs(currentDate).toDate(),
            endDate: dayjs(currentDate).add(1, 'week').toDate(),
          },
    [currentDate, queryString.calendarView],
  );
  const defaultFilter = {
    _or: {
      format: 'raw-query',
      value: [
        {
          inspection_date: { _lte: dateRange.endDate.toISOString(), _gte: dateRange.startDate.toISOString() },
        },
        {
          due_date: { _lte: dateRange.endDate.toISOString(), _gte: dateRange.startDate.toISOString() },
        },
      ],
    },
  };

  if (!identity) return <span />;

  const { role } = identity;

  const HiddenPagination = () => {
    const { page, perPage, total, setPage } = useListContext();

    React.useEffect(() => {
      if (page > 1 && total <= (page - 1) * perPage) {
        setPage(page - 1);
      }
    }, [total, page, perPage, setPage]);

    return null;
  };

  return (
    <List
      {...omit(props, 'staticContext')}
      hasShow={false}
      classes={classes}
      hasCreate={standardMutationRoles.includes(role ?? '')}
      hasEdit={true}
      hasList={false}
      title="Schedule"
      resource="schedule"
      basePath="/schedule"
      syncWithLocation
      sort={{ field: 'order_by_date', order: 'DESC' }}
      filters={<ListFilter isCalendar />}
      pagination={<HiddenPagination />}
      perPage={200}
      bulkActionButtons={false}
      actions={<ListActions hasExport={false} />}
      filter={defaultFilter}
      empty={false}
    >
      <Calendar defaultFilter={defaultFilter} onChangeDate={onChangeDate} currentDate={currentDate} />
    </List>
  );

  function onChangeDate(currentDate: Date) {
    onChangeQuery({ currentDate: currentDate.toISOString() });
    setCurrentDate(currentDate);
  }
}

export default CalendarView;

import Tracker from '@openreplay/tracker';
import { Config } from 'shared/constants/config';
import trackerGraphQL from '@openreplay/tracker-graphql';

const openReplay = new Tracker({
  projectKey: Config.openReplayId,
  obscureTextEmails: false,
  obscureTextNumbers: false,
  obscureInputEmails: false,
  obscureInputDates: false,
  defaultInputMode: 0,
});

export const recordGraphQL = openReplay.use(trackerGraphQL());

export default openReplay;

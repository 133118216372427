import { Field } from 'shared/components/Resource/List';

const listFields: Field[] = [
  {
    type: 'TextField',
    source: 'appraisal_file_number',
    label: 'File #',
  },
  {
    type: 'TextField',
    source: 'assignee',
    label: 'Assignee ',
  },
  {
    type: 'TextField',
    source: 'quantity',
    label: 'Quantity',
  },
  {
    type: 'DateField',
    source: 'expense_date',
    label: 'Date',
  },
];

export const createFields: Field[] = [];

export const filterFields: Field[] = [
  {
    autoFocus: true,
    type: 'SearchInput',
    source: 'appraisal_file_number,assignee_full_name',
    label: 'Search Commissions',
    alwaysOn: true,
    fullWidth: true,
    resettable: true,
  },
  {
    type: 'ReferenceInput',
    label: 'Assignee',
    source: 'assignee_user_account_id',
    reference: 'user_profiles',
    allowEmpty: false,
    fullWidth: true,
    // perPage: 2,
    sort: { field: 'full_name', order: 'ASC' },

    filterToQuery: (searchText: string) => ({ full_name: searchText }),
    children: [
      {
        type: 'AutocompleteInput',
        optionText: 'full_name',
        optionValue: 'user_account_id',
      },
    ],
  },
  {
    type: 'ReferenceInput',
    label: 'Role',
    source: 'assignee_role_id',
    reference: 'assignee_roles',
    allowEmpty: false,
    fullWidth: true,
    // perPage: 2,
    sort: { field: 'order', order: 'ASC' },
    filterToQuery: (searchText: string) => ({ role: searchText }),
    children: [
      {
        type: 'AutocompleteInput',
        optionText: 'role',
      },
    ],
  },

  {
    type: 'ReferenceInput',
    label: 'Property Type',
    source: 'property_type_id',
    reference: 'property_types',
    allowEmpty: false,
    fullWidth: true,
    perPage: 2,
    sort: { field: 'order', order: 'ASC' },
    filterToQuery: (searchText: string) => ({ type: searchText }),
    children: [
      {
        type: 'AutocompleteInput',
        optionText: 'type',
      },
    ],
  },
  {
    type: 'ReferenceInput',
    label: 'Commercial Property Type',
    source: 'commercial_property_type_id',
    reference: 'commercial_property_type',
    alwaysOn: true,
    fullWidth: true,
    perPage: 100,
    sort: { field: 'order', order: 'ASC' },
    filterToQuery: (searchText: string) => ({ type: searchText }),
    children: [
      {
        type: 'AutocompleteInput',
        optionText: 'type',
      },
    ],
  },
  {
    type: 'ReferenceInput',
    label: 'Residential Ownership Type',
    source: 'residential_ownership_type_id',
    reference: 'residential_ownership_types',
    alwaysOn: true,
    allowEmpty: false,
    fullWidth: true,
    perPage: 100,
    sort: { field: 'order', order: 'ASC' },
    filterToQuery: (searchText: string) => ({ type: searchText }),
    children: [
      {
        type: 'AutocompleteInput',
        optionText: 'type',
      },
    ],
  },

  {
    type: 'ReferenceInput',
    label: 'Rate Type',
    source: 'rate_type_id',
    reference: 'rate_types',
    allowEmpty: false,
    fullWidth: true,
    perPage: 2,
    sort: { field: 'order', order: 'ASC' },
    filterToQuery: (searchText: string) => ({ type: searchText }),
    children: [
      {
        type: 'AutocompleteInput',
        optionText: 'type',
      },
    ],
  },
  {
    type: 'RangeInput',
    label: 'Total Amount',
    source: 'total_amount',
    fullWidth: true,
    defaultMin: 0,
    defaultMax: 25000000,
  },
  {
    type: 'DateRangeInput',
    source: 'paid_date',
    label: 'Paid',
    fullWidth: true,
    variant: 'standard',
  },
  {
    type: 'DateRangeInput',
    source: 'created_at',
    label: 'Created',
    fullWidth: true,
    variant: 'standard',
  },
];

export const exportFields = [
  'id',
  'appraisal_file_number',
  'assignee_full_name',
  'quantity',
  'rate_type',
  'rate',
  'total_amount',
  'completed_date',
  'paid_date',
  'created_at',
  'updated_at',
];

export default listFields;

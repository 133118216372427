import React, { useEffect, useState } from 'react';
import { ReferenceInput } from 'react-admin';
import { Grid, Typography, Box, TextField, FormHelperText, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AutocompleteInput from 'shared/components/AutocompleteInput';
import { PublicFieldProps } from 'ra-ui-materialui/lib/field/types';
import { Contacts } from 'views/Contact/types';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Clear from '@material-ui/icons/Clear';
import { useContactsSearch } from 'shared/hooks/useContactOptions';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const filter = createFilterOptions<any>();
const styles = makeStyles({
  msg: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: '-10px',
  },
});
type AddContactFieldProps = PublicFieldProps & {
  source: string;
  filter?: {
    [key in string]?: any;
  };
  onAssign?(id: string): Promise<void>;
  onSubmit?: any;
  onTextSearch?: any;
  clearTextSearch?: any;
};
function AddContactField(props: AddContactFieldProps) {
  const [msg, setMsg] = useState('');
  const classes = styles();
  const [isNewContact, setIsNewContact] = useState(false);
  const [newValue, setNewValue] = useState('');
  const [getContactsFullTextSearch, { data }] = useContactsSearch();
  const [inputValue, setInputValue] = useState('');

  const handleChange = (e: any) => {
    if (!e.target.value) {
      setIsNewContact(false);
    }
  };

  useEffect(() => {
    if (inputValue) {
      getContactsFullTextSearch({ variables: { keyword: inputValue } });
    } else {
      props.clearTextSearch();
    }
    // eslint-disable-next-line
  }, [inputValue]);

  useEffect(() => {
    if (data && data?.search_contacts?.length !== 0) {
      const { search_contacts } = data;
      const ids = search_contacts.map((item) => item.id);
      props.onTextSearch(ids);
    }
    // eslint-disable-next-line
  }, [data]);

  if (isNewContact) {
    return (
      <Box mt={2}>
        <TextField
          color="primary"
          label="Add Contact"
          fullWidth
          defaultValue={`Add "${newValue}" as new contact`}
          variant="outlined"
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsNewContact(false)}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText style={{ fontSize: '1rem' }}>
          A new contact will be created and linked to this appraisal
        </FormHelperText>
      </Box>
    );
  }
  return (
    <Grid container alignItems="center">
      <Grid item md={12}>
        <Box mt={2}>
          <ReferenceInput
            label="Add Contact"
            source="selected_contact_id"
            onChange={(val: any) => {
              if (val && val.split('.').length === 2) {
                props.onSubmit();
                setIsNewContact(true);
                setNewValue(val.split('.')[0]);
                setMsg(`A new contact will be created and linked to this appraisal`);
              } else {
                setMsg(`The existing contact will be linked to this appraisal`);
              }
            }}
            reference="contacts"
            fullWidth
            perPage={Infinity}
            // variant="standard"
            variant={'outlined'}
            sort={{ field: 'full_name', order: 'ASC' }}
            filter={props.filter}
            filterToQuery={(searchText: string) => ({ full_name: searchText })}
          >
            <AutocompleteInput
              // renderOption={(props: any) => (
              //   <Box component="li" {...props}>
              //     {props?.id?.split('.').length === 2 ? (
              //       <b style={{ color: '#2196f3' }}> {props.full_name} </b>
              //     ) : (
              //       <>
              //         <b> {props.full_name}</b>
              //         <br /> {props?.type} {props?.client_name ? 'at' : ''} <b>{props?.client_name}</b>
              //         <br /> {props?.email} {props?.email && props?.phone_number ? ' - ' : ''} {props?.phone_number}
              //       </>
              //     )}
              //   </Box>
              // )}
              renderOption={(option: any, { inputValue }: any) => {
                const searchKeys = ['full_name', 'client_name', 'email'];
                let partsData: any;
                searchKeys?.forEach((i) => {
                  let matchData: any = option[i] && match(option[i], inputValue);
                  partsData = {
                    ...partsData,
                    [i]: option[i] ? parse(option[i], matchData) : [],
                  };
                });

                return (
                  <div>
                    <Box component="li" {...props}>
                      {partsData?.full_name?.map((part: any, index: any) => (
                        <span key={index} style={{ fontSize: '1.1rem', fontWeight: part.highlight ? 800 : 400 }}>
                          {part.text}
                        </span>
                      ))}
                      <br />
                      <span style={{ fontWeight: 300 }}>
                        {option?.type} {option?.client_name ? 'at' : ''}{' '}
                      </span>
                      <span>
                        {partsData?.client_name?.map((part: any, index: any) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 800 : 400 }}>
                            {part?.text}
                          </span>
                        ))}
                      </span>
                      <br />
                      <span>
                        {partsData?.email?.map((part: any, index: any) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 800 : 300 }}>
                            {part?.text}
                          </span>
                        ))}
                        {option?.email && option?.phone_number ? ' - ' : ''} {option?.phone_number}
                      </span>
                    </Box>
                  </div>
                );
              }}
              freeSolo={true}
              filterOptions={(options: any, params: any) => {
                const filtered = filter(options, params);
                setInputValue(params.inputValue);

                //to make email filtering work
                options.forEach((element: any) => {
                  if (
                    element?.email &&
                    element?.email?.replace(',', '').toLowerCase().includes(params.inputValue.toLowerCase())
                  ) {
                    const checkDuplicate = (obj: any) => obj?.id === element?.id;
                    const duplicateStatus = filtered.some(checkDuplicate);
                    !duplicateStatus && filtered.push(element);
                  }
                });

                //for new contact
                if (params.inputValue !== '') {
                  filtered.push({
                    id: `${params.inputValue}.new`,
                    client_name: '',
                    email: '',
                    full_name: `Add "${params.inputValue}" as new contact`,
                  });
                }

                return filtered;
              }}
              source=""
              optionText={(record: Contacts) => [record.full_name, record.client_name].filter(Boolean).join(' - ')}
              autoFocus
              openOnFocus
            />
          </ReferenceInput>
          <Typography className={classes.msg}>{msg}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AddContactField;

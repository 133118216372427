import React, { Dispatch, SetStateAction } from 'react';

import { Checkbox, Divider, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { TextInput } from 'react-admin';

import { styles } from '../../appraisalStyles';

interface WorkFlowSectionProps {
  appraisalStatus: number;
  setAppraisalStatus: Dispatch<SetStateAction<number>>;
  appraisalPriority: number;
  setAppraisalPriority: Dispatch<SetStateAction<number>>;
}

const WorkFlowSection = ({
  appraisalStatus,
  setAppraisalStatus,
  appraisalPriority,
  setAppraisalPriority,
}: WorkFlowSectionProps) => {
  const classes = styles();

  return (
    <>
      <Typography classes={{ root: classes.sectionHeading }}>WORKFLOW</Typography>
      <Divider classes={{ root: classes.divider }}></Divider>

      <Grid container direction="column" alignItems="center">
        <Grid container md={5} sm={12} xs={12}>
          <TextInput label="Appraisal File #" source="appraisal_file_number" fullWidth variant="outlined" />
        </Grid>
      </Grid>

      <Grid container direction="column" alignItems="center">
        <Grid container md={5} sm={12} xs={12} style={{ marginBottom: '4px' }}>
          <Grid item md={11} sm={11} xs={11} style={{ display: 'flex', alignItems: 'center' }}>
            Request for Quote?
          </Grid>
          <Grid item md={1} sm={1} xs={1}>
            <Checkbox
              onChange={(e) => {
                setAppraisalStatus(e.target.checked ? 8 : 1);
              }}
              style={{ color: '#2196F3' }}
              checked={appraisalStatus === 8}
            />
          </Grid>
        </Grid>
        <Grid container md={5} sm={12} xs={12}>
          <Grid item md={11} sm={11} xs={11} style={{ display: 'flex', alignItems: 'center' }}>
            Rush?
          </Grid>
          <Grid item md={1} sm={1} xs={1}>
            <Checkbox
              onChange={(e) => {
                setAppraisalPriority(e.target.checked ? 2 : 1);
              }}
              style={{ color: '#2196F3' }}
              checked={appraisalPriority === 2}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkFlowSection;

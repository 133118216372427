import React from 'react';
import { Datagrid, TextField, Record, FunctionField } from 'react-admin';
import HighlightTextField from 'shared/components/HighlightTextField/HighlightTextField';
import ResourceCount from 'shared/components/ResourceCount';
import TruncatedField from 'shared/components/TruncatedField';

const GridComponent = ({ role }: any) => {
  return (
    <Datagrid rowClick="edit" optimized>
      <HighlightTextField label="Name" source="full_name" />
      <HighlightTextField source="email" />
      <TextField label="Phone" source="phone_number" />
      <TruncatedField label="Client" source="client_name" />
      <HighlightTextField source="type" />
      <FunctionField label="Primary" render={(record: any) => `${record.primary ? 'Yes' : 'No'}`} />
      {role !== 'appraisal_firm_limited_access' && (
        <ResourceCount
          label="Appraisals"
          filter={(record: Record) => ({
            contact_ids: [record.id],
          })}
          basePath="/appraisals"
          countKey="appraisals_count"
          source="appraisals_count"
        />
      )}
    </Datagrid>
  );
};

export default GridComponent;

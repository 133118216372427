import { Field } from 'shared/components/Resource/List';

const listFields: Field[] = [
  {
    type: 'TextField',
    source: 'appraisal_file_number',
    label: 'File #',
  },
  {
    type: 'TextField',
    source: 'description',
    label: 'Description',
  },
  {
    type: 'TextField',
    source: 'quantity',
    label: 'Quantity',
  },
  {
    type: 'DateField',
    source: 'expense_date',
    label: 'Date',
  },
];

export const createFields: Field[] = [];

export const filterFields: Field[] = [
  {
    autoFocus: true,
    type: 'SearchInput',
    source: 'appraisal_file_number,description',
    label: 'Search Fees',
    alwaysOn: true,
    fullWidth: true,
    resettable: true,
  },
  {
    type: 'ReferenceInput',
    label: 'Rate Type',
    source: 'rate_type_id',
    reference: 'rate_types',
    allowEmpty: false,
    fullWidth: true,
    perPage: 2,
    sort: { field: 'order', order: 'ASC' },
    filterToQuery: (searchText: string) => ({ type: searchText }),
    children: [
      {
        type: 'AutocompleteInput',
        optionText: 'type',
      },
    ],
  },
  {
    type: 'RangeInput',
    label: 'Total Amount',
    source: 'total',
    fullWidth: true,
    defaultMin: 0,
    defaultMax: 25000000,
  },

  {
    type: 'DateRangeInput',
    source: 'completed_date',
    label: 'Completed',
    fullWidth: true,
    variant: 'standard',
  },
  {
    type: 'DateRangeInput',
    source: 'paid_date',
    label: 'Paid',
    fullWidth: true,
    variant: 'standard',
  },
  {
    type: 'DateRangeInput',
    source: 'created_at',
    label: 'Created',
    fullWidth: true,
    variant: 'standard',
  },
];

export const exportFields = [
  'id',
  'appraisal_file_number',
  'description',
  'quantity',
  'rate_type',
  'rate',
  'total_amount',
  'completed_date',
  'paid_date',
  'created_at',
  'updated_at',
];

export default listFields;

export const pushOrRemove = (data: number[], num: number) => {
  const arr = [...data];
  const index = arr.indexOf(num);
  if (index === -1) {
    // Number doesn't exist in the array, so push it.
    arr.push(num);
  } else {
    // Number already exists in the array, so remove it.
    arr.splice(index, 1);
  }
  return arr;
};

export function isValidDate(date: any) {
  const parsedDate: any = new Date(date);
  // Check if the parsed date is a valid date and not NaN
  return !isNaN(parsedDate) && !isNaN(parsedDate.getDate());
}

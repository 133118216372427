import Divider from '@material-ui/core/Divider';
import ListIcon from '@material-ui/icons/List';
import MapIcon from '@material-ui/icons/Map';
import omit from 'lodash/omit';
import Empty from 'ra-ui-materialui/lib/list/Empty';
import * as React from 'react';
import { BulkActionProps, Button, List, ListProps, useGetIdentity, useListContext } from 'react-admin';
import { useLocation } from 'react-router-dom';
import ListActions from 'shared/components/Resource/ListActions';
import Pagination from 'shared/components/Resource/Pagination';
import { useListStyles } from 'shared/components/Resource/styles';
import TablePreloader from 'shared/components/TablePreloader';
import { INDEX_LIST_PER_PAGE } from 'shared/constants/config';
import { appraisalMutationRoles } from 'shared/constants/roles';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import useAppraisalRowStyle from 'shared/hooks/useRowStyle';
import { createExporter } from 'shared/utils';
import AppraisalListTabs from './AppraisalListTabs';
import ListFilter from './ListFilter';
import { AppraisalGrid } from './components/AppraisalGrid';
import AppraisalMap from './components/AppraisalMap';
import { exportFields } from './fields';

const AppraisalList = (props: ListProps) => {
  const classes = useListStyles();
  const [queryParams, onChangeQuery] = useLocationQuery();
  const location = useLocation();
  const [isMapView, setIsMapView] = React.useState(Boolean(queryParams.view === 'map'));
  const { identity } = useGetIdentity();
  const exporter = React.useMemo(() => createExporter(exportFields, 'appraisals'), []);
  return (
    <List
      {...omit(props, 'staticContext')}
      exporter={exporter}
      key={location.pathname}
      classes={classes}
      hasShow={false}
      hasCreate={false}
      hasEdit={true}
      hasList={true}
      resource="appraisals"
      basePath="/appraisals"
      bulkActionButtons={false}
      perPage={INDEX_LIST_PER_PAGE}
      sort={{ field: 'due_date', order: 'DESC' }}
      filters={<ListFilter />}
      pagination={isMapView ? false : <Pagination limit={<span />} />}
      syncWithLocation
      actions={
        <ListActions
          hasCreate={appraisalMutationRoles.includes(identity?.role ?? '')}
          customAction={
            <Button
              label={isMapView ? 'List' : 'Map'}
              onClick={() => {
                setIsMapView((prev) => !prev);
                onChangeQuery({ view: isMapView ? 'list' : 'map' });
              }}
            >
              {isMapView ? <ListIcon /> : <MapIcon />}
            </Button>
          }
        />
      }
    >
      <TabbedDatagrid onChangeParams={onChangeQuery} {...omit(props, 'staticContext', 'total')} isMapView={isMapView} />
    </List>
  );
};

type TabbedDatagridProps = ListProps &
  BulkActionProps & {
    isMapView: boolean;
    onChangeParams(data: object): void;
  };

function TabbedDatagrid(props: TabbedDatagridProps) {
  const { loading, total } = useListContext();
  const [, { rowStyle }] = useAppraisalRowStyle();

  const { filterValues, onChangeParams } = props;

  return (
    <div>
      <AppraisalListTabs filterValues={filterValues} onChangeParams={onChangeParams} />
      <Divider />
      {total === 0 && !loading && <Empty resource="appraisals" />}
      {props.isMapView && total !== 0 ? (
        <AppraisalMap />
      ) : loading ? (
        <TablePreloader columns={6} />
      ) : (
        <AppraisalGrid filterValues={filterValues} rowStyle={rowStyle} />
      )}
    </div>
  );
}

export default AppraisalList;

import { Box } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { Dispatch, SetStateAction } from 'react';
import { useStyles } from '../styles';

interface AttachmentsProps {
  setFiles: Dispatch<SetStateAction<File[] | undefined>>;
}

export const Attachments = ({ setFiles }: AttachmentsProps) => {
  const classes = useStyles();

  return (
    <>
      <Box my={1}>
        <DropzoneArea
          onChange={(e) => {
            if (e.length > 0) {
              setFiles(e);
            } else {
              setFiles(undefined);
            }
          }}
          showAlerts={false}
          showFileNames
          clearOnUnmount
          dropzoneText={'Drag and drop files here \n - or - \n Click and select files to upload'}
          dropzoneClass={classes.dropzoneClass}
        />
      </Box>
    </>
  );
};

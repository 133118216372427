import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parseDate from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { Identifier } from 'react-admin';
import { dateFnsLocalizer } from 'react-big-calendar';

import { Appraisals as Appraisal, AppraisalEvent, AppraisalEventType } from 'views/Appraisal/types';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

export const localizer = dateFnsLocalizer({
  format,
  parse: parseDate,
  startOfWeek,
  getDay,
  locales,
});

export function getEvents(
  keys: Identifier[],
  data: { [x: string]: Appraisal },
  key: AppraisalEventType,
  selected?: {
    isTaskSelected: boolean;
    isDueSelected: boolean;
  },
): AppraisalEvent[] {
  return (
    keys
      .filter((e) => {
        const item = data[e];

        if (selected) {
          if (selected.isDueSelected && selected.isTaskSelected) {
            return item['task_description'] || item[key];
          } else if (selected.isTaskSelected) {
            return item['task_description'];
          } else if (selected.isDueSelected) {
            return item[key] && !item['task_description'];
          }
        }

        return !!item[key] || item.appraisal_status === 'In Progress';
      })
      .map((e) => {
        const item = data[e];

        const start = key === AppraisalEventType.IN_PROGRESS ? new Date(item.engagement_date) : new Date(item[key]);
        const end =
          key === AppraisalEventType.IN_PROGRESS ? endOfDay(new Date(item.due_date)) : endOfDay(new Date(item[key]));

        return {
          title: `${item.appraisal_file_number} - ${item.location_address}`,
          start,
          end,
          allDay: false,
          type: key,
          ...item,
        };
      }) ?? []
  );
}

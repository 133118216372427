import AccountBalance from '@material-ui/icons/AccountBalanceWallet';
import withAuthRoute from 'shared/components/ProtectedRoute';
import CreateExpense from './Create';
import EditExpense from './Edit';
import ExpenseList from './List';

export default {
  name: 'expenses',
  create: withAuthRoute('expenses')(CreateExpense),
  edit: withAuthRoute('expenses')(EditExpense),
  list: withAuthRoute('expenses')(ExpenseList),
  icon: AccountBalance,
};

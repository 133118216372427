import { useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Client } from 'views/Client/types';

const QUERY = gql`
  query getContacts($client_id: uuid) {
    contacts: contacts(where: { client_id: { _eq: $client_id } }) {
      id
      first_name
      last_name
      email
      phone_number
      appraisals_count
      type
      primary
    }
  }
`;

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const alphabet: any = [...alpha.map((x) => String.fromCharCode(x))];
type Alphabets = (typeof alphabet)[number];

const CLIENTS_COUNT_QUERY = gql`
  query ClientsCount {
    ${alphabet?.map((each: any) => {
      return each + ': clients_aggregate(where: {name: {_similar:"(' + each + ')%"}}){aggregate{count}}';
    })}
  }
`;

const SEARCH_CLIENTS_QUERY = gql`
  query clientSearch($keyword: String) {
    search_clients(args: { search: $keyword }) {
      id
      name
    }
  }
`;
const CLIENT_DETAIL_QUERY = gql`
  query clientById($id: uuid!) {
    client_by_pk(id: $id) {
      id
      name
    }
  }
`;

export type ContactOptionsResponse = {
  contacts: Pick<
    Client,
    'id' | 'email' | 'primary' | 'phone_number' | 'first_name' | 'last_name' | 'appraisals_count'
  >[];
};
export default function useContactOptions(props: { client_id: string }) {
  const options = useQuery<ContactOptionsResponse>(QUERY, { fetchPolicy: 'cache-first', variables: props });
  return [options] as const;
}

export type ClientsCountResponse = {
  [x in Alphabets]: { aggregate: { count: number } };
};
export function useClientsTotal() {
  const options = useQuery<ClientsCountResponse>(CLIENTS_COUNT_QUERY, {
    fetchPolicy: 'cache-first',
  });
  return [options] as const;
}

export function useClientsSearch() {
  return useLazyQuery<{ search_clients: Client[] }>(SEARCH_CLIENTS_QUERY);
}

export function useClientDetailById(props: { id: any }) {
  return useQuery<Client>(CLIENT_DETAIL_QUERY, {
    fetchPolicy: 'cache-first',
    variables: props,
    skip: !props?.id,
  });
}

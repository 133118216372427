import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Integrations, Integration_Authentication, Integration_Webhook } from 'shared/generated/types';

const INTEGRATIONS_QUERY = gql`
  query {
    integrations {
      id
      name
      description
      active
      integration_level
    }
    integration_authentication {
      integration_id
    }
    integration_webhook {
      active
      id
      integration_id
      signing_secret
      webhook_url
    }
  }
`;

export const ADD_INTEGRATION_AUTHENTICATION = gql`
  mutation integrationAuthentication($integrationId: Int!, $integrationLevel: integration_level, $authId: String!) {
    insert_integration_authentication(
      objects: { integration_id: $integrationId, integration_level: $integrationLevel, auth_id: $authId }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_INTEGRATION_AUTHENTICATION = gql`
  mutation deleteIntegrationAuthentication($integrationId: Int!) {
    delete_integration_authentication(where: { integration_id: { _eq: $integrationId } }) {
      affected_rows
    }
  }
`;

export type IntegrationsResponse = {
  integrations: Integrations[];
  integration_authentication: Integration_Authentication[];
  integration_webhook: Integration_Webhook[];
};

export const useIntegrationResponse = () => {
  const options = useQuery<IntegrationsResponse>(INTEGRATIONS_QUERY);
  return [options] as const;
};

export const INSERT_INTEGRATION_WEBHOOK_QUERY = gql`
  mutation Create_Webhook($integration_id: Int) {
    insert_integration_webhook_one(object: { integration_id: $integration_id, webhook_url: "" }) {
      id
      integration_id
      signing_secret
      webhook_url
    }
  }
`;

interface InsetIntegrationRequest {
  integration_id?: number | null;
}

interface InsetIntegrationResponse {
  insert_integration_webhook_one: Integration_Webhook;
}

export const useInsertIntegrationWebhook = () => {
  return useMutation<InsetIntegrationResponse, InsetIntegrationRequest>(INSERT_INTEGRATION_WEBHOOK_QUERY);
};

export const UPDATE_INTEGRATION_WEBHOOK_QUERY = gql`
  mutation updateIntegrationWebhook($id: uuid, $webhook_url: String) {
    update_integration_webhook(where: { id: { _eq: $id } }, _set: { webhook_url: $webhook_url }) {
      affected_rows
    }
  }
`;

interface UpdateIntegrationRequest {
  integration_id: number | null;
  webhook_url: string;
}

export const useUpdateIntegrationWebhook = () => {
  return useMutation<any, UpdateIntegrationRequest>(UPDATE_INTEGRATION_WEBHOOK_QUERY);
};

export const DELETE_INTEGRATION_WEBHOOK_QUERY = gql`
  mutation deleteIntegrationWebhook($id: uuid) {
    delete_integration_webhook(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

interface DeleteIntegrationRequest {
  id: string;
}

export const useDeleteIntegrationWebhook = () => {
  return useMutation<any, DeleteIntegrationRequest>(DELETE_INTEGRATION_WEBHOOK_QUERY);
};

import React from 'react';
import {
  Edit,
  EditProps,
  Record,
  ResourceContextProvider,
  TitleProps,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import EditAction from 'shared/components/Resource/EditAction';
import { simpleFormContainer } from 'shared/hooks/useEditFormStyle';
import { CreateExpenseFields } from './Create';
import { Expense } from './types';

const EditTitle = ({ record }: TitleProps) => {
  return <span>Expense: {record ? record?.description : ''}</span>;
};
const EditExpense = (props: EditProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const classes = simpleFormContainer();
  function onSuccess() {
    notify('expense.updated');
    redirect('/expenses');
    refresh();
  }
  const transform = (data: Record) => {
    const newExpense: Expense = {
      ...(data as Expense),
    };
    return newExpense;
  };
  return (
    <ResourceContextProvider value="expense">
      <Edit
        {...props}
        actions={<EditAction />}
        onSuccess={onSuccess}
        transform={transform}
        title={<EditTitle />}
        resource="expense"
        mutationMode="optimistic"
        className={classes.formWrapper}
      >
        <CreateExpenseFields formType="edit" />
      </Edit>
    </ResourceContextProvider>
  );
};

export default EditExpense;

import * as React from 'react';
import { Route } from 'react-router-dom';
import ConfirmAccount from 'shared/components/Auth/ConfirmAccount';
import LoginPage from 'shared/components/Auth/Login';
import ProfilePage from 'shared/components/Auth/Profile';
import RequestResetPassword from 'shared/components/Auth/RequestResetPassword';
import ResetPassword from 'shared/components/Auth/ResetPassword';
import Orders from 'shared/components/Orders';
import Appraisal from 'views/Appraisal';
import appraisalReducer from 'views/Appraisal/reducer';
import appraisalSaga from 'views/Appraisal/saga';
import Schedule from 'views/Calendar/List';
import Client from 'views/Client';
import Commission from 'views/Commission';
import Contact from 'views/Contact';
import Expense from 'views/Expense';
import Fee from 'views/Fee';
import Insight from 'views/Insight/List';
import Team from 'views/Team';

const resources = [
  Appraisal,
  Expense,

  {
    name: 'appraisal',
  },
  {
    name: 'property',
  },
  {
    name: 'assessment',
  },
  {
    name: 'client',
  },
  {
    name: 'organization',
  },
  {
    name: 'user_preference_notification',
  },
  {
    name: 'appraisal_statuses',
  },
  {
    name: 'appraisal_priorities',
  },
  {
    name: 'appraisal_purposes',
  },
  {
    name: 'appraisal_types',
  },
  {
    name: 'property_interests',
  },
  {
    name: 'commercial_property_type',
  },
  {
    name: 'commercial_property_subtype',
  },
  {
    name: 'report_types',
  },
  {
    name: 'residential_form_types',
  },
  {
    name: 'loan_types',
  },
  {
    name: 'rate_types',
  },
  {
    name: 'user_account',
  },
  {
    name: 'user_accounts',
  },
  {
    name: 'user_profile',
  },
  {
    name: 'user_profiles',
  },
  {
    name: 'us_states',
  },
  {
    name: 'residential_ownership_types',
  },
  {
    name: 'residential_styles',
  },
  {
    name: 'client_types',
  },
  {
    name: 'transaction_type',
  },
  {
    name: 'commercial_lease_types',
  },
  {
    name: 'residential_lease_types',
  },
  {
    name: 'sales_transactions',
  },
  {
    name: 'lease_transactions',
  },
  {
    name: 'contact_types',
  },
  {
    name: 'contact',
  },
  {
    name: 'transaction',
  },
  {
    name: 'property_types',
  },
  {
    name: 'expense',
  },
  {
    name: 'fees',
  },
  {
    name: 'commissions',
  },
  {
    name: 'assignee_roles',
  },
  {
    name: 'schedule',
  },
  {
    name: 'client_contacts',
  },
  {
    name: 'appraisal_sources',
  },
  { name: 'invoice' },
  Client,
  Contact,
  Team,
];

export default resources;
//.map((props) => React.createElement(Resource, { ...props, key: props.name }));

export const customReducers = {
  appraisal: appraisalReducer,
};
export const customSagas = [appraisalSaga];
export const customRoutes: JSX.Element[] = [
  // {
  //   key: 'signup',
  //   path: '/account/signup',
  //   component: SignupPage,
  // },
  {
    key: 'order',
    path: '/orders/:id',
    component: Orders,
  },
  {
    key: 'reset',
    path: '/account/reset',
    component: RequestResetPassword,
  },
  {
    key: 'reset_password',
    path: '/account/reset_password',
    component: ResetPassword,
  },
  {
    key: 'set_password',
    path: '/account/set_password',
    component: ResetPassword,
  },
  {
    key: 'reset',
    path: '/account/confirm',
    component: ConfirmAccount,
  },
  {
    key: 'login',
    path: '/account/login',
    component: LoginPage,
  },
  {
    key: 'profile',
    path: '/account/my-profile',
    component: ProfilePage,
    noLayout: false,
  },
  {
    key: 'appraisals',
    path: '/appraisals/:id(all|request|in-progress|unassigned|due-soon|upcoming-inspections|past-due|unpaid|starred|unscheduled|rush)?',
    component: Appraisal.list,
    noLayout: false,
  },

  {
    key: 'schedule',
    path: '/schedule',
    component: Schedule,
    noLayout: false,
  },
  {
    key: 'insights',
    path: '/insights',
    component: Insight,
    noLayout: false,
  },
  {
    key: 'accounting',
    path: '/accounting/expenses',
    component: Expense.list,
    noLayout: false,
  },
  {
    key: 'accounting',
    path: '/accounting/fees',
    component: Fee.list,
    noLayout: false,
  },
  {
    key: 'accounting',
    path: '/accounting/commissions',
    component: Commission.list,
    noLayout: false,
  },
].map((e) => <Route noLayout exact {...e} />);

import { gql, useSubscription } from '@apollo/client';
import { Messages } from 'shared/generated/types';

interface MessagesListRequest {
  messageThreadId: string;
}
interface MessagesListResponse {
  messages: Messages[];
}

const MESSAGE_SUBSCRIPTION = gql`
  subscription MessagesSubs($messageThreadId: uuid) {
    messages(order_by: { created_at: asc }, where: { message_thread_id: { _eq: $messageThreadId } }) {
      created_at
      id
      message
      message_thread_id
      message_thread_key
      message_thread_participants
      message_thread_subtopic
      message_thread_topic
      message_thread_topic_id
      notified
      organization_id
      read
      recipients
      sender_full_name
      sender_id
      sender_type
      source
      updated_at
    }
  }
`;

export const useMessageSubscription = (variables: any) => {
  return useSubscription<MessagesListResponse, MessagesListRequest>(MESSAGE_SUBSCRIPTION, {
    variables,
    skip: !variables.messageThreadId,
  });
};

import { Box, Chip, Divider, Grid, Typography, Modal } from '@material-ui/core';
import React, { Dispatch, FC, useState } from 'react';
import AddCommissionButton from 'views/Client/components/popups/AddCommission';
import { styles } from '../../appraisalStyles';
import EditCommissionButton from 'views/Client/components/popups/EditCommission';

import { Button, SaveButton } from 'react-admin';
import { Cancel, Check } from '@material-ui/icons';

interface AssigneeSectionProps {
  commissions: any;
  setCommissions: Dispatch<any>;
  reportFee: any;
}

const AssigneeSection: FC<AssigneeSectionProps> = ({ commissions, setCommissions, reportFee }) => {
  const classes = styles();

  const [selectedItem, setSelectedItem] = useState<any>(undefined);
  const [deleteItem, setDeleteItem] = useState<any>(undefined);

  const closeDeleteModal = () => {
    setDeleteItem(undefined);
  };
  const closeEditModal = () => {
    setSelectedItem(undefined);
  };

  const onAssigneeAdd = (value: any) => {
    const newCommission: any = [...commissions];
    newCommission.push({
      id: new Date().getTime() + '',
      ...value,
    });
    setCommissions(newCommission);
  };

  const handleDeleteCommission = () => {
    const index = commissions.findIndex((item: any) => item.id === deleteItem.id);

    setCommissions((prev: any) => {
      const newCommissions = [...prev];
      newCommissions.splice(index, 1);
      return newCommissions;
    });
    closeDeleteModal();
  };

  const onEditCommission = (value: any): void => {
    if (selectedItem) {
      const index = commissions.findIndex((item: any) => item.id === selectedItem.id) as any;
      if (index !== -1) {
        const newCommissions: Array<any> = [...commissions];
        newCommissions[index] = value;
        setCommissions(newCommissions);
        setSelectedItem(undefined);
      }
    }
  };

  return (
    <>
      <Typography classes={{ root: classes.sectionHeading }}>ASSIGNEES</Typography>
      <Divider classes={{ root: classes.divider }} />
      <Grid container direction="column" alignItems="center">
        <Grid container md={5} sm={12} xs={12}>
          {commissions.map((commission: any) => {
            return (
              <Chip
                key={commission.id}
                label={`${commission?.assignee_full_name} (${commission?.assignee_role})`}
                size="medium"
                className={classes.chipStyle}
                onClick={() => setSelectedItem(commission)}
                onDelete={() => setDeleteItem(commission)}
              />
            );
          })}

          <AddCommissionButton appraisal={null} clientId="" onSave={onAssigneeAdd} reportFee={reportFee} />
          {selectedItem && (
            <EditCommissionButton
              currentCommission={selectedItem}
              hideEditButton={true}
              triggerCloseModal={closeEditModal}
              reportFee={reportFee}
              onSave={onEditCommission}
            />
          )}
        </Grid>
      </Grid>

      <Modal
        open={!!deleteItem}
        onClose={closeDeleteModal}
        aria-labelledby="delete-commission"
        aria-describedby="delete-commission-description"
      >
        <Box className={classes.paper}>
          <Typography classes={{ root: classes.heading }}>
            Remove Commissions: {deleteItem?.assignee_full_name}
          </Typography>
          <p>Are you sure you want to remove this assignee from appraisal?</p>
          <Divider />
          <Box display={'flex'} justifyContent={'space-between'} mt={2}>
            <Button label="ra.action.cancel" onClick={closeDeleteModal}>
              <Cancel />
            </Button>
            <SaveButton label="Confirm" onClick={handleDeleteCommission} icon={<Check />} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AssigneeSection;

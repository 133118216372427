import { Box, Typography } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, TextInput, email, required } from 'react-admin';

import AutocompleteInput from 'shared/components/AutocompleteInput';
import { Contact_Types } from 'shared/generated/types';
import { validatePhone } from 'shared/utils';
import { useGetOptions } from '../queries';
import { useStyles } from '../styles';

interface ClientInformationProps {
  showMore?: boolean;
  contactType: Contact_Types | null;
  setContactType: Dispatch<SetStateAction<Contact_Types | null>>;
}

const initialContactTypeIds = [14, 7, 15, 11, 1, 22, 19, 3, 20, 17];

export const ClientInformation = ({ showMore, contactType, setContactType }: ClientInformationProps) => {
  const classes = useStyles();

  const [showMoreContactTypes, setShowMoreContactTypes] = useState(false);

  const { data: options } = useGetOptions();

  return (
    <>
      {showMore && (
        <>
          <TextInput fullWidth variant="outlined" label="Your Name" source="name" autoFocus />
          <TextInput fullWidth variant="outlined" label="Your Company Name" source="company_name" />
          <TextInput
            fullWidth
            variant="outlined"
            label="Your Phone Number"
            source="phone_number"
            validate={validatePhone}
          />
        </>
      )}
      <TextInput
        autoFocus={!showMore}
        source="email"
        fullWidth
        id="email"
        variant="outlined"
        label="Your Email"
        autoComplete="email"
        validate={[required(), email()]}
      />

      {showMore && (
        <>
          {showMoreContactTypes ? (
            <AutocompleteInput
              label="Your Role"
              fullWidth
              variant="outlined"
              source="contact_type_id"
              choices={options?.contactTypes ?? []}
              optionText={'type'}
            />
          ) : (
            <>
              <Typography>Your Role</Typography>
              <Box mb={2.5} mt={0.5}>
                {options?.contactTypes.map((item) => {
                  if (item.id && item.type && initialContactTypeIds.includes(item.id)) {
                    return (
                      <Button
                        key={item.id}
                        variant={contactType?.id === item.id ? 'contained' : 'outlined'}
                        className={classes.chipButton}
                        label={item.type}
                        onClick={() => {
                          contactType?.id === item.id ? setContactType(null) : setContactType(item);
                        }}
                      />
                    );
                  }
                })}
                <Button
                  variant={'outlined'}
                  className={classes.chipButton}
                  label="More"
                  onClick={() => setShowMoreContactTypes(true)}
                />
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import { MessageThread } from '../types';

const MESSAGE_THREAD_LIST = gql`
  query MessageThreadList($messageTopicId: uuid) {
    message_thread(
      where: { message_topic: { _eq: "appraisal" }, _and: { message_topic_id: { _eq: $messageTopicId } } }
    ) {
      created_at
      id
      key
      message_subtopic
      message_topic
      message_topic_id
      messages_count
      organization_id
      participants
      updated_at
      messages {
        read
        recipients
      }
    }
  }
`;

interface MessageThreadListRequest {
  messageTopicId: string;
}
interface MessageThreadListResponse {
  message_thread: MessageThread[];
}

export const useMessageThreadList = (variables: MessageThreadListRequest) => {
  return useQuery<MessageThreadListResponse>(MESSAGE_THREAD_LIST, { variables });
};

const INSERT_MESSAGE_THREAD_QUERY = gql`
  mutation InsertMessageThread(
    $message_topic: message_topic_type
    $message_topic_id: uuid
    $message_subtopic: message_subtopic_type
    $sender_type: message_user_type
    $sender_id: uuid
    $message: String
  ) {
    insert_message_thread(
      objects: {
        message_topic: $message_topic
        message_topic_id: $message_topic_id
        message_subtopic: $message_subtopic
        messages: { data: { sender_type: $sender_type, sender_id: $sender_id, message: $message } }
      }
    ) {
      returning {
        id
      }
    }
  }
`;

interface InsertMessageThreadRequestData {
  message_topic: string;
  message_topic_id: string;
  message_subtopic: string;
  sender_type: string;
  sender_id: string;
  message: string;
}

export const useInsertMessageThread = (variables: InsertMessageThreadRequestData) => {
  return useMutation(INSERT_MESSAGE_THREAD_QUERY, { variables });
};

const MESSAGE_COUNT_SUBSCRIPTION_QUERY = gql`
  subscription MessageCount($messageTopicId: uuid!) {
    message_thread(where: { message_topic_id: { _eq: $messageTopicId } }) {
      id
      messages_count
      participants
    }
  }
`;

interface MessageCountSubscriptionResponse {
  message_thread: MessageThread[];
}

export const useMessageCountSubscription = (variables: MessageThreadListRequest) => {
  return useSubscription<MessageCountSubscriptionResponse>(MESSAGE_COUNT_SUBSCRIPTION_QUERY, { variables });
};

import Typography from '@material-ui/core/Typography';
import formatDate from 'date-fns/format';
import React from 'react';
import { EventProps } from 'react-big-calendar';

import { getCommaSeparateNameFromArray, getTruncatedText } from 'shared/utils';
import { AppraisalEvent } from 'views/Appraisal/types';
import EventWrapper from './EventWrapper';

const MonthEvent: React.FunctionComponent<EventProps<AppraisalEvent>> = (props) => {
  let title = '';

  const { appraisal_file_number, property_name, location_address, client_name, appraisal_assignee_user_account_names } =
    props.event;

  if (props.event.type !== 'in_progress') {
    title = [appraisal_file_number, formatDate(props.event.start, 'hh:mm a')].filter(Boolean).join(' @ ');
  } else {
    // ‌<file_#> - <property_name_or_location_address> - <client_name> - <comma separated list of assignees></comma>
    const propertyOrLocation = property_name ? property_name : location_address || '';

    const truncatedPropertyOrLocation = getTruncatedText(propertyOrLocation, 13);

    const commaSeparatedNames =
      appraisal_assignee_user_account_names?.length > 0
        ? getCommaSeparateNameFromArray(appraisal_assignee_user_account_names)
        : '';

    const titleContent = [
      appraisal_file_number,
      undefined,
      null,
      truncatedPropertyOrLocation,
      client_name,
      commaSeparatedNames,
    ];

    title = titleContent.filter((item) => !!item).join(' - ');
  }

  return (
    <EventWrapper event={props.event}>
      <Typography variant="body2">{title}</Typography>
    </EventWrapper>
  );
};
export default MonthEvent;

import { Tab, Tabs } from '@material-ui/core';
import { omit, orderBy } from 'lodash';
import React from 'react';
import { BulkActionProps, ListProps, useListContext } from 'react-admin';
import useMaxDimensions from 'shared/hooks/useMaxDimension';
import useAppraisalRowStyle, { BackGroundType } from 'shared/hooks/useRowStyle';
// import CircleIcon from '@material-ui/icons/FiberManualRecord';
// import { useIsLimitedAndRestrictedRole } from 'shared/hooks/useResourcePermissions';

type Props = ListProps &
  BulkActionProps & {
    onChangeParams(data: object): void;
  };

const AppraisalListTabs = (props: Props) => {
  const { loading } = useListContext();
  const dimensions = useMaxDimensions();
  const { filterValues } = props;

  const handleChangeStatusFilter = (event: React.ChangeEvent<{}>, value: number) => {
    let additionalQuery: { sort: undefined | string; order: undefined | string } = {
      sort: undefined,
      order: undefined,
    };
    if (value === 0) {
      additionalQuery = {
        sort: 'appraisal_file_number',
        order: 'DESC',
      };
      props.onChangeParams({
        filter: JSON.stringify(omit(filterValues, 'appraisal_status_id')),
        ...additionalQuery,
      });
    } else {
      if (value === 1) {
        additionalQuery = {
          sort: 'due_date',
          order: 'ASC',
        };
      } else if (value === 3) {
        additionalQuery = {
          sort: 'reviewed_date',
          order: 'DESC',
        };
      } else if (value === 4) {
        additionalQuery = {
          sort: 'revision_request_date',
          order: 'DESC',
        };
      } else if (value === 5) {
        additionalQuery = {
          sort: 'completed_date',
          order: 'DESC',
        };
      } else if (value === 6) {
        additionalQuery = {
          sort: 'on_hold_date',
          order: 'DESC',
        };
      } else if (value === 7) {
        additionalQuery = {
          sort: 'canceled_date',
          order: 'DESC',
        };
      } else if (value === 8) {
        additionalQuery = {
          sort: 'quote_made_date',
          order: 'DESC',
        };
      } else {
        additionalQuery = {
          sort: 'appraisal_file_number',
          order: 'DESC',
        };
      }
      props.onChangeParams({
        filter: JSON.stringify({ ...filterValues, appraisal_status_id: value }),
        ...additionalQuery,
      });
    }
  };

  const [{ statusMap }, { getBackgroundColor }] = useAppraisalRowStyle(loading);
  // const canViewAggregate = !useIsLimitedAndRestrictedRole();

  const tabs = React.useMemo(
    () =>
      orderBy(
        Object.entries(statusMap).map(([key, value]) => ({
          id: Number(key),
          name: (
            <>
              <span>{value.name}</span>
              <span style={{ fontSize: '0.75rem', fontWeight: 400 }}>
                {value.count}{' '}
                {/* {canViewAggregate && value.aggregate && (
                  <>
                    <CircleIcon style={{ fontSize: '4px', color: '#A2A2A2F', marginBottom: '2px' }} /> {value.aggregate}
                  </>
                )} */}
              </span>
            </>
          ),
          order: value.order,
        })),
        'order',
      ),
    // [statusMap, canViewAggregate],
    [statusMap],
  );

  return (
    <div
      style={{
        width: dimensions.width,
      }}
    >
      <Tabs
        variant="scrollable"
        value={Number(filterValues.appraisal_status_id || 0)}
        indicatorColor="primary"
        onChange={handleChangeStatusFilter}
        TabIndicatorProps={{
          style: {
            backgroundColor: getBackgroundColor(filterValues.appraisal_status_id || 0, BackGroundType.Tab),
          },
        }}
      >
        {tabs.map((choice) => (
          <Tab key={choice.id} label={choice.name} style={{ width: '62px' }} value={choice.id} />
        ))}
      </Tabs>
    </div>
  );
};

export default AppraisalListTabs;

import { FormPermissions } from 'shared/hooks/useResourcePermissions';

export const rolesMapping: { [key in number]: string } = {
  0: '',
  1: 'admin',
  2: 'appraisal_firm_account_owner',
  3: 'appraisal_firm_full_access',
  4: 'appraisal_firm_standard_access',
  5: 'appraisal_firm_limited_access',
  6: 'appraisal_firm_restricted_access',
};

export const elavatedRoles = [rolesMapping[3], rolesMapping[2], rolesMapping[1]];
export const topLevelRoles = [rolesMapping[3], rolesMapping[2], rolesMapping[4]];
export const standardMutationRoles = elavatedRoles.concat([rolesMapping[4]]);
export const appraisalMutationRoles = standardMutationRoles.concat([rolesMapping[5]]);

export const restrictedRoles = [rolesMapping[6]];
export const limitedAndRestrictedRoles = restrictedRoles.concat([rolesMapping[5]]);
export const standardLimitedRestrictedRoles = limitedAndRestrictedRoles.concat([rolesMapping[4]]);

export type ResourcePermission = {
  [key in string]: FormPermissions | boolean;
};
export type RoleAccessMapping = {
  [key in UserRole]?: ResourcePermission;
};

export type UserRole =
  | 'appraisal_firm_full_access'
  | 'appraisal_firm_account_owner'
  | 'admin'
  | 'appraisal_firm_standard_access'
  | 'appraisal_firm_limited_access'
  | 'appraisal_firm_restricted_access';

import { Field } from 'shared/components/Resource/List';

const listFields: Field[] = [
  {
    type: 'TextField',
    source: 'appraisal_file_number',
    label: 'File #',
  },
  {
    type: 'TextField',
    source: 'description',
    label: 'Description',
  },
  {
    type: 'TextField',
    source: 'quantity',
    label: 'Quantity',
  },
  {
    type: 'DateField',
    source: 'expense_date',
    label: 'Date',
  },
];
// export const showFields = listFields;

export const createFields: Field[] = [];

export const filterFields: Field[] = [
  {
    autoFocus: true,
    type: 'SearchInput',
    source: 'appraisal_file_number,description',
    label: 'Search Expenses',
    alwaysOn: true,
    fullWidth: true,
    resettable: true,
  },
  {
    type: 'ReferenceInput',
    label: 'Rate Type',
    source: 'rate_type_id',
    reference: 'rate_types',
    allowEmpty: false,
    fullWidth: true,
    perPage: 2,
    sort: { field: 'order', order: 'ASC' },
    filterToQuery: (searchText: string) => ({ type: searchText }),
    children: [
      {
        type: 'AutocompleteInput',
        optionText: 'type',
      },
    ],
  },
  // {
  //   type: 'AutocompleteInput',
  //   label: 'Expense Type',
  //   source: 'expense_type',
  //   fullWidth: true,
  //   choices: [
  //     { id: 'Appraisal', name: 'Appraisal' },
  //     { id: 'Standard', name: 'Standard' },
  //   ],
  //   optionValue: 'id',
  //   alwaysOn: true,
  // },
  {
    type: 'DateRangeInput',
    source: 'expense_date',
    label: 'Expense Date',
    fullWidth: true,
    variant: 'standard',
  },
  {
    type: 'RangeInput',
    label: 'Total Amount',
    source: 'total_amount',
    fullWidth: true,
    defaultMin: 0,
    defaultMax: 25000000,
  },
];

export const exportFields = [
  'id',
  'expense_type',
  'appraisal_file_number',
  'description',
  'quantity',
  'rate_type',
  'rate',
  'total_amount',
  'expense_date',
  'created_at',
  'updated_at',
];

export default listFields;

import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Button } from 'react-admin';
import { useForm } from 'react-final-form';

interface OrderSuccessProps {
  handleReset: (form: any) => void;
}
const OrderSuccess = ({ handleReset }: OrderSuccessProps) => {
  const form = useForm();

  return (
    <Box textAlign={'center'}>
      <Typography variant="h6">You have successfully placed</Typography>
      <Typography variant="h6">an Appraisal Order!</Typography>
      <Box mt={3}>
        <Button
          label="ORDER ANOTHER APPRAISAL"
          variant="contained"
          onClick={() => {
            handleReset(form);
          }}
        />
      </Box>
    </Box>
  );
};

export default OrderSuccess;

import { Box, Divider, Typography } from '@material-ui/core';
import React from 'react';

interface DividerWithTextProps {
  text: string;
}

export const DividerWithText = ({ text }: DividerWithTextProps) => {
  return (
    <Box my={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
      <Box flex={1}>
        <Divider />
      </Box>
      <Box paddingX={2}>
        <Typography variant="body2" color="textSecondary">
          {text}
        </Typography>
      </Box>
      <Box flex={1}>
        <Divider />
      </Box>
    </Box>
  );
};

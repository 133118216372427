import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles(() => ({
  calendar: {
    boxShadow: 'none',
    '& .rbc-event-label': {
      display: 'none',
    },
    '& .rbc-header': {
      fontWeight: 500,
    },
    '& .rbc-event.inspection': {
      backgroundColor: '#9c27b0',
    },
    '& .rbc-event.due': {
      backgroundColor: '#ff9800',
    },
    '& .rbc-event.task': {
      backgroundColor: '#2196f3',
    },
    '& .rbc-event.progress': {
      backgroundColor: green[500],
    },
    '& .rbc-agenda-view': {
      '& .inspection': {
        '& .rbc-agenda-time-cell': {
          borderLeftColor: '#9c27b0',
          borderLeftStyle: 'solid',
          borderLeftWidth: '5px',
          textTransform: 'none',
        },
      },
      '& .due': {
        '& .rbc-agenda-time-cell': {
          borderLeftColor: '#ff9800',
          borderLeftStyle: 'solid',
          borderLeftWidth: '5px',
          textTransform: 'none',
        },
      },
      '& .task': {
        '& .rbc-agenda-time-cell': {
          borderLeftColor: '#2196f3',
          borderLeftStyle: 'solid',
          borderLeftWidth: '5px',
          textTransform: 'none',
        },
      },
      '& .rbc-agenda-table': {
        borderCollapse: 'separate',
        '& thead': {
          display: 'none',
        },
      },
      '& .rbc-agenda-date-cell, .rbc-agenda-time-cell, .rbc-agenda-event-cell': {
        borderBottom: '1px solid #DDD',
        padding: '12px',
      },
      '& .rbc-agenda-event-cell': {
        cursor: 'pointer',
      },
    },
  },
}));

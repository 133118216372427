import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import { Tasklist, Task_Updates, User_Profiles } from 'shared/generated/types';

const GET_TASK_LIST_QUERY = gql`
  query GetTaskList($appraisal_id: uuid) {
    tasklist(where: { appraisal_id: { _eq: $appraisal_id } }, order_by: { updated_at: asc }) {
      created_at
      id
      name
      tasks(order_by: { order: asc }) {
        id
        description
        due_date
        assignee_user_account_id
        color
        completed_date
        created_at
        order
        updated_at
        user_account_id
      }
      tasks_completed_count
      tasks_count
      tasks_incompleted_count
    }

    user_profiles {
      id
      user_account_id
      first_name
      last_name
      full_name
    }
  }
`;

interface TaskListResponse {
  tasklist: Tasklist[];
  user_profiles: User_Profiles[];
}

const useGetTaskList = (appraisal_id: string) =>
  useQuery<TaskListResponse>(GET_TASK_LIST_QUERY, { variables: { appraisal_id }, fetchPolicy: 'network-only' });

const SET_TASK_QUERY = gql`
  mutation SetTask($appraisal_id: uuid, $tasklist_id: uuid, $order: Int, $description: String) {
    insert_task(
      objects: { appraisal_id: $appraisal_id, tasklist_id: $tasklist_id, order: $order, description: $description }
    ) {
      affected_rows
      returning {
        id
        appraisal_id
        description
        tasklist_id
        order
      }
    }
  }
`;

interface SetTaskRequest {
  appraisal_id: string;
  tasklist_id: string;
  order: number;
  description: string;
}

const useCreateTask = (variables: SetTaskRequest, onCompleted: () => void) => {
  return useMutation(SET_TASK_QUERY, {
    variables,
    onCompleted,
  });
};

export const DELETE_TASK_QUERY = gql`
  mutation DeleteTask($taskId: uuid) {
    delete_task(where: { id: { _eq: $taskId } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

interface DeleteTaskRequest {
  taskId: string;
}

const useDeleteTask = (onCompleted: () => void) => {
  return useMutation<any, DeleteTaskRequest>(DELETE_TASK_QUERY, { onCompleted });
};

const UPDATE_TASK_QUERY = gql`
  mutation UpdateTask(
    $id: uuid
    $description: String
    $assignee_user_account_id: uuid
    $completed_date: timestamptz
    $due_date: timestamptz
  ) {
    update_task(
      where: { id: { _eq: $id } }
      _set: {
        description: $description
        assignee_user_account_id: $assignee_user_account_id
        completed_date: $completed_date
        due_date: $due_date
      }
    ) {
      affected_rows
      returning {
        id
        description
        assignee_user_account_id
        completed_date
        due_date
      }
    }
  }
`;

interface UpdateTaskRequest {
  id: string;
  description: string;
  assignee_user_account_id?: string;
  completed_date?: string | null;
  due_date?: string | Date;
}

const useUpdateTask = (onCompleted: () => void) => {
  return useMutation<any, UpdateTaskRequest>(UPDATE_TASK_QUERY, {
    onCompleted,
  });
};

const UPDATE_TASK_MANY_QUERY = gql`
  mutation UpdateTaskMany($updates: [task_updates!]!) {
    update_task_many(updates: $updates) {
      affected_rows
      returning {
        id
        order
      }
    }
  }
`;

interface UpdateTaskManyRequest {
  updates: Task_Updates[];
}

const useUpdateTaskMany = (onCompleted: () => void) => {
  return useMutation<any, UpdateTaskManyRequest>(UPDATE_TASK_MANY_QUERY, { onCompleted });
};

const GET_NEXT_TASK_QUERY = gql`
  subscription GetNextTask($appraisal_id: uuid) {
    tasklist(where: { appraisal_id: { _eq: $appraisal_id } }) {
      tasks_completed_count
      tasks_count
      tasks(where: { completed_date: { _is_null: true } }, limit: 1, order_by: { order: asc }) {
        description
        order
        completed_date
      }
    }
  }
`;

interface GetNextTaskResponse {
  tasklist: Tasklist[];
}

const useGetNextTaskSubscription = (appraisal_id: string) => {
  return useSubscription<GetNextTaskResponse>(GET_NEXT_TASK_QUERY, {
    variables: { appraisal_id },
    skip: !appraisal_id,
  });
};

export { useGetTaskList, useCreateTask, useDeleteTask, useUpdateTask, useUpdateTaskMany, useGetNextTaskSubscription };

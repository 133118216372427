import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const MessageSkeleton = () => {
  const currentUserStyles = {
    justify: 'end',
    bgcolor: '#f0f0f0',
  };
  const userStyles = {
    justify: 'start',
    bgcolor: 'rgba(33, 150, 243, 0.08)',
  };
  return (
    <Box>
      <Box>
        <Skeleton />
        <Box mb={2}>
          <Box component={'div'} borderRadius={8} px={2} py={1} my={1} bgcolor={userStyles.bgcolor}>
            <Skeleton />
          </Box>
        </Box>
        <Box mb={2}>
          <Box component={'div'} borderRadius={8} px={2} py={1} my={1} bgcolor={userStyles.bgcolor}>
            <Skeleton />
            <Skeleton />
          </Box>
        </Box>
      </Box>

      <Box>
        <Skeleton />
        <Box mb={2}>
          <Box component={'div'} borderRadius={8} px={2} py={1} my={1} bgcolor={currentUserStyles.bgcolor}>
            <Skeleton />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

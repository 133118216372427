import React, { useEffect, useMemo, useState } from 'react';
import { Button, FormWithRedirect, required, SaveButton, TextInput } from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon, Cancel as IconCancel, Close } from '@material-ui/icons';
import AutocompleteInput from 'shared/components/AutocompleteInput';
import ButtonWithConfirm from 'shared/components/ButtonWithConfirm';
import CustomSelectInput from 'shared/components/CustomSelectInput';
import { Assignee_Roles, Commission_Templates } from 'shared/generated/types';
import { useAssigneeRoleOptions } from 'shared/hooks/useAppraisalOptions';
import {
  useDeleteCommissionTemplate,
  useGetCommissionTemplate,
  useInsertCommissionTemplate,
  useOptions,
  useUpdateCommissionTemplate,
} from 'shared/hooks/useCommissionTemplates';
import { formatDecimalNumber } from 'shared/utils';
import styles from 'views/Client/components/hooks/useContactListStyles';
import useContactPopupStyles from 'views/Client/components/hooks/useContactPopupStyles';
import useFormPermissions from 'shared/hooks/useResourcePermissions';
import getTeamPermission from '../permissions';

interface Props {
  member: any;
}

export const RolesCommissions = ({ member }: Props) => {
  const popUpClasses = useContactPopupStyles();
  const classes = styles();

  const [isOpen, setOpen] = useState(false);
  const [isFlat, setFlat] = useState(true);
  const [isGross, setGross] = useState(true);
  const [template_property_type_id, setPropertyType] = useState(0);

  const [selectedTemplate, setSelectedTemplate] = useState<Commission_Templates>();

  const { data: options } = useOptions();
  const [getAssigneeRoleOptions, { data: assigneeRoleOptions }] = useAssigneeRoleOptions();

  const { data: commissionTemplateData, refetch: refetchCommissionTemplate } = useGetCommissionTemplate(
    member.user_account_id,
  );
  const [insertCommissionTemplate, { data: insertResponse }] = useInsertCommissionTemplate();
  const [updateCommissionTemplate, { data: updateResponse }] = useUpdateCommissionTemplate();
  const [deleteCommissionTemplate, { data: deleteResponse }] = useDeleteCommissionTemplate();

  const handleCloseClick = () => {
    setOpen(false);
    setFlat(true);
    setGross(true);
    setPropertyType(0);
    setSelectedTemplate(undefined);
  };

  const handleSubmit = (values: any) => {
    const insertVariables = {
      ...values,
      ...(template_property_type_id !== 0 ? { ...values.property } : { template_property_type_id: null }),
      assignee_user_account_id: member.user_account_id,
      net_expenses: !isGross,
      rate_type_id: isFlat ? 2 : 1,
    };

    if (!isFlat) {
      insertVariables.rate = formatDecimalNumber(values.percentage / 100, 2);
    }

    if (selectedTemplate) {
      updateCommissionTemplate({
        variables: {
          ...insertVariables,
        },
      });
    } else {
      insertCommissionTemplate({
        variables: {
          ...insertVariables,
        },
      });
    }
  };

  useEffect(() => {
    getAssigneeRoleOptions();
  }, [getAssigneeRoleOptions]);

  useEffect(() => {
    if (
      insertResponse?.insert_appraisal_commission.affected_rows ||
      updateResponse?.update_appraisal_commission.affected_rows
    ) {
      refetchCommissionTemplate();
      handleCloseClick();
    }
  }, [insertResponse, refetchCommissionTemplate, updateResponse]);

  useEffect(() => {
    if (deleteResponse?.delete_appraisal_commission.affected_rows) {
      refetchCommissionTemplate();
    }
  }, [deleteResponse, refetchCommissionTemplate]);

  const assigneeRoles = useMemo(() => {
    if (isOpen) {
      let options = assigneeRoleOptions?.assignee_roles ?? [];
      return options;
    }
  }, [assigneeRoleOptions, isOpen]);

  const sanitizedPropertyTypes = useMemo(() => {
    if (options?.propertyTypes) {
      const currentPropertyTypes = [...options?.propertyTypes];
      currentPropertyTypes?.unshift({ id: 0, type: 'All' });
      return currentPropertyTypes;
    } else return [];
  }, [options]);

  const [{ permissions }] = useFormPermissions({ getPermission: getTeamPermission });

  return (
    <Card variant="outlined" style={{ marginTop: '30px' }}>
      <Table>
        <TableHead classes={{ root: classes.tableHead }}>
          <TableRow>
            <TableCell classes={{ root: classes.headCell }}>ROLE</TableCell>
            <TableCell classes={{ root: classes.headCell }}>TYPE/FORMS</TableCell>
            <TableCell classes={{ root: classes.headCell }}>PERCENTAGE</TableCell>
            <TableCell classes={{ root: classes.headCell }}>RATE</TableCell>
            <TableCell classes={{ root: classes.headCell }}>DEFAULT</TableCell>
            {permissions.edit && <TableCell classes={{ root: classes.cellEdit }}></TableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {options &&
            commissionTemplateData?.commission_templates?.map((commissionTemplate, index) => {
              const {
                assignee_role_id,
                id,
                net_expenses,
                rate_type_id,
                rate,
                property_type,
                template_default,
                template_property_type_id: property_type_id,
                template_commercial_property_type_id,
                template_residential_ownership_type_id,
              } = commissionTemplate;

              const role = assigneeRoleOptions?.assignee_roles.find(
                (roleOption) => roleOption.id === assignee_role_id,
              )?.role;

              const percentage = rate_type_id === 1 ? formatDecimalNumber(rate * 100, 2) + '%' : '';
              const sanitizedRate = rate_type_id === 2 ? rate : '';

              let typeForm = 'All';

              if (property_type) {
                if (property_type_id === 1) {
                  const ownershipType =
                    options.residentialOwnershipTypes?.find(
                      (item) => item.id === template_residential_ownership_type_id,
                    )?.type || '';

                  typeForm = !!ownershipType ? `${property_type} / ${ownershipType}` : property_type;
                } else {
                  const commercialType =
                    options.commercialPropertyTypes?.find((item) => item.id === template_commercial_property_type_id)
                      ?.type || '';

                  typeForm = !!commercialType ? `${property_type} / ${commercialType}` : property_type;
                }
              }

              return (
                <TableRow key={index}>
                  <TableCell>{role}</TableCell>
                  <TableCell>{typeForm}</TableCell>
                  <TableCell>
                    {percentage}
                    {net_expenses && (
                      <Typography component={'span'} className={classes.italic}>
                        (Net)
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>{sanitizedRate ? `$${sanitizedRate}` : ''}</TableCell>
                  <TableCell>
                    <Typography>{template_default ? 'Yes' : 'No'}</Typography>
                  </TableCell>
                  {permissions.edit && (
                    <TableCell classes={{ root: classes.cell }}>
                      <Box className={classes.actionCell}>
                        <Button
                          className={classes.icon}
                          size="small"
                          onClick={() => {
                            setOpen(true);
                            setPropertyType(commissionTemplate.template_property_type_id || 0);
                            setFlat(commissionTemplate.rate_type_id === 2);
                            setGross(!commissionTemplate.net_expenses);
                            setSelectedTemplate(commissionTemplate);
                          }}
                        >
                          <Icon classes={{ root: classes.activeCursor }} fontSize="small">
                            edit
                          </Icon>
                        </Button>
                        {!template_default && (
                          <ButtonWithConfirm
                            title={'Remove Template'}
                            content="Are you sure you want to remove this template?"
                            btnClassname={classes.icon}
                            onConfirm={() => deleteCommissionTemplate({ variables: { id } })}
                            btnIcon={() => <Close />}
                          />
                        )}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      {permissions.edit && (
        <Box className={classes.addBox}>
          <Box className={classes.addContactBtn}>
            <Button
              variant="text"
              label="Add Role & Commission"
              onClick={() => {
                setOpen(true);
              }}
            >
              <AddIcon />
            </Button>
          </Box>
        </Box>
      )}

      <Dialog fullWidth={true} maxWidth={'md'} open={isOpen} onClose={handleCloseClick} aria-label="Add Assignee">
        <FormWithRedirect
          initialValues={{
            assignee_role_id: null,
            template_property_type_id: template_property_type_id,
            ...(selectedTemplate && {
              id: selectedTemplate.id,
              assignee_role_id: selectedTemplate?.assignee_role_id,
              template_property_type_id: selectedTemplate?.template_property_type_id || 0,
              rate: selectedTemplate.rate,
              property: {
                template_residential_ownership_type_id: selectedTemplate?.template_residential_ownership_type_id,
                template_commercial_property_type_id: selectedTemplate?.template_commercial_property_type_id,
              },
              percentage:
                selectedTemplate.rate_type_id === 1 ? formatDecimalNumber(selectedTemplate.rate * 100, 2) : '',
            }),
          }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }: any) => {
            return (
              <DialogContent classes={{ root: popUpClasses.dialogContent }}>
                <Card variant="outlined" className={popUpClasses.formBottom}>
                  <CardHeader title="Add Assignee Role & Commission" classes={{ root: popUpClasses.cardHeader }} />

                  <Divider />

                  <Box className={popUpClasses.formContainer}>
                    <Box>
                      <Typography classes={{ root: popUpClasses.heading }}>ASSIGNEE</Typography>
                      <Divider classes={{ root: popUpClasses.divider }} />

                      <Grid container direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                          <AutocompleteInput
                            label="Role"
                            fullWidth
                            variant="outlined"
                            source="assignee_role_id"
                            optionText={(record: Assignee_Roles) => record?.role}
                            choices={assigneeRoles}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box>
                      <Typography classes={{ root: popUpClasses.heading }}>TYPE/FORMS</Typography>
                      <Divider classes={{ root: popUpClasses.divider }}></Divider>

                      <Grid container direction="row">
                        <Grid item md={6} sm={8} xs={12} direction="row" style={{ marginBottom: '32px' }}>
                          <CustomSelectInput
                            // className={classes.pillsMargin}
                            // style={{ mar }}
                            className={'mb-2 mr-2'}
                            label=""
                            fullWidth
                            customCSS={{
                              chips: {
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                // marginBottom: '1rem',
                              },
                            }}
                            variant="outlined"
                            choices={sanitizedPropertyTypes}
                            source="template_property_type_id"
                            onChange={(val: number) => setPropertyType(val)}
                            optionText="type"
                            // disabled={isExistingProperty}
                          />
                        </Grid>
                        <Grid container direction="row">
                          <Grid item md={6} sm={8} xs={12}>
                            {template_property_type_id === 1 && (
                              <AutocompleteInput
                                label="Ownership"
                                variant="outlined"
                                fullWidth
                                source="property.template_residential_ownership_type_id"
                                optionText="type"
                                choices={options?.residentialOwnershipTypes ?? []}
                              />
                            )}
                            {template_property_type_id === 2 && (
                              <AutocompleteInput
                                choices={options?.commercialPropertyTypes ?? []}
                                label="Property Type"
                                variant="outlined"
                                fullWidth
                                source="property.template_commercial_property_type_id"
                                optionText="type"
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box>
                      <Typography classes={{ root: popUpClasses.heading }}>COMMISSION</Typography>
                      <Divider classes={{ root: popUpClasses.divider }}></Divider>
                      <Grid container direction="row" style={{ marginBottom: '32px' }}>
                        <Button
                          label="Flat"
                          onClick={() => setFlat(true)}
                          variant={isFlat ? 'contained' : 'outlined'}
                          style={{ borderRadius: '24px', marginRight: '16px', paddingRight: '16px' }}
                        />
                        <Button
                          label="Percentage of Total Fees"
                          onClick={() => setFlat(false)}
                          variant={!isFlat ? 'contained' : 'outlined'}
                          style={{ borderRadius: '24px', marginRight: '16px', paddingRight: '16px' }}
                        />
                      </Grid>
                      {isFlat ? (
                        <Grid item md={6} sm={8} xs={12}>
                          <TextInput
                            validate={required()}
                            source="rate"
                            variant="outlined"
                            type="number"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            fullWidth
                          />
                        </Grid>
                      ) : (
                        <>
                          <Grid container direction="row" style={{ marginBottom: '32px' }}>
                            <Button
                              label="Gross Expenses"
                              onClick={() => setGross(true)}
                              variant={isGross ? 'contained' : 'outlined'}
                              style={{ borderRadius: '24px', marginRight: '16px', paddingRight: '16px' }}
                            />
                            <Button
                              label="Net Expenses"
                              onClick={() => setGross(false)}
                              variant={!isGross ? 'contained' : 'outlined'}
                              style={{ borderRadius: '24px', paddingRight: '16px' }}
                            />
                          </Grid>

                          <Grid item md={6} sm={8} xs={12}>
                            <TextInput
                              validate={required()}
                              source={'percentage'}
                              variant="outlined"
                              type="number"
                              fullWidth
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </Box>

                    <Divider classes={{ root: popUpClasses.divider }}></Divider>

                    <DialogActions classes={{ root: popUpClasses.editActions }}>
                      <Button
                        label="ra.action.cancel"
                        onClick={handleCloseClick}
                        //  disabled={loading}
                      >
                        <IconCancel />
                      </Button>
                      <SaveButton
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        saving={!!saving}
                        // disabled={loading || disabled}
                      />
                    </DialogActions>
                  </Box>
                </Card>
              </DialogContent>
            );
          }}
        ></FormWithRedirect>
      </Dialog>
    </Card>
  );
};

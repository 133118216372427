import { useMutation } from '@apollo/client';
import { Box, Card, Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import gql from 'graphql-tag';
import omit from 'lodash/omit';
import React, { useEffect, useState } from 'react';
import {
  Create,
  CreateProps,
  FormDataConsumer,
  Record,
  ResourceContextProvider,
  SimpleForm,
  useCreate,
  useGetIdentity,
  useNotify,
} from 'react-admin';

import EditAction from 'shared/components/Resource/EditAction';
import Toolbar from 'shared/components/Resource/ToolbarV2';
import { Property } from 'shared/generated/types';
import { useAppraisalOptions, useOrganizationByPk } from 'shared/hooks/useAppraisalOptions';
import { usePropertyData } from 'shared/hooks/usePropertyData';
import { Appraisal } from 'views/Appraisal/types';
import {
  AssigneeSection,
  EngagementSection,
  InspectionSection,
  PropertySection,
  WorkFlowSection,
} from './components/Create';
import { styles } from './appraisalStyles';

import getAppraisalPermission from './permission';

const INSERT_PROPERTY = gql`
  mutation insert_property($object: property_insert_input!) {
    property: insert_property_one(object: $object) {
      id
    }
  }
`;

const CreateAppraisal = (props: CreateProps) => {
  const styles = makeStyles({
    actionContainer: {
      marginTop: '2rem',
      padding: '0 1em',
    },
  });

  const classes = styles();
  const { identity } = useGetIdentity();

  const [mutation] = useMutation<{ property: Property }>(INSERT_PROPERTY);

  if (!identity) {
    return <span />;
  }

  return (
    <Box className={classes.actionContainer}>
      <ResourceContextProvider value="appraisal">
        <Create {...props} actions={<EditAction />} basePath="/appraisals" transform={transform} resource="appraisal">
          <CreateAppraisalFields />
        </Create>
      </ResourceContextProvider>
    </Box>
  );

  async function transform(data: Record) {
    let newAppraisal: Appraisal = {
      ...(data as Appraisal),
    };
    if (data.assignee_user_account_ids && typeof data.assignee_user_account_ids === 'string') {
      newAppraisal.assignee_user_account_ids = [data.assignee_user_account_ids];
    }
    const contactIds = localStorage.getItem('contactIds');
    if (contactIds) {
      const ids = JSON.parse(contactIds);
      newAppraisal.contact_ids = ids;
      localStorage.removeItem('contactIds');
    }
    if (!newAppraisal.property_id) {
      let residentialOwnershipType = null;
      if (newAppraisal.property_type_id === 1) {
        residentialOwnershipType = 1;
      }
      let commissionsArray = newAppraisal.commissions;
      commissionsArray?.forEach(function (each: any) {
        delete each.appraisal_id;
        delete each.assignee_full_name;
        delete each.assignee_role;
        delete each.client_id;
        delete each.id;
        delete each.net_expenses;
        delete each.percentage;
        delete each.total_amount;
        if (each?.rate_type_id === 1) delete each?.quantity;
      });
      const response = await mutation({
        variables: {
          object: {
            ...newAppraisal.property,
            property_type_id: newAppraisal.property_type_id,
            residential_ownership_type_id: residentialOwnershipType,
          },
        },
      });
      newAppraisal = {
        ...omit(newAppraisal, 'property'),
        property_id: response?.data?.property?.id,
      } as Appraisal;
    } else {
      newAppraisal = {
        ...omit(newAppraisal, 'property'),
      } as Appraisal;
    }
    return newAppraisal;
  }
};

function CreateAppraisalFields(props: any) {
  const classes = styles();
  const [appraisalOptions] = useAppraisalOptions();
  const { identity } = useGetIdentity();

  const { data: organization } = useOrganizationByPk({
    id: identity?.organization_id,
  });
  const isOnlyOneUserActive = organization?.organization_by_pk.user_accounts_active_count === 1;

  const notify = useNotify();
  const [create] = useCreate('appraisal_commission');

  const [appraisalStatus, setAppraisalStatus] = useState(1);
  const [appraisalPriority, setAppraisalPriority] = useState(1);
  const [quoteMadeDate, setQuoteMadeDate] = useState<any>(null);
  const [engagementDate, setEngagementDate] = useState<any>(null);
  const [propertyType, setPropertyType] = useState<any>(2);
  const [newCommissionsList, setNewCommissionsList] = useState([]);
  const [reportFee, setReportFee] = useState<any>(0);
  const [dateVal, setDateVal] = useState<MaterialUiPickersDate>(null);
  // const [timeVal, setTimeVal] = useState<any>(null);
  const [inspDateVal, setInspDateVal] = useState<MaterialUiPickersDate>(null);
  const [clientId, setClientId] = useState<any>(null);
  const [contactIds, setContactIds] = useState<any>(null);
  const [ownerContactIds, setOwnerContactIds] = useState<any[]>([]);
  const [inspectionContactIds, setInspectionContactIds] = useState<any[]>([]);

  const [property, setProperty] = useState<any>({ residential_ownership_type_id: 1 });

  const [getPropertyData, propertyDataResponse] = usePropertyData();

  const [engagementData, setEngagementData] = useState({
    appraisal_purpose_id: 26,
    reportType: 2,
    residential_form_type_ids: [],
    dateVal: null,
  });

  useEffect(() => {
    if (propertyDataResponse?.loading) {
      notify('property.loading');
      setPropertyType(2);
      setProperty({ residential_ownership_type_id: 1 });
    } else if (propertyDataResponse.data?.property_data?.property_type_id) {
      setPropertyType(propertyDataResponse.data.property_data.property_type_id);

      const {
        property_name,
        location_street,
        property_type_id,
        commercial_property_type_id,
        commercial_property_subtype_id,
        residential_ownership_type_id,
        residential_style_id,
        parcel_number,
        legal_description,
        subdivision,
        zoning,
        total_acres,
        year_built,
        residential_above_grade_bedrooms,
        residential_above_grade_bathrooms,
        residential_gross_living_area,
        commercial_gross_area,
        commercial_units,
        commercial_floors,
        commercial_buildings,
        batch_data,
        geoapify_data,
        location_components,
      } = propertyDataResponse.data.property_data;

      let data: any = {
        parcel_number,
        legal_description,
        subdivision,
        zoning,
        total_acres,
        year_built,
        batch_data,
        geoapify_data,
        location_components,
      };

      if (property_type_id === 1) {
        data = {
          ...data,
          property_name: location_street,
          residential_ownership_type_id,
          residential_style_id,
          residential_above_grade_bedrooms,
          residential_above_grade_bathrooms,
          residential_gross_living_area,
        };
      } else {
        data = {
          ...data,
          property_name,
          commercial_property_type_id,
          commercial_property_subtype_id,
          commercial_gross_area,
          commercial_units,
          commercial_floors,
          commercial_buildings,
        };
      }

      setProperty(data);
    }
  }, [notify, propertyDataResponse, propertyDataResponse.data]);

  useEffect(() => {
    if (property.owners && property.owners.length > 0) {
      const contacts = property.owners.map((item: any) => item.id);
      setOwnerContactIds(contacts);
      setProperty((prev: any) => {
        const newData = { ...prev };
        delete newData.owners;
        return newData;
      });
    }
  }, [property.owners]);

  const createCommissions = (appraisalId: any, clientId: any) => {
    const results: any = [];
    (newCommissionsList || []).forEach((commission: any) => {
      let finalCommissionData = { ...commission };
      delete finalCommissionData.id;
      delete finalCommissionData.total_amount;
      if (commission?.rate_type_id === 1) delete finalCommissionData.quantity;
      results.push(
        new Promise((resolve, reject) => {
          create(
            {
              payload: {
                data: { ...finalCommissionData, appraisal_id: appraisalId, client_id: clientId },
              },
            },
            {
              onSuccess: ({ data }: any) => {
                resolve(data);
                setReportFee(0);
              },
              onFailure: ({ error }: any) => {
                notify(error.message, 'error');
                reject(error);
              },
            },
          );
        }),
      );
    });
    return Promise.all(results);
  };

  useEffect(() => {
    if (appraisalStatus === 8) {
      setQuoteMadeDate(new Date());
      setEngagementDate(null);
    } else {
      setEngagementDate(new Date());
      setQuoteMadeDate(null);
    }
  }, [appraisalStatus]);

  useEffect(() => {
    if (propertyType === 1) {
      // Residential
      setEngagementData((prev) => ({ ...prev, report_type_id: 1 }));
    } else {
      // Commercial
      setEngagementData((prev) => ({ ...prev, report_type_id: 2 }));
    }
  }, [propertyType]);

  const { loading } = appraisalOptions;
  if (!identity) return null;

  if (loading)
    return (
      <Grid container direction="column" alignItems="center">
        <CircularProgress />
      </Grid>
    );

  return (
    <Card variant="outlined" className={classes.formBottom}>
      <Box pr={2}>
        <SimpleForm
          {...props}
          initialValues={{
            appraisal_status_id: appraisalStatus,
            property_type_id: propertyType,
            appraisal_priority_id: appraisalPriority,
            engagement_date: engagementDate,
            quote_made_date: quoteMadeDate,
            property,
            due_date: dateVal && dateVal?.toISOString(),
            inspection_date: inspDateVal && inspDateVal?.toISOString(),
            client_id: clientId,
            ordered_by_contact_ids: contactIds,
            owner_contact_ids: ownerContactIds,
            inspection_contact_ids: inspectionContactIds,
            ...engagementData,
            // commissions: newCommissionsList,
            // loan_type_id: 1,
          }}
          toolbar={<Toolbar getPermission={getAppraisalPermission} createCommissions={createCommissions} />}
        >
          <FormDataConsumer>
            {({ formData }) => (
              <Box className={classes.formContainer}>
                <WorkFlowSection
                  setAppraisalStatus={setAppraisalStatus}
                  setAppraisalPriority={setAppraisalPriority}
                  appraisalStatus={appraisalStatus}
                  appraisalPriority={appraisalPriority}
                />

                <PropertySection
                  formData={formData}
                  propertyType={propertyType}
                  setProperty={setProperty}
                  setPropertyType={setPropertyType}
                  getPropertyData={getPropertyData}
                  propertyDataResponse={propertyDataResponse}
                />

                <EngagementSection
                  formData={formData}
                  setContactIds={setContactIds}
                  setClientId={setClientId}
                  dateVal={dateVal}
                  setDateVal={setDateVal}
                  setReportFee={setReportFee}
                  setEngagementData={setEngagementData}
                />

                {appraisalStatus === 1 && (
                  <InspectionSection
                    formData={formData}
                    dateVal={inspDateVal}
                    setDateVal={setInspDateVal}
                    setInspectionContactIds={setInspectionContactIds}
                  />
                )}

                {!isOnlyOneUserActive && (
                  <AssigneeSection
                    commissions={newCommissionsList}
                    setCommissions={setNewCommissionsList}
                    reportFee={reportFee}
                  />
                )}

                <Grid container direction="row">
                  <Grid container md={12}>
                    <Divider classes={{ root: classes.dividerBottom }}></Divider>
                  </Grid>
                </Grid>
              </Box>
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Box>
    </Card>
  );
}

export default CreateAppraisal;

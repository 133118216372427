/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  SaveButton,
  useNotify,
  FormWithRedirect,
  TextInput,
  required,
  useRefresh,
  useUpdate,
  useGetIdentity,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { Card, CardHeader, Divider, Typography, Box, InputAdornment, Icon, Checkbox } from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import styles from '../hooks/useContactPopupStyles';
import styles2 from '../hooks/useContactListStyles';
import { useAssigneeRoleOptions, useAssigneesOptions } from 'shared/hooks/useAppraisalOptions';
import { Assignee_Roles, User_Profiles } from 'shared/generated/types';
import AutocompleteInput from 'shared/components/AutocompleteInput';
import { DateInput } from 'shared/components/Pickers';
import { displayFormattedDate, formatDecimalNumber } from 'shared/utils';
import { useAssigneeCommission, useGenericCommissionQuery } from 'shared/hooks/useAppraisalFees';
import { DeleteOutlined } from '@material-ui/icons';

type EditCommissionProps = {
  label?: string;
  appraisal?: any;
  active?: boolean;
  clientId?: string;
  currentCommission?: any;
  onSave?: any;
  reportFee?: any;
  refetchCommission?: any;
  hideEditButton?: boolean;
  triggerCloseModal?: any;
  expenses?: any;
};
interface State {
  quantity: number;
  rate: number;
}

function EditCommissionButton({
  appraisal,
  currentCommission,
  clientId,
  onSave,
  reportFee,
  refetchCommission,
  hideEditButton = false,
  triggerCloseModal,
  expenses,
}: EditCommissionProps) {
  const popUpClasses = styles();
  const classes = styles2();

  const [showDialog, setShowDialog] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isFlat, setFlat] = useState(currentCommission.rate_type_id === 2);
  const [isGross, setGross] = useState(currentCommission.net_expenses ? false : true);
  const [assigneeId, setAssigneeId] = useState('');
  const [roleId, setRoleId] = useState(0);
  const [subtractExpensesBefore, setSubtractExpensesBefore] = useState(true);
  const [customExpenses, setCustomExpenses] = useState<any>([]);
  const [removedExpenses, setRemovedExpenses] = useState<any>([]);
  const [addExpense, setAddExpense] = useState<string>('');

  const [values, setValues] = useState<State>({
    quantity: currentCommission.quantity,
    rate:
      currentCommission.rate_type_id === 1
        ? formatDecimalNumber(currentCommission.rate * 100, 2)
        : currentCommission.rate,
  });
  const [percentage, setPercentage] = useState<any>(formatDecimalNumber(currentCommission.rate * 100, 2) || 0);
  const { identity } = useGetIdentity();
  const [assigneeOptions] = useAssigneesOptions();
  const [getAssigneeRoleOptions, { data: assigneeRoleOptions }] = useAssigneeRoleOptions();
  const [hasFetchedAssignee, setHasFetchedAssignee] = useState(false);

  const refresh = useRefresh();
  const notify = useNotify();
  const [update, { loading }] = useUpdate('appraisal_commission', currentCommission.id);

  useEffect(() => {
    hideEditButton && handleClick();
  }, [hideEditButton]);

  const assignees = useMemo(() => {
    if (showDialog) {
      let options = assigneeOptions.data?.assignees ?? [];
      if (identity?.role === 'appraisal_firm_limited_access') {
        options = options.filter((e) => e.id === identity?.id);
      }
      getAssigneeRoleOptions();
      return options;
    }
  }, [identity, assigneeOptions, showDialog]);

  const assigneeRoles = useMemo(() => {
    if (showDialog) {
      let options = assigneeRoleOptions?.assignee_roles ?? [];
      return options;
    }
  }, [assigneeRoleOptions, showDialog]);

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangePercentage = (prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPercentage(event.target.value);
  };

  const { templatePropertyId, residentialId, commercialId, template, templateDefault } = {
    templatePropertyId: appraisal?.property?.property_type_id,
    residentialId:
      appraisal?.property?.property_type_id === 1 ? appraisal?.property?.residential_ownership_type_id : null,
    commercialId: appraisal?.property?.property_type_id === 2 ? appraisal?.property?.commercial_property_type_id : null,
    template: true,
    templateDefault: true,
  };

  const [getAssigneeData, { data: commissionData1 }] = useAssigneeCommission({
    assigneeId,
    roleId,
    templatePropertyId,
    residentialId,
    commercialId,
    template,
  });
  const [getAssigneeData2, { data: commissionData2 }] = useGenericCommissionQuery({
    assigneeId,
    template,
    templateDefault,
  });

  const templateData: any = useMemo(() => {
    if (showDialog && assigneeId) {
      if (commissionData1?.appraisal_commission?.length) {
        return commissionData1?.appraisal_commission[0];
      } else {
        getAssigneeData2();
        return '';
      }
    }
  }, [showDialog, commissionData1]);

  const defaultTemplateData: any = useMemo(() => {
    if (showDialog || !appraisal) {
      if (commissionData2?.appraisal_commission?.length) {
        return commissionData2?.appraisal_commission[0];
      } else return '';
    }
  }, [showDialog, commissionData2]);

  useEffect(() => {
    if (hasFetchedAssignee && assigneeId) {
      let temp = templateData ? templateData : defaultTemplateData;
      temp?.rate_type_id === 2 ? setFlat(true) : setFlat(false);
      if (temp?.rate_type_id === 1) {
        setFlat(false);
        setGross(temp?.net_expenses ? false : true);
        setPercentage(formatDecimalNumber(temp?.rate * 100, 2) || 0);
        setValues({
          quantity: temp?.quantity,
          rate: temp?.rate,
        });
      } else {
        setFlat(true);
        setValues({
          quantity: temp?.quantity,
          rate: temp?.rate,
        });
      }
    }
  }, [templateData, defaultTemplateData]);

  useEffect(() => {
    if (expenses?.length > 0 && currentCommission) {
      setSubtractExpensesBefore(!currentCommission?.expenses_after);
      const existingExpenses = expenses.filter((item: any) =>
        currentCommission?.appraisal_expense_ids?.includes(item?.id),
      );
      const remainingExpenses = expenses.filter(
        (item: any) => !currentCommission?.appraisal_expense_ids?.includes(item?.id),
      );
      setCustomExpenses(existingExpenses);
      setRemovedExpenses(remainingExpenses);
    }
  }, [expenses, currentCommission]);

  const handleClick = () => {
    setShowDialog(true);
    moveToolbarBelow();
  };

  const handleCloseClick = () => {
    setShowDialog(false);
    hideEditButton && triggerCloseModal();
  };

  const moveToolbarBelow = () => {
    setTimeout(() => {
      const toolbar = document.querySelector('#notes-popup .ql-toolbar') as any;
      const container = document.querySelector('#notes-popup .ql-container') as any;
      const editor = document.querySelector('#notes-popup .ql-editor') as any;
      if (toolbar && container && editor) {
        container.append(toolbar);
        container.style.border = 'none';
        editor.style.border = '1px solid rgba(0, 0, 0, 0.12)';
        editor.style.borderRadius = '3px';
      }
    }, 2);
  };

  const handleAssigneeSelect = (val: string) => {
    setAssigneeId(val);
    setHasFetchedAssignee(true);
    if (val) {
      appraisal ? getAssigneeData() : getAssigneeData2();
    }
  };

  const handleRoleSelect = (val: number) => {
    setRoleId(val);
  };

  const handleSubmit = async (values: any) => {
    const paid_status = {
      paid_date: displayFormattedDate(values.paid_date, 'yyyy-MM-dd'),
    };
    const values2 = {
      ...values,
      appraisal_expense_ids: customExpenses?.map((each: any) => each?.id),
      expenses_after: !subtractExpensesBefore,
      rate: formatDecimalNumber(percentage / 100, 2),
      ...(values?.paid_date && paid_status),
    };
    setDisabled(true);
    if (appraisal) {
      update(
        {
          payload: {
            id: currentCommission.id,
            data: values.rate_type_id === 2 ? { ...values, ...(values.paid_date && paid_status) } : values2,
            previousData: currentCommission,
          },
        },
        {
          onSuccess: () => {
            refetchCommission();
            setShowDialog(false);
            setDisabled(false);
            notify('appraisal_commission.updated');
            hideEditButton && triggerCloseModal();
            refresh();
          },
          onFailure: ({ error }: any) => {
            setDisabled(false);
            notify(error.message, 'error');
          },
        },
      );
    } else {
      let full_name = assignees?.find((each) => each?.id === values?.assignee_user_account_id)?.full_name;
      let role = assigneeRoles?.find((each) => each?.id === values?.assignee_role_id)?.role;
      const newCommissionData = {
        ...(values.rate_type_id === 2 ? values : values2),
        assignee_full_name: full_name,
        assignee_role: role,
      };
      onSave(newCommissionData);
    }
    setShowDialog(false);
    setDisabled(false);
  };
  let dataObj;
  if (hasFetchedAssignee && !appraisal) {
    if (assigneeId) dataObj = templateData ? templateData : defaultTemplateData;
  } else {
    dataObj = currentCommission;
  }

  const GridContainer = ({ title, value, hasDivider = false }: any) => (
    <Grid
      container
      direction="row"
      md={7}
      sm={12}
      justify="space-between"
      alignItems="center"
      className={popUpClasses.commissionGrid}
      style={hasDivider ? { borderBottom: '1px solid #e2e2e2', paddingBottom: '4px' } : {}}
    >
      <Typography>{title}</Typography>
      <Typography>{value}</Typography>
    </Grid>
  );

  const handleRemoveExpense = (removedData: any) => {
    setAddExpense('');
    setCustomExpenses(customExpenses.filter((each: any) => each?.id !== removedData?.id));
    setRemovedExpenses((prevState: any) => [...prevState, removedData]);
  };

  const handleAddExpense = (value: any) => {
    if (value) {
      setAddExpense(value);
      setCustomExpenses((prevState: any) => [...prevState, removedExpenses?.find((each: any) => each?.id === value)]);
      setRemovedExpenses(removedExpenses.filter((each: any) => each?.id !== value));
      setAddExpense('');
    }
  };

  const VerticalLayoutGrid = ({ title, input, applyMargin = false }: any) => (
    <Grid
      container
      direction="row"
      md={7}
      sm={12}
      justify="space-between"
      style={!applyMargin ? { marginBottom: '-7px' } : {}}
      className={popUpClasses.commissionGrid}
    >
      <Typography className={popUpClasses.labelMargin}>{title}</Typography>
      <Grid item md={3} sm={5} xs={7} alignItems="center">
        {input}
      </Grid>
    </Grid>
  );

  const percentageSplit = () => (
    <Grid
      container
      direction="row"
      md={7}
      sm={12}
      justify="space-between"
      alignItems="center"
      className={popUpClasses.commissionGrid}
    >
      <Typography>PERCENTAGE SPLIT</Typography>
      <Grid item md={3} sm={5} xs={7}>
        <TextInput
          validate={required()}
          source={'percentage'}
          variant="outlined"
          type="number"
          value={percentage}
          onChange={handleChangePercentage('net')}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label={false}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      {!hideEditButton && (
        <Icon onClick={handleClick} classes={{ root: classes.activeCursor }} fontSize="small">
          edit
        </Icon>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Edit Commission"
      >
        <FormWithRedirect
          initialValues={{
            appraisal_id: appraisal?.id,
            client_id: clientId,
            report_fee: appraisal?.report_fee,
            rate_type_id: isFlat ? 2 : 1,
            net_expenses: !isGross && !isFlat ? true : false,
            assignee_user_account_id: dataObj?.assignee_user_account_id,
            assignee_role_id: dataObj?.assignee_role_id,
            paid_date: dataObj?.paid_date,
            rate: dataObj ? dataObj?.rate : 0,
            percentage: dataObj ? formatDecimalNumber(dataObj?.rate * 100, 2) : 0,
            total_amount: appraisal
              ? (appraisal?.total_fees * (percentage / 100)).toFixed(2)
              : (reportFee * (percentage / 100)).toFixed(2),
          }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving, ...rest }: any) => (
            <DialogContent classes={{ root: popUpClasses.dialogContent }}>
              <Card variant="outlined" className={popUpClasses.formBottom}>
                <CardHeader title="Edit Assignee & Commission" classes={{ root: popUpClasses.cardHeader }}></CardHeader>
                <Divider></Divider>
                <Box className={popUpClasses.formContainer}>
                  <Typography classes={{ root: popUpClasses.heading }}>ASSIGNEE</Typography>
                  <Divider classes={{ root: popUpClasses.divider }}></Divider>
                  <Grid container direction="row">
                    <Grid item md={6} sm={8} xs={12}>
                      <AutocompleteInput
                        label="Assignee"
                        fullWidth
                        variant="outlined"
                        source="assignee_user_account_id"
                        optionText={(record: User_Profiles) => record?.full_name}
                        choices={assignees}
                        onChange={handleAssigneeSelect}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item md={6} sm={8} xs={12}>
                      <AutocompleteInput
                        label="Role"
                        fullWidth
                        variant="outlined"
                        source="assignee_role_id"
                        optionText={(record: Assignee_Roles) => record?.role}
                        choices={assigneeRoles}
                        onChange={handleRoleSelect}
                      />
                    </Grid>
                  </Grid>
                  <Typography classes={{ root: popUpClasses.heading }}>COMMISSION</Typography>
                  <Divider classes={{ root: popUpClasses.divider }}></Divider>

                  <Grid container direction="row" style={{ marginBottom: '32px' }}>
                    <Button
                      label="Flat"
                      onClick={() => setFlat(true)}
                      variant={isFlat ? 'contained' : 'outlined'}
                      style={{ borderRadius: '24px', marginRight: '16px', paddingRight: '16px' }}
                    />
                    <Button
                      label="Percentage of Total Fees"
                      onClick={() => setFlat(false)}
                      variant={isFlat ? 'outlined' : 'contained'}
                      style={{ borderRadius: '24px', paddingRight: '16px' }}
                    />
                  </Grid>
                  {isFlat ? (
                    ''
                  ) : (
                    <Grid container direction="row" style={{ marginBottom: '32px' }}>
                      <Button
                        label="Gross Expenses"
                        onClick={() => setGross(true)}
                        variant={isGross ? 'contained' : 'outlined'}
                        style={{ borderRadius: '24px', marginRight: '16px', paddingRight: '16px' }}
                      />
                      <Button
                        label="Net Expenses"
                        onClick={() => setGross(false)}
                        variant={isGross ? 'outlined' : 'contained'}
                        style={{ borderRadius: '24px', paddingRight: '16px' }}
                      />
                    </Grid>
                  )}
                  <Grid container direction="row" justify="space-between">
                    {isFlat ? (
                      <>
                        <VerticalLayoutGrid
                          title="QUANTITY"
                          input={
                            <TextInput
                              validate={required()}
                              source={'quantity'}
                              variant="outlined"
                              type="number"
                              value={values.quantity}
                              onChange={handleChange('quantity')}
                              fullWidth
                              defaultValue={currentCommission.quantity}
                              label={false}
                            />
                          }
                        />
                        <VerticalLayoutGrid
                          title="RATE"
                          input={
                            <TextInput
                              validate={required()}
                              value={values.rate}
                              onChange={handleChange('rate')}
                              source="rate"
                              variant="outlined"
                              type="number"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              fullWidth
                              defaultValue={currentCommission.rate}
                              label={false}
                            />
                          }
                        />
                        <VerticalLayoutGrid
                          title="FEE"
                          input={
                            <Typography style={{ paddingTop: '12.5px', textAlign: 'right' }}>
                              $
                              {isFlat
                                ? (values.rate * values.quantity).toFixed(2)
                                : ((values.rate / 100) * appraisal.total_fees).toFixed(2)}
                            </Typography>
                          }
                        />
                      </>
                    ) : isGross ? (
                      <>
                        <VerticalLayoutGrid
                          title="TOTAL FEES"
                          input={
                            <Typography style={{ padding: '12.5px 0px 16.5px 0px', textAlign: 'right' }}>
                              ${appraisal ? appraisal?.total_fees?.toFixed(2) : reportFee}
                            </Typography>
                          }
                          applyMargin={true}
                        />

                        <VerticalLayoutGrid
                          title="PERCENTAGE"
                          input={
                            <TextInput
                              validate={required()}
                              value={percentage}
                              onChange={handleChangePercentage('gross')}
                              source={'percentage'}
                              variant="outlined"
                              type="number"
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                              fullWidth
                              defaultValue={
                                !currentCommission?.net_expenses ? (currentCommission.rate * 100).toFixed(0) : ''
                              }
                              label={false}
                            />
                          }
                        />

                        <VerticalLayoutGrid
                          title="COMMISSION"
                          input={
                            <Typography style={{ paddingTop: '10.5px', textAlign: 'right' }}>
                              $
                              {appraisal
                                ? (appraisal?.total_fees * (percentage / 100)).toFixed(2)
                                : (reportFee * (percentage / 100)).toFixed(2)}
                            </Typography>
                          }
                        />
                      </>
                    ) : (
                      <>
                        <GridContainer
                          title="Subtract Expenses before Commission calculation?"
                          value={
                            <Checkbox
                              onChange={(e) => {
                                setSubtractExpensesBefore(e.target.checked);
                              }}
                              style={{ color: '#2196F3' }}
                              checked={!!subtractExpensesBefore}
                            />
                          }
                        />
                        <GridContainer
                          title="TOTAL FEES"
                          value={`$${appraisal ? appraisal?.total_fees?.toFixed(2) : reportFee}`}
                        />
                        {!subtractExpensesBefore && (
                          <>
                            {percentageSplit()}
                            <GridContainer
                              title="FEES BEFORE EXPENSES"
                              value={`$${(appraisal?.total_fees * (percentage / 100))?.toFixed(2)}`}
                            />
                          </>
                        )}
                        {expenses?.length > 0 && (
                          <>
                            {customExpenses?.length > 0 && (
                              <>
                                <Grid container style={{ marginBottom: '10px' }}>
                                  EXPENSE
                                </Grid>

                                {customExpenses?.map((each: any) => (
                                  <GridContainer
                                    title={
                                      <Grid alignItems="center">
                                        <Button
                                          onClick={() => handleRemoveExpense(each)}
                                          title="Remove Expense"
                                          className={popUpClasses.delIcon}
                                        >
                                          <DeleteOutlined
                                            style={{
                                              color: '#434445',
                                              fontSize: '22px',
                                            }}
                                          />
                                        </Button>
                                        {each?.description}
                                      </Grid>
                                    }
                                    value={`$${each?.total_amount?.toFixed(2)}`}
                                  />
                                ))}
                              </>
                            )}
                            {removedExpenses?.length > 0 && (
                              <Grid container direction="row" style={{ marginBottom: '10px' }}>
                                <Grid item md={5} sm={8} xs={12}>
                                  <AutocompleteInput
                                    label="+ Select Expenses"
                                    fullWidth
                                    variant="outlined"
                                    source="delId"
                                    optionText={(record: any) => record?.description}
                                    choices={removedExpenses}
                                    onChange={handleAddExpense}
                                    value={addExpense || null}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            {customExpenses?.length > 0 && appraisal && (
                              <>
                                <GridContainer
                                  hasDivider={true}
                                  title="TOTAL EXPENSES"
                                  value={`-$${customExpenses
                                    ?.reduce((sum: any, expense: any) => sum + expense?.total_amount, 0)
                                    .toFixed(2)}`}
                                />
                                {subtractExpensesBefore && (
                                  <GridContainer
                                    title="FEES AFTER EXPENSES"
                                    value={`$${(
                                      appraisal?.total_fees -
                                      customExpenses
                                        ?.reduce((sum: any, expense: any) => sum + expense?.total_amount, 0)
                                        .toFixed(2)
                                    )?.toFixed(2)}`}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                        {subtractExpensesBefore && <>{percentageSplit()}</>}
                        <GridContainer
                          title="COMMISSION"
                          value={`$${
                            appraisal
                              ? subtractExpensesBefore
                                ? (
                                    (appraisal?.total_fees -
                                      customExpenses
                                        ?.reduce((sum: any, expense: any) => sum + expense?.total_amount, 0)
                                        .toFixed(2)) *
                                    (percentage / 100)
                                  ).toFixed(2)
                                : (
                                    appraisal?.total_fees * (percentage / 100) -
                                    customExpenses?.reduce((sum: any, expense: any) => sum + expense?.total_amount, 0)
                                  ).toFixed(2)
                              : (reportFee * (percentage / 100)).toFixed(2)
                          }`}
                        />
                      </>
                    )}
                  </Grid>
                  {appraisal && (
                    <>
                      <Typography classes={{ root: popUpClasses.heading }}>STATUS</Typography>
                      <Divider classes={{ root: popUpClasses.divider }}></Divider>
                      <Grid container direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                          <DateInput variant="outlined" fullWidth label="Paid" source="paid_date" />
                        </Grid>
                      </Grid>
                      <Divider classes={{ root: popUpClasses.divider }}></Divider>
                    </>
                  )}
                  <DialogActions classes={{ root: popUpClasses.editActions }}>
                    <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
                      <IconCancel />
                    </Button>
                    <SaveButton
                      handleSubmitWithRedirect={handleSubmitWithRedirect}
                      saving={saving}
                      disabled={loading || disabled}
                    />
                  </DialogActions>
                </Box>
              </Card>
            </DialogContent>
          )}
        />
      </Dialog>
    </>
  );
}

export default EditCommissionButton;

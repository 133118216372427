import { Box, Card, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import { Confirm } from 'ra-ui-materialui';
import React, { useState } from 'react';
import { Button, ResourceComponentInjectedProps, SimpleForm } from 'react-admin';

import { useMutation } from '@apollo/client';
import { Tasklist_Templates } from 'shared/generated/types';
import {
  DELETE_TASKLIST_QUERY,
  GET_TASKLIST_TEMPLATE,
  useTasklistTemplates,
} from 'shared/hooks/useAppraisalSettingsQueries';
import { profileStyles, simpleFormContainer, styleRight } from 'shared/hooks/useEditFormStyle';
import styles from 'views/Client/components/hooks/useContactListStyles';
import TemplateForm from './TemplateForm';

const TasklistTemplate = (props: ResourceComponentInjectedProps) => {
  const tableClasses = styles();
  const formClasses = simpleFormContainer();
  const classes = styleRight();
  const profileClasses = profileStyles();

  const [isOpen, setOpen] = useState(false);
  const [selectedTasklistTemplate, setSelectedTasklistTemplate] = useState<Tasklist_Templates | undefined>();
  const [deleteItem, setDeleteItem] = useState<string | undefined>();

  const { data: tasklistTemplatesData, refetch: refetchTasklistTemplateData } = useTasklistTemplates();

  const [deleteTasklist] = useMutation<any, { id: string }>(DELETE_TASKLIST_QUERY, {
    onCompleted: () => {
      setDeleteItem(undefined);
    },
    update(cache, { data }) {
      const { tasklist_templates } = cache.readQuery<any>({
        query: GET_TASKLIST_TEMPLATE,
      });
      cache.writeQuery({
        query: GET_TASKLIST_TEMPLATE,
        data: {
          tasklist_templates: tasklist_templates.filter(
            (template: { id: any }) => template.id !== data.delete_tasklist_by_pk.id,
          ),
        },
      });
    },
  });

  const handleAddClick = () => {
    setOpen(true);
  };

  return (
    <SimpleForm {...props} toolbar={<span />}>
      <Box p={0} className={formClasses.formContainerOrganization} mt={2}>
        <Card variant="outlined" classes={{ root: classes.card }}>
          <Box className={`${classes.flexBox} ${profileClasses.userAccountBox}`}>
            <Typography classes={{ root: `${profileClasses.userAccountHeading} ${classes.heading500}` }}>
              TASKLIST TEMPLATES
            </Typography>
          </Box>
          <Table>
            <TableHead classes={{ root: tableClasses.tableHead }}>
              <TableRow>
                <TableCell classes={{ root: tableClasses.headCell }}>NAME</TableCell>
                <TableCell classes={{ root: tableClasses.headCell }}>TYPE / FORMS</TableCell>
                <TableCell classes={{ root: tableClasses.headCell }}>DEFAULT</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tasklistTemplatesData?.tasklist_templates.map((tasklist_template) => {
                const {
                  property_type,
                  residential_ownership_type,
                  commercial_property_type,
                  id,
                  name,
                  template_default,
                } = tasklist_template;
                let type = property_type === 'Residential' ? residential_ownership_type : commercial_property_type;

                if (!type) {
                  type = 'All';
                }
                return (
                  <TableRow key={id}>
                    <TableCell classes={{ root: tableClasses.cell }}>{name}</TableCell>
                    <TableCell classes={{ root: tableClasses.cell }}>{type}</TableCell>
                    <TableCell classes={{ root: tableClasses.cell }}>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography>{template_default ? 'Yes' : 'No'}</Typography>
                        <Box>
                          <IconButton
                            onClick={() => {
                              setSelectedTasklistTemplate(tasklist_template);
                              setOpen(true);
                            }}
                          >
                            <Edit />
                          </IconButton>
                          {!template_default && (
                            <IconButton onClick={() => setDeleteItem(id)}>
                              <Delete />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box className={tableClasses.addBox}>
            <Box className={tableClasses.addContactBtn}>
              <Button onClick={handleAddClick} label="Add Tasklist Template">
                <Add />
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>

      <Confirm
        isOpen={!!deleteItem}
        title={'Confirm Delete'}
        content={'Are you sure you want to delete this tasklist template?'}
        onConfirm={() => {
          deleteItem &&
            deleteTasklist({
              variables: { id: deleteItem },
            });
        }}
        onClose={() => setDeleteItem(undefined)}
      />

      <TemplateForm
        isOpen={isOpen}
        setOpen={setOpen}
        tasklistTemplatesData={tasklistTemplatesData}
        refetchTasklistTemplateData={refetchTasklistTemplateData}
        selectedTasklistTemplate={selectedTasklistTemplate}
        setSelectedTasklistTemplate={setSelectedTasklistTemplate}
      />
    </SimpleForm>
  );
};

export default TasklistTemplate;

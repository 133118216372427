import { gql, useLazyQuery } from '@apollo/client';
import { PropertyDataOutput } from 'shared/generated/types';

const QUERY = gql`
  query PropertyData($address: String!) {
    property_data(args: { address: $address }) {
      batch_data
      commercial_buildings
      commercial_floors
      commercial_gross_area
      commercial_property_subtype_id
      commercial_property_type_id
      commercial_units
      geoapify_data
      legal_description
      location_address
      location_city
      location_components
      location_county
      location_geography
      location_postal_code
      location_state
      location_street
      owners
      parcel_number
      property_name
      property_type_id
      residential_above_grade_bathrooms
      residential_above_grade_bedrooms
      residential_gross_living_area
      residential_ownership_type_id
      residential_style_id
      subdivision
      total_acres
      year_built
      zoning
    }
  }
`;

export interface PropertyDataResponse {
  property_data: PropertyDataOutput;
}
export interface PropertyDataRequest {
  address?: string;
}

export const usePropertyData = () => {
  return useLazyQuery<PropertyDataResponse, PropertyDataRequest>(QUERY, {
    fetchPolicy: 'cache-first',
  });
};

import { Box, Card, Divider, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';
import {
  BooleanInput,
  Button,
  Edit,
  ResourceComponentInjectedProps,
  SimpleForm,
  TextInput,
  useGetIdentity,
  useMutation,
  useNotify,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

import { profileStyles, simpleFormContainer, styleRight } from 'shared/hooks/useEditFormStyle';
import { copyToClipboard } from 'shared/utils';

const ClientForm = (props: ResourceComponentInjectedProps) => {
  const classes = simpleFormContainer();
  const notify = useNotify();
  const identityState = useGetIdentity();

  const onSuccess = () => {
    notify('Client Form settings updated');
  };

  return (
    <Edit
      {...props}
      undoable={false}
      onSuccess={onSuccess}
      resource="organization"
      title="Settings: Appraisal Settings"
      id={identityState?.identity?.organization_id}
      basePath="/account/my-profile"
      mutationMode="pessimistic"
    >
      <SimpleForm {...props} toolbar={<span />} margin="none">
        <Box p={0} className={classes.formContainerOrganization}>
          <ClientFormSection />
        </Box>
      </SimpleForm>
    </Edit>
  );
};

const ClientFormSection = (props: any) => {
  const formClasses = simpleFormContainer();
  const classes = styleRight();
  const profileClasses = profileStyles();

  const [edit, setEdit] = useState(false);

  const notify = useNotify();

  const onSuccess = () => {
    notify('Client Form settings updated');
  };

  const getDefaultFields = (initialValue: boolean) => ({
    client_form_active: initialValue,
    slug: initialValue,
  });
  const [fields, setFields] = useState<any>(getDefaultFields(false));
  const setAllFields = (val: boolean) => {
    setFields(getDefaultFields(val));
    setEdit(val);
  };

  const formData = useFormState();
  const form = useForm();

  const save = useCallback(debounce(form.submit, 0), []);

  const [saveClientFormActive] = useMutation({
    type: 'update',
    resource: 'organization',
    payload: { id: formData.values.id, data: {} },
  });

  const ordersUrl = process.env.REACT_APP_APPRAISER_WEB_HOST + '/orders/';

  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;
    // Use a regular expression to match only letters, numbers, and dashes
    const pattern = /^[A-Za-z0-9-]*$/;

    if (pattern.test(inputValue)) {
      // If the input matches the pattern, allow it
      // You can also update the state or handle it as needed
    } else {
      // If the input doesn't match the pattern, prevent it
      e.target.value = e.target.value.slice(0, -1);
    }
  };

  return (
    <Box p={0} className={formClasses.formContainerOrganization}>
      <Card variant="outlined" classes={{ root: classes.card }}>
        <Box className={`${classes.flexBox} ${profileClasses.userAccountBox}`}>
          <Typography classes={{ root: `${profileClasses.userAccountHeading} ${classes.heading500}` }}>
            CLIENT FORM
          </Typography>
          {!edit ? (
            <IconButton
              className={classes.topEditBtnProfile}
              edge="end"
              disabled={edit}
              onClick={() => setAllFields(true)}
            >
              <EditIcon classes={{ root: classes.icon }} />
            </IconButton>
          ) : (
            <IconButton
              classes={{ root: classes.topEditBtnProfile }}
              disabled={!edit}
              edge="end"
              onClick={() => {
                setEdit(false);
                setAllFields(false);
              }}
            >
              <CloseIcon classes={{ root: classes.icon }} />
            </IconButton>
          )}
        </Box>
        <Divider classes={{ root: classes.dividerMB }} />

        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Box>
              <Typography
                classes={{ root: `${classes.heading400} ${profileClasses.toggle} ${profileClasses.clientForm}` }}
              >
                Allow Clients to submit appraisal orders via Client Form?
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box ml={2}>
              <Typography classes={{ root: `${classes.inputFontWeight}` }}>
                <BooleanInput
                  className={profileClasses.toggleDisabled}
                  disabled={!edit}
                  label={formData.values.client_form_active ? 'Yes' : 'No'}
                  source="client_form_active"
                  onChange={(bool) => {
                    saveClientFormActive(
                      { payload: { id: formData.values.id, data: { client_form_active: bool } } },
                      { onSuccess },
                    );
                  }}
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" alignItems="center">
          <Grid item xs={4}>
            <Typography classes={{ root: `${classes.heading400} ${profileClasses.clientForm}` }}>
              Client Form Link (share this with your Clients):
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box pr={2}>
              <TextInput
                size="small"
                btnUrl={classes.btnUrl}
                color="secondary"
                InputProps={{
                  placeholder: 'Click pencil to edit',
                  classes: {
                    root: `${classes.cssOutlinedInput} ${classes.inputFontSize} ${
                      fields.slug ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                    }`,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  endAdornment: formData.dirtyFields.slug ? (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        className={classes.fieldEditBtn}
                        edge="end"
                        disabled={fields.slug && !formData.dirtyFields.slug}
                      >
                        {fields.slug && formData.dirtyFields.slug && (
                          <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                        )}
                        &nbsp;
                        {fields.slug && formData.dirtyFields.slug && (
                          <CloseIcon
                            fontSize="small"
                            classes={{ root: classes.icon }}
                            onClick={() => form.change('url', formData.initialValues.slug)}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <Button
                      label="Copy"
                      onClick={(e) => {
                        const fullWebsite = `${ordersUrl}${formData.values.slug}`;
                        copyToClipboard(fullWebsite, e, false);
                      }}
                    ></Button>
                  ),
                  onInput: handleInputChange,
                  startAdornment: (
                    <InputAdornment position="start" className={classes.marginNone}>
                      {ordersUrl}
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{ style: { display: 'none' } }}
                disabled={!fields.slug}
                variant="outlined"
                label=""
                fullWidth
                source="slug"
              />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default ClientForm;

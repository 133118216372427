import React from 'react';
import { ResourceComponentInjectedProps, useGetIdentity } from 'react-admin';

import ClientForm from './AppraisalSettings/ClientForm';
import TasklistTemplate from './AppraisalSettings/TasklistTemplate';

function AppraisalSettings(props: ResourceComponentInjectedProps) {
  const identityState = useGetIdentity();

  const isAccountOwner = identityState.identity?.role === 'appraisal_firm_account_owner';

  return (
    <>
      {isAccountOwner && <ClientForm {...props} />}
      <TasklistTemplate {...props} />
    </>
  );
}

export default AppraisalSettings;

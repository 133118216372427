import { Box, IconButton, TextField, Typography } from '@material-ui/core';
import { Delete, Done } from '@material-ui/icons';
import Edit from '@material-ui/icons/Edit';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetIdentity } from 'react-admin';
import { Messages } from 'shared/generated/types';
import {
  useMessageDeleteMutation,
  useMessageMarkRead,
  useMessageUpdateMutation,
} from 'views/Appraisal/hooks/messageHooks';

interface MessageProps {
  message: Messages;
}

export const Message = ({ message }: MessageProps) => {
  const identityState = useGetIdentity();
  const [markMessageRead] = useMessageMarkRead();
  const [showButtons, setShowButtons] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState('');

  const [deleteMessage] = useMessageDeleteMutation();

  const [updateMessage, updateMessageResponse] = useMessageUpdateMutation();

  useEffect(() => {
    if (updateMessageResponse.data) {
      setIsEditing(false);
      setEditedMessage('');
    }
  }, [updateMessageResponse]);

  const { bgcolor, justify, time, from, hoursFromNow, isFromEmail, isCurrentUser } = useMemo(() => {
    const sender = message.sender_full_name || '';
    const name = sender.split(' ');
    let shortName = `${name[0]} ${name[1].charAt(0)}.`;

    const isCurrentUser = message.sender_id === identityState.identity?.id;

    if (!isCurrentUser) {
      const sender = message.message_thread_participants?.find(
        (item: any) => item.participant_id === message.sender_id,
      );

      if (sender?.participant_type === 'user_account') {
        if (sender.assignee_role) {
          shortName += ` (${sender.assignee_role})`;
        }
      } else if (sender?.participant_type === 'client_contact' && sender.contact_type) {
        shortName += ` (${sender.contact_type})`;
      }
    }

    const from = isCurrentUser ? 'You' : shortName || '';

    const bgcolor = isCurrentUser ? '#f0f0f0' : 'rgba(33, 150, 243, 0.08)';
    const justify = isCurrentUser ? 'end' : 'start';
    const time = moment(message.created_at).format('@ hh:MM a');

    const duration = moment.duration(moment().diff(message.created_at));
    const hoursFromNow = duration.asHours();
    const isFromEmail = message.source === 'email';

    if (identityState.identity && !message.read) {
      message.recipients?.map((recipient: any) => {
        if (recipient.recipient_id === identityState.identity?.id && recipient.read_at === null) {
          markMessageRead({
            variables: {
              message_id: message.id,
              read_where: 'web_app',
              recipient_id: String(identityState.identity?.id),
            },
          });
        }
        return null;
      });
    }

    return { bgcolor, justify, time, from, hoursFromNow, isFromEmail, isCurrentUser };
  }, [message, identityState.identity, markMessageRead]);

  return (
    <Box mb={2}>
      <Box display={'flex'} justifyContent={justify} alignItems={'center'}>
        <Box pr={1}>
          <Typography variant="body1">{from}</Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {time} {isFromEmail ? 'via email' : ''}
        </Typography>
      </Box>

      <Box
        display={'flex'}
        justifyContent={isCurrentUser ? 'end' : 'start'}
        onMouseEnter={() => {
          hoursFromNow < 1 && isCurrentUser && setShowButtons(true);
        }}
        onMouseLeave={() => setShowButtons(false)}
      >
        {(isEditing || showButtons) && (
          <Box display={'flex'} alignItems={'center'} pl={1}>
            <IconButton
              onClick={() =>
                deleteMessage({
                  variables: { id: message.id },
                })
              }
            >
              <Delete fontSize="small" />
            </IconButton>
            {isEditing ? (
              <IconButton
                onClick={() => {
                  updateMessage({
                    variables: {
                      id: message.id,
                      message: editedMessage,
                    },
                  });
                }}
              >
                <Done />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  setIsEditing(true);
                  setEditedMessage(message.message || '');
                }}
              >
                <Edit fontSize="small" />
              </IconButton>
            )}
          </Box>
        )}
        <Box display={'flex'} component={'div'} borderRadius={8} px={2} py={1} my={1} bgcolor={bgcolor}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            {isEditing ? (
              <TextField
                autoFocus
                value={editedMessage}
                onChange={(e) => {
                  setEditedMessage(e.target.value);
                }}
              />
            ) : (
              <Typography>{message.message}</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import { withStyles } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import React from 'react';

export const DueCheckbox = withStyles({
  root: {
    color: '#ff9800',
    '&$checked': {
      color: '#ff9800',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const InProgressCheckbox = withStyles({
  root: {
    color: green[500],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const InspectionCheckbox = withStyles({
  root: {
    color: '#9c27b0',
    '&$checked': {
      color: '#9c27b0',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const TaskCheckbox = withStyles({
  root: {
    color: '#2196f3',
    '&$checked': {
      color: '#2196f3',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

import Typography from '@material-ui/core/Typography';
import React from 'react';
import { EventProps } from 'react-big-calendar';
import { AppraisalEvent } from 'views/Appraisal/types';

import { Box, Chip } from '@material-ui/core';
import { useRedirect } from 'react-admin';
import { useIsOnlyOneUserActive } from 'shared/hooks/useAppraisalOptions';

type AgendaEventProps = Omit<EventProps<AppraisalEvent>, 'title'>;
function AgendaEvent(props: AgendaEventProps) {
  const redirect = useRedirect();
  const isOnlyOneUserActive = useIsOnlyOneUserActive();

  const {
    appraisal_assignee_user_account_names,
    appraisal_id,
    appraisal_file_number,
    client_name,
    location_address,
    schedule_type,
    task_description,
    task_assignee_full_name,
  } = props.event;

  const taskEvent = appraisal_file_number ? `${appraisal_file_number} - ${location_address}` : `${location_address}`;

  if (schedule_type === 'appraisal') {
    return (
      <Box
        onClick={() => {
          redirect(`/appraisals/${appraisal_id}`);
        }}
      >
        <Box>
          <Typography variant="body2">{taskEvent}</Typography>
        </Box>
        <Box my={0.75}>
          <Typography variant="body2">Client: {client_name}</Typography>
        </Box>

        {!isOnlyOneUserActive && (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant="body2">Assignees: </Typography>
            {appraisal_assignee_user_account_names && appraisal_assignee_user_account_names.length > 0 ? (
              appraisal_assignee_user_account_names.map((item: string) => (
                <Box mx={1} key={item}>
                  <Chip label={item} size={'small'} />
                </Box>
              ))
            ) : (
              <Box ml={1}>
                <Typography variant="body2">Unassigned</Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Box
        onClick={() => {
          redirect(`/appraisals/${appraisal_id}#tasks`);
        }}
      >
        {!isOnlyOneUserActive && (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant="body2">{task_description}</Typography>
            {task_assignee_full_name && (
              <Box mx={1}>
                <Chip label={task_assignee_full_name} size={'small'} />
              </Box>
            )}
          </Box>
        )}
        <Box my={0.75}>
          <Typography variant="body2">{taskEvent}</Typography>
        </Box>
        <Box>
          <Typography variant="body2">Client: {client_name}</Typography>
        </Box>
      </Box>
    );
  }
}
export default AgendaEvent;

import { Box, ListItem, List, Chip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const MessageThreadSkeleton = () => {
  return (
    <List component={'nav'}>
      <ListItem style={{ justifyContent: 'space-between' }} selected>
        <Box>
          <Skeleton width={200} />
        </Box>
        <Chip size="small" />
      </ListItem>
      <ListItem style={{ justifyContent: 'space-between' }}>
        <Box>
          <Skeleton width={150} />
        </Box>
        <Chip size="small" />
      </ListItem>
    </List>
  );
};

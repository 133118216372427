import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { gqlClient } from 'shared/ApolloClient';
import {
  Appraisal_Purposes,
  Contact_Types,
  Mutation_RootInsert_Client_OneArgs,
  Mutation_RootInsert_Property_OneArgs,
  Organization,
  Property,
  Report_Types,
  Residential_Form_Types,
} from 'shared/generated/types';
import { Client } from 'views/Client/types';

const defaultOptions = {
  variables: {
    isExternal: true,
  },
};

export const ClientOrderFormSignIn = gql`
  mutation OrderFormSignIn($id: String) {
    client_order_form_sign_in(args: { id: $id }) {
      jwt_token
    }
  }
`;
export interface OderFormSignInResponse {
  client_order_form_sign_in: {
    jwt_token: string;
  }[];
}
export const clientOrderFormSignIn = async (id: string) => {
  return await gqlClient.mutate<OderFormSignInResponse>({
    mutation: ClientOrderFormSignIn,
    variables: {
      id,
    },
  });
};

export interface GetContactByEmailResponse {
  contact: {
    id: string;
    client_id: string;
  }[];
}
const GetContactByEmailQuery = gql`
  query GetContactByEmail($email: String) {
    contact(where: { email: { _eq: $email } }, limit: 1) {
      id
      client_id
    }
  }
`;
export const useGetContactByEmail = () => {
  return useLazyQuery<GetContactByEmailResponse>(GetContactByEmailQuery, { defaultOptions });
};

interface GetOptionsResponse {
  contactTypes: Contact_Types[];
  appraisalPurposes: Appraisal_Purposes[];
  reportTypes: Report_Types[];
  residentialFormTypes: Residential_Form_Types[];
}
const GetAppraisalPurposeQuery = gql`
  query AppraisalPurpose {
    contactTypes: contact_types {
      client_type_id
      id
      type
    }
    appraisalPurposes: appraisal_purposes(order_by: { order: asc }) {
      id
      purpose
      is_loan
    }
    reportTypes: report_types(order_by: { order: asc }) {
      id
      type
    }
    residentialFormTypes: residential_form_types(order_by: { order: asc }) {
      id
      type
    }
  }
`;
export const useGetOptions = () => {
  return useQuery<GetOptionsResponse>(GetAppraisalPurposeQuery, {
    defaultOptions,
  });
};

const SetClientMutation = gql`
  mutation SetClient($object: client_insert_input!) {
    insert_client_one(object: $object) {
      id
      name
      contacts {
        id
        user_account_id
        email
      }
    }
  }
`;
interface SetClientResponse {
  insert_client_one: Client;
}
export const useSetClient = () => {
  return useMutation<SetClientResponse, Mutation_RootInsert_Client_OneArgs>(SetClientMutation, {
    ...(defaultOptions as any),
  });
};

const SetPropertyMutation = gql`
  mutation SetProperty($object: property_insert_input!) {
    insert_property_one(object: $object) {
      id
      appraisals {
        id
        property_id
      }
    }
  }
`;
interface SetPropertyResponse {
  insert_property_one: Property;
}
export const useSetProperty = () => {
  return useMutation<SetPropertyResponse, Mutation_RootInsert_Property_OneArgs>(SetPropertyMutation, {
    ...(defaultOptions as any),
  });
};

export interface KeyData {
  key: string;
  signedUrl: string;
  urlId: string;
  file: File;
}

const GetOrganizationQuery = gql`
  query GetOrganization {
    organization {
      name
      location_address
      phone_number
    }
  }
`;
interface GetOrganizationResponse {
  organization: Organization[];
}
export const useGetOrganization = (skip?: boolean) => {
  return useQuery<GetOrganizationResponse>(GetOrganizationQuery, {
    defaultOptions,
    skip,
  });
};

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { ClientActivities } from 'views/Client/types';
// import { Client } from 'views/Client/types';

const QUERY = gql`
  query ClientActivitySummary($clientId: uuid, $offset: Int, $limit: Int) {
    client_activity_summary(
      args: { _client_id: $clientId }
      offset: $offset
      limit: $limit
      order_by: { commit_timestamp: desc }
    ) {
      activity
      activity_id
      appraisal_assignees
      appraisal_assignee_ids
      appraisal_file_number
      appraisal_location_address
      appraisal_status
      commit_timestamp
      contact_id
      contact_name
      client_id
      client_name
      notes
      timestamp_group_by
      type
      user_account_name
      user_account_id
    }
  }
`;

export type ClientActivitySummaryResponse = {
  client_activity_summary: ClientActivities[];
};

export function useClientActivitySummary(props: { clientId: string; offset: number; limit: number }) {
  return useQuery<ClientActivitySummaryResponse>(QUERY, { fetchPolicy: 'cache-first', variables: props });
}

const AGG_QUERY = gql`
  query ClientActivitySummaryAgg($clientId: uuid) {
    client_activity_summary_aggregate(args: { _client_id: $clientId }) {
      aggregate {
        count
      }
    }
  }
`;

export type ClientActivitySummaryAggResponse = {
  client_activity_summary_aggregate: { aggregate: { count: number } };
};

export function useClientActivitySummaryAggregate(props: { clientId: string }) {
  return useQuery<ClientActivitySummaryAggResponse>(AGG_QUERY, { fetchPolicy: 'cache-first', variables: props });
}

import { gql, useQuery } from '@apollo/client';
import { TeamMemberActivities } from 'shared/types';

const QUERY = gql`
  query TeamMemberActivitySummary($activityId: uuid, $offset: Int, $limit: Int) {
    user_profile_activity_summary(
      args: { _user_profile_id: $activityId }
      offset: $offset
      limit: $limit
      order_by: { commit_timestamp: desc }
    ) {
      activity
      activity_id
      appraisal_assignee_ids
      appraisal_assignees
      appraisal_contact_ids
      appraisal_contacts
      appraisal_file_number
      appraisal_location_address
      appraisal_status
      changes
      client_name
      commit_timestamp
      contact_name
      notes
      organization_id
      timestamp_group_by
      type
      user_account_id
      user_account_name
    }
  }
`;

export type TeamMemberActivitySummaryResponse = {
  user_profile_activity_summary: TeamMemberActivities[];
};

export function useTeamMemberActivitySummary(props: { activityId: string; offset: number; limit: number }) {
  return useQuery<TeamMemberActivitySummaryResponse>(QUERY, {
    fetchPolicy: 'cache-first',
    variables: props,
  });
}

const AGG_QUERY = gql`
  query TeamMemberActivitySummaryAgg($activityId: uuid) {
    user_profile_activity_summary_aggregate(args: { _user_profile_id: $activityId }) {
      aggregate {
        count
      }
    }
  }
`;

type TeamMemberActivitySummaryAggResponse = {
  user_profile_activity_summary_aggregate: { aggregate: { count: number } };
};

export function useTeamMemberActivitySummaryAgg(props: { activityId: string }) {
  return useQuery<TeamMemberActivitySummaryAggResponse>(AGG_QUERY, {
    fetchPolicy: 'cache-first',
    variables: props,
  });
}

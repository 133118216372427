import { Box, Button, Grid, Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormDataConsumer, SimpleForm } from 'react-admin';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';

import { authProvider } from 'shared/AuthProvider';
import { Client_Insert_Input, Contact_Types, Property_Insert_Input } from 'shared/generated/types';
import { getFilePresignUploadUrl, useUploadFileMutation } from 'views/Appraisal/hooks/workfileHooks';
import { Attachments, ClientInformation, Instructions, OrderDetails, OrderSuccess, Property } from './Steps';
import { OrderData } from './Steps/OrderDetails';
import { KeyData, useGetContactByEmail, useSetClient, useSetProperty } from './queries';
import { useStyles } from './styles';

const steps = ['Client Information', 'Property', 'Order Details', 'Attachments', 'Instructions'];

const initialOrderData: OrderData = {
  appraisal_status_id: 8,
  appraisal_priority_id: 1,
  dateVal: null,
  showReportFee: false,
};

export const StepperForm = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  const [activeStep, setActiveStep] = useState(0);

  const [contactType, setContactType] = useState<Contact_Types | null>(null);
  const [files, setFiles] = useState<File[]>();

  const [orderData, setOrderData] = useState<OrderData>(initialOrderData);

  const [getContactByEmail, { data: contactByEmailData }] = useGetContactByEmail();
  const [setClient] = useSetClient();
  const [setProperty] = useSetProperty();
  const [uploadFileMutation] = useUploadFileMutation(true);

  useEffect(() => {
    if (contactByEmailData && contactByEmailData?.contact.length > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [contactByEmailData]);

  const handleFilesUpload = async (appraisalId: string) => {
    if (files && files?.length > 0 && appraisalId) {
      let queryString = '{';
      const tempKeyData: KeyData[] = [];

      files.map((file, index) => {
        const key = uuidv4();
        const urlId = `url${index}`;

        tempKeyData[index] = { key, urlId, file, signedUrl: '' };
        queryString += `${urlId} : file_presign_upload_url (
            args: {
              key: "${key}"
              parent: "appraisal"
              parent_id: "${appraisalId}"
            }
          ) {
            signed_url
          }
        `;
        return null;
      });
      queryString += '}';
      if (queryString.length > 1) {
        const signedUrls = await getFilePresignUploadUrl(queryString, true);
        if (signedUrls) {
          const sanitizedKeyData = tempKeyData.map((keyData) => {
            const temp: KeyData = { ...keyData, signedUrl: signedUrls[keyData.urlId].signed_url };
            return temp;
          });

          if (sanitizedKeyData.length > 0) {
            Promise.all(
              sanitizedKeyData.map(async (item) => {
                return await fetch(item.signedUrl, {
                  method: 'PUT',
                  body: item.file,
                })
                  .then((data) => {
                    if (data.status === 200) {
                      const { name, type, size } = item.file;
                      uploadFileMutation({
                        variables: {
                          parent: 'appraisal',
                          parent_id: appraisalId,
                          key: item.key,
                          filename: name,
                          filetype: type,
                          filesize: size,
                        },
                      });
                    }
                  })
                  .catch((error) => {
                    console.log('Upload to bucket failed', { error });
                  });
              }),
            );
          }
        }
      }
    }
  };

  const handleNext = (email?: string) => {
    if (activeStep === 0) {
      if (!contactByEmailData) {
        getContactByEmail({ variables: { email } });
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = async (formData: any) => {
    const {
      company_name,
      name,
      location_address,
      location_components,
      email,
      phone_number,
      report_type_id,
      appraisal_purpose_id,
      order_instructions,
      residential_form_type_ids,
    } = formData;

    await authProvider.getUpdatedExternalToken(id);

    const { appraisal_status_id, appraisal_priority_id, dateVal } = orderData;

    const setPropertyRequestData: Property_Insert_Input = {
      location_address,
      location_components,
      appraisals: {
        data: [
          {
            appraisal_status_id,
            appraisal_priority_id,
            report_type_id,
            appraisal_purpose_id,
            due_date: dateVal?.toISOString(),
            order_instructions,
            residential_form_type_ids,
            client_id: contactByEmailData?.contact[0]?.client_id,
            ordered_by_contact_ids: contactByEmailData?.contact[0] ? [contactByEmailData.contact[0].id] : [],
          },
        ],
      },
    };

    if (!contactByEmailData?.contact.length) {
      const nameParts = name?.split(' ');
      const [first_name, ...last_nameParts] = nameParts || [];
      const last_name = last_nameParts.join(' ');

      const setClientRequestData: Client_Insert_Input = {
        client_type_id: contactType?.client_type_id || 17,
        name: company_name,
        contacts: {
          data: [
            {
              contact_type_id: contactType?.id,
              email,
              first_name,
              last_name,
              phone_number,
            },
          ],
        },
      };

      const { data: setClientResponse } = await setClient({ variables: { object: setClientRequestData } });

      if (setClientResponse && setPropertyRequestData.appraisals) {
        const { contacts, id } = setClientResponse.insert_client_one;
        const ordered_by_contact_ids = contacts.map((item) => item.id);
        setPropertyRequestData.appraisals.data[0].client_id = id;
        setPropertyRequestData.appraisals.data[0].ordered_by_contact_ids = ordered_by_contact_ids;
      }
    }

    const { data: setPropertyResponse } = await setProperty({ variables: { object: setPropertyRequestData } });
    const appraisalId = setPropertyResponse?.insert_property_one.appraisals[0]?.id;
    if (appraisalId) {
      setActiveStep((prev) => prev + 1);
      handleFilesUpload(appraisalId);
    }
  };

  const showMore = contactByEmailData && contactByEmailData?.contact.length === 0;
  const stepperButtonLabel = activeStep === steps.length - 1 ? 'SUBMIT APPRAISAL ORDER' : 'Next Step';

  return (
    <Box width={'100%'}>
      <SimpleForm
        toolbar={<></>}
        initialValues={{
          contact_type_id: contactType?.id,
        }}
      >
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, key) => (
                  <Step key={label + key}>
                    <StepLabel>
                      <Typography className={classes.heading} variant="body1">
                        {label}
                      </Typography>
                    </StepLabel>
                    <StepContent>
                      <Grid container md={6} direction="column">
                        {activeStep === 0 && (
                          <ClientInformation
                            showMore={showMore}
                            contactType={contactType}
                            setContactType={setContactType}
                          />
                        )}

                        {activeStep === 1 && <Property />}

                        {activeStep === 2 && <OrderDetails orderData={orderData} setOrderData={setOrderData} />}

                        {activeStep === 3 && <Attachments setFiles={setFiles} />}

                        {activeStep === 4 && <Instructions />}
                      </Grid>

                      <Box className={classes.actionsContainer}>
                        <Box textAlign={'end'}>
                          {activeStep !== 0 && (
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              className={classes.button}
                              size="small"
                              color="primary"
                            >
                              Back
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            color={'primary'}
                            size="small"
                            onClick={() => {
                              stepperButtonLabel === 'Next Step' ? handleNext(formData.email) : handleFinish(formData);
                            }}
                          >
                            {stepperButtonLabel}
                          </Button>
                        </Box>
                      </Box>
                    </StepContent>
                  </Step>
                ))}

                {activeStep === steps.length && (
                  <OrderSuccess
                    handleReset={(form) => {
                      form.reset();
                      setActiveStep(0);
                      form.change('email', formData.email);
                      setContactType(null);
                      setOrderData(initialOrderData);
                      setFiles([]);
                    }}
                  />
                )}
              </Stepper>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Box>
  );
};

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles({
  divider: {
    marginBottom: '20px',
    width: '100%',
  },
  dividerBottom: {
    marginBottom: '5px',
    marginTop: '30px',
    width: '100%',
  },
  formContainer: {
    display: 'flow-root',
    width: '100%',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  sectionHeading: {
    marginTop: '30px',
  },
  heading: {
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  formBottom: {
    marginBottom: '35px',
  },
  pillsMargin: {
    marginBottom: '23px',
  },

  addBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    padding: '10px 5px 8px 0px',
  },
  addAssigneeBtn: {
    marginRight: '20px',
  },
  dateWrapper: {
    paddingRight: '5px',
    '@media (max-width: 960px)': {
      padding: '0px',
    },
  },
  timeWrapper: {
    paddingLeft: '5px',
    '@media (max-width: 960px)': {
      padding: '0px',
    },
  },
  chipButton: {
    borderRadius: '24px',
    marginRight: '16px',
    paddingRight: '16px',
    marginBottom: '8px',
  },

  chipStyle: {
    backgroundColor: '#e0e0e0',
    color: 'rgba(0, 0, 0, 0.87)',
    height: '32px',
    margin: '3px',
  },
  paper: {
    position: 'absolute',
    width: 520,
    backgroundColor: 'white',
    padding: '20px',
    top: `${47}%`,
    left: `${47}%`,
    transform: `translate(-${47}%, -${47}%)`,
    outline: 'none',
  },
  confirmBtnBox: {
    textAlign: 'right',
  },
});

import { Box, Card, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { styleRight } from 'shared/hooks/useEditFormStyle';

export const LoadingSkeleton = () => {
  const formClasses = styleRight();

  return (
    <Card variant="outlined" classes={{ root: `${formClasses.card}` }} style={{ marginTop: '30px' }}>
      <CardContent>
        <Box pl={2} pt={2}>
          <Skeleton />
          <Skeleton />
          <Skeleton width={200} />
        </Box>
      </CardContent>
    </Card>
  );
};

import React, { useState, useEffect, useMemo } from 'react';
import { BulkActionProps, List, ListProps, useGetIdentity, useListContext } from 'react-admin';
import { useListStyles } from 'shared/components/Resource/styles';
import ListActions from 'shared/components/Resource/ListActions';
import Filter from './Filter';
import GridComponent from './Grid';
import { elavatedRoles } from 'shared/constants/roles';
import { INDEX_LIST_PER_PAGE } from 'shared/constants/config';
import { createExporter } from 'shared/utils';
import { exportFields } from './fields';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import useMaxDimensions from 'shared/hooks/useMaxDimension';
import { useUserProfilesTotal } from 'shared/hooks/useTeamOptions';
import { Divider, Tab, Tabs } from '@material-ui/core';
import Empty from 'ra-ui-materialui/lib/list/Empty';

const TeamList = (props: ListProps): JSX.Element => {
  const classes = useListStyles();
  const { identity } = useGetIdentity();
  const exporter = React.useMemo(() => createExporter(exportFields, 'team'), []);
  return (
    <List
      {...props}
      exporter={exporter}
      perPage={INDEX_LIST_PER_PAGE}
      classes={classes}
      bulkActionButtons={false}
      filters={<Filter />}
      actions={<ListActions hasCreate={elavatedRoles.includes(identity?.role)} />}
      syncWithLocation
      filterDefaultValues={{ enabled: true }}
      sort={{ field: 'full_name', order: 'ASC' }}
      resource="user_profiles"
      hasCreate={false}
    >
      <TeamGrid {...props} />
    </List>
  );
};
export default TeamList;

type TabbedDatagridProps = ListProps & BulkActionProps;

function TeamGrid(props: TabbedDatagridProps) {
  const { filterValues } = props;

  const [, onChangeQuery] = useLocationQuery();
  const dimensions = useMaxDimensions();
  const listContext = useListContext();

  const total = useUserProfilesTotal();
  const { data }: any = total[0];
  const [tabs, setTabs] = useState([]);

  const alphabets = useMemo(() => {
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    const alphabet: any = ['All', ...alpha.map((x) => String.fromCharCode(x))];
    return alphabet;
  }, []);

  useEffect(() => {
    if (alphabets?.length !== 0) {
      const arr: any = ['All'];
      alphabets?.map((each: String) => {
        if (data) {
          Object.entries(data)?.map((item: any) => {
            if (each === item[0] && item[1]?.aggregate?.count !== 0) {
              arr.push(each);
            }
          });
        }
      });
      setTabs(arr);
    }
  }, [alphabets, data]);

  const handleChange = (event: any, newValue: any) => {
    onChangeQuery({
      filter: JSON.stringify({
        ...filterValues,
        full_name:
          newValue === 'All'
            ? ''
            : {
                format: 'raw-query',
                value: {
                  _similar: `(${newValue})%`,
                },
              },
      }),
    });
  };
  return (
    <div style={{ width: dimensions.width }}>
      <Tabs
        variant="scrollable"
        textColor="primary"
        indicatorColor="primary"
        value={filterValues?.full_name?.value?._similar?.charAt(1) || 'All'}
        onChange={handleChange}
        aria-label="scrollable auto tabs example"
        scrollButtons="auto"
      >
        {tabs?.length !== 0 &&
          tabs?.map((each: String, index: any) => (
            <Tab label={each} key={index} value={each} style={{ width: '50px', maxWidth: '50px', minWidth: '50px' }} />
          ))}
      </Tabs>
      <Divider />
      {listContext.total === 0 && <Empty resource="user_profiles" />}
      {listContext.total !== 0 ? <GridComponent /> : ''}
    </div>
  );
}

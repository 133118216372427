import { LazyQueryExecFunction } from '@apollo/client';
import { Box, Collapse, IconButton, Modal } from '@material-ui/core';
import Grid, { GridSize } from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Close, ExpandLess, ExpandMore, Warning } from '@material-ui/icons';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import L from 'leaflet';
import debounce from 'lodash/debounce';
import intersection from 'lodash/intersection';
import { FieldTitle } from 'ra-core';
import { TextInputProps } from 'ra-ui-materialui/lib/input/TextInput';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { InputHelperText, useInput } from 'react-admin';
import { Viewport } from 'react-leaflet';

import Map from 'shared/components/Map';
import { PropertyDataRequest, PropertyDataResponse } from 'shared/hooks/usePropertyData';
import usePlacesAutocomplete, { LatLng, PlaceType, geocodeByLatLng, getLatLng } from './usePlacesAutocomplete';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    // paddingTop: '5px',
    marginBottom: '15px',
  },
  showMapButton: {
    fontStyle: 'italic',
    fontSize: '14px',
    color: '#444444',
    cursor: 'pointer',
  },
  mapContainer: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    transition: 'height 3s ease-in-out',
  },
  mapContainerShow: {
    opacity: 1,
    height: 'auto',

    overflow: 'hidden',
    transition: 'height 2s ease-in-out',
  },
  mapBottomText: {
    fontStyle: 'italic',
    marginTop: '5px',
    marginBottom: '15px',
    fontSize: '16px',
    color: '#444444',
  },

  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow:
      '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
    padding: '1.5rem 2rem',
  },
  modalHeading: {
    fontWeight: 500,
    fontSize: '1.2rem',
    // marginTop: '30px',
  },
}));

function addPrefix(prefix: string, key: string): string {
  return [prefix, key].filter(Boolean).join('.');
}

type PlacesAutocompleteProps = Omit<TextInputProps, 'source'> & {
  source?: string;
  xlCols?: GridSize;
  isMapVisible?: boolean;
  allowToggleMap?: boolean;
  aside?: React.ReactNode;
  lgCols?: GridSize;
  prefix?: string;
  variant?: string | undefined;
  getPropertyData?: LazyQueryExecFunction<PropertyDataResponse, PropertyDataRequest>;
  setDirty?: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  isMapToggled?: boolean;
  isEditPage?: boolean;
};
export default function PlacesAutocomplete(props: PlacesAutocompleteProps) {
  const classes = useStyles(props);
  const {
    prefix = '',
    isMapVisible = true,
    allowToggleMap = false,
    isRequired: isRequiredOverride,
    helperText,
    validate,
    source = addPrefix(prefix, 'location_address'),
    height,
    showLabel = false,
    autoFocus = true,
    aside,
    label,
    xlCols = 12,
    lgCols,
    disabled,
    variant,
    getPropertyData,
    setDirty,
    isMapToggled,
    isEditPage = false,
  } = props;

  const {
    input: locationAddress,
    isRequired,
    meta: { touched, error },
  } = useInput({ source, validate });
  const { input: locationComponents } = useInput({ source: addPrefix(prefix, 'location_components') });
  const { input: locationGeography } = useInput({ source: addPrefix(prefix, 'location_geography') });
  const { input: locationPostalCode } = useInput({ source: addPrefix(prefix, 'location_postal_code') });
  const { input: locationCity } = useInput({ source: addPrefix(prefix, 'location_city') });
  const { input: locationCounty } = useInput({ source: addPrefix(prefix, 'location_county') });
  const { input: locationState } = useInput({ source: addPrefix(prefix, 'location_state') });
  const [viewPort, setViewPort] = React.useState<Viewport>(initializeViewPort);
  const [markers, setMarkers] = React.useState<{ coordinates: [number, number] }[]>(initializeMarkers);
  const debounceLocationAddressChange = useCallback(debounce(onLocationAddressChange, 3000), []);
  const [autoCompleteState, autoCompleteHandlers] = usePlacesAutocomplete({
    onGeocodeSuccess,
    initialValue: convertToPlaceType(locationAddress.value),
  });
  const { options, value, isLoading } = autoCompleteState;
  const { onChange, setInputValue } = autoCompleteHandlers;
  // const [isMapVisibleByDefault, setIsMapVisibleByDefault] = useState(isMapVisible);
  const [toggled, setToggled] = useState(false);
  const defaultChange = () => {
    // intential default empty function
    console.log(); // Empty console log to bypass lint error about empty function body
  };
  const onInputValChangeCB = props.onInputValChange || defaultChange;
  const onInputValChange = useCallback(debounce(onInputValChangeCB, 2000), []);
  const [loading, setLoading] = useState(true);
  const [allowModal, setAllowModal] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const getLabel = () => {
    if (label === ' ') {
      return '';
    } else if (label) {
      return label;
    } else {
      return 'Address';
    }
  };

  useEffect(() => {
    if (getPropertyData && value?.description) {
      getPropertyData({ variables: { address: value.description } });
    }
  }, [getPropertyData, value]);

  const onClose = () => {
    setIsOpen(false);
    setAllowModal(false);
    locationAddress.onFocus();
  };

  return (
    <>
      {!!getPropertyData && (
        <Modal
          open={isOpen && allowModal}
          onClose={onClose}
          aria-labelledby="modal-"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalContainer}>
            <Box display={'flex'}>
              <Box p={1}>
                <Warning fontSize="large" style={{ color: '#ff9966' }} />
              </Box>
              <Box ml={2} pt={1}>
                <Typography classes={{ root: classes.modalHeading }}>
                  We highly recommend that you type and select an address from the list of suggestions or manually find
                  and click the subject's parcel on the map. This will ensure accurate geocoding and allow for retrieval
                  of public data.
                </Typography>
              </Box>

              <Box>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}

      <div className={classes.container} style={{ marginBottom: isEditPage ? '0px' : 'auto' }}>
        <Grid container spacing={2}>
          <Grid
            style={{ paddingTop: showLabel ? '0px' : 'auto', paddingBottom: isEditPage ? '0px' : 'auto' }}
            spacing={0}
            alignItems="center"
            container
            item
            xl={aside !== null ? xlCols : 12}
            lg={lgCols || 12}
          >
            {showLabel && (
              <Box style={{ width: '30%' }}>
                <Typography style={{ position: 'absolute', top: '16px' }}>Address</Typography>
              </Box>
            )}
            <Box style={{ width: showLabel ? '70%' : '100%' }} id="address-container">
              <Autocomplete
                disabled={disabled}
                id="google-map"
                loading={isLoading}
                getOptionLabel={(option) => option?.description ?? ''}
                filterOptions={(x) => x}
                options={options}
                // autoComplete
                includeInputInList
                filterSelectedOptions
                freeSolo
                fullWidth
                value={value}
                onChange={(event, newValue: PlaceType | null | string) => {
                  if (!newValue) onReset();
                  if (typeof newValue === 'object') onChange(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setDirty?.((prev) => ({ ...prev, address: true }));
                  setInputValue(newInputValue);
                  debounceLocationAddressChange(newInputValue);
                  if (onInputValChange && loading === false) {
                    onInputValChange(newInputValue);
                  }
                }}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      onBlur={(e) => {
                        if (e.target.value.length > 2) {
                          setIsOpen(true);
                        }
                      }}
                      InputLabelProps={{ shrink: props.shrink }}
                      InputProps={{ ...params.InputProps, ...(props.InputProps ? props.InputProps : {}) }}
                      FormHelperTextProps={props.FormHelperTextProps}
                      variant={variant && variant === 'outlined' ? 'outlined' : 'standard'}
                      error={!!(touched && error)}
                      fullWidth
                      rowsMax={props.rows}
                      multiline={props.multiline}
                      autoFocus={autoFocus}
                      margin="dense"
                      label={
                        <FieldTitle
                          label={getLabel()}
                          isRequired={typeof isRequiredOverride !== 'undefined' ? isRequiredOverride : isRequired}
                        />
                      }
                      helperText={
                        error &&
                        helperText && (
                          <InputHelperText touched={Boolean(touched)} error={error} helperText={helperText} />
                        )
                      }
                    />
                    {allowToggleMap && (
                      <Box display={'flex'} justifyContent={'end'} alignItems={'center'} pr={0.5}>
                        {toggled ? <ExpandLess /> : <ExpandMore />}
                        <Typography
                          classes={{ root: classes.showMapButton }}
                          onClick={(e) => {
                            e.preventDefault();
                            setToggled((prev) => !prev);
                          }}
                        >
                          <u>{toggled ? 'Close Map' : 'Show Map to manually select parcel'}</u>
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
                renderOption={(option) => {
                  const matches = option.structured_formatting.main_text_matched_substrings ?? [];
                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [match.offset, match.offset + match.length]),
                  );

                  return (
                    <Grid container alignItems="center">
                      <Grid item>
                        <LocationOnIcon className={classes.icon} />
                      </Grid>
                      <Grid item xs>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                          </span>
                        ))}
                        <Typography variant="body2" color="textSecondary">
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
              />
            </Box>
          </Grid>

          {aside && aside}
        </Grid>
        <Collapse in={isMapVisible || toggled || isMapToggled}>
          <Map
            viewport={viewPort}
            onPin={onPin}
            markers={markers}
            onViewportChanged={(viewport: Viewport) => setViewPort(viewport)}
            height={height}
            allowPin={!disabled}
          />
        </Collapse>
      </div>
      {toggled && (
        <Typography classes={{ root: classes.mapBottomText }}>
          Can't find property by address? Manually select by clicking pen icon then selecting parcel
        </Typography>
      )}
    </>
  );

  async function onPin(result: LatLng) {
    setLocation(result);
    const [geocoded] = await Promise.all([geocodeByLatLng(result)]);
    const selected = geocoded[1];
    onChange({
      place_id: selected.place_id,
      description: selected.formatted_address,
      structured_formatting: {
        main_text: selected.address_components[0].short_name,
        secondary_text: selected.address_components[0].long_name,
        main_text_matched_substrings: [
          {
            length: 4,
            offset: 0,
          },
        ],
      },
    });
  }

  function onLocationAddressChange(value: string) {
    locationAddress.onChange(value);
  }

  function initializeViewPort() {
    const initialPosition = getCoordinates(locationGeography.value);
    return { center: initialPosition, zoom: 16 };
  }

  function initializeMarkers() {
    if (!locationGeography.value) {
      return [] as any;
    }
    const initialPosition = getCoordinates(locationGeography.value);
    return [
      {
        coordinates: initialPosition,
      },
    ];
  }

  function setLocation(result: LatLng) {
    const position: [number, number] = [result.lat, result.lng];
    setViewPort({
      center: position,
      zoom: Math.max(16, viewPort.zoom as number),
    });
    setMarkers([{ coordinates: position }]);
  }

  async function onGeocodeSuccess(results: google.maps.GeocoderResult[], placeType: PlaceType) {
    const latlng = await getLatLng(results[0]);
    setValues(results[0], latlng, placeType);
    setLocation(latlng);
  }

  function setValues(result: google.maps.GeocoderResult, latlng: LatLng, placeType?: PlaceType) {
    const { address_components: addressComponents } = result;
    locationPostalCode.onChange(addressComponents.find((e) => e.types.includes('postal_code'))?.long_name ?? '');
    locationState.onChange(
      addressComponents.find((e) => intersection(e.types, ['administrative_area_level_1']).length > 0)?.long_name ?? '',
    );
    locationCounty.onChange(
      addressComponents.find((e) => intersection(e.types, ['administrative_area_level_2']).length > 0)?.long_name ?? '',
    );
    locationCity.onChange(
      addressComponents.find((e) => intersection(e.types, ['locality']).length > 0)?.long_name ?? '',
    );
    if (placeType?.description) {
      locationAddress.onChange(placeType.description);
    } else {
      locationAddress.onChange(result.formatted_address);
    }
    const point = L.CRS.EPSG4326.project(latlng);
    locationGeography.onChange(
      JSON.stringify({
        type: 'Point',
        coordinates: [point.x, point.y],
      }),
    );
    locationComponents.onChange(result);
  }

  function onReset() {
    locationPostalCode.onChange('');
    locationState.onChange('');
    locationCounty.onChange('');
    locationCity.onChange('');
    locationAddress.onChange('');
    locationGeography.onChange(null);
    locationComponents.onChange(null);
  }
}

export function getCoordinates(data: { coordinates: [number, number] } | null): [number, number] {
  if (!data || !data.coordinates) {
    return [41.850033, -87.6500523];
  }
  const latlng = L.CRS.EPSG4326.unproject(new L.Point(data.coordinates[0], data.coordinates[1]));
  return [latlng.lat, latlng.lng];
}

function convertToPlaceType(formattedAddress: string): PlaceType | null {
  if (!formattedAddress) {
    return null;
  }
  return {
    place_id: '',
    description: formattedAddress,
    structured_formatting: {
      main_text: '',
      secondary_text: '',
      main_text_matched_substrings: [
        {
          length: 4,
          offset: 0,
        },
      ],
    },
  };
}

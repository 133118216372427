import { gql, useMutation } from '@apollo/client';

const MESSAGE_THREAD_UNSUBSCRIBE = gql`
  mutation MessageThreadUnsubscribe($messageThreadId: String, $participantId: String, $participantType: String) {
    message_thread_unsubscribe(
      args: { message_thread_id: $messageThreadId, participant_id: $participantId, participant_type: $participantType }
    ) {
      id
    }
  }
`;

export const useMessageThreadUnsubscribeMutation = () => {
  return useMutation(MESSAGE_THREAD_UNSUBSCRIBE);
};

const MESSAGE_THREAD_SUBSCRIBE = gql`
  mutation MessageThreadSubscribe($messageThreadId: String, $participantId: String, $participantType: String) {
    message_thread_subscribe(
      args: { message_thread_id: $messageThreadId, participant_id: $participantId, participant_type: $participantType }
    ) {
      id
    }
  }
`;

export const useMessageThreadSubscribeMutation = () => {
  return useMutation(MESSAGE_THREAD_SUBSCRIBE);
};
